<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>Status of Request:</h1>
        </div>

        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
      <component :is="applicationHero"> </component>
    </div>
  </div>
</template>
<script>
import "animate.css";
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
import HeroPaymentStatus from "@/components/common/HeroPaymentStatus.vue";
import HeroLeaveStatus from "@/components/common/HeroLeaveStatus.vue";
import HeroLiquidationStatus from "@/components/common/HeroLiquidationStatus.vue";
import HeroTravelStatus from "@/components/common/HeroTravelStatus.vue";
import HeroConferenceStatus from "@/components/common/HereConferenceStatus.vue";
import HeroOvertimeStatus from "@/components/common/HeroOvertimeStatus.vue";
import HeroOfficialBusinessStatus from "@/components/common/HeroOfficialBusinessStatus.vue";
import { onMounted, ref } from "@vue/runtime-core";
import { inject } from "vue";
export default {
  components: {
    closeIconSVG,
    HeroPaymentStatus,
    HeroLeaveStatus,
    HeroLiquidationStatus,
    HeroTravelStatus,
    HeroConferenceStatus,
    HeroOvertimeStatus,
    HeroOfficialBusinessStatus,
  },
  props: {
    approvedBy: String,
    applicationId: Number,
    applicationType: String,
  },

  setup(props, { emit }) {
    const store = inject("$store");
    const applicationHero = ref(null);
    const goRoute = (val) => {
      emit("passModal", val);
    };
    onMounted(() => {
      selectedApplication();
    });
    const selectedApplication = () => {
      let value;

      if (props.applicationType === "requestpayment") {
        value = "HeroPaymentStatus";
      } else if (props.applicationType === "requestleave") {
        value = "HeroLeaveStatus";
      } else if (props.applicationType === "requesttravel") {
        value = "HeroTravelStatus";
      } else if (props.applicationType === "requestreservation") {
        value = "HeroConferenceStatus";
      } else if (props.applicationType === "requestovertime") {
        value = "HeroOvertimeStatus";
      } else if (props.applicationType === "requestob") {
        value = "HeroOfficialBusinessStatus";
      } else {
        value = "HeroLiquidationStatus";
      }

      store.state.applicationId = props.applicationId;
      applicationHero.value = value;
    };

    return {
      goRoute,
      selectedApplication,
      applicationHero,
    };
  },
};
</script>
<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 20px;
  color: var(--lightFont);
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 24px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.route {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .header-card {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 24px 0px;
  }
  .title h1 {
    font-size: 16px;
  }
  .modal {
    font-family: "Montserrat";
    background-color: #fff;
    width: 380px;
    max-width: 100%;
    padding: 30px 10px;
  }
}
</style>

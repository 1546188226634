<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <div class="page-container">
      <SectionEmployeeProfile :employeeProfileDetails="employeeDetails" />
      <SectionNavigationProfile @currentComponent="selectedComponent" />
      <hr />
      <section>
        <component
          :is="activeComponent"
          :employeeProfileDetails="employeeDetails"
        ></component>
      </section>
    </div>
  </div>
</template>
<script>
import { onMounted, inject, ref } from "vue";
import SectionEmployeeProfile from "@/components/pages/admin/Employees/masterlist/SectionEmployeeProfile.vue";
import SectionNavigationProfile from "@/components/pages/admin/Employees/masterlist/SectionNavigationProfile.vue";
import indexProfile from "@/components/pages/admin/Employees/masterlist/SectionIndexProfile.vue";
import indexEmployment from "@/components/pages/admin/Employees/masterlist/SectionEmployeeEmployment.vue";
import indexAttedance from "@/components/pages/admin/Employees/masterlist/SectionEmployeeAttendance.vue";
export default {
  components: {
    SectionEmployeeProfile,
    SectionNavigationProfile,
    indexProfile,
    indexEmployment,
    indexAttedance,
  },
  setup() {
    const store = inject("$store");
    const employeeDetails = ref([]);
    const activeComponent = ref("indexProfile");
    onMounted(() => {
      userProfile();
    });
    const userProfile = () => {
      employeeDetails.value = store.state.getProfileInfo;
    };
    const selectedComponent = (val) => {
      activeComponent.value = val;
    };
    return {
      userProfile,
      employeeDetails,
      selectedComponent,
      activeComponent,
    };
  },
};
</script>
<style scoped>
.card-one {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  font-family: "Montserrat";
  color: rgb(94, 92, 92);
  margin: 10px 0px;
}
.setting-header {
  padding: 15px 15px 10px 15px;
}
.setting-container {
  display: flex;
  justify-content: space-between;
}
.setting-title {
  display: flex;
  gap: 10px;
}
.setting-title h1 {
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 800;
  padding-top: 1px;
}
.header-content {
  display: flex;
  justify-content: space-between;
}
.route-menu,
.setting-icon {
  cursor: pointer;
}
.wrapper-content {
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .wrapper-content {
    margin: 16px 4px 0px 4px;
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import moment from "moment";
import "../src/assets/css/global.css";
import "../src/assets/css/admin.css";
import TopHeader from "./components/common/HeroTopHeader.vue";
import FooterHeader from "./components/common/HeroFooterHeader.vue";
import CardHeader from "./components/common/HeroCardHeader.vue";
import AuthGrid from "./components/common/HeroAuthGrid.vue";
import AuthCardProfile from "./components/common/HeroAuthProfile.vue";
import AuthCardNav from "./components/common/HeroAuthNav.vue";
import AuthCardDashboardHeader from "./components/common/HeroAuthDashboardHeader.vue";
import AuthCardDashboardContent from "./components/common/HeroAuthDashboardContent.vue";
import AuthTitleHeader from "./components/common/HeroAuthTitleHeader.vue";
import AuthPaymentActivity from "./components/common/HeroPaymentActivity.vue";
import LoadingHeader from "./components/common/HeroLoading.vue";
import FormHeader from "./components/common/HeroFormHeader.vue";
import ModalPopUp from "./components/common/HeroStatusModal.vue";
import ModalApproval from "./components/common/HeroApprovalModal.vue";
import ResponsiveNav from "./components/common/HeroSideBarModal.vue";
import ModalCalendar from "./components/common/HeroAddEventModal.vue";
import AuthCardTravelDashboard from "./components/partials/dashboard/HeroAuthTravelDashboard.vue";
import AuthCardDriverDashboard from "./components/partials/dashboard/HeroAuthDriverDashboard.vue";
import AuthCardConferenceDashboard from "./components/partials/dashboard/HeroAuthConferenceDashboard.vue";
import AuthCardLeaveDashboard from "./components/partials/dashboard/HeroAuthLeaveDashboard.vue";
import ModalTravelApproval from "./components/common/HeroApprovalTravelModal.vue";
import ModalConferenceApproval from "./components/common/HeroApprovalConferenceModal.vue";
import ModalLeaveApproval from "./components/common/HeroApprovalLeaveModal.vue";
import ModalOvertimeApproval from "./components/common/HeroApprovalOvertimeModal.vue";
import ModalOfficialBusinessApproval from "./components/common/HeroApprovalOfficialBusinessModal.vue";
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";
import "animate.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import JsonExcel from "vue-json-excel3";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = localStorage.getItem(
      `${process.env.VUE_APP_AUTH_TOKEN}_XXX`
    );
    store.state.getRoleUser = localStorage.getItem(
      `${process.env.VUE_APP_USER_ROLE}_XXX`
    );
    store.state.getUserType = localStorage.getItem(
      `${process.env.VUE_APP_USER_TYPE}_XXX`
    );
    store.state.getProfilePath = localStorage.getItem(
      `${process.env.VUE_APP_PROFILE_IMAGE}_XXX`
    );
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const app = createApp(App);

app.component("app-header", TopHeader);
app.component("app-footer", FooterHeader);
app.component("app-card", CardHeader);
app.component("app-auth-grid", AuthGrid);
app.component("app-auth-profile", AuthCardProfile);
app.component("app-auth-nav", AuthCardNav);
app.component("app-auth-dashboard-header", AuthCardDashboardHeader);
app.component("app-auth-dashboard-content", AuthCardDashboardContent);
app.component("app-auth-dashboard-travel", AuthCardTravelDashboard);
app.component("app-auth-dashboard-driver", AuthCardDriverDashboard);
app.component("app-auth-dashboard-conference", AuthCardConferenceDashboard);
app.component("app-auth-dashboard-leave", AuthCardLeaveDashboard);
app.component("app-auth-title", AuthTitleHeader);
app.component("app-modal-payment-status", AuthPaymentActivity);
app.component("app-modal", ModalPopUp);
app.component("app-responsive-sidebar", ResponsiveNav);
app.component("app-loading", LoadingHeader);
app.component("app-form-header", FormHeader);
app.component("app-modal-approval", ModalApproval);
app.component("app-modal-travel-approval", ModalTravelApproval);
app.component("app-modal-conference-approval", ModalConferenceApproval);
app.component("app-modal-leave-approval", ModalLeaveApproval);
app.component("app-modal-overtime-approval", ModalOvertimeApproval);
app.component("app-modal-ob-approval", ModalOfficialBusinessApproval);
app.component("app-modal-calendar", ModalCalendar);
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
app.component("downloadExcel", JsonExcel);

app.use(store);
app.use(router);
app.use(setupCalendar, {});
app.provide("$axios", axios);
app.provide("$store", store);
app.provide("$router", router);
app.provide("$moment", moment);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDwYYhQEcBZw0qLW3GlJ6VHAeNGsXhq_O0",
    libraries: "places",
  },
});
app.mount("#app");

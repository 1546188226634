<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="page-container">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div v-if="isRender" class="waiting">
        <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
      </div>
      <div class="dataTable" v-else>
        <table>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
              <th scope="col">Date</th>
              <th scope="col">IP Address</th>
              <th scope="col">Device</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filterItem" :key="index">
              <td data-label="Name">{{ item.User.name }}</td>
              <td data-label="Action">{{ item.action }}</td>
              <td data-label="Date">{{ dateFormat(item.createdAt) }}</td>
              <td data-label="IP Address">{{ item.ipAddress }}</td>
              <td data-label="Device">
                {{ !item.device ? "-" : item.device }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <div class="previous">
          <input
            type="submit"
            class="prev-btn btn"
            value="Previous"
            @click="prevRecords(current_page)"
          />
        </div>

        <div class="pages">
          <span
            >Page {{ total_record ? current_page : 0 }} of
            {{ total_page }}
          </span>
        </div>
        <div class="next">
          <input
            :disabled="total_page === current_page ? true : false"
            type="submit"
            class="nxt-btn btn"
            value="Next"
            @click="nextRecords(current_page)"
          />
        </div>
      </div>
    </div>

    <div class="auth-grid"></div>
  </div>
</template>
<script>
import { inject, ref, computed, onActivated } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const showEntries = ref([10, 25, 50]);
    const selectedEntry = ref(10);
    const itemActivity = ref([]);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const activityItemList = ref([]);
    const filterItem = computed(() => {
      return modified.value;
    });
    const isRender = ref(true);
    onActivated(async () => {
      getActivityLog();
    });
    const getActivityLog = async () => {
      try {
        const { data } = await axios.get(`/api/userlogs/`);
        itemActivity.value = data;
        getFilterAction();
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("MMMM DD YYYY, h:mm:ss a") : "-";
    };
    const pagination = (page, pr_page) => {
      let data = activityItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 10;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;

      total_record.value = data.length;
    };
    const getFilterAction = (val) => {
      let selectedEntry = val;
      activityItemList.value = itemActivity.value.filter((post) => {
        let getAction = post.action ? post.action : "";
        return (
          getAction.toLowerCase().includes(searchValue.value.toLowerCase()) ||
          post.User.name.toLowerCase().includes(searchValue.value.toLowerCase())
        );
      });

      pagination(0, selectedEntry);
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    return {
      itemActivity,
      showEntries,
      selectedEntry,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      searchValue,
      activityItemList,
      filterItem,
      prevRecords,
      nextRecords,
      getActivityLog,
      getFilterAction,
      dateFormat,
      pagination,
      isRender,
    };
  },
};
</script>
<style scoped>
.page-container {
  font-size: 15px;
  padding: 18px;
}
.pagination {
  padding: 20px 0px 20px 0px;
}
.show-entries {
  padding: 20px 0px 20px 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    background-color: #ffffff;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>

<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="admin-grid">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="filter">
        Filter by date requested:
        <select
          v-model="selectedFilter"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showFilterDate" :key="item">
            {{ item }}
          </option>
        </select>
        <select
          v-show="true"
          v-model="selectedStatus"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterStat" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="payment-grid">
        <div v-if="isNotFound">
          <h3>{{ messageNotFound }}</h3>
        </div>
        <div v-if="isRender" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <div v-else v-for="(transaction, index) in filterItem" :key="index">
          <div class="admin-card accordion">
            <div style="display: flex">
              <div class="accordion-content">
                <div>
                  <h4>{{ transaction.payee }}</h4>
                </div>
                <div>
                  <span class="card-date"
                    >Date: {{ dateFormat(transaction.dateFiled) }}</span
                  >
                </div>
                <div>
                  <span class="card-status">
                    Status:
                    <span
                      class="dot"
                      :class="[
                        transaction.statusProcessed === 'Forwarded to Treasury'
                          ? 'status-pending'
                          : 'status-approved',
                      ]"
                    ></span>
                    {{
                      transaction.statusProcessed === "Forwarded to Treasury"
                        ? "Pending"
                        : transaction.statusProcessed
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div>
              <input
                @click="routeModal(index)"
                type="submit"
                :value="amountFormat(transaction.total)"
                class="btn-amount"
              />
            </div>
          </div>
          <div
            class="panel"
            v-if="index === indexSelected && ifExpand ? true : false"
          >
            <SectionPaymentStatus
              :transaction="transaction"
            ></SectionPaymentStatus>
            <div class="panel-payment-details">
              <SectionPaymentDetails
                :transaction="transaction"
              ></SectionPaymentDetails>
              <div class="btn-action-container">
                <input
                  v-if="actionApprover(transaction.dtprocessed)"
                  class="btn btn-action"
                  type="submit"
                  value="Action"
                  @click="isModal = true"
                />
                <input
                  class="btn btn-close"
                  type="submit"
                  value="Close"
                  @click="ifExpand = false"
                />
              </div>
              <div v-if="isModal">
                <app-modal-approval
                  :requestId="transaction.id"
                  :amountToApproved="transaction.total"
                  :ifRecurring="transaction.ifrecurring"
                  @passClose="getValue"
                ></app-modal-approval>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-record">
          <div>Total Record(s): {{ total_record }}</div>
          <div></div>
          <div>Total Amount: {{ amountFormat(total_amount) }}</div>
        </div>
        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionPaymentDetails from "@/components/partials/payments/AuthPaymentDetails.vue";
import SectionPaymentStatus from "@/components/partials/payments/AuthPaymentStatusTracker.vue";
import { ref, computed, onActivated } from "vue";
import { inject } from "vue";
export default {
  components: {
    SectionPaymentDetails,
    SectionPaymentStatus,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const ifExpand = ref(false);
    const indexSelected = ref(0);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const messageNotFound = ref("");
    const isNotFound = ref(false);
    const showEntries = ref([5, 10, 25, 50]);
    const isRender = ref(true);
    const showFilterDate = ref([
      "Today",
      "Last 7 Days",
      "This Month",
      "Last Month",
      "Year to Date",
      "All Time",
    ]);
    const showFilterStatus = ref(["All", "Pending", "Released"]);
    const selectedEntry = ref(5);
    const total_amount = ref(0);
    const transactions = ref([]);
    const paymentItemList = ref([]);
    const selectedFilter = ref("All Time");
    const selectedStatus = ref("Pending");
    const expandPanel = ref(false);
    const filterItem = computed(() => {
      return modified.value;
    });
    const filterStat = computed(() => {
      return showFilterStatus.value;
    });
    onActivated(() => {
      selectedStatus.value =
        store.state.getUserType === "super" ? "Pre-approved" : "Pending";
      getPaymentRequest();
    });

    const routeModal = (val) => {
      indexSelected.value = val;

      ifExpand.value = !ifExpand.value;
    };
    const actionApprover = (val) => {
      return !val ? true : false;
    };
    const getValue = (
      val,
      payload,
      reqId,
      status,
      approver,
      approverDate,
      remarks,
      dvNumber,
      chckNumber,
      dtprepared,
      dtprocessed
    ) => {
      isModal.value = val;

      if (payload) {
        setTimeout(async () => {
          let itemData;
          itemData = transactions.value.find((val) => {
            return val.id === reqId;
          });

          let accountingStat = status;

          if (status === "Released") {
            status = "Approved";
          }

          itemData.statusRequest = status;
          itemData.statusProcessed = accountingStat;

          itemData.dtprocessed = dtprocessed;

          getFilterAction();
          store.commit("notificationCounter", transactions.value);
          expandPanel.value = false;
          ifExpand.value = false;

          /*getPaymentRequest();
           */
        }, 500);
      }
    };
    const totalAmountComputation = (data) => {
      total_amount.value = data.reduce(function (accumulator, curValue) {
        return accumulator + curValue.total;
      }, 0);
      total_record.value = data.length;
    };
    const getFilterAction = (val) => {
      let selectedEntry = val;
      paymentItemList.value = transactions.value
        .filter((post) => {
          let payee = post.payee;
          let referenceNumber = post.id + 1000000;
          let dvNumber = post.dvNumber ? post.dvNumber : "";
          let checkNumber = post.checkNumber ? post.checkNumber : "";
          referenceNumber = referenceNumber.toString();
          return (
            payee.toLowerCase().includes(searchValue.value.toLowerCase()) ||
            post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            post.dateFiled
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            dvNumber.toLowerCase().includes(searchValue.value.toLowerCase()) ||
            checkNumber
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            referenceNumber
              .toLowerCase()
              .includes(searchValue.value.toLowerCase())
          );
        })
        .filter((val) => {
          let dateSelected;
          const currentDate = store.state.currentDate;
          const lastSevenDays = moment(currentDate)
            .subtract(7, "days")
            .format("YYYY-MM-DD");

          const thisMonth = moment(currentDate)
            .subtract(moment().format("DD"), "days")
            .format("YYYY-MM-DD");

          const lastMonth = moment(currentDate)
            .subtract(1, "months")
            .format("YYYY-MM");

          const yearDate = moment(currentDate).format("YYYY");

          switch (selectedFilter.value) {
            case "Today":
              dateSelected = val.dateFiled === currentDate;
              break;
            case "Last 7 Days":
              dateSelected = val.dateFiled > lastSevenDays;
              break;
            case "This Month":
              dateSelected = val.dateFiled > thisMonth;
              break;
            case "Last Month":
              dateSelected =
                moment(val.dateFiled).format("YYYY-MM") === lastMonth;
              break;
            case "Year to Date":
              dateSelected = moment(val.dateFiled).format("YYYY") === yearDate;
              break;
            default:
              dateSelected = val;
          }

          return dateSelected;
        })
        .filter((role) => {
          let userLogIn = store.state.getUserType;
          //let userPosition = store.state.getDesignation;
          let userType;
          let expenseCategory = role.expenseDetails[0].name;

          switch (userLogIn) {
            case "distributed-moderator":
              if (
                expenseCategory !== "UTILIZATION (DOCTORS)" &&
                expenseCategory !== "UTILIZATION (HOSPITALS)" &&
                expenseCategory !== "UTILIZATION (CLINICS)" &&
                expenseCategory !== "UTILIZATION (SUBSIDY)" &&
                expenseCategory !== "UTILIZATION (CASH BOND)"
              ) {
                userType =
                  role.dtprepared && role.statusProcessed !== "Cancelled";
              }
              break;
            case "dispatch-moderator":
              userType =
                role.dtprepared &&
                (expenseCategory === "UTILIZATION (HOSPITALS)" ||
                  expenseCategory === "UTILIZATION (CLINICS)" ||
                  expenseCategory === "UTILIZATION (DOCTORS)" ||
                  expenseCategory === "UTILIZATION (SUBSIDY)" ||
                  expenseCategory === "UTILIZATION (CASH BOND)");
              break;
            default:
              userType;
          }
          return userType;
        })
        .filter((stat) => {
          let status;

          if (selectedStatus.value === "All") {
            status = stat;
          } else if (selectedStatus.value === "Pending") {
            status = stat.statusProcessed === "Forwarded to Treasury";
          } else {
            status = stat.statusProcessed === selectedStatus.value;
          }

          return status;
        });
      pagination(0, selectedEntry);
    };
    const pagination = (page, pr_page) => {
      let data = paymentItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 5;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;
      isNotFound.value = !modified.value.length ? true : false;

      totalAmountComputation(data);

      messageNotFound.value = searchValue.value
        ? `We couldn't find anything for ${searchValue.value.toLowerCase()}. Try a new search.`
        : `No transactions yet. Want to try again with different dates?`;
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const getPaymentRequest = async () => {
      try {
        const response = await axios.get(`api/form/payrequest`);
        isNotFound.value = response.data.length ? false : true;
        transactions.value = response.data;
        store.commit("notificationCounter", response.data);
        getFilterAction();
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const amountFormat = (val) => {
      return val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("ll") : "-";
    };
    const generateReference = (val) => {
      return 1000000 + val;
    };

    return {
      isModal,
      showEntries,
      selectedEntry,
      searchValue,
      transactions,
      paymentItemList,
      selectedStatus,
      showFilterStatus,
      ifExpand,
      indexSelected,
      filterItem,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      messageNotFound,
      isNotFound,
      total_amount,
      selectedFilter,
      showFilterDate,
      expandPanel,
      filterStat,
      isRender,
      prevRecords,
      nextRecords,
      actionApprover,
      getFilterAction,
      totalAmountComputation,
      routeModal,
      pagination,
      getPaymentRequest,
      amountFormat,
      dateFormat,
      generateReference,
      getValue,
    };
  },
};
</script>
<style scoped>
.status-approved {
  background-color: #1d9405;
}
.status-pending {
  background-color: #fab700;
}
</style>

<template>
  <div class="auth-card">
    <div class="auth-card-profiles">
      <div class="profile">
        <img
          class="profile-round"
          :src="$store.state.getProfilePath"
          alt="Profile"
        />
        <div
          class="ring-container"
          v-show="true"
          @mouseover="isHover = true"
          @mouseleave="isHover = false"
        >
          <div
            class="badge"
            :class="[$store.state.getProfileInfo.ifDtr ? 'online' : 'offline']"
          ></div>
          <span v-if="isHover" class="tooltiptext">
            {{ status($store.state.getProfileInfo) }}
          </span>
        </div>
      </div>
      <div class="details">
        <div>
          <h2>{{ $store.state.getUserLog }}</h2>
        </div>
        <div>
          <h4>{{ $store.state.getDesignation }}</h4>
        </div>
        <div class="toggle-content">
          <span class="label-text">Switch as Employee</span>
          <label class="toggle">
            <input
              type="checkbox"
              id="switch"
              v-model="isActive"
              @change="toggleSwitch"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";

export default {
  name: "LandingPage",
  components: {},
  setup() {
    const store = inject("$store");
    const moment = inject("$moment");
    const isActive = ref(null);
    const isHover = ref(false);
    const toggleSwitch = () => {
      if (isActive.value) {
        let userAccount = "admin";
        store.commit("switchAccountHandler", userAccount);
      }
    };
    const status = (val) => {
      let stat = val.ifDtr;
      let date = val.dtrDate;
      let time = val.dtrTime;
      let dateLogged = `${date} ${time}`;
      let statusMessage;
      let datelog = moment(dateLogged).format("llll");
      if (stat) {
        statusMessage = `Time-in: ${datelog}`;
      } else {
        if (val.dtrDate) {
          statusMessage = `Time-out: ${datelog}`;
        } else {
          if (store.state.getUserType === "super") {
            statusMessage = `Work status: Remote`;
          } else {
            statusMessage = `Work status: Offline`;
          }
        }
      }

      return statusMessage;
    };
    return {
      toggleSwitch,
      isActive,
      isHover,
      status,
    };
  },
};
</script>
<style scoped>
.auth-card {
  height: 150px;
}
.auth-card-profiles {
  display: flex;
  column-gap: 30px;
}
.profile {
  position: relative;
  top: 30px;
  bottom: 0;
  left: 16px;
  right: 0;
}
.details {
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  line-height: 25px;
}
.details h2 {
  font-size: 15px;
  font-weight: 600;
}
.details h4 {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
}
img {
  height: 90px;
}
.label-text {
  font-size: 13px;
  font-weight: 500;
  padding: 2px 0px;
}
.toggle-content {
  display: flex;
  align-content: center;
  line-height: 16px;
  gap: 5px;
}
.active {
  background-color: #1d9405;
  position: absolute;
  bottom: 78px;
  right: -2px;
}
.dot {
  height: 18px;
  width: 18px;
}
.badge {
  position: absolute;
  height: 16px;
  width: 16px;
  left: 68px;
  top: -32px;
}
.tooltiptext {
  min-width: 150px;
  background-color: rgb(116, 114, 114);
  text-align: center;
  padding: 6px;
  border-radius: 6px;
  color: rgb(255, 252, 252);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: -0px;
  right: -20px;
}
@media only screen and (max-width: 600px) {
  .profile img {
    height: 80px;
  }
  .auth-card {
    height: 150px;
  }
  .auth-card-profiles {
    display: flex;
    column-gap: 25px;
  }
  .profile {
    position: relative;
    top: 32px;
    bottom: 0;
    left: 16px;
    right: 0;
  }
  .details {
    justify-content: center;
    align-items: center;
    margin-top: 38px;
    line-height: 25px;
  }
  .details h2 {
    font-size: 13px;
    font-weight: 600;
  }
  .details h4 {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
  }
  .label-text {
    font-size: 11px;
    font-weight: 500;
    padding: 2px 0px;
  }
  .toggle-content {
    display: flex;
    align-content: center;
    line-height: 16px;
    gap: 5px;
  }
  .badge {
    left: 60px;
    top: -30px;
  }

  .tooltiptext {
    z-index: 1;
    bottom: -0px;
    right: -50px;
  }
}
</style>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>

    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label for=""> Date Filed: </label>
          <div class="input">
            <input type="date" v-model="formData.dateFiled" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Date Intended: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="formData.dateIntended" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Start Time: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="time"
              v-model="formData.timeStart"
              step="any"
              @input="autoComputeTime"
            />
          </div>
        </div>
        <div class="form-group">
          <label for=""> End Time: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="time"
              v-model="formData.timeEnd"
              step="any"
              @input="autoComputeTime"
            />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Purpose: <span class="required">*</span> </label>
          <div class="input">
            <select
              v-model="formData.purpose"
              @change="getSeverity(formData.purpose)"
            >
              <option v-for="list in reservationType" :key="list.id">
                {{ list.description }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="flex">
            <label for=""> Priority: <span class="required">*</span> </label>
            <span class="tooltip">
              <img src="../../assets/images/help-me.png" alt="expand" />
              <span class="tooltiptext">
                Priority level refers to the importance and urgency.
                <ul style="padding-left: 20px">
                  <li>High - Important and urgent</li>
                  <li>Medium - Important, but not urgent</li>
                  <li>Low - Not important and not urgent</li>
                </ul>
              </span>
            </span>
          </div>
          <div class="input">
            <select v-model="selectedList">
              <option v-for="item in listPriority" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Remarks: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="6" v-model="formData.remarks" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Requested By: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="formData.requestedBy">
              <option v-for="name in empName" :key="name.id">
                {{ name.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <button class="submit">Submit</button>
        </div>
        <div v-show="isAlert">
          <div v-show="isStatus" class="alert-error form-alert">
            {{ $store.state.formErrorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { inject } from "vue";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const listPriority = ref(["HIGH", "MEDIUM", "LOW"]);
    const selectedList = ref("LOW");
    const isAlert = ref(false);
    const isStatus = ref(false);
    const empName = ref([]);
    const reservationType = ref([]);
    const severityStatus = ref(null);
    const formData = reactive({
      employeeId: store.state.getEmployeeId,
      dateFiled: moment().format("YYYY-MM-DD"),
      dateIntended: moment().format("YYYY-MM-DD"),
      timeStart: moment().format("HH:mm"),
      timeEnd: moment().format("HH:mm"),
      noOfHours: 0,
      purpose: null,
      severity: selectedList.value,
      remarks: null,
      requestedBy: store.state.getUserLog,
      department: store.state.getDepartment,
    });

    const handleSubmit = async () => {
      handleValidation(formData);
    };

    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          formData.dateIntended = moment().format("YYYY-MM-DD");
          formData.timeStart = moment().format("HH:mm");
          formData.timeEnd = moment().format("HH:mm");
          formData.purpose = null;
          formData.severity = null;
          formData.remarks = null;
        }, 1100);
      }
    };

    const handleValidation = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "noOfHours") {
          if (!value) {
            validate.push(key);
          }
        }
      });
      if (validate.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            insertFormData();
            emit("isLoaded");
          }
        });
      }
    };

    const insertFormData = async () => {
      try {
        await axios.post(`/api/form/conferenceRequest/`, formData);
        isAlert.value = false;
        const SEQUENCE_ARRAY_CODE = 3;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearFormField(true);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getUsers = async () => {
      try {
        const { data } = await axios.get("/api/getUsers");
        empName.value = data.filter((user) => {
          return user.department === store.state.getDepartment;
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getReservations = async () => {
      try {
        const { data } = await axios.get("/api/form/ReservationType");
        reservationType.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getSeverity = (recurring) => {
      const result = reservationType.value.filter((reservation) => {
        return reservation.description === recurring;
      });
      selectedList.value = result[0].severity;
      formData.severity = selectedList.value;
    };

    const autoComputeTime = () => {
      let start = `${formData.dateIntended} ${formData.timeStart}`;
      let end = `${formData.dateIntended} ${formData.timeEnd}`;
      let hrs = moment(start) - moment(end);
      //let min = moment(end) - moment(start);
      formData.noOfHours = `${8 - moment(hrs).format("H")}`;
    };
    onMounted(async () => {
      try {
        getUsers();
        getReservations();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    });

    return {
      listPriority,
      formData,
      handleSubmit,
      clearFormField,
      insertFormData,
      handleValidation,
      isAlert,
      isStatus,
      getUsers,
      empName,
      autoComputeTime,
      getReservations,
      reservationType,
      severityStatus,
      getSeverity,
      selectedList,
    };
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.destination {
  padding-top: 20px;
}

.form1 .autotext .form-group {
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  box-sizing: border-box;
  gap: 5px;
}
.form1 .autotext .form-group select {
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 60.5%;
}
.form1 .autotext .form-group input {
  min-width: 29.5%;
}
.grid-travel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.btn-add {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 20px 0px;
}
.tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltiptext {
  visibility: hidden;
  min-width: 280px;
  background-color: rgb(116, 114, 114);
  text-align: left;
  padding: 5px;
  border-radius: 6px;
  font-size: 13px;
  color: rgb(255, 252, 252);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}
</style>

<template>
  <div class="card-container-header">
    <div v-for="(form, index) in forms" :key="index">
      <div class="card">
        <h3>{{ form.title }}</h3>
        <div class="card-icons">
          <div>
            <img
              @click="goRoute(form.component, form.name)"
              :src="require(`@/assets/images/icons/${form.icon}`)"
              alt="icon"
            />
          </div>
          <span v-if="selectedNotif.includes(form.name)" class="badge-icon">{{
            form.counter
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import formList from "@/components/utils/reusables/formList";
export default {
  setup(props, { emit }) {
    const store = inject("$store");
    const { forms, selectedNotif, formNotificationHandler } = formList();
    formNotificationHandler();
    const goRoute = (val, pay) => {
      store.state.isVisible = true;
      store.state.getTitleHeaderForm = pay;
      emit("passLoad", val);
    };

    return {
      forms,
      selectedNotif,
      goRoute,
      formNotificationHandler,
    };
  },
};
</script>

<style scoped>
.card-container-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 40px 0px 40px 0px;
}
.card {
  padding: 20px 20px;
  border-radius: 15px;
  min-height: 200px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  color: var(--lightFont);
}
.card-icons {
  padding: 35px 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
h3 {
  padding-top: 20px;
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
}
img {
  height: 90px;
}
.card-icons .badge-icon {
  position: relative;
  padding: 5px 10px;
  border-radius: 50%;
  top: -40px;
  left: -28px;
  background-color: #f33514;
  color: #fff;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .card-container-header {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .card-container-header {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .card-container-header {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .card-container-header {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>

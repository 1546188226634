<template>
  <div class="setting-body">
    <div class="notif-row-one" v-for="(item, index) in itemList" :key="index">
      <div class="flex-card">
        <div class="title">
          <h4>{{ item.title }}</h4>
        </div>
        <div class="description">
          <img
            v-show="item.isDownload"
            style="cursor: pointer"
            src="../../assets/images/download_.png"
            alt="expand"
            @click="download(item.attachment)"
          />
        </div>
      </div>
      <div class="notif-details">{{ item.publish }}</div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { inject } from "vue";
export default {
  setup() {
    const store = inject("$store");
    const axios = inject("$axios");
    const itemList = ref([
      {
        title: "How to change profile picture?",
        attachment: "DAP_ATTENDANCE_GUIDLINES.pdf",
        publish: `Go to Account Setting > General Information > Edit`,
        isDownload: false,
      },
      {
        title: "How to check my remaining leave balance?",
        attachment: "DAP_ATTENDANCE_GUIDLINES.pdf",
        publish: `Go to My Profile > Employment tab`,
        isDownload: false,
      },
      {
        title: "How to check my attendance status?",
        attachment: "DAP_ATTENDANCE_GUIDLINES.pdf",
        publish:
          "Please download attach file (Note: Safari browser is not supported)",
        isDownload: true,
      },
      {
        title: "IMS Wellth Care Handbook",
        attachment: "IMS Wellth Care Handbook.pdf",
        publish:
          "Please download attach file (Note: Safari browser is not supported)",
        isDownload: true,
      },
    ]);
    onMounted(() => {});
    const download = async (val) => {
      const filename = val;
      try {
        const response = await axios.get(
          `/api/media/meta/resources/files/announcement/${filename}`
        );
        let url = response.data.mediaLink;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    return {
      download,
      itemList,
    };
  },
};
</script>
<style scoped>
.flex-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 15px;
  font-family: "Montserrat";
}
.notif-details {
  font-size: 12.5px;
  font-family: "Montserrat";
  color: rgb(109, 108, 108);
  position: relative;
  top: -5px;
}
</style>

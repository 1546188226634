<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="admin-grid">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="filter">
        Filter by date requested:
        <select
          v-model="selectedFilter"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterDate" :key="item">
            {{ item }}
          </option>
        </select>
        <select
          v-model="selectedStatus"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterStat" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="payment-grid">
        <div v-if="isNotFound">
          <h3>{{ messageNotFound }}</h3>
        </div>
        <div v-for="(transaction, index) in filterItem" :key="index">
          <div class="admin-card accordion">
            <div style="display: flex">
              <div class="accordion-content">
                <div>
                  <h4>
                    {{ transaction.purpose }}
                    <span
                      v-show="false"
                      class="badge-severity"
                      :class="[
                        transaction.severity === 'Low'
                          ? 'badge-low'
                          : 'badge-high',
                      ]"
                      >{{ transaction.severity }}</span
                    >
                  </h4>
                </div>
                <div>
                  <span class="card-date"
                    >Date Intended:
                    {{ dateFormat(transaction.dateIntended) }}</span
                  >
                </div>
                <div>
                  <span class="card-date"
                    >Date Requested:
                    {{ dateFormat(transaction.dateFiled) }}</span
                  >
                </div>
                <div>
                  <span class="card-date"
                    >Department: {{ transaction.department }}</span
                  >
                </div>
              </div>
            </div>

            <div>
              <input
                @click="routeModal(index)"
                type="submit"
                :value="!transaction.status ? 'Pending' : transaction.status"
                class="btn-status"
                :class="[
                  !transaction.status
                    ? 'status-pending'
                    : transaction.status === 'Approved'
                    ? 'status-approved'
                    : 'status-declined ',
                ]"
              />
            </div>
          </div>
          <div
            class="panel"
            v-if="index === indexSelected && ifExpand ? true : false"
          >
            <SectionConferenceStatus
              :transaction="transaction"
            ></SectionConferenceStatus>
            <div class="panel-payment-details">
              <SectionConferenceDetails
                :transaction="transaction"
              ></SectionConferenceDetails>
              <div class="btn-action-container">
                <input
                  v-if="actionApprover(transaction)"
                  class="btn btn-action"
                  type="submit"
                  value="Action"
                  @click="isModal = true"
                />
                <input
                  class="btn btn-close"
                  type="submit"
                  value="Close"
                  @click="ifExpand = false"
                />
              </div>
              <div v-if="isModal">
                <app-modal-conference-approval
                  :requestId="transaction.id"
                  :dateIntended="transaction.dateIntended"
                  :transaction="transactions"
                  @passClose="getValue"
                ></app-modal-conference-approval>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-record">
          <div>Total Record(s): {{ total_record }}</div>
        </div>
        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionConferenceDetails from "@/components/partials/conferences/AuthReservationDetails.vue";
import SectionConferenceStatus from "@/components/partials/conferences/AuthReservationStatusTracker.vue";
import { ref, computed, onActivated } from "vue";
import { inject } from "vue";
export default {
  components: {
    SectionConferenceDetails,
    SectionConferenceStatus,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const ifExpand = ref(false);
    const indexSelected = ref(0);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const messageNotFound = ref("");
    const isNotFound = ref(false);
    const showEntries = ref([5, 10, 25, 50]);
    const showFilterDate = ref([
      "Today",
      "Incoming",
      "Last 7 Days",
      "This Month",
      "Last Month",
      "Year to Date",
      "All Time",
    ]);
    const showFilterStatus = ref([
      "All",
      "Pending",
      "Approved",
      "Reschedule",
      "Disapproved",
    ]);
    const selectedEntry = ref(5);
    const transactions = ref([]);
    const reservationItemList = ref([]);
    const selectedFilter = ref("All Time");
    const selectedStatus = ref("Pending");
    const expandPanel = ref(false);
    const filterItem = computed(() => {
      return modified.value;
    });
    const filterStat = computed(() => {
      return showFilterStatus.value.filter((stat) => {
        return stat;
      });
    });
    const filterDate = computed(() => {
      return showFilterDate.value.filter((stat) => {
        return stat;
      });
    });
    onActivated(() => {
      getConferenceRequest();
    });
    const routeModal = (val) => {
      indexSelected.value = val;
      ifExpand.value = !ifExpand.value;
    };
    const actionApprover = (val) => {
      return val.status !== "Approved" && val.status !== "Disapproved";
    };
    const getValue = (val, payload) => {
      isModal.value = val;
      if (payload) {
        setTimeout(async () => {
          getConferenceRequest();
          expandPanel.value = false;
          ifExpand.value = false;
        }, 500);
      }
    };

    const getFilterAction = (val) => {
      let selectedEntry = val;
      reservationItemList.value = transactions.value
        .filter((post) => {
          return (
            post.purpose
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase())
          );
        })
        .filter((val) => {
          let dateSelected;
          const currentDate = store.state.currentDate;
          const lastSevenDays = moment(currentDate)
            .subtract(7, "days")
            .format("YYYY-MM-DD");

          const thisMonth = moment(currentDate)
            .subtract(moment().format("DD"), "days")
            .format("YYYY-MM-DD");

          const lastMonth = moment(currentDate)
            .subtract(1, "months")
            .format("YYYY-MM");

          const yearDate = moment(currentDate).format("YYYY");

          switch (selectedFilter.value) {
            case "Today":
              dateSelected = val.dateFiled === currentDate;
              break;
            case "Last 7 Days":
              dateSelected = val.dateFiled > lastSevenDays;
              break;
            case "This Month":
              dateSelected = val.dateFiled > thisMonth;
              break;
            case "Last Month":
              dateSelected =
                moment(val.dateFiled).format("YYYY-MM") === lastMonth;
              break;
            case "Year to Date":
              dateSelected = moment(val.dateFiled).format("YYYY") === yearDate;
              break;
            default:
              dateSelected = val;

              return dateSelected;
          }
        })
        .filter((role) => {
          let userLogIn = store.state.getUserType;
          let userType;

          switch (userLogIn) {
            case "checker-moderator":
              userType = role;
              break;
            default:
              userType;
          }
          return userType;
        })
        .filter((stat) => {
          let status;

          if (selectedStatus.value === "All") {
            status = stat;
          } else if (selectedStatus.value === "Pending") {
            status = !stat.status;
          } else {
            status = stat.status === selectedStatus.value;
          }
          return status;
        });
      pagination(0, selectedEntry);
    };
    const pagination = (page, pr_page) => {
      let data = reservationItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 5;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;
      isNotFound.value = !modified.value.length ? true : false;
      messageNotFound.value = searchValue.value
        ? `We couldn't find anything for ${searchValue.value.toLowerCase()}. Try a new search.`
        : `No transactions yet. Want to try again with different status?`;

      total_record.value = data.length;
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const getConferenceRequest = async () => {
      try {
        const response = await axios.get(`/api/admin/conference/reservation`);
        isNotFound.value = response.length ? false : true;
        transactions.value = response.data;
        store.commit("notificationCounterReservation");

        getFilterAction();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("ll") : "-";
    };
    const dayFormat = (val) => {
      return val ? moment(val).format("dddd") : "-";
    };

    return {
      isModal,
      showEntries,
      selectedEntry,
      searchValue,
      transactions,
      reservationItemList,
      selectedStatus,
      showFilterStatus,
      ifExpand,
      indexSelected,
      filterItem,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      messageNotFound,
      isNotFound,
      selectedFilter,
      showFilterDate,
      expandPanel,
      filterStat,
      filterDate,
      prevRecords,
      nextRecords,
      actionApprover,
      getFilterAction,
      routeModal,
      pagination,
      getConferenceRequest,
      dateFormat,
      dayFormat,
      getValue,
    };
  },
};
</script>
<style scoped>
.footer-record {
  justify-content: flex-start;
}

.badge-severity {
  margin: 0px 15px;
  font-size: 11px;
  background-color: #ffdd7f;
  padding: 8px;
  border-radius: 20px;
  color: white;
  font-variant: small-caps;
}
.badge-low {
  background-color: #f8b944;
}
.badge-high {
  background-color: #fc3b00;
}
.status-declined {
  background-color: #ad1105;
}
.status-declined:hover,
.status-declined:focus {
  background-color: #ad1105;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-declined:active {
  background-color: #81130b;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.status-approved {
  background-color: #1d9405;
}
.status-approved:hover,
.status-approved:focus {
  background-color: #1d9405;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-approved:active {
  background-color: #0e5f04;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
</style>

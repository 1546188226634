import { inject, reactive, toRefs } from "vue";
export default function dtrAttendance() {
  const axios = inject("$axios");
  const moment = inject("$moment");
  //const store = inject("$store");
  const state = reactive({
    dtrList: [],
    dtrTimeOut: null,
    dtrOrigTimeOut: null,
    dtrHrsRender: 0,
  });

  const getEmployeeDTR = async (dataItems, payLoad, obDetails) => {
    let dateEnd = !payLoad ? dataItems.dateEnd : payLoad;
    let timeStart = dataItems.timeStart;
    let employeeNumber = dataItems.employeeno;
    let startSchedule = dataItems.User.maintenance_schedule_attendance.timeIn;
    let result = await axios.get(`api/dtrs/${employeeNumber}`);
    /* let ifHaveWeekendDuty =
      store.state.getProfileInfo.maintenance_schedule_attendance.withWeekend;*/
    //let stat = 0;
    let endTimeOB;
    let ifWeekend;
    let isOrder = 0;
    let numberIn = [];
    let itemResult = [];
    let day = moment(dateEnd).format("dddd");
    ifWeekend = day === "Saturday" || day === "Sunday" ? true : false;
    //dateEnd is null means OB
    if (!dateEnd) {
      let obDate = dataItems.obDate;
      itemResult = result.data
        .filter((val) => {
          return val.datelog === obDate;
        })
        .filter((val) => {
          if (!ifWeekend) {
            if (dataItems.obDate === obDate) {
              //find early bird

              if (val.io === "I") {
                return false;
              }
              //beyond the duty
              if (val.io === "O") {
                isOrder = 1;
                return val;
              }
            }
          } else {
            if (val.io === "O") {
              isOrder = 1;
              return val;
            }
          }
        })
        .sort((a, b) => {
          if (isOrder) {
            return b.id - a.id;
          }
        });
    } else {
      itemResult = result.data
        .filter((val) => {
          return val.datelog === dateEnd;
        })
        .filter((val, index) => {
          if (!ifWeekend) {
            if (dataItems.dateStart === dateEnd) {
              //find early bird
              if (val.io === "I") {
                numberIn.push(index++);
                var dtrTime = moment(val.timelog, "H:mm:ss");
                var schedTime = moment(startSchedule, "H:mm:ss");
                let minHrsOt = moment(dtrTime).diff(moment(schedTime), "hours");
                let isHalfDay = numberIn.length === 1 ? true : false;
                return (
                  val.timelog > startSchedule && minHrsOt && !index && isHalfDay
                );
              }
              //beyond the duty
              if (val.io === "O") {
                if (val.timelog > timeStart) {
                  if (startSchedule) {
                    let stat = timeStart > startSchedule ? true : false;

                    if (stat) {
                      isOrder = 1;
                      return val;
                    } else {
                      return !val;
                    }
                    //return stat ? val : !val;
                  } else {
                    isOrder = 1;
                    return val;
                  }
                }
              }
            } else {
              //out in the midnight
              return !index && val.io === "O";
            }
          } else {
            if (val.io === "O") {
              if (timeStart < val.timelog) {
                if (startSchedule) {
                  if (timeStart < startSchedule) {
                    isOrder = 1;
                    return val;
                  } else {
                    isOrder = 1;
                    return val;
                  }
                } else {
                  isOrder = 1;
                  return val;
                }
              }
            }
          }
        })
        .sort((a, b) => {
          if (isOrder) {
            return b.id - a.id;
          }
        });
    }
    endTimeOB =
      typeof obDetails !== "undefined"
        ? obDetails.obDate === dateEnd
          ? obDetails.returnTime
          : null
        : null;
    state.dtrList = itemResult;
    state.dtrTimeOut = itemResult.length
      ? itemResult[0].timelog
      : startSchedule > timeStart && !ifWeekend
      ? startSchedule
      : endTimeOB;
    state.dtrOrigTimeOut = itemResult.length
      ? itemResult[0].timelog
      : startSchedule > timeStart && !ifWeekend
      ? startSchedule
      : endTimeOB;
    const otStart = `${dataItems.dateStart} ${dataItems.timeStart}`;
    const otEnd = `${dateEnd} ${state.dtrTimeOut}`;
    state.dtrHrsRender = !state.dtrTimeOut
      ? 0
      : moment
          .utc(
            moment(otEnd, "YYYY-MM-DD HH:mm:ss").diff(
              moment(otStart, "YYYY-MM-DD HH:mm:ss")
            )
          )
          .format("HH:mm");
  };
  return {
    ...toRefs(state),
    getEmployeeDTR,
  };
}

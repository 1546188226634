<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <div class="page-container">
      <div class="setting-items" v-if="isVisible">
        <div v-for="(item, index) in settingList" :key="index">
          <div class="card-one">
            <div class="setting-header">
              <div class="setting-container">
                <div class="setting-title">
                  <span>
                    <img
                      :src="require(`@/assets/images/${item.icon}.png`)"
                      alt="icon"
                    />
                  </span>

                  <h1>{{ item.title }}</h1>
                </div>
                <div
                  class="setting-icon"
                  @click="routeHandler(item.title, item.page)"
                >
                  <img
                    :src="require(`@/assets/images/plus.png`)"
                    alt="expand"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-content" v-else>
        <div class="header-content">
          <h3>{{ titleHeader }}</h3>
          <div class="route-menu" @click="isVisible = true">
            <img src="../../assets/images/back.png" alt="expand" />
          </div>
        </div>
        <div class="wrapper-content">
          <component :is="pageComponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import HeroSettingGeneralInfo from "@/components/common/HeroSettingGeneralInfo.vue";
import HeroSettingNotification from "@/components/common/HeroSettingNotification.vue";
import HeroSettingUserManual from "@/components/common/HeroSettingUserManual.vue";
import HeroSettingSystemAbout from "@/components/common/HeroSettingSystemAbout.vue";
export default {
  components: {
    HeroSettingGeneralInfo,
    HeroSettingNotification,
    HeroSettingUserManual,
    HeroSettingSystemAbout,
  },
  setup() {
    const isVisible = ref(true);
    const titleHeader = ref(null);
    const pageComponent = ref(null);
    const settingList = reactive([
      {
        title: "General Information",
        icon: "account-setting",
        page: "HeroSettingGeneralInfo",
      },
      {
        title: "Notifications",
        icon: "bell",
        page: "HeroSettingNotification",
      },
      {
        title: "User Guide",
        icon: "manual",
        page: "HeroSettingUserManual",
      },
      {
        title: "System About",
        icon: "help",
        page: "HeroSettingSystemAbout",
      },
    ]);
    const routeHandler = (title, page) => {
      isVisible.value = false;
      titleHeader.value = title;
      pageComponent.value = page;
    };
    return {
      settingList,
      isVisible,
      titleHeader,
      pageComponent,
      routeHandler,
    };
  },
};
</script>
<style scoped>
.card-one {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  font-family: "Montserrat";
  color: rgb(94, 92, 92);
  margin: 10px 0px;
}
.setting-header {
  padding: 15px 15px 10px 15px;
}
.setting-container {
  display: flex;
  justify-content: space-between;
}
.setting-title {
  display: flex;
  gap: 10px;
}
.setting-title h1 {
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 800;
  padding-top: 1px;
}
.header-content {
  display: flex;
  justify-content: space-between;
}
.route-menu,
.setting-icon {
  cursor: pointer;
}
.wrapper-content {
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .wrapper-content {
    margin: 16px 4px 0px 4px;
  }
}
</style>

changepassword.vue

<template>
  <div class="wrapper">
    <div class="form-container">
      <div class="logo">
        <img src="../../assets/images/iwc-logo.png" alt="icon" />
      </div>
      <div class="top-header">
        <header>Reset Password</header>
        <form @submit.prevent="handleSubmit">
          <div class="input-field">
            <input
              class="input"
              type="password"
              placeholder="Current Password"
              v-model="oldPassword"
              autocomplete="on"
              required
            />
            <i class="icon-lock">
              <lockSVG />
            </i>
          </div>
          <div class="input-field">
            <input
              class="input"
              type="password"
              placeholder="New Password"
              v-model="newPassword"
              autocomplete="on"
              required
            />
            <i class="icon-lock">
              <lockSVG />
            </i>
          </div>
          <div class="input-field">
            <input
              class="input"
              type="password"
              placeholder="Confirm Password"
              v-model="rePassword"
              autocomplete="on"
              required
            />
            <i class="icon-lock">
              <lockSVG />
            </i>
          </div>
          <div class="input-field">
            <input type="submit" class="submit" value="Reset Password" id="" />
          </div>
          <div class="alert error" v-show="errorStat">
            {{ errorMessage }}
          </div>
        </form>
        <span class="title-footer">{{ appVersion }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, computed } from "vue";
import router from "@/router";
import { inject } from "vue";
import lockSVG from "@/assets/svgs/lockIcon.vue";
import systemVersion from "@/components/utils/version/systemVersion";
export default {
  components: {
    lockSVG,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");

    const errorStat = ref(false);
    const isStatus = ref(false);
    const errorMessage = computed(() => store.getters.message);
    const { appVersion } = systemVersion();
    const userInfo = reactive({
      oldPassword: "",
      newPassword: "",
      rePassword: "",
    });
    const handleSubmit = async () => {
      handleValidate(userInfo);
    };
    const handleValidate = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "filename") {
          if (!value) {
            validate.push(key);
          }
        }
      });

      if (validate.length) {
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else {
        insertData();
      }
    };
    const insertData = async () => {
      try {
        const result = await axios.put(`/api/auth/password`, userInfo);
        if (result.data.code === "SC") {
          store.state.getIfChangePassword = 1;
          return router.push("/");
        } else {
          store.state.errorMessage = result.data.message;
          errorStat.value = true;
          hideAlert(errorStat.value);
        }
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const hideAlert = (clear) => {
      if (clear) {
        setTimeout(function () {
          errorStat.value = false;
        }, 6000);
      }
    };

    return {
      appVersion,
      errorStat,
      errorMessage,
      ...toRefs(userInfo),
      handleSubmit,
      handleValidate,
      insertData,
      hideAlert,
      isStatus,
    };
  },
};
</script>
<style scoped>
.wrapper {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 15px;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 140px;
}
.input-field .input {
  height: 45px;
  width: 87%;
  border: none;
  border-radius: 30px;
  color: var(--lightFont);
  font-size: 15px;
  padding: 0 0 0 48px;
  background: rgba(255, 255, 255, 1);
  outline: none;
}
span {
  color: var(--lightFont);
  font-size: small;
  display: flex;
  justify-content: center;
  padding: 10px 0 10px 0;
}
header {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
  padding: 28px 0 40px 0;
}
.icon-user,
.icon-lock {
  position: relative;
  left: 18px;
  top: -35px;
}
.alert {
  margin-top: 25px;
  border-radius: 10px;
  padding: 12px;
}
.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}
.title-footer {
  margin: 50px 0px 30px 0px;
}

::-webkit-input-placeholder {
  color: var(--lightFont);
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>{{ titleRequest }}</h1>
        </div>
      </div>
      <form @submit.prevent="handleAction(selectedStatus)">
        <div v-if="userType === 'Employee'">
          <label for=""> Reason Cancel: <span class="required">*</span> </label>
          <div class="input" style="padding-top: 15px">
            <textarea rows="8" v-model="approverRemarks" required />
          </div>
        </div>
        <div v-else>
          <div
            class="form-group"
            v-if="status !== 'Approved' && status !== 'Transferred'"
          >
            <select v-model="selectedStatus" required>
              <option v-for="item in modItemStatus" :key="item">
                {{ item }}
              </option>
            </select>
            <div class="form-group" v-if="selectedStatus === 'Disapproved'">
              <label for=""> Remarks: <span class="required">*</span> </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" required />
              </div>
            </div>
            <div
              v-else-if="
                selectedStatus === 'Approved' &&
                $store.state.getUserType === 'operator-moderator'
              "
            >
              <div class="form-group">
                <label for="">
                  Assigned Driver: <span class="required">*</span>
                </label>
                <div class="input">
                  <select v-model="selectedDriver" required>
                    <option v-for="item in driverItemList" :key="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="">
                  Assigned Vehicle: <span class="required">*</span>
                </label>
                <div class="input">
                  <select v-model="selectedVehicle" required>
                    <option
                      v-for="item in vehicleItemList"
                      :key="item.plateNumber"
                      :value="`${item.brand} ${item.model} (${item.plateNumber})`"
                    >
                      {{ item.vehicle }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" v-else>
            <div v-if="$store.state.getUserType === 'driver-moderator'">
              <div class="form-cancelled">
                <label for=""> Transaction Complete: </label>
                <label class="toggle">
                  <input type="checkbox" id="switch" v-model="isToggle" />
                  <span class="slider"></span>
                </label>
              </div>

              <div class="form-group" v-show="isToggle">
                <h4 style="padding-bottom: 25px">
                  Actual Gasoline Consumption:
                </h4>
                <div class="form-group">
                  <label for=""> Beginning km: </label>
                  <div class="input">
                    <input
                      type="text"
                      v-model.lazy="beginningKm"
                      @input="autoCompute"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for=""> Ending km: </label>
                  <div class="input">
                    <input
                      type="text"
                      v-model="endingKm"
                      @input="autoCompute"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <h3>Total: {{ totalKm }}</h3>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="form-cancelled">
                <label for=""> Cancel Transaction: </label>
                <label class="toggle">
                  <input type="checkbox" id="switch" v-model="isToggle" />
                  <span class="slider"></span>
                </label>
              </div>
              <div class="form-group" v-if="isToggle">
                <label for=""> Remarks: <span class="required">*</span> </label>
                <div class="input">
                  <textarea rows="8" v-model="approverRemarks" required />
                </div>
              </div>
              <div class="form-group" v-else>
                <div class="form-group">
                  <label for="">
                    Transfer Driver: <span class="required">*</span>
                  </label>
                  <div class="input">
                    <select v-model="selectedDriver" required>
                      <option v-for="item in driverItemList" :key="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">
                    Transfer Vehicle: <span class="required">*</span>
                  </label>
                  <div class="input">
                    <select v-model="selectedVehicle" required>
                      <option
                        v-for="item in vehicleItemList"
                        :key="item.plateNumber"
                        :value="`${item.brand} ${item.model} (${item.plateNumber})`"
                      >
                        {{ item.vehicle }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-button">
          <div>
            <input
              :disabled="disabled"
              class="btn approved"
              type="submit"
              value="Submit"
            />
          </div>

          <div>
            <input
              class="btn cancel"
              type="submit"
              value="Cancel"
              @click="closeModal"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { computed, inject, onMounted, ref } from "vue";
export default {
  props: {
    requestId: Number,
    codingDay: String,
    status: String,
    dateIntended: String,
    timeIntended: String,
    userType: String,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const disabled = ref(false);
    const approverRemarks = ref(null);
    const posts = ref(null);
    const driverList = ref([]);
    const vehicleList = ref([]);
    const itemSchedule = ref([]);
    const itemScheduleTime = ref([]);
    const itemScheduleVehicle = ref([]);
    const selectedStatus = ref(null);
    const selectedDriver = ref(null);
    const selectedVehicle = ref(null);
    const duplicateMessage = ref(false);
    const isToggle = ref(false);
    const beginningKm = ref(0);
    const totalKm = ref(0);
    const endingKm = ref(0);
    const estimateTimeReturn = ref(null);
    const itemStatus = ref([
      "Approved",
      "Disapproved",
      "Cancelled",
      "Completed",
    ]);
    onMounted(() => {
      getSchedule();
    });
    const closeModal = (val, payLoad) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit("passClose", val, payLoad);
    };
    const titleRequest = computed(() => {
      return "Travel Request";
    });
    const modItemStatus = computed(() => {
      return itemStatus.value.filter((val) => {
        let status;
        status = val === "Approved" || val === "Disapproved";
        return status;
      });
    });
    const driverItem = async () => {
      try {
        const response = await axios.get("/api/getUsers");
        driverList.value = response.data
          .filter((item) => {
            return item.designation === "Company Driver";
          })
          .map((val) => {
            let indexNumber = itemSchedule.value.indexOf(val.name);
            return itemSchedule.value.includes(val.name)
              ? `${val.name} ${itemScheduleTime.value.at(indexNumber)}`
              : `${val.name} (Available)`;
          });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const driverItemList = computed(() => {
      return driverList.value;
    });
    const vehicleItem = async () => {
      try {
        const response = await axios.get("/api/vehicles/");
        vehicleList.value = response.data.map((val) => {
          let indexNumber = itemScheduleVehicle.value.indexOf(val.plateNumber);
          return {
            ...val,
            vehicle: itemScheduleVehicle.value.includes(val.plateNumber)
              ? `${val.model} (${val.plateNumber}) ${itemScheduleTime.value.at(
                  indexNumber
                )}`
              : `${val.model} (${val.plateNumber}) ${
                  val.dayOfCoding === props.codingDay ? "Coding" : "Available"
                }`,
          };
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const vehicleItemList = computed(() => {
      return vehicleList.value.filter((item) => {
        return item.status;
      });
    });

    // alert(selectedVehicle.value.match(/\((.*)\)/).pop());

    //need to refactor

    const approverAction = async (
      id,
      action,
      drivers,
      vehicles,
      plateNumbers,
      remarks,
      assignTime
    ) => {
      try {
        if (props.userType === "Employee") {
          await axios.put(`/api/admin/travel/request/${id}`, {
            status: "Revert",
            statusRemarks: remarks,
          });
        } else {
          if (store.state.getUserType === "operator-moderator") {
            await axios.put(`/api/admin/travel/request/${id}`, {
              status: isToggle.value
                ? "Cancelled"
                : props.status === "Approved" || props.status === "Transferred"
                ? "Transferred"
                : action,
              approverBy: store.state.getUserLog,
              dtApproved: moment().format(),
              driver: drivers,
              vehicle: vehicles,
              plateNumber: plateNumbers,
              assignedTime: assignTime,
              statusRemarks: remarks,
            });
          } else if (store.state.getUserType === "driver-moderator") {
            await axios.put(`/api/admin/travel/request/${id}`, {
              status: isToggle.value ? "Completed" : "Approved",
              averageKm: `B-${beginningKm.value} - E-${endingKm.value}`,
              gasoline: totalKm.value,
            });
          } else {
            await axios.put(`/api/admin/travel/request/${id}`, {
              status: action === "Approved" ? "Checked" : action,
              checkedBy: action === "Approved" ? store.state.getUserLog : null,
              statusRemarks: remarks,
            });
          }
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const handleAction = async () => {
      disabled.value = true;
      let action = selectedStatus.value;
      let userType = store.state.getUserType;
      let driver = selectedDriver.value
        ? selectedDriver.value.replace(/ *\([^)]*\) */g, "")
        : null;
      let vehicle = selectedVehicle.value;
      let plateNumber = selectedVehicle.value
        ? selectedVehicle.value.match(/\((.*)\)/).pop()
        : null;
      let timeFormat = `${props.dateIntended} ${props.timeIntended}`;
      timeFormat = moment(timeFormat).format("LT");
      let assignTime = selectedDriver.value
        ? selectedDriver.value.match(/\((.*)\)/).pop() === "Available"
          ? timeFormat
          : estimateTimeReturn.value >
            `${props.dateIntended} ${props.timeIntended}`
          ? selectedDriver.value
              .match(/\((.*)\)/)
              .pop()
              .split("-")[0]
          : timeFormat
        : null;

      //confirmation
      Swal.fire({
        title: "Are you sure",
        text: "you want to submit?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          const id = props.requestId;
          const remarks = approverRemarks.value;
          approverAction(
            id,
            action,
            driver,
            vehicle,
            plateNumber,
            remarks,
            assignTime
          );
          swalConfirmation(action, userType);
        } else {
          disabled.value = false;
        }
      });
    };
    const swalConfirmation = async (action, userType) => {
      let message;

      if (props.userType === "Employee") {
        message = `Request was successfully Revert`;
      } else {
        if (userType === "operator-moderator") {
          if (isToggle.value) {
            message = "Request was successfully put on Cancelled";
          } else {
            if (props.status !== "Approved" && props.status !== "Transferred") {
              message =
                action === "Disapproved"
                  ? `Request was regretfully ${action}`
                  : `Request was successfully ${action}`;
            } else {
              message = `Request was successfully Transferred`;
            }
          }
        }
        if (
          userType === "moderator" ||
          userType === "head" ||
          userType === "semi-moderator" ||
          userType === "pre-moderator" ||
          userType === "super"
        ) {
          message =
            action === "Disapproved"
              ? `Request was regretfully ${action}`
              : `Request was successfully ${action}`;
        }

        if (userType === "driver-moderator") {
          message = "Transaction was successfully Completed";
        }
      }

      setTimeout(async () => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 6000,
        });
        closeModal(false, true);
      }, 400);
    };
    const autoCompute = () => {
      totalKm.value = endingKm.value - beginningKm.value;
    };
    const getSchedule = async () => {
      try {
        const response = await axios.get(`/api/admin/travel/request`);

        const itemSched = response.data.filter((val) => {
          return (
            val.dateIntended === props.dateIntended &&
            (val.status === "Approved" || val.status === "Transferred")
          );
        });
        let rowEnd = itemSched.length;

        for (let i = 0; i < rowEnd; i++) {
          let startTime = `${itemSched[i].dateIntended} ${itemSched[i].timeIntended}`;
          let endTime = `${itemSched[i].dateIntended} ${itemSched[i].timeReturned}`;
          let modFormat = `${moment(startTime).format("LT")} - ${moment(
            endTime
          ).format("LT")}`;

          itemScheduleVehicle.value.push(`${itemSched[i].plateNumber}`);
          itemSchedule.value.push(`${itemSched[i].driver}`);
          itemScheduleTime.value.push(`(${modFormat})`);
          estimateTimeReturn.value = endTime;
        }

        driverItem();
        vehicleItem();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      closeModal,
      handleAction,
      disabled,
      approverRemarks,
      posts,
      itemStatus,
      modItemStatus,
      selectedStatus,
      approverAction,
      titleRequest,
      swalConfirmation,
      duplicateMessage,
      isToggle,
      driverItem,
      driverList,
      driverItemList,
      selectedDriver,
      selectedVehicle,
      vehicleItem,
      vehicleList,
      vehicleItemList,
      beginningKm,
      endingKm,
      autoCompute,
      totalKm,
      getSchedule,
      itemSchedule,
      itemScheduleTime,
      itemScheduleVehicle,
      estimateTimeReturn,
    };
  },
};
</script>
<style scoped>
select {
  font-size: 15px;
  font-style: normal;
  font-family: "Montserrat";
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 100%;
  margin-bottom: 15px;
}
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.approved {
  background-color: rgb(25, 179, 102);
}
.disapproved {
  background-color: rgb(219, 86, 53);
}
.cancel {
  background-color: rgb(137, 67, 228);
}
.dv-form {
  padding-top: 20px;
}
.form-cancelled {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 20px;
}
@media only screen and (min-width: 210px) and (max-width: 500px) {
  .modal-container {
    min-width: 200px;
  }
  .modal {
    width: 300px;
    max-width: 100%;
    padding: 30px;
  }
}
</style>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <div class="page-container">
      <div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>10</h1></span>
                <div class="date">
                  <h2>July</h2>
                  <h3>2024</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>Important Advisory</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>
            <div class="announcement-content">
              <p>
                These past few days, we encountered challenges with our system.
                We apologize for the inconvenience. After troubleshooting, we
                are now back to full operation. We appreciate your patience and
                understanding. Thank you.
              </p>
              <br />
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>16</h1></span>
                <div class="date">
                  <h2>April</h2>
                  <h3>2024</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>New Features</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>
            <div class="announcement-content">
              <p>
                We’re excited to introduce our newest feature update:
                <b>Badge Notifications!</b>. Keep an eye out for those badges
                that require your attention, ensuring you don't miss out and
                stay up to date.
              </p>
              <br />
              <p>
                Thank you for being part of our journey towards continuous
                improvement. We're committed to enhancing your experience every
                step of the way. If you have any questions or feedback, feel
                free to reach out to us.
              </p>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>25</h1></span>
                <div class="date">
                  <h2>Nov</h2>
                  <h3>2023</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>Important Advisory: System Maintenance</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>
            <div class="announcement-content">
              <p>
                We would like to inform you that our system will be undergoing
                scheduled maintenance facilitated by our
                <b>third-party service provider</b>. This maintenance is
                essential to ensure the continued reliability and optimal
                performance of our services.
              </p>
              <br />
              <p>
                <b>Maintenance Details:</b> <br />
                <span style="padding-left: 30px"
                  ><b>Start Date and Time: December 07, 2023 @ 09:30AM</b></span
                ><br />
                <span style="padding-left: 30px"
                  ><b>End Date and Time: December 07, 2023 @ 12:00PM </b></span
                >
              </p>
              <br />
              <p>
                During this period, Digital Application Portal (DAP) may be
                affected and <b>temporarily unavailable</b>. We apologize for
                any inconvenience caused and appreciate your understanding.
                Thank you.
              </p>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>01</h1></span>
                <div class="date">
                  <h2>Oct</h2>
                  <h3>2023</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>System Update</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>
            <div class="announcement-content">
              <p>Our latest version is finally live!</p>
              <br />
              <p>
                No more wasting time worrying about whether you forgot to use
                biotime. You can now monitor it in version 4.6.65. For more
                guidance,
                <b
                  >go to Account Settings > User Guide > How to check my
                  attendance status</b
                >
              </p>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>26</h1></span>
                <div class="date">
                  <h2>Sep</h2>
                  <h3>2023</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>New Features</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>
            <div class="announcement-content">
              <p class="indent">
                We are excited to announce the release of our new DAP version,
                <b>4.6.65</b>. In the last few months, we have been tirelessly
                working to improve our system and we believe that new features
                will help you enjoy your experience even more. So, what is
                V4.6.65 all about?
              </p>

              <span class="attachment">
                <img
                  :src="require(`@/assets/images/fingerprint.gif`)"
                  alt="icon"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>8</h1></span>
                <div class="date">
                  <h2>Jun</h2>
                  <h3>2023</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>System DAP Orientation</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>
            <div class="announcement-content">
              <p class="indent">
                There will be a DAP-TRF meeting on Thursday, June 8 @3:05pm
                Common Area.
              </p>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="announcement-header">
            <div class="announcement-wrapper">
              <div class="event-date">
                <span><h1>11</h1></span>
                <div class="date">
                  <h2>Apr</h2>
                  <h3>2023</h3>
                </div>
              </div>
              <hr />
              <div class="announcement-title">
                <h2>System Advisory</h2>
                <h5>By: DAP Support Team</h5>
              </div>
            </div>

            <div class="announcement-content">
              <p class="indent">
                We sincerly apologize for the inconvience. Some pages shall be
                temporarily unavailable due to system enhancement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onActivated } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    onActivated(async () => {
      const notificationCount = store.state.getNotifCounter;
      const getEmployeeId = store.state.getEmployeeId;
      if (notificationCount > 0) {
        try {
          await axios.put(`api/user/${getEmployeeId}`, {
            notifCounter: 0,
          });
        } catch (error) {
          store.commit("errorHandler", error);
        }
      }
    });
    const download = async () => {
      const filename = "DIGITAL_APPLICATION _PORTAL_MEMO.jpg";
      try {
        const response = await axios.get(
          `api/media/meta/resources/files/announcement/${filename}`
        );
        let url = response.data.mediaLink;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    return {
      download,
    };
  },
};
</script>
<style scoped>
.card-one {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  font-family: "Montserrat";
  color: rgb(94, 92, 92);
  margin: 0px 0px 20px 0px;
}
.announcement-header {
  padding: 20px;
}
.announcement-date {
  padding-bottom: 18px;
  font-family: "Montserrat";
  color: rgb(5, 5, 5);
}
.announcement-title {
  line-height: 30px;
}
.announcement-wrapper {
  display: flex;
}
.announcement-content {
  padding: 18px;
}
.announcement-content {
  line-height: 32px;
}
.indent {
  text-indent: 30px;
}
hr {
  margin: 0px 10px;
}
.event-date span h1 {
  font-size: 45px;
  font-family: "Montserrat";
  font-weight: 800;
}
.event-date .date {
  color: var(--lightSecondary);
  margin: 5px 8px 0px 15px;
}
.event-date .date h2 {
  font-size: 18px;
}
.event-date .date h3 {
  font-size: 14px;
}
.event-date {
  display: flex;
  font-family: "Poppins";
  padding: 2px 0px 2px 0px;
}
.announcement-download {
  display: flex;
  gap: 5px;
}
.announcement-download:hover {
  cursor: pointer;
}
.announcement-download div {
  margin: 2px;
  font-size: 14px;
}
.attachment {
  display: flex;
  justify-content: center;
  align-items: center;
}
.attachment img {
  height: 180px;
  width: 180px;
}

@media only screen and (max-width: 600px) {
  .card-one {
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    border-radius: 10px;
    font-family: "Montserrat";
    color: rgb(94, 92, 92);
    margin: 0px 0px 20px 0px;
  }
  .announcement-header {
    padding: 15px;
  }
  .announcement-date {
    padding-bottom: 18px;
    font-family: "Montserrat";
    color: rgb(5, 5, 5);
  }
  .announcement-title {
    line-height: 25px;
  }
  .announcement-wrapper {
    display: flex;
  }
  .announcement-content {
    padding: 15px 5px;
    font-size: 15px;
  }
  .announcement-content p {
    line-height: 25px;
  }
  hr {
    margin: 0px 10px;
  }
  .event-date span h1 {
    font-size: 40px;
    font-family: "Montserrat";
    font-weight: 800;
  }
  .event-date .date {
    color: var(--lightSecondary);
    margin: 5px 8px 0px 15px;
  }
  .event-date .date h2 {
    font-size: 15px;
  }
  .event-date .date h3 {
    font-size: 12px;
  }
  .event-date {
    display: flex;
    font-family: "Poppins";
    padding: 2px 0px 2px 0px;
  }
  .announcement-download {
    display: flex;
    gap: 5px;
  }
  .announcement-download:hover {
    cursor: pointer;
  }
  .announcement-download div {
    margin: 2px;
    font-size: 14px;
  }
}
</style>

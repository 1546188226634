<template>
  <div class="auth-section">
    <h3>General Information</h3>
    <div class="section-container">
      <table>
        <tr class="flex">
          <td class="title">Employee ID:</td>
          <td class="description">
            {{
              employeeProfileDetails.employee_number
                ? employeeProfileDetails.employee_number
                : "-"
            }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Department:</td>
          <td class="description">{{ employeeProfileDetails.department }}</td>
        </tr>
        <tr class="flex">
          <td class="title">Position:</td>
          <td class="description">{{ employeeProfileDetails.designation }}</td>
        </tr>
        <tr class="flex">
          <td class="title">Work Schedule:</td>
          <td class="description">
            {{
              checkWorkSchedule(
                employeeProfileDetails.maintenance_schedule_attendance
              )
            }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Date Hired:</td>
          <td class="description">
            {{ dateFormat(employeeProfileDetails.dateHired) }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Year of Services:</td>
          <td class="description">{{ hiredInformation }}</td>
        </tr>
        <tr class="flex">
          <td class="title">Status:</td>
          <td class="description">{{ employeeStatus }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { inject, computed } from "vue";
export default {
  props: {
    employeeProfileDetails: Object,
  },
  setup(props) {
    const moment = inject("$moment");
    // const axios = inject("$axios");
    // const store = inject("$store");
    const dateFormat = (val) => {
      return val ? moment(val).format("LL") : "-";
    };
    const hiredInformation = computed(() => {
      var currentDate = moment();
      var dateHired = moment(props.employeeProfileDetails.dateHired).format(
        "YYYY-MM-DD"
      );

      var diffDuration = moment.duration(currentDate.diff(dateHired));

      let details = `${diffDuration.years()} Years, ${diffDuration.months()} months and ${diffDuration.days()} days`;

      return details;
    });

    const employeeStatus = computed(() => {
      let workStatus = props.employeeProfileDetails.isBiotime;
      let result;
      if (workStatus === 1) {
        result = "Regular";
      }
      if (workStatus === 2) {
        result = "Probationary";
      }
      return result;
    });
    const checkWorkSchedule = (val) => {
      let checkifDisplay = val.schedStatus;

      if (!checkifDisplay) {
        return val.description;
      } else {
        let timeIn = `${moment().format("YYYY-MM-DD")} ${val.timeIn}`;
        let timeOut = `${moment().format("YYYY-MM-DD")} ${val.timeOut}`;
        return `${moment(timeIn).format("hh:mm A")} - ${moment(timeOut).format(
          "hh:mm A"
        )} `;
      }
    };

    return {
      dateFormat,
      hiredInformation,
      employeeStatus,
      checkWorkSchedule,
    };
  },
};
</script>
<style scoped>
.auth-section {
  padding-top: 40px;
}
.section-container {
  margin: 30px 40px;
}
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 15.1px;
}
.title {
  min-width: 180px;
  font-weight: 600;
  color: #464545;
}
.description {
  font-weight: 500;
  color: #5c5c5c;
}
.flex {
  display: flex;
  margin: 10px 0px;
}
h3 {
  font-family: "Montserrat";
  font-weight: 800;
  color: #3e535f;
}
@media only screen and (max-width: 600px) {
  .auth-section {
    padding-top: 30px;
  }
  .section-container {
    margin: 16px 10px;
  }
  table {
    font-size: 11.5px;
  }
  .title {
    min-width: 138px;
  }
  .flex {
    display: flex;
    margin: 10px 0px;
  }
  h3 {
    font-size: 15px;
  }
}
</style>

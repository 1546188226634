<template>
  <div class="auth-content">
    <h1>My Schedule</h1>

    <div class="auth-grid">
      <VCalendar :attributes="attributes" expanded title-position="left" />

      <div class="legend">
        <h3>Legend</h3>
        <ul>
          <li><span class="dot blue md"></span>Single Destination</li>
          <li><span class="dot red md"></span>Multiple Destination</li>
          <li>
            <span class="dot blue"></span><span class="dot blue md"></span>Two
            requester with same day
          </li>
          <li>
            <span class="dot blue"></span><span class="dot blue"></span
            ><span class="dot red md"></span>Three requester with same day
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onActivated, computed } from "vue";
export default {
  components: {},
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const vehicleList = ref([]);
    const isModal = ref(false);
    const date = ref(new Date());
    const isModalSchedStatus = ref(false);
    const isModalAutoDetails = ref(false);
    const travelRequest = ref([]);

    onActivated(() => {
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        const response = await axios.get(`/api/admin/travel/request`);
        const itemList = response.data.filter((val) => {
          return (
            val.status === "Approved" ||
            val.status === "Completed" ||
            val.status === "Transferred"
          );
        });
        travelRequest.value = itemList
          .map((item) => {
            let startTime = `${item.dateIntended} ${item.timeIntended}`;
            let endTime = `${item.dateIntended} ${item.timeReturned}`;
            return {
              ...item,

              requester: item.requestedBy,
              purpose: `${item.travelItemDetails[0].purpose} (${moment(
                startTime
              ).format("LT")} - ${moment(endTime).format("LT")})`,

              time: item.timeIntended,
              isComplete: false,
              dates: [item.dateIntended],
              color: item.travelItemDetails.length > 1 ? "red" : "blue",
            };
          })
          .filter((value) => {
            return (
              (value.status === "Approved" ||
                value.status === "Completed" ||
                value.status === "Transferred") &&
              value.driver === store.state.getUserLog
            );
          });
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const attributes = computed(() => [
      // Attributes for travelRequest
      ...travelRequest.value.map((todo) => ({
        dates: todo.dates,
        dot: {
          color: todo.color,
          class: todo.isComplete ? "opacity-75" : "",
        },
        popover: {
          label:
            todo.purpose === "Coding"
              ? todo.purpose
              : `${todo.requester} - ${todo.purpose}`,
          visibility: "focus",
        },
      })),
    ]);

    return {
      vehicleList,
      isModal,
      date,
      isModalSchedStatus,
      isModalAutoDetails,
      dataLoad,
      travelRequest,
      attributes,
    };
  },
};
</script>
<style scoped>
.auth-content {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  padding: 30px 20px 50px 20px;
  margin-top: 30px;
  font-family: "Montserrat";
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.auth-grid {
  display: grid;
  gap: 30px;
  margin-top: 30px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 180px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
}
.auto-description {
  padding-bottom: 20px;
}
.auto-title {
  padding: 20px 0px;
}
.auto-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.auto-btn-availability {
  margin-bottom: 8px;
}
.avatar {
  max-width: 100%;
  height: auto;
}
.card-one,
.card-two {
  display: flex;
  line-height: 26px;
  gap: 6px;
  font-size: 14px;
}
.submit {
  background-color: rgb(62, 83, 95);
}
.available {
  background-color: rgb(250, 115, 25);
}
.maintenance {
  background-color: rgb(103, 104, 103);
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.blue {
  background-color: blue;
}
.red {
  background-color: red;
}
.gray {
  background-color: gray;
}
.md {
  margin-right: 10px;
}
ul {
  list-style-type: none;
  color: var(--lightFont);
  font-size: 13px;
  font-style: normal;
  font-family: "Montserrat";
  line-height: 20px;
}
.legend {
  padding: 20px 0px;
}
.legend h3 {
  padding-bottom: 10px;
}
.my-calendar {
  color: #6366f1;
}

@media only screen and (max-width: 600px) {
  .auth-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: auto;
  }
}
/* Medium Devices, Desktops */
</style>

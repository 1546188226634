<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
import userDetails from "@/components/utils/reusables/userLogDetails";
export default {
  setup() {
    const { getUserDetails } = userDetails();
    onMounted(() => {
      getUserDetails();
    });
    return {
      getUserDetails,
    };
  },
};
</script>

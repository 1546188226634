<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="page-container">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="show-range-date">
        <div>
          Range Date
          <input
            v-model="fromRangeDate"
            class="item-entries"
            style="min-width: 160px; margin-right: 10px"
            type="date"
            @change="getFilterAction(selectedEntry)"
          />
          <input
            v-model="endRangeDate"
            class="item-entries"
            style="min-width: 160px"
            type="date"
            @change="getFilterAction(selectedEntry)"
          />
          <select
            v-model="selectedDepartment"
            class="item-entries md-status"
            style="padding: 13px 5px"
            @change="getFilterAction(selectedEntry)"
          >
            <option>All</option>
            <option v-for="item in departmentItem" :key="item">
              {{ item.deptName }}
            </option>
          </select>
          <select
            v-if="$store.state.getUserType === 'admin-moderator'"
            v-model="selectedReport"
            class="item-entries md-status"
            style="padding: 13px 5px"
            @change="getFilterAction(selectedEntry)"
          >
            <option v-for="item in reportItems" :key="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div>
          <img
            class="download"
            @click="excelFormatted()"
            :src="require('@/assets/images/download_.png')"
            alt="icon"
          />
        </div>
      </div>

      <div class="dataTable">
        <table v-if="selectedReport === 'Leave'">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Leave Type</th>
              <th scope="col">Date</th>
              <th scope="col">No. of Days</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filterItem" :key="index">
              <td data-label="Name">
                {{ item.name }}
              </td>
              <td data-label="Leave Type">
                {{ item.leaveType }}
              </td>
              <td data-label="Date">
                {{ dateFormat(item.start, item.end) }}
              </td>
              <td data-label="No. of Days">{{ item.numberDays }}</td>
            </tr>
          </tbody>
        </table>
        <table v-else-if="selectedReport === 'Overtime'">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date Start</th>
              <th scope="col">Date End</th>
              <th scope="col">Time Render</th>
              <th scope="col">Hours</th>
              <th scope="col">Minutes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filterItem" :key="index">
              <td data-label="Name">
                {{ item.name }}
              </td>
              <td data-label="Date Start">
                {{ dateFormat(item.startDate) }}
              </td>
              <td data-label="Date End">
                {{ dateFormat(item.endDate) }}
              </td>
              <td data-label="Time Render">
                {{ item.renderTime }}
              </td>
              <td data-label="No. of Days">{{ item.hrs }}</td>
              <td data-label="No. of Days">{{ item.min }}</td>
            </tr>
          </tbody>
        </table>
        <table v-else>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date Intended</th>
              <th scope="col">Time Render</th>
              <th scope="col">Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filterItem" :key="index">
              <td data-label="Name">{{ item.name }}</td>
              <td data-label="Date">{{ dateFormat(item.startDate) }}</td>
              <td data-label="Time Render">{{ item.renderTime }}</td>
              <td data-label="Purpose">{{ item.purposes }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <div class="previous">
          <input
            type="submit"
            class="prev-btn btn"
            value="Previous"
            @click="prevRecords(current_page)"
          />
        </div>

        <div class="pages">
          <span
            >Page {{ total_record ? current_page : 0 }} of
            {{ total_page }}
          </span>
        </div>
        <div class="next">
          <input
            :disabled="total_page === current_page ? true : false"
            type="submit"
            class="nxt-btn btn"
            value="Next"
            @click="nextRecords(current_page)"
          />
        </div>
      </div>
    </div>

    <div class="auth-grid"></div>
  </div>
</template>
<script>
import * as XLSX from "sheetjs-style";
import { inject, ref, computed, onActivated } from "vue";
export default {
  components: {},
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const showEntries = ref([10, 25, 50]);
    const selectedEntry = ref(10);
    const itemDetails = ref([]);
    const itemDetailsOvertime = ref([]);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const activityItemList = ref([]);
    const fromRangeDate = ref(store.state.currentDate);
    const endRangeDate = ref(store.state.currentDate);
    const excelFileName = ref("Sample");
    const departmentItem = ref([]);
    const selectedDepartment = ref("All");
    const reportItems = ref(["Leave", "Overtime", "Official Business"]);
    const selectedReport = ref("Leave");
    const itemDetailsOfficialBusiness = ref([]);
    const filterItem = computed(() => {
      return modified.value;
    });
    onActivated(async () => {
      getActivityLog();
      getDepartment();
    });
    const getActivityLog = async () => {
      try {
        getReportLeave();
        getReportOvertime();
        getReportOfficialBusiness();
        selectedReport.value =
          store.state.getUserType === "admin-moderator" ? "Leave" : "Overtime";
        getFilterAction();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const getReportLeave = async () => {
      const { data } = await axios.get(`api/admin/employee/report/leave`);
      itemDetails.value = data.filter((val) => {
        return val.approverStatus === "Approved";
      });
    };
    const getReportOvertime = async () => {
      const { data } = await axios.get(`/api/admin/employeee/overtime`);
      itemDetailsOvertime.value = data.filter((val) => {
        return val.status === "Completed";
      });
    };
    const getReportOfficialBusiness = async () => {
      const { data } = await axios.get(`/api/admin/employeee/ob`);
      itemDetailsOfficialBusiness.value = data.filter((val) => {
        return val.status === "Completed";
      });
    };
    const dateFormat = (start, end) => {
      let dateStart = start;
      let dateEnd = end;
      let modFormat;

      if (!end) {
        modFormat = `${moment(dateStart).format("LL")}`;
      } else {
        modFormat = `${moment(dateStart).format("LL")} - ${moment(
          dateEnd
        ).format("LL")}`;
      }

      return modFormat;
    };
    const pagination = (page, pr_page) => {
      let data = activityItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 10;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;

      total_record.value = data.length;
    };
    const getFilterAction = (val) => {
      if (selectedReport.value === "Leave") {
        let selectedEntry = val;
        activityItemList.value = itemDetails.value
          .filter((post) => {
            return post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase());
          })
          .filter((item) => {
            return (
              item.dateStart >= fromRangeDate.value &&
              item.dateStart <= endRangeDate.value
            );
          })
          .filter((item) => {
            let department = selectedDepartment.value;
            let result;
            if (department === "All") {
              result = item;
            } else {
              result = department === item.department;
            }

            return result;
          })
          .sort(function (a, b) {
            var nameOne = a.requestedBy.toUpperCase();
            var nameTwo = b.requestedBy.toUpperCase();
            let name = 0;

            if (nameOne > nameTwo) {
              name = 1;
            } else if (nameOne < nameTwo) {
              name = -1;
            }

            var departmentOne = a.department.toUpperCase();
            var departmentTwo = b.department.toUpperCase();
            let department = 0;

            if (departmentOne > departmentTwo) {
              department = 1;
            } else if (departmentOne < departmentTwo) {
              department = -1;
            }

            var c = new Date(`${a.dateStart}`);
            var d = new Date(`${b.dateStart}`);
            return department || name || c - d;
          })
          .map((val) => {
            let leaveTypeMod = convertToWord(val.leaveType, val.awopType);
            return {
              department: val.department,
              name: val.requestedBy,
              leaveType: leaveTypeMod,
              start: val.dateStart,
              end: val.dateEnd,
              numberDays: val.numberDays,
              slBalFrom: val.leaveCreditHistories[0].slBalFrom,
              slBalTo: val.leaveCreditHistories[0].slBalTo,
              vlBalFrom: val.leaveCreditHistories[0].vlBalFrom,
              vlBalTo: val.leaveCreditHistories[0].vlBalTo,
              historyCreditId: val.leaveCreditHistories[0].id,
            };
          });

        pagination(0, selectedEntry);
      } else if (selectedReport.value === "Overtime") {
        let selectedEntry = val;
        activityItemList.value = itemDetailsOvertime.value
          .filter((post) => {
            return post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase());
          })
          .filter((item) => {
            return (
              item.dateStart >= fromRangeDate.value &&
              item.dateStart <= endRangeDate.value
            );
          })
          .filter((item) => {
            let department = selectedDepartment.value;
            let result;
            if (department === "All") {
              result = item;
            } else {
              result = department === item.department;
            }

            return result;
          })
          .sort(function (a, b) {
            var nameOne = a.requestedBy.toUpperCase();
            var nameTwo = b.requestedBy.toUpperCase();
            let name = 0;

            if (nameOne > nameTwo) {
              name = 1;
            } else if (nameOne < nameTwo) {
              name = -1;
            }

            var departmentOne = a.department.toUpperCase();
            var departmentTwo = b.department.toUpperCase();
            let department = 0;

            if (departmentOne > departmentTwo) {
              department = 1;
            } else if (departmentOne < departmentTwo) {
              department = -1;
            }

            var c = new Date(`${a.dateStart}`);
            var d = new Date(`${b.dateStart}`);
            return department || name || c - d;
          })
          .map((val) => {
            let startTime = `${val.dateStart} ${val.timeStart}`;
            let endTime = `${val.dateEnd} ${val.timeEnd}`;
            let modFormat;
            modFormat = `${moment(startTime).format("LT")} - ${moment(
              endTime
            ).format("LT")}`;

            let origTime;

            let convertTime = `${moment().format("YYYY-MM-DD")} ${
              val.origNumberHours
            }`;
            let getHours = moment(convertTime).format("HH");
            if (getHours >= 1 && getHours <= 12) {
              origTime = `${moment(convertTime).format("hh [hrs] mm [mins]")}`;
            } else if (getHours > 12) {
              origTime = `${moment(convertTime).format("HH [hrs] mm [mins]")}`;
            } else {
              origTime = `${moment(convertTime).format("mm [mins]")}`;
            }

            let time = val.numberHours;
            let mod = time.split(":");
            let hour = parseInt(mod[0]);
            let min = parseInt(mod[1]);
            return {
              department: val.department,
              name: val.requestedBy,
              day: moment(val.dateStart).format("dddd"),
              hrs: hour,
              min: min,
              startDate: val.dateStart,
              endDate: val.dateEnd,
              startTime: val.timeStart,
              endTime: val.timeEnd,
              renderTime: modFormat,
              remarks:
                val.origNumberHours !== val.numberHours
                  ? `Original Filing time (${origTime})`
                  : "",
            };
          });

        pagination(0, selectedEntry);
      } else {
        let selectedEntry = val;
        activityItemList.value = itemDetailsOfficialBusiness.value
          .filter((post) => {
            return post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase());
          })
          .filter((item) => {
            return (
              item.obDate >= fromRangeDate.value &&
              item.obDate <= endRangeDate.value
            );
          })
          .filter((item) => {
            let department = selectedDepartment.value;
            let result;
            if (department === "All") {
              result = item;
            } else {
              result = department === item.department;
            }

            return result;
          })
          .sort(function (a, b) {
            var nameOne = a.requestedBy.toUpperCase();
            var nameTwo = b.requestedBy.toUpperCase();
            let name = 0;

            if (nameOne > nameTwo) {
              name = 1;
            } else if (nameOne < nameTwo) {
              name = -1;
            }

            var departmentOne = a.department.toUpperCase();
            var departmentTwo = b.department.toUpperCase();
            let department = 0;

            if (departmentOne > departmentTwo) {
              department = 1;
            } else if (departmentOne < departmentTwo) {
              department = -1;
            }

            var c = new Date(`${a.dateStart}`);
            var d = new Date(`${b.dateStart}`);
            return department || name || c - d;
          })
          .map((val) => {
            let startTime = `${val.obDate} ${val.obTime}`;
            let endTime = `${val.obDate} ${val.returnTime}`;
            let modFormat;
            modFormat = `${moment(startTime).format("LT")} - ${moment(
              endTime
            ).format("LT")}`;
            return {
              department: val.department,
              name: val.requestedBy,
              day: moment(val.obDate).format("dddd"),
              startDate: val.obDate,
              startTime: val.obTime,
              endDate: val.obDate,
              endTime: val.returnTime,
              renderTime: modFormat,
              purposes:
                val.obItemDetails.length > 1
                  ? `${val.obItemDetails[0].purpose} & Etc`
                  : val.obItemDetails[0].purpose,
            };
          });

        pagination(0, selectedEntry);
      }
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const convertToWord = (val, subVal) => {
      let abbreviation = val;
      let awopAbbrev = subVal;
      let word;
      if (val) {
        if (abbreviation === "SL") {
          word = "Sick Leave";
        }
        if (abbreviation === "VL") {
          word = "Vacation Leave";
        }
        if (abbreviation === "EL") {
          word = "Emergency Leave";
        }
        if (abbreviation === "AWOP") {
          word = "Absent without pay";
        }
        if (abbreviation === "CL") {
          word = "Compassionate Leave";
        }
        if (abbreviation === "ML") {
          word = "Maternity Leave";
        }
        if (abbreviation === "PL") {
          word = "Paternity Leave";
        }
        if (abbreviation === "BL") {
          word = "Birthday Leave";
        }
        if (abbreviation === "SPL") {
          word = "Solo Parent Leave";
        }
        if (abbreviation === "MCW") {
          word = "Magna Carta for Women";
        }
        if (abbreviation === "VAWC") {
          word = "Violence Againts Women and their Children";
        }

        if (awopAbbrev === "AWOPSL" && abbreviation === "AWOP") {
          word = `${word} (Sick Leave)`;
        }
        if (awopAbbrev === "AWOPVL" && abbreviation === "AWOP") {
          word = `${word} (Vacation Leave)`;
        }
        return word;
      }
    };

    const excelFormatted = () => {
      if (selectedReport.value === "Leave") {
        leaveReports();
      }
      if (selectedReport.value === "Overtime") {
        overtimeReports();
      }
      if (selectedReport.value === "Official Business") {
        obReports();
      }
    };
    const leaveReports = () => {
      let rows = activityItemList.value
        .sort((valOne, valTwo) => {
          var departmentOne = valOne.department.toUpperCase();
          var departmentTwo = valTwo.department.toUpperCase();
          let department = 0;

          if (departmentOne > departmentTwo) {
            department = 1;
          } else if (departmentOne < departmentTwo) {
            department = -1;
          }

          var nameOne = valOne.name.toUpperCase();
          var nameTwo = valTwo.name.toUpperCase();
          let name = 0;

          if (nameOne > nameTwo) {
            name = 1;
          } else if (nameOne < nameTwo) {
            name = -1;
          }
          return (
            department ||
            name ||
            valOne.historyCreditId - valTwo.historyCreditId
          );
        })
        .map((val) => {
          return {
            department: val.department,
            name: val.name,
            leaveType: val.leaveType,
            start: val.start,
            end: val.end,
            numberDays: val.numberDays,
            slBalFrom: val.slBalFrom,
            slBalTo: val.slBalTo,
            vlBalFrom: val.vlBalFrom,
            vlBalTo: val.vlBalTo,
          };
        });
      let filename = `${moment().format("YYYYMMDD")}.xlsx`;
      let startRangeFormat = moment(fromRangeDate.value).format("LL");
      let endRangeFormat = moment(endRangeDate.value).format("LL");
      let secondTitle = `Cut-off: ${startRangeFormat} - ${endRangeFormat}`;
      /* generate worksheet and workbook */

      let worksheet = XLSX.utils.json_to_sheet(rows, {
        origin: "A6",
        skipHeader: true,
      });

      /* XLSX.utils.sheet_add_aoa(worksheet, [["", "", "", "", "", ""]], {
        origin: -1,
      });*/

      XLSX.utils.sheet_add_aoa(worksheet, [["Summary of Leave Request"]], {
        origin: "A1",
      });
      XLSX.utils.sheet_add_aoa(worksheet, [[secondTitle]], {
        origin: "A2",
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");

      /* fix headers */

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Department",
            "Name",
            "Leave Type",
            "Date Start",
            "Date End",
            "Number of days",
            "SL Beginning Balance",
            "SL Ending Balance",
            "VL Beginning Balance",
            "VL Ending Balance",
          ],
        ],
        {
          origin: "A5",
          skipHeader: true,
        }
      );

      /* calculate column width */
      var wscols = [
        { width: 50 },
        { width: 40 },
        { width: 30 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];

      worksheet["!cols"] = wscols;

      worksheet["A1"].s = {
        font: {
          name: "Calibri",
          sz: 14,
          bold: true,
        },
      };

      for (let i in worksheet) {
        if (typeof worksheet[i] != "object") continue;

        if (i !== "A1" && i !== "A2") {
          worksheet[i].s = {
            // styling for all cells

            border: {
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              bottom: {
                style: "thin",
                color: "000000",
              },
            },
          };
        }
        if (
          i === "A5" ||
          i === "B5" ||
          i === "C5" ||
          i === "D5" ||
          i === "E5" ||
          i === "F5" ||
          i === "G5" ||
          i === "H5" ||
          i === "I5" ||
          i === "J5"
        ) {
          worksheet[i].s = {
            // styling for all cells
            font: {
              bold: true,
            },
            border: {
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              bottom: {
                style: "thin",
                color: "000000",
              },
            },
          };
        }
      }

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSX.writeFile(workbook, filename, { compression: true });
    };
    const overtimeReports = () => {
      let rows = activityItemList.value
        .sort((valOne, valTwo) => {
          var departmentOne = valOne.department.toUpperCase();
          var departmentTwo = valTwo.department.toUpperCase();
          let department = 0;

          if (departmentOne > departmentTwo) {
            department = 1;
          } else if (departmentOne < departmentTwo) {
            department = -1;
          }

          var nameOne = valOne.name.toUpperCase();
          var nameTwo = valTwo.name.toUpperCase();
          let name = 0;

          if (nameOne > nameTwo) {
            name = 1;
          } else if (nameOne < nameTwo) {
            name = -1;
          }
          return department || name;
        })
        .map((val) => {
          return {
            department: val.department,
            name: val.name,
            day: val.day,
            startDate: val.startDate,
            endDate: val.endDate,
            renderTime: val.renderTime,
            hrs: val.hrs,
            min: val.min,
            remarks: val.remarks,
          };
        });
      let filename = `Overtime Reports - ${moment().format("YYYYMMDD")}.xlsx`;
      let startRangeFormat = moment(fromRangeDate.value).format("LL");
      let endRangeFormat = moment(endRangeDate.value).format("LL");
      let secondTitle = `Cut-off: ${startRangeFormat} - ${endRangeFormat}`;
      /* generate worksheet and workbook */

      let worksheet = XLSX.utils.json_to_sheet(rows, {
        origin: "A6",
        skipHeader: true,
      });

      /* XLSX.utils.sheet_add_aoa(worksheet, [["", "", "", "", "", ""]], {
        origin: -1,
      });*/

      XLSX.utils.sheet_add_aoa(worksheet, [["Summary of Overtime Request"]], {
        origin: "A1",
      });
      XLSX.utils.sheet_add_aoa(worksheet, [[secondTitle]], {
        origin: "A2",
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");

      /* fix headers */

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Department",
            "Name",
            "Day",
            "Date Start",
            "Date End",
            "Render Time",
            "Hours",
            "Minutes",
            "Remarks",
          ],
        ],
        {
          origin: "A5",
          skipHeader: true,
        }
      );

      /* calculate column width */
      var wscols = [
        { width: 50 },
        { width: 40 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 50 },
      ];

      worksheet["!cols"] = wscols;

      worksheet["A1"].s = {
        font: {
          name: "Calibri",
          sz: 14,
          bold: true,
        },
      };
      for (let i in worksheet) {
        if (typeof worksheet[i] != "object") continue;

        if (i !== "A1" && i !== "A2") {
          worksheet[i].s = {
            // styling for all cells

            border: {
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              bottom: {
                style: "thin",
                color: "000000",
              },
            },
          };
        }
        if (
          i === "A5" ||
          i === "B5" ||
          i === "C5" ||
          i === "D5" ||
          i === "E5" ||
          i === "F5" ||
          i === "G5" ||
          i === "H5" ||
          i === "I5" ||
          i === "J5"
        ) {
          worksheet[i].s = {
            // styling for all cells
            font: {
              bold: true,
            },
            border: {
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              bottom: {
                style: "thin",
                color: "000000",
              },
            },
          };
        }
      }

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSX.writeFile(workbook, filename, { compression: true });
    };
    const obReports = () => {
      let rows = activityItemList.value
        .sort((valOne, valTwo) => {
          var departmentOne = valOne.department.toUpperCase();
          var departmentTwo = valTwo.department.toUpperCase();
          let department = 0;

          if (departmentOne > departmentTwo) {
            department = 1;
          } else if (departmentOne < departmentTwo) {
            department = -1;
          }

          var nameOne = valOne.name.toUpperCase();
          var nameTwo = valTwo.name.toUpperCase();
          let name = 0;

          if (nameOne > nameTwo) {
            name = 1;
          } else if (nameOne < nameTwo) {
            name = -1;
          }
          return department || name;
        })
        .map((val) => {
          return {
            department: val.department,
            name: val.name,
            day: val.day,
            startDate: val.startDate,
            renderTime: val.renderTime,
            purposes: val.purposes,
          };
        });
      let filename = `Official Business Reports - ${moment().format(
        "YYYYMMDD"
      )}.xlsx`;
      let startRangeFormat = moment(fromRangeDate.value).format("LL");
      let endRangeFormat = moment(endRangeDate.value).format("LL");
      let secondTitle = `Cut-off: ${startRangeFormat} - ${endRangeFormat}`;
      /* generate worksheet and workbook */

      let worksheet = XLSX.utils.json_to_sheet(rows, {
        origin: "A6",
        skipHeader: true,
      });

      /* XLSX.utils.sheet_add_aoa(worksheet, [["", "", "", "", "", ""]], {
        origin: -1,
      });*/

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [["Summary of Official Business Request"]],
        {
          origin: "A1",
        }
      );
      XLSX.utils.sheet_add_aoa(worksheet, [[secondTitle]], {
        origin: "A2",
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");

      /* fix headers */

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [["Department", "Name", "Day", "Date Start", "Render Time", "Purpose"]],
        {
          origin: "A5",
          skipHeader: true,
        }
      );

      /* calculate column width */
      var wscols = [
        { width: 35 },
        { width: 35 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 50 },
      ];

      worksheet["!cols"] = wscols;

      worksheet["A1"].s = {
        font: {
          name: "Calibri",
          sz: 14,
          bold: true,
        },
      };
      for (let i in worksheet) {
        if (typeof worksheet[i] != "object") continue;

        if (i !== "A1" && i !== "A2") {
          worksheet[i].s = {
            // styling for all cells

            border: {
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              bottom: {
                style: "thin",
                color: "000000",
              },
            },
          };
        }
        if (
          i === "A5" ||
          i === "B5" ||
          i === "C5" ||
          i === "D5" ||
          i === "E5" ||
          i === "F5" ||
          i === "G5" ||
          i === "H5" ||
          i === "I5" ||
          i === "J5"
        ) {
          worksheet[i].s = {
            // styling for all cells
            font: {
              bold: true,
            },
            border: {
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              bottom: {
                style: "thin",
                color: "000000",
              },
            },
          };
        }
      }

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSX.writeFile(workbook, filename, { compression: true });
    };
    const getDepartment = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        departmentItem.value = response.data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const convertTime = (val) => {
      let startTime = `${val.startDate} ${val.startTime}`;
      let endTime = `${val.endDate} ${val.endTime}`;
      let modFormat;

      modFormat = `${moment(startTime).format("LT")} - ${moment(endTime).format(
        "LT"
      )}`;
      return modFormat;
    };
    return {
      getDepartment,
      excelFormatted,
      itemDetails,
      showEntries,
      selectedEntry,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      searchValue,
      activityItemList,
      filterItem,
      prevRecords,
      nextRecords,
      getActivityLog,
      getFilterAction,
      itemDetailsOvertime,
      dateFormat,
      pagination,
      fromRangeDate,
      endRangeDate,
      convertToWord,
      excelFileName,
      departmentItem,
      selectedDepartment,
      reportItems,
      selectedReport,
      getReportLeave,
      getReportOvertime,
      convertTime,
      leaveReports,
      overtimeReports,
      getReportOfficialBusiness,
      itemDetailsOfficialBusiness,
      obReports,
    };
  },
};
</script>
<style scoped>
.page-container {
  font-size: 15px;
  padding: 18px;
}
.pagination {
  padding: 20px 0px 20px 0px;
}
.pages {
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
.show-entries {
  padding: 20px 0px 0px 0px;
}
.show-range-date {
  padding: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.filter {
  padding: 20px 0px 40px 0px;
}
.download {
  margin-top: 10px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    background-color: #ffffff;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>

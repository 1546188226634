<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="">
      <div class="form1">
        <div class="form-group">
          <label for=""> Date Filed: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Payee: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Amount: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Total: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Approved For Payment: <span class="required">*</span>
          </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for="">
            Received Payment: <span class="required">*</span>
          </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Remarks: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="4" />
          </div>
        </div>
        <div class="form-group">
          <button class="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>

<template>
  <div class="auth-content">
    <h1>Calendar Events</h1>
    <div class="filter-event">
      <div>Incoming</div>
      <div>Archived</div>
    </div>
    <div class="auth-dashboard-grid">
      <div v-for="(event, index) in eventLists" :key="index">
        <div class="card">
          <span v-if="$store.state.getUserType === 'super'" style="float: right"
            ><img src="../../assets/images/edit_icon.png" alt="icon" />
          </span>
          <div class="event-date">
            <span
              ><h1>{{ event.date }}</h1></span
            >
            <div class="date">
              <h2>{{ event.month }}</h2>
              <h3>{{ event.time }}</h3>
            </div>
          </div>
          <div class="event-name">
            <div><img src="../../assets/images/event.png" alt="icon" /></div>
            <div class="event-title">{{ event.title }}</div>
          </div>
          <div class="event-place">
            <div><img src="../../assets/images/location.png" alt="icon" /></div>
            <div class="event-title-place">{{ event.place }}</div>
          </div>
          <div class="event-attachment" @click="download(event.filename)">
            <div>
              <img src="../../assets/images/attachment.png" alt="icon" />
            </div>
            <div class="event-title-attachment">
              {{ !event.filename ? "No attachment" : event.filename }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="$store.state.getUserType === 'super'" class="card add-event">
        <img
          src="../../assets/images/add_icon.png"
          alt="icon"
          @click="isModal = true"
        />
      </div>
      <div v-if="false">
        <app-modal-calendar @passClose="getValue"></app-modal-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import { onActivated } from "vue";
import { inject, ref } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const eventLists = ref([]);
    const isModal = ref(false);
    onActivated(() => {
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        const response = await axios.get(`api/event`);
        eventLists.value = response.data.map((entry) => {
          return {
            ...entry,

            title: entry.name,
            place: entry.venue,
            date: moment(entry.date).format("DD"),
            month: moment(entry.date).format("MMMM"),
            time: `${entry.timeStart.slice(0, -3)} - ${entry.timeEnd.slice(
              0,
              -3
            )}`,
          };
        });
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };

    const getValue = (val, payload) => {
      isModal.value = val;

      if (payload) {
        setTimeout(async () => {
          dataLoad();
        }, 4000);
      }
    };

    const download = async (val) => {
      const filename = val;
      try {
        const response = await axios.get(
          `api/media/meta/resources/files/announcement/${filename}`
        );
        let url = response.data.mediaLink;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      eventLists,
      isModal,
      dataLoad,
      getValue,
      download,
    };
  },
};
</script>
<style scoped>
.auth-content {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  padding: 30px 20px 50px 20px;
  margin-top: 30px;
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.auth-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 30px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 120px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  font-family: "Poppins";
}
.event-date span h1 {
  font-size: 48px;
  font-family: "Montserrat";
  font-weight: 800;
}
.event-date .date {
  color: var(--lightSecondary);
  margin: 5px 8px 0px 15px;
  line-height: 22px;
}
.event-date .date h2 {
  font-size: 18px;
}
.event-date .date h3 {
  font-size: 14px;
}
.event-date,
.event-place,
.event-attachment,
.event-name {
  display: flex;
  font-family: "Poppins";
  padding: 2px 0px 2px 0px;
}
.event-attachment img,
.event-name img,
.event-place img {
  padding-right: 6px;
}
.event-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.event-title-attachment,
.event-title-place {
  font-size: 14px;
  line-height: 20px;
  margin-top: 1.5px;
}
.add-event {
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-attachment:hover {
  cursor: pointer;
}
.filter-event {
  padding: 15px 0px 0px 0px;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  column-gap: 30px;
}
@media only screen and (max-width: 600px) {
  .auth-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: auto;
  }
}
/* Medium Devices, Desktops */
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>
            {{
              titleModal === "liquidation"
                ? "List of Liquidated Account Expenses"
                : "List of Account Expenses"
            }}
          </h1>
        </div>
        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
      <div class="auth-grid">
        <div v-for="(item, index) in itemModified" :key="index">
          <div class="card">
            <div class="description">{{ index + 1 }}. {{ item.name }}</div>

            <div class="amount">{{ amountFormat(item.amount) }}</div>
          </div>
        </div>

        <div class="card">
          <h3>TOTAL AMOUNT</h3>
          <h3>{{ amountFormat(totalAmount) }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
import { computed } from "vue";
export default {
  components: {
    closeIconSVG,
  },
  props: {
    titleModal: String,
    expenseList: Object,
    totalAmount: Number,
    itemList: Object,
  },
  setup(props, { emit }) {
    const goRoute = (val) => {
      emit("passClose", val);
    };
    const itemModified = computed(() => {
      return props.expenseList.filter((val) => {
        if (props.itemList.liquidatedStatus === "Liquidation Disapproved") {
          return val.isActive === 0;
        } else {
          return val.isActive === 1;
        }
      });
    });
    const amountFormat = (val) => {
      return val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    };
    return {
      goRoute,
      amountFormat,
      itemModified,
    };
  },
};
</script>

<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 700px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
  padding-bottom: 15px;
}

.route {
  cursor: pointer;
}
.card {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: auto;
  font-family: "Montserrat";
  font-size: 14px;
}
.card .description,
.card .amount {
  font-size: 16px;
}

@media only screen and (min-width: 410px) and (max-width: 600px) {
  .modal-container {
    min-width: 200px;
  }
  .modal {
    width: 400px;
    padding: 10px 20px;
  }
  .title {
    font-size: 20px;
    padding-bottom: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .modal-container {
    min-width: 250px;
  }
  .modal {
    width: 450px;
    padding: 20px 30px;
  }
  .title {
    font-size: 25px;
    padding-bottom: 15px;
  }
  .card {
    padding: 10px 0px;
    font-size: 11px;
  }
  .card .description,
  .card .amount {
    font-size: 14px;
  }
}
</style>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="">
      <div class="form1">
        <div class="form-group">
          <label for=""> Date Filed: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Control No.: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Name: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Position: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Department: <span class="required">*</span> </label>
          <div class="input">
            <select name="department">
              <option value="" selected disabled>--Select Department--</option>
              <option v-for="(types, index) in departments" :key="index">
                {{ types.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for=""> Purpose: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="3" v-model="reason" />
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Borrower: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
          <div class="form-group">
            <label for=""> Approved By: <span class="required">*</span> </label>
            <div class="input">
              <input type="text" />
            </div>
          </div>
          <div class="form-group">
            <label for=""> Returned By: <span class="required">*</span> </label>
            <div class="input">
              <input type="text" />
            </div>
          </div>
          <div class="form-group">
            <label for=""> Received By: <span class="required">*</span> </label>
            <div class="input">
              <input type="text" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label for="">
            Transaction Type: <span class="required">*</span>
          </label>
          <div class="input">
            <select name="otType" v-model="otType" @change="handlerCategory">
              <option
                v-for="(types, index) in ovtTypes"
                :key="index"
                :value="types.code"
              >
                {{ types.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group" v-show="ifOTCompletion">
          <label for="">
            Search Reference Number: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="text"
              v-model="formData.referenceNumber"
              placeholder="Search reference number"
              @input="searchReferenceNumber()"
            />
          </div>
          <div
            class="otref-container"
            v-if="
              formData.referenceNumber && otreferenceItemList.length
                ? true
                : false
            "
          >
            <ul>
              <li
                v-for="(reference, index) in otreferenceItemList.slice(0, 10)"
                :key="index"
                @click="selectedReference(reference.id + 100000, index)"
              >
                {{ reference.id + 100000 }}
              </li>
            </ul>
          </div>
        </div>

        <div class="form-group" v-if="ifWithOB" v-show="ifOTCompletion">
          <label for="">
            Search OB Reference Number: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="text"
              v-model="formData.obRefNo"
              placeholder="Search reference number"
              @input="searchOBReferenceNumber()"
            />
          </div>
          <div
            class="otref-container"
            v-if="formData.obRefNo && obreferenceItemList.length ? true : false"
          >
            <ul>
              <li
                v-for="(reference, index) in obreferenceItemList.slice(0, 10)"
                :key="index"
                @click="selectedOBReference(reference.id + 100000, index)"
              >
                {{ reference.id + 100000 }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group" v-show="ifOTCompletion">
          <div class="checkbox-container">
            <label for=""> With OB Form: </label>
            <div class="input">
              <input
                type="checkbox"
                v-model="ifWithOB"
                @click="computeDurationLeave((ifWithOB = !ifWithOB))"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Render Start Date: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="date"
              v-model="formData.dateStart"
              @change="validateRefNo"
              :min="pastDates"
              :disabled="ifOTCompletion"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Render Start Time: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="time"
              v-model="formData.timeStart"
              @change="computeDurationLeave"
              :disabled="ifOTCompletion"
            />
          </div>
        </div>
        <div class="form-group" v-if="ifOTCompletion">
          <label for="">
            Render End Date: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="date"
              v-model="formData.dateEnd"
              @change="computeDurationLeave"
              :min="formData.dateStart"
              :disabled="!ifOTCompletion"
            />
          </div>
        </div>
        <div class="form-group" v-if="ifOTCompletion">
          <label for="">
            Render End Time: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="time"
              v-model="formData.timeEnd"
              @change="hrsComputation"
              :max="dtrOrigTimeOut"
              step="any"
            />
          </div>
        </div>
        <div class="form-group" v-if="ifOTCompletion">
          <label for=""> Total Time Rendered: </label>
          <div class="input">
            <input
              type="text"
              step="any"
              v-model="formData.numberHours"
              disabled
            />
          </div>
        </div>
        <span v-show="isDTR"
          ><h5 class="required">{{ DTRRemarks }}</h5></span
        >
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Description: </label>
          <div class="input">
            <textarea
              rows="5"
              v-model="formData.description"
              :disabled="ifOTCompletion"
            />
          </div>
        </div>
        <div class="form-group" v-if="ifOTCompletion">
          <label for=""> Accomplishment/Remarks: </label>
          <div class="input">
            <textarea rows="5" v-model="formData.remarks" />
          </div>
        </div>
        <div class="form-group">
          <button class="submit" :disabled="buttonMessageVisible">
            {{ !buttonMessageVisible ? "Submit" : "Please wait..." }}
          </button>
        </div>
        <div v-show="isAlert">
          <div v-show="isStatus" class="alert-error form-alert">
            {{ $store.state.formErrorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import moment from "moment";
import dtrAttendance from "@/components/utils/reusables/attendanceDTR";

export default {
  setup(props, { emit }) {
    const {
      getEmployeeDTR,
      dtrList,
      dtrTimeOut,
      dtrOrigTimeOut,
      dtrHrsRender,
    } = dtrAttendance();
    const axios = inject("$axios");
    const store = inject("$store");
    const isAlert = ref(false);
    const isStatus = ref(false);
    const isDTR = ref(false);
    const ifOTCompletion = ref(false);
    const ifWithOB = ref(false);
    const ifWithOBShow = ref(false);
    const shiftingDesc = ref(null);
    const isInvalidTime = ref(false);
    const dtrOut = ref(null);
    const curTimeIn = ref(null);
    const DTRRemarks = ref("");
    const logMessages = ref("");
    const pastDates = ref(null);
    const refNoIndex = ref(null);
    const empSchedId = ref([]);
    const otType = ref("FNO");
    const otReferenceList = ref([]);
    const obReferenceList = ref([]);
    const otreferenceItemList = ref([]);
    const obreferenceItemList = ref([]);
    const obitemDetails = ref([]);
    const dtrs = ref([]);
    const employeeSched = ref([]);
    const shiftingSched = ref([]);
    const shiftingSchedDetails = ref([]);
    const shiftingSchedDay = ref([]);
    const filteredRef = ref([]);
    const inputRef = ref("");
    const ovtTypes = reactive([
      { name: "File New Overtime", code: "FNO" },
      { name: "Overtime Completion", code: "OC" },
    ]);
    const formData = reactive({
      referenceNumber: null,
      obRefNo: 0,
      empId: store.state.getEmployeeId,
      employeeno: store.state.getProfileInfo.employee_number,
      department: store.state.getDepartment,
      designation: store.state.getDesignation,
      dateStart: null,
      timeStart: null,
      dateEnd: null,
      timeEnd: null,
      numberHours: 0,
      description: null,
      remarks: null,
      requestedBy: store.state.getUserLog,
    });
    const dataItems = ref([]);
    const buttonMessageVisible = ref(false);
    onMounted(() => {
      store.commit("globalDate");
      getOTReferenceNumber();
      getOBReferenceNumber();
      getFilingFormat();
      computeDurationLeave();
      getDTRs();
      employeeSchedList();
      employeeShiftingSched();
    });
    const handleSubmit = async () => {
      handleValidation(formData);
    };
    const getFilingFormat = () => {
      let previousDate;
      let graceDay =
        store.state.getProfileInfo.filingLimit === 2
          ? 0
          : store.state.getProfileInfo.filingLimit;
      previousDate = moment().subtract(graceDay, "days").format("YYYY-MM-DD");
      pastDates.value = previousDate;
    };
    const insertFormData = async () => {
      try {
        if (otType.value === "FNO") {
          await axios.post(`/api/form/ot`, formData);
          isAlert.value = false;
          const SEQUENCE_ARRAY_CODE = 2;
          store.commit("successAlert", SEQUENCE_ARRAY_CODE);
          clearFormField(true);
        } else if (otType.value === "OC") {
          let id = formData.referenceNumber - 100000;
          await axios.put(`/api/form/ot/${id}`, {
            dateEnd: formData.dateEnd,
            timeEnd: formData.timeEnd,
            numberHours: formData.numberHours,
            remarks: formData.remarks,
          });
          isAlert.value = false;
          const SEQUENCE_ARRAY_CODE = 2;
          store.commit("successAlert", SEQUENCE_ARRAY_CODE);
          clearFormField(true);
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          formData.otType = ref("FNO");
          formData.referenceNumber = null;
          formData.obRefNo = 0;
          formData.dateStart = null;
          formData.timeStart = null;
          formData.dateEnd = null;
          formData.timeEnd = null;
          formData.numberHours = 0;
          formData.description = null;
          formData.remarks = null;
          ifWithOB.value = false;
          formData.requestedBy = store.state.getUserLog;
          buttonMessageVisible.value = false;
        }, 1100);
      }
    };

    const handleValidation = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (otType.value === "FNO") {
          if (
            key !== "dateEnd" &&
            key !== "obRefNo" &&
            key !== "referenceNumber" &&
            key !== "timeEnd" &&
            key !== "remarks" &&
            key !== "numberHours"
          ) {
            if (!value) {
              validate.push(key);
            }
          }
        } else if (otType.value === "OC") {
          if (ifWithOB.value) {
            if (key !== "remarks" && key !== "obRefNo") {
              if (!value) {
                validate.push(key);
              }
            }
          } else {
            if (key !== "remarks" && key !== "obRefNo") {
              if (!value) {
                validate.push(key);
              }
            }
          }
        }
      });

      if (isDTR.value || isInvalidTime.value) {
        isStatus.value = true;
        isAlert.value = true;
        store.state.formErrorMessage = `There was a problem with your submission. Please review the fields that require your attention.`;
      } else if (validate.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            buttonMessageVisible.value = true;
            insertFormData();
            removeRefNo(formData.referenceNumber);
            emit("isLoaded");
          }
        });
      }
      setTimeout(() => {
        isStatus.value = false;
      }, 8000);
    };

    const computeDurationLeave = () => {
      const otStart = `${formData.dateStart} ${formData.timeStart}`;
      const otEnd = `${formData.dateEnd} ${formData.timeEnd}`;
      formData.numberHours = moment
        .utc(
          moment(otEnd, "YYYY-MM-DD HH:mm").diff(
            moment(otStart, "YYYY-MM-DD HH:mm")
          )
        )
        .format("HH:mm");

      if (formData.numberHours === "Invalid date") {
        formData.numberHours = 0;
      } else {
        for (const item of empSchedId.value) {
          curTimeIn.value = item.timeIn;
          shiftingDesc.value = item.description;
        }

        if (shiftingDesc.value === "Shifting") {
          // For CSD (Early OT and Regular OT)

          const modified = shiftingSchedDetails.value.filter((ob) => {
            return ob.date == formData.dateStart;
          });
          shiftingSchedDay.value = modified;

          for (const day of shiftingSchedDay.value) {
            curTimeIn.value = day.timeIn;
          }
          if (formData.timeStart < curTimeIn.value) {
            // For early OT - CSD
            formData.dateEnd = formData.dateStart;
            formData.timeEnd = curTimeIn.value;

            const otStart = `${formData.dateStart} ${formData.timeStart}`;
            const otEnd = `${moment().format("YYYY-MM-DD")} ${curTimeIn.value}`;
            formData.numberHours = moment
              .utc(
                moment(otEnd, "YYYY-MM-DD HH:mm").diff(
                  moment(otStart, "YYYY-MM-DD HH:mm")
                )
              )
              .format("HH:mm");
          } else {
            // For regular OT - CSD
            let obDetails = obitemDetails.value;
            getEmployeeDTR(dataItems.value, formData.dateEnd, obDetails);
            formData.timeEnd = dtrTimeOut;
            formData.numberHours = dtrHrsRender;
          }
        } else {
          //not shifting
          let obDetails = obitemDetails.value;
          getEmployeeDTR(dataItems.value, formData.dateEnd, obDetails);
          formData.timeEnd = dtrTimeOut;
          formData.numberHours = dtrHrsRender;
        }
      }
    };

    const getOTReferenceNumber = async () => {
      try {
        const { data } = await axios.get("/api/otreference");
        otReferenceList.value = data.filter((item) => {
          const dateA = moment(item.dateStart);
          const dateB = moment();
          const totDays = dateB.diff(dateA, "days");
          let NUMBER_FILTER_LIMIT = store.state.NUMBER_FILING_LIMIT + 1;
          if (totDays <= NUMBER_FILTER_LIMIT) {
            return item;
          }
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getDTRs = async () => {
      try {
        const empNum = store.state.getProfileInfo.employee_number;
        const { data } = await axios.get(`/api/empLogout/${empNum}`);
        dtrs.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const searchReferenceNumber = () => {
      otreferenceItemList.value = otReferenceList.value.filter((item) => {
        let convertString = 100000 + item.id;
        return convertString
          .toString()
          .includes(formData.referenceNumber.trim());
      });
    };

    const selectedReference = (referenceNumber, index) => {
      let itemDetails = otreferenceItemList.value[index];
      refNoIndex.value = index;
      dataItems.value = itemDetails;
      formData.referenceNumber = referenceNumber;
      // ot table
      formData.dateStart = itemDetails.dateStart;
      formData.timeStart = itemDetails.timeStart;
      formData.description = itemDetails.description;
      formData.dateEnd = null;
      formData.timeEnd = null;
      const dateA = moment(formData.dateStart);
      const dateB = moment();
      const totDays = dateB.diff(dateA, "days");
      if (totDays > store.state.NUMBER_FILING_LIMIT) {
        // Late Filing
        isInvalidTime.value = true;
        isDTR.value = true;
        DTRRemarks.value = "Late filing! Please coordinate with HR.";
      } else {
        isInvalidTime.value = false;
        isDTR.value = false;
        computeDurationLeave();
      }

      otreferenceItemList.value = [];
    };

    // For OB Reference Number --------------------------------------------- //

    const getOBReferenceNumber = async () => {
      try {
        const empDepartment = store.state.getProfileInfo.department;
        const { data } = await axios.get("/api/obForOtreference");
        obReferenceList.value = data.filter((item) => {
          if (
            empDepartment === "Corporate Services" ||
            empDepartment === "Astral Clinic"
          ) {
            return (
              item.status === "Pre-qualified" || item.status === "Approved"
            );
          } else {
            return item.status === "Approved";
          }
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const searchOBReferenceNumber = () => {
      obreferenceItemList.value = obReferenceList.value.filter((item) => {
        let convertString = 100000 + item.id;
        return convertString.toString().includes(formData.obRefNo.trim());
      });
    };

    const selectedOBReference = (referenceNumber, index) => {
      isInvalidTime.value = false;
      isDTR.value = false;

      obitemDetails.value = obreferenceItemList.value[index];

      formData.obRefNo = referenceNumber;

      // ot table

      formData.dateStart = obitemDetails.value.obDate;
      formData.dateEnd = obitemDetails.value.obDate;
      formData.timeEnd = obitemDetails.value.returnTime;
      formData.description = obitemDetails.value.remarks;
      computeDurationLeave();

      obreferenceItemList.value = [];
    };

    const employeeSchedList = async () => {
      try {
        const empschedIdVal =
          store.state.getProfileInfo.maintenanceScheduleAttendanceId;
        const { data } = await axios.get("/api/attendanceSchedule");
        employeeSched.value = data;

        const modified = employeeSched.value.filter((ob) => {
          return ob.id == empschedIdVal;
        });
        empSchedId.value = modified;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const employeeShiftingSched = async () => {
      try {
        const empschedIdVal = store.state.getProfileInfo.id;
        const { data } = await axios.get("/api/shiftingSchedule");
        shiftingSched.value = data;
        const modified = shiftingSched.value.filter((ob) => {
          return ob.employeeId == empschedIdVal;
        });
        shiftingSchedDetails.value = modified;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const hrsComputation = () => {
      const otStart = `${formData.dateStart} ${formData.timeStart}`;
      const otEnd = `${formData.dateEnd} ${formData.timeEnd}`;
      formData.numberHours = moment
        .utc(
          moment(otEnd, "YYYY-MM-DD HH:mm").diff(
            moment(otStart, "YYYY-MM-DD HH:mm")
          )
        )
        .format("HH:mm");
    };
    const handlerCategory = () => {
      let selectedOPType = otType.value;
      if (selectedOPType === "FNO") {
        ifOTCompletion.value = false;
        ifWithOB.value = false;
      } else {
        ifOTCompletion.value = true;
        ifWithOB.value = true;
      }
      formData.referenceNumber = null;
      formData.obRefNo = 0;
      formData.dateStart = null;
      formData.timeStart = null;
      formData.dateEnd = null;
      formData.timeEnd = null;
      formData.numberHours = 0;
      formData.description = null;
      formData.remarks = null;
      ifWithOB.value = false;
    };

    const validateRefNo = () => {
      if (ifWithOB.value) {
        if (!obreferenceItemList.value.includes(formData.obRefNo)) {
          isInvalidTime.value = true;
          isDTR.value = true;
          DTRRemarks.value = "Invalid Reference Number!";
        }
      }
    };

    const removeRefNo = () => {
      otReferenceList.value.splice(refNoIndex.value, 1);
    };

    return {
      formData,
      insertFormData,
      isStatus,
      isAlert,
      clearFormField,
      handleValidation,
      handleSubmit,
      computeDurationLeave,
      logMessages,
      isDTR,
      DTRRemarks,
      pastDates,
      ovtTypes,
      ifOTCompletion,
      otType,
      getOTReferenceNumber,
      searchReferenceNumber,
      otreferenceItemList,
      obreferenceItemList,
      otReferenceList,
      inputRef,
      selectedReference,
      getDTRs,
      dtrs,
      searchOBReferenceNumber,
      getOBReferenceNumber,
      obReferenceList,
      ifWithOB,
      ifWithOBShow,
      selectedOBReference,
      isInvalidTime,
      employeeSched,
      employeeSchedList,
      empSchedId,
      dtrOut,
      curTimeIn,
      employeeShiftingSched,
      shiftingSched,
      shiftingSchedDetails,
      shiftingDesc,
      shiftingSchedDay,
      getEmployeeDTR,
      dtrList,
      dataItems,
      dtrTimeOut,
      dtrOrigTimeOut,
      dtrHrsRender,
      hrsComputation,
      handlerCategory,
      filteredRef,
      validateRefNo,
      removeRefNo,
      refNoIndex,
      buttonMessageVisible,
    };
  },
};
</script>

<style scoped>
.otref-container {
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  max-width: auto;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  cursor: pointer;
}
.otref-container ul {
  list-style: none;
  line-height: 30px;
}
.otref-container ul li:hover {
  background-color: #e0dede;
}

.checkbox-container {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;
}

.checkbox-container label {
  margin-right: 14px;
}
.checkbox-container .input input {
  margin-top: 6px;
  cursor: pointer;
  width: 25px;
  text-align: center;
}
</style>

<template>
  <div v-if="isRender" class="waiting">
    <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
  </div>
  <SectionLeaveStatus v-else :transaction="transactions"></SectionLeaveStatus>
  <div>
    <div class="header-form">Leave Details</div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <div v-else class="grid-container">
      <table>
        <tr>
          <td class="title">Reference Number:</td>
          <td class="description">{{ transactions.id + 100000 }}</td>
        </tr>
        <tr>
          <td class="title">Type of Leave:</td>
          <td class="description">
            {{ selectedLeave(transactions.leaveType) }}
          </td>
        </tr>
        <tr>
          <td class="title">Category Leave:</td>
          <td class="description">{{ categoryLeave }}</td>
        </tr>
        <tr>
          <td class="title">Duration Date:</td>
          <td class="description">{{ durationDate }}</td>
        </tr>
        <tr>
          <td class="title">Number of day(s):</td>
          <td class="description">{{ renderDay }}</td>
        </tr>
        <tr v-if="transactions.leaveType !== 'BL'">
          <td class="title">Reason:</td>
          <td class="description">{{ transactions.reason }}</td>
        </tr>
        <tr>
          <td class="title">Date of Resume:</td>
          <td class="description">{{ dateResume }}</td>
        </tr>
        <tr v-if="transactions.approverRemarks">
          <td class="title">Disapproved Remarks:</td>
          <td class="description">{{ transactions.approverRemarks }}</td>
        </tr>
        <tr
          v-show="
            !transactions.isPreapproved &&
            transactions.approverStatus !== 'Revert'
          "
        >
          <td class="title">Cancel Request:</td>
          <td class="description">
            <label class="toggle">
              <input
                type="checkbox"
                id="switch"
                v-model="isToggle"
                @change="toggleHandler"
              />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
        <div v-if="isToggle">
          <app-modal-leave-approval
            :requestId="transactions.id"
            :dataItems="transactions"
            :transaction="transactions"
            userType="Employee"
            @passClose="getValue"
          ></app-modal-leave-approval>
        </div>
      </table>
    </div>
  </div>
</template>
<script>
import SectionLeaveStatus from "@/components/partials/employees/AuthEmployeeStatusTracker.vue";
import { inject, onMounted, ref, computed } from "vue";
export default {
  components: {
    SectionLeaveStatus,
  },
  setup() {
    const store = inject("$store");
    const axios = inject("$axios");
    const moment = inject("$moment");
    const transactions = ref([]);
    const isToggle = ref(false);
    const isRender = ref(true);
    onMounted(() => {
      getData();
    });
    const getData = async () => {
      try {
        const response = await axios.get(`api/form/leave`);
        transactions.value = response.data;

        const result = transactions.value.filter((post) => {
          return post.id === store.state.applicationId;
        });
        transactions.value = result[0];
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const selectedLeave = (leaveType) => {
      if (leaveType === "VL") {
        leaveType = "Vacation Leave";
      } else if (leaveType === "SL") {
        leaveType = "Sick Leave";
      } else if (leaveType === "EL") {
        leaveType = "Emergency Leave";
      } else if (leaveType === "AWOP") {
        leaveType = "Absence Without Pay";
      } else if (leaveType === "CL") {
        leaveType = "Compassionate Leave";
      } else if (leaveType === "ML") {
        leaveType = "Maternity Leave";
      } else if (leaveType === "PL") {
        leaveType = "Paternity Leave";
      } else if (leaveType === "BL") {
        leaveType = "Birthday Leave";
      } else if (leaveType === "O") {
        leaveType = "Others";
      }
      return leaveType;
    };
    const durationDate = computed(() => {
      return `${moment(transactions.value.dateStart).format("ll")}  -  ${moment(
        transactions.value.dateEnd
      ).format("ll")}`;
    });
    const renderDay = computed(() => {
      const word = transactions.value.numberDays > 1 ? "Days" : "Day";
      return `${transactions.value.numberDays} ${word}`;
    });

    const categoryLeave = computed(() => {
      let modified;
      if (transactions.value.leaveCategory === "Half Day (AM)") {
        modified = `Half Day (Morning)`;
      } else if (transactions.value.leaveCategory === "Half Day (PM)") {
        modified = `Half Day (Afternoon)`;
      } else {
        modified = transactions.value.leaveCategory;
      }
      return modified;
    });
    const dateResume = computed(() => {
      return moment(transactions.value.dateResume).format("LL");
    });
    const getValue = (
      val,
      payload,
      reqId,
      status,
      date,
      superiorDate,
      remarks
    ) => {
      isToggle.value = val;
      if (payload) {
        setTimeout(async () => {
          transactions.value.approverStatus = "Revert";
          transactions.value.approverRemarks = remarks;
        }, 2000);
      }
    };
    return {
      getData,
      selectedLeave,
      transactions,
      categoryLeave,
      renderDay,
      durationDate,
      dateResume,
      isToggle,
      getValue,
      isRender,
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;

  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 35px;
}
table > tr .title,
table > tr .description {
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 170px;
  font-family: "Montserrat";
  font-weight: 500;
}
.description {
  width: 360px;
  color: #272626;
}

.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.col-status-one,
.col-status-two,
.col-status-three {
  background-color: green;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-three {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img {
  width: 50px;
  height: 50px;
}

.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 24px 0px;
}
.header-form {
  padding-bottom: 30px;
  color: var(--lightFont);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}

@media only screen and (max-width: 600px) {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 12px;
    width: 100%;
  }
  table > tr {
    height: 28px;
  }
  table > tr .title,
  table > tr .description {
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 124px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .description {
    max-width: 100%;
    color: #272626;
  }
  .grid-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0px 30px 0px 30px;
  }

  .col-status-one,
  .col-status-two,
  .col-status-three {
    height: 10px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img {
    width: 34px;
    height: 34px;
  }
  .icons {
    height: 70px;
    font-size: 12px;
  }
  .header-form {
    padding-bottom: 30px;
    font-size: 16px;
  }
}
</style>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label for="">Firstname: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" placeholder="firstname" v-model="firstName" />
          </div>
        </div>
        <div class="form-group">
          <label for="">Middlename: </label>
          <div class="input">
            <input type="text" placeholder="middlename" v-model="middleName" />
          </div>
        </div>
        <div class="form-group">
          <label for="">Lastname: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" placeholder="lastname" v-model="lastName" />
          </div>
        </div>
        <div class="form-group">
          <label for="">Suffix: </label>
          <div class="input">
            <input type="text" placeholder="suffix" v-model="suffix" />
          </div>
        </div>
        <div class="form-group">
          <label for="">Designation: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="text"
              placeholder="designation"
              v-model="designation"
            />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Department: <span class="required">*</span> </label>
          <div class="input">
            <select name="department" v-model="department">
              <option value="" selected disabled>--Select Department--</option>
              <option v-for="(types, index) in departments" :key="index">
                {{ types.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for=""> Home Address: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="5" v-model="homeAddress" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Birthdate: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="birthDate" />
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Contact Number: <span class="required">*</span>
          </label>
          <div class="input">
            <input type="number" v-model="contactNumber" />
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Tin Number: </label>
          <div class="input">
            <input type="number" v-model="tinNumber" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> SSS Number: </label>
          <div class="input">
            <input type="number" v-model="sssNumber" />
          </div>
        </div>
        <div class="form-group sub-title">
          <label for="">
            <h4>(CONTACT PERSON IN CASE OF EMERGENCY)</h4>
          </label>
        </div>
        <div class="form-group">
          <label for="">Name: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="contactName" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Home Address: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="5" v-model="contactHomeAddress" />
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Contact Number: <span class="required">*</span>
          </label>
          <div class="input">
            <input type="number" v-model="contactMobile" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Signature: <span class="required">*</span> </label>
          <div class="input">
            <input type="file" style="width: 40px" />
          </div>
        </div>
        <div class="form-group">
          <button class="submit">Submit</button>
        </div>
        <div v-show="isStatus" class="alert-error form-alert">
          {{ $store.state.formErrorMessage }}
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");

    const isVisible = ref(false);
    const isStatus = ref(false);
    const dateStatus = ref(null);
    const idForm = reactive({
      requestedByEmpId: store.state.getEmployeeId,
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      designation: null,
      homeAddress: null,
      birthDate: null,
      contactNumber: null,
      tinNumber: null,
      sssNumber: null,
      contactName: null,
      contactHomeAddress: null,
      contactMobile: null,
    });

    const handleSubmit = async () => {
      handleValidate(idForm);
    };
    const insertData = async () => {
      try {
        await axios.post(`/api/form/id`, idForm);
        isStatus.value = false;
        const SEQUENCE_ARRAY_CODE = 3;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearField(true);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const clearField = (value) => {
      if (value) {
        setTimeout(function () {
          isStatus.value = false;
        }, 1100);
      }
    };
    const handleValidate = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (!value) {
          validate.push(key);
        }
      });
      if (validate.length) {
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else {
        isStatus.value = false;
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            insertData();
          }
        });
      }
    };
    return {
      isVisible,
      isStatus,
      dateStatus,
      ...toRefs(idForm),
      handleValidate,
      handleSubmit,
      insertData,
      clearField,
    };
  },
};
</script>

<template>
  <div class="cpanel">
    <table>
      <tr>
        <td class="title">Reference Number:</td>
        <td class="description">{{ referenceFormat(transaction.id) }}</td>
      </tr>
      <tr>
        <td class="title">Date Intended and Time:</td>
        <td class="description">
          {{
            dateFormat(
              transaction.dateIntended,
              transaction.timeStart,
              transaction.timeEnd
            )
          }}
        </td>
      </tr>
      <tr>
        <td class="title">Purpose:</td>
        <td class="description">
          {{ transaction.purpose }}
        </td>
      </tr>
      <tr>
        <td class="title">Remarks:</td>
        <td class="description">
          {{ transaction.remarks }}
        </td>
      </tr>
      <tr>
        <td class="title">Requested by:</td>
        <td class="description">
          {{ transaction.requestedBy }}
        </td>
      </tr>
      <tr>
        <td class="title">Status:</td>
        <td class="description">
          {{ !transaction.status ? "Pending" : transaction.status }}
        </td>
      </tr>
      <tr>
        <td class="title">Approve by:</td>
        <td class="description">
          {{ transaction.approverBy ? transaction.approverBy : "-" }}
        </td>
      </tr>
      <tr>
        <td class="title">Approved date:</td>
        <td class="description">
          {{ transaction.dtapproved }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { ref, onMounted, inject } from "vue";
export default {
  components: {},
  props: {
    transaction: Object,
  },
  setup(props) {
    const moment = inject("$moment");
    const store = inject("$store");
    const isModalStatus = ref(false);
    onMounted(() => {
      store.commit("travelReference", props.transaction);
      if (screen.width <= 600) {
        window.scrollTo(0, 0);
      }
    });
    const referenceFormat = (reference) => {
      let serial = 100000;
      return reference + serial;
    };
    const dateFormat = (date, start, end) => {
      let modFormat;
      let startTime = `${date} ${start}`;
      let endTime = `${date} ${end}`;

      modFormat = `${moment(date).format("LL")} (${moment(startTime).format(
        "LT"
      )} - ${moment(endTime).format("LT")})`;
      return modFormat;
    };

    return {
      isModalStatus,
      dateFormat,
      referenceFormat,
    };
  },
};
</script>
<style scoped>
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #666;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  border: 1px solid #666;
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 230px;
  font-family: "Montserrat";
  font-weight: 800;
  color: #474646;
}
.description {
  max-width: 100%;
  color: #272626;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 14px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
@media only screen and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 12px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 160px;
    font-weight: 500;
    color: #272626;
  }
}
</style>

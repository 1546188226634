<template>
  <app-auth-title />
  <div class="auth-content">
    <div class="employee-list" v-if="isProfile">
      <h1>Employees ({{ employeeList.length }})</h1>
      <div class="filter-container">
        <div class="search-bar">
          <input
            type="text"
            placeholder="search..."
            v-model="searchValue"
            @input="filterMasterList()"
          />
        </div>
        <div
          class="filter"
          v-if="
            $store.state.getUserType === 'super' ||
            $store.state.getUserType === 'admin-moderator'
          "
        >
          <select
            v-model="selectedDepartment"
            class="item-entries"
            @change="filterMasterList()"
          >
            <option>All</option>
            <option v-for="item in departmentItem" :key="item">
              {{ item.deptName }}
            </option>
          </select>
        </div>
      </div>
      <div class="onDuty">
        On duty
        <label class="toggle">
          <input
            type="checkbox"
            id="switch"
            v-model="onDuty"
            @change="filterMasterList()"
          />
          <span class="slider"></span>
        </label>
      </div>
      <span v-if="isRender" class="waiting">
        <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
      </span>
      <div v-else class="auth-dashboard-grid">
        <div
          v-if="$store.state.getUserType === 'admin-moderator'"
          class="card add-event"
        >
          <img
            :src="require('@/assets/images/add_icon.png')"
            alt="mobile"
            @click="
              (activeComponent = 'SectionEmployeeEncoding'),
                (isProfile = false),
                (isEntry = false);
              isEditMode = false;
            "
          />
        </div>
        <div v-for="(item, index) in employeeList" :key="index">
          <div class="card">
            <div class="card-profile">
              <div class="ring-container" v-show="true">
                <div
                  class="badge"
                  :class="[item.ifDtr ? 'online' : 'offline']"
                ></div>
              </div>
              <img class="profile-round" :src="item.mediaLink" alt="Profile" />
              <h6 v-show="true">{{ status(item) }}</h6>
              <h4>{{ item.name }}</h4>
              <h5>{{ item.designation }}</h5>
              <span
                class="btn-view"
                v-show="true"
                @click="handlerProfileDetail(item.id, item)"
                >View Profile</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <SectionEmployeeProfile
        @editMode="handlerEdit"
        v-if="isEntry || isEdit"
        :selectedEmployee="employeeSelected"
        :employeeProfileDetails="employeeDetails"
        @isBackMenu="(isProfile = true), (activeComponent = 'indexProfile')"
      />
      <SectionNavigationProfile
        @currentComponent="selectedComponent"
        v-if="isEntry || isEdit"
      />
      <hr v-if="isEntry || isEdit" />
      <section>
        <component
          @isBackMenu="handlerRoute"
          :is="activeComponent"
          :employeeProfileDetails="employeeDetails"
          :ifEditmode="isEditMode"
        ></component>
      </section>
    </div>
  </div>
</template>

<script>
import SectionEmployeeProfile from "@/components/pages/admin/Employees/masterlist/SectionEmployeeProfile.vue";
import SectionNavigationProfile from "@/components/pages/admin/Employees/masterlist/SectionNavigationProfile.vue";
import indexProfile from "@/components/pages/admin/Employees/masterlist/SectionIndexProfile.vue";
import indexEmployment from "@/components/pages/admin/Employees/masterlist/SectionEmployeeEmployment.vue";
import indexAttedance from "@/components/pages/admin/Employees/masterlist/SectionEmployeeAttendance.vue";
import SectionEmployeeEncoding from "@/components/pages/admin/Employees/masterlist/SectionEmployeeEncoding.vue";
import { onActivated } from "vue";
import { inject, ref } from "vue";
export default {
  components: {
    SectionEmployeeProfile,
    SectionNavigationProfile,
    SectionEmployeeEncoding,
    indexProfile,
    indexEmployment,
    indexAttedance,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const itemData = ref([]);
    const employeeList = ref([]);
    const isModal = ref(false);
    const departmentItem = ref([]);
    const selectedDepartment = ref("All");
    const searchValue = ref(null);
    const employeeDetails = ref([]);
    const employeeSelected = ref(null);
    const activeComponent = ref("indexProfile");
    const isProfile = ref(true);
    const isEntry = ref(true);
    const isRender = ref(true);
    const isEdit = ref(false);
    const isEditMode = ref(false);
    const onDuty = ref(0);
    onActivated(() => {
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        const response = await axios.get(`api/getUsers`);
        itemData.value = response.data;
        isProfile.value = true;
        isEntry.value = true;
        getDepartment();
        filterMasterList();
        isRender.value = false;
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const filterMasterList = () => {
      employeeList.value = itemData.value
        .filter((val) => {
          return val.isBiotime > 0 && val.isBiotime < 3;
        })
        .filter((val) => {
          let department = store.state.getDepartment;
          let userType = store.state.getUserType;
          let result;

          if (userType === "super" || userType === "admin-moderator") {
            result = val;
          } else if (
            userType === "moderator" &&
            (val.department === "Marketing" ||
              val.department === "Health Provider & Patient Relations")
          ) {
            result = val;
          } else if (val.department === department) {
            result = val;
          }

          return result;
        })
        .filter((val) => {
          if (searchValue.value) {
            return val.name
              .toLowerCase()
              .includes(searchValue.value.toLowerCase());
          } else {
            return val;
          }
        })
        .filter((val) => {
          let userType = store.state.getUserType;
          let result;

          if (selectedDepartment.value === "All") {
            result = val;
          } else if (userType === "super" || userType === "admin-moderator") {
            result = val.department === selectedDepartment.value;
          }
          return result;
        })
        .filter((val) => {
          let toggleStat = onDuty.value ? 1 : 0;
          if (toggleStat) {
            return val.ifDtr;
          } else {
            return val;
          }
        })
        .sort((a, b) => (a.name < b.name ? 1 : -1));
    };
    const getDepartment = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        departmentItem.value = response.data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const selectedComponent = (val) => {
      activeComponent.value = val;
    };
    const status = (val) => {
      let stat = val.ifDtr;
      let date = val.dtrDate;
      let time = val.dtrTime;
      let dateLogged = `${date} ${time}`;
      let statusMessage;
      let offline = moment(dateLogged).fromNow();
      if (stat) {
        statusMessage = "On duty";
      } else {
        if (val.dtrDate) {
          statusMessage = `Off duty ${offline}`;
        } else {
          statusMessage = `Offline`;
        }
      }

      return statusMessage;
    };
    const handlerProfileDetail = (id, details) => {
      employeeSelected.value = id;
      employeeDetails.value = details;
      activeComponent.value = "indexProfile";
      isProfile.value = false;
      store.state.getTitleHeaderForm = null;
    };
    const handlerEdit = (val) => {
      isEditMode.value = val;
      activeComponent.value = "SectionEmployeeEncoding";
      isEdit.value = false;
      isEntry.value = false;
    };
    const handlerRoute = (val) => {
      if (val) {
        dataLoad();
      }
      isProfile.value = true;
      isEntry.value = true;
    };

    return {
      employeeList,
      itemData,
      isModal,
      dataLoad,
      searchValue,
      departmentItem,
      selectedDepartment,
      filterMasterList,
      selectedComponent,
      activeComponent,
      employeeDetails,
      employeeSelected,
      handlerProfileDetail,
      isProfile,
      status,
      isEntry,
      isEdit,
      handlerEdit,
      isEditMode,
      handlerRoute,
      onDuty,
      isRender,
    };
  },
};
</script>
<style scoped>
.auth-content {
  padding: 30px 20px 50px 20px;
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.auth-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 120px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  font-family: "Poppins";
}
img {
  height: 100px;
  width: 100px;
}
.card-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
h5 {
  color: #fa7319;
  font-weight: 800;
}
.search-bar {
  margin: 10px 0px;
}
.search-bar input {
  height: 48px;
  width: 480px;
}
.filter-container {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
}
.item-entries {
  height: 42px;
}
.btn-view {
  background-color: #ff5303;
  padding: 5px 20px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
}
section {
  margin: 0px 20px;
}
.badge {
  height: 18px;
  width: 18px;
  left: 20px;
  right: 0px;
  top: 85px;
  z-index: 1;
}
.add-event {
  display: flex;
  justify-content: center;
  align-items: center;
}
.onDuty {
  color: var(--lightSecondary);
  font-family: "Montserrat";
  margin: 10px 0px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
}
.waiting img {
  height: 24px;
  width: 24px;
}
@media only screen and (max-width: 600px) {
  .auth-content {
    padding: 18px 12px 50px 12px;
  }
  .auth-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: auto;
  }
  .filter-container {
    flex-direction: column;
  }
  img {
    height: 60px;
    width: 60px;
  }
  h5 {
    font-size: 13px;
  }
  h4 {
    font-size: 12px;
  }
  .search-bar input {
    width: auto;
    max-width: auto;
  }
  section {
    margin: 0px 10px;
  }
  .badge {
    height: 16px;
    width: 16px;
    left: 13px;
    right: 0px;
    top: 48px;
    z-index: 1;
  }
  .btn-view {
    padding: 5px 15px;
    font-size: 12px;
  }
}
/* Medium Devices, Desktops */
</style>

<template>
  <div class="auth-section">
    <h3>Leave Credit Details</h3>
    <div class="section-container">
      <table>
        <tr class="flex">
          <td class="title">Sick Leave:</td>
          <td class="description">
            {{ availability(employeeProfileDetails.slBalance) }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Vacation Leave:</td>
          <td class="description">
            {{ availability(employeeProfileDetails.vlBalance) }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Birthday Leave:</td>
          <td class="description">
            {{ employeeBirthDate }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Solo Parent Leave:</td>
          <td class="description">
            {{ availability(employeeProfileDetails.splCredit, 1) }}
          </td>
        </tr>
        <tr class="flex" v-if="employeeProfileDetails.gender === 'F'">
          <td class="title">VAWC Credit:</td>
          <td class="description">
            {{ availability(employeeProfileDetails.vawcCredit, 2) }}
          </td>
        </tr>
      </table>
    </div>
    <h3>Work Schedule</h3>
    <div class="section-container">
      <div class="card-rows">
        <div v-if="isLoading" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <div class="card-schedule" v-else>
          <div v-if="isVisible" class="header-sched">
            <span>
              <img
                class="action-toggle"
                @click="filterHandler((selectedWeek -= 1))"
                :src="require(`@/assets/images/previous_.png`)"
                alt="icon"
              />
            </span>
            <h4>{{ scheduleInformation }}</h4>
            <span>
              <img
                class="action-toggle"
                @click="filterHandler((selectedWeek += 1))"
                :src="require(`@/assets/images/next_.png`)"
                alt="icon"
              />
            </span>
          </div>
          <div class="card-cols">
            <div
              @mouseover="hoverHandler(index)"
              class="card-day-sched"
              :class="item.duty ? 'duty' : null"
              v-for="(item, index) in workingDays"
              :key="index"
            >
              <span
                v-if="
                  !scheduleStatus && scheduleInformation !== 'No Schedule yet'
                "
              >
                {{ item.date }}</span
              >
              {{ item.day }}
            </div>
          </div>
          <div class="card-cols">
            <div
              class="card-time"
              v-for="(item, index) in workingDays"
              :key="index"
            >
              <span v-if="selectedIndexHover === index && screenWidth <= 768">
                {{ item.time }}
              </span>
              <span v-if="screenWidth > 768">
                {{ item.time }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed, ref, onMounted } from "vue";
export default {
  props: {
    employeeProfileDetails: Object,
  },
  setup(props) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const screenWidth = screen.width;
    const itemSchedule = ref([]);
    const itemShiftingSchedule = ref([]);
    const currentWeek = ref(0);
    const selectedWeek = ref(0);
    const scheduleInformation = ref(null);
    const isVisible = ref(false);
    const selectedIndexHover = ref(null);
    const isLoading = ref(true);
    const daySchedule = ref(null);
    const dateSchedItem = ref([]);
    const scheduleStatus = ref(
      props.employeeProfileDetails.maintenance_schedule_attendance.schedStatus
    );
    const workingDays = ref([
      {
        id: 1,
        day: "Mon",
        date: null,
        duty: false,
        time: null,
        seq: 1,
      },
      {
        id: 2,
        day: "Tue",
        date: null,
        duty: false,
        time: null,
        seq: 2,
      },
      {
        id: 3,
        day: "Wed",
        date: null,
        duty: false,
        time: null,
        seq: 3,
      },
      {
        id: 4,
        day: "Thu",
        date: null,
        duty: false,
        time: null,
        seq: 4,
      },
      {
        id: 5,
        day: "Fri",
        date: null,
        duty: false,
        time: null,
        seq: 5,
      },
      {
        id: 6,
        day: "Sat",
        date: null,
        duty: false,
        time: null,
        seq: 6,
      },
      {
        id: 0,
        day: "Sun",
        date: null,
        duty: false,
        time: null,
        seq: 7,
      },
    ]);

    const dateFormat = (val) => {
      return val ? moment(val).format("LL") : "-";
    };
    onMounted(() => {
      getShiftingSchedule();
    });
    const getShiftingSchedule = async () => {
      try {
        currentWeek.value = moment().format("W") * 1;
        selectedWeek.value = currentWeek.value;
        const response = await axios.get("/api/shiftingSchedule");
        itemSchedule.value = response.data;

        itemShiftingSchedule.value = itemSchedule.value.filter((val) => {
          return (
            val.employeeId === props.employeeProfileDetails.id &&
            val.weekNumber === selectedWeek.value &&
            val.isActive
          );
        });

        workingDayItem();
        weekFormat();
        isLoading.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const weekFormat = () => {
      moment.updateLocale("en", {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
      let weekStart = moment().clone().startOf("week");
      let weekEnd = moment().clone().endOf("week");
      let scheduleTitle = `${weekStart.format("ll")} - ${weekEnd.format("ll")}`;

      let dateStart = moment(weekStart);
      let dateEnd = moment(dateStart).add(7, "days").format("YYYY-MM-DD");

      while (dateStart.isBefore(dateEnd, "days")) {
        let days = dateStart.format("D");
        dateSchedItem.value.push(days);
        dateStart.add(1, "days");
      }

      const modified = workingDays.value.map((val, index) => {
        val.date = dateSchedItem.value.at(index);
        return val;
      });
      workingDays.value = modified;

      scheduleInformation.value = scheduleTitle;
      isVisible.value =
        props.employeeProfileDetails.maintenance_schedule_attendance
          .description === "Shifting"
          ? true
          : false;
    };
    const workingDayItem = () => {
      let withWeekend =
        props.employeeProfileDetails.maintenance_schedule_attendance
          .withWeekend;
      let checkWorkStatus =
        props.employeeProfileDetails.maintenance_schedule_attendance
          .schedStatus;
      let timeInSched =
        props.employeeProfileDetails.maintenance_schedule_attendance.timeIn;
      let timeOutSched =
        props.employeeProfileDetails.maintenance_schedule_attendance.timeOut;
      let item = workingDays.value;

      if (checkWorkStatus) {
        workingDays.value = item.map((value) => {
          let timeIn = `${moment().format("YYYY-MM-DD")} ${timeInSched}`;
          let timeOut = `${moment().format("YYYY-MM-DD")} ${timeOutSched}`;
          let timeSched = `${moment(timeIn).format("hh:mmA")} ${moment(
            timeOut
          ).format("hh:mmA")} `;
          const container = {};
          container.id = value.id;
          container.day = value.day;
          container.duty = true;
          container.time = timeSched;
          return container;
        });
        if (withWeekend) {
          workingDays.value[6].time = null;
          workingDays.value[6].duty = false;
        } else {
          workingDays.value[5].time = null;
          workingDays.value[5].duty = false;
          workingDays.value[6].time = null;
          workingDays.value[6].duty = false;
        }
      } else {
        if (itemShiftingSchedule.value.length) {
          moment.updateLocale("en", {
            week: {
              dow: 1, // Monday is the first day of the week.
            },
          });
          let weekStart = moment(itemShiftingSchedule.value[0].date)
            .clone()
            .startOf("week");
          let weekEnd = moment(itemShiftingSchedule.value[0].date)
            .clone()
            .endOf("week");

          let scheduleTitle = `${weekStart.format("ll")} - ${weekEnd.format(
            "ll"
          )}`;
          scheduleInformation.value = scheduleTitle;
          let modItems = [];
          itemShiftingSchedule.value.filter((val) => {
            let timeIn = val.timeIn
              ? `${moment().format("YYYY-MM-DD")} ${val.timeIn}`
              : null;
            let timeOut = val.timeOut
              ? `${moment().format("YYYY-MM-DD")} ${val.timeOut}`
              : null;
            let timeFormat = timeIn
              ? `${moment(timeIn).format("hh:mmA")} ${moment(timeOut).format(
                  "hh:mmA"
                )} `
              : "Off";

            item.find((value, index) => {
              let dayFormat = Number(moment(val.date).format("d"));

              if (dayFormat === value.id) {
                modItems.push({
                  id: item[index].id,
                  day: item[index].day,
                  duty: (item[index].duty =
                    timeFormat !== "Off" ? true : false),
                  time: (item[index].time = timeFormat),
                  seq: item[index].seq,
                });
              }
            });
          });

          let sample = item.filter(
            (o1) => !modItems.some((o2) => o1.id === o2.id)
          );
          sample.forEach((val) => {
            modItems.push({
              id: val.id,
              day: val.day,
              duty: null,
              time: null,
              seq: val.seq,
            });
          });
          workingDays.value = modItems.sort((a, b) => {
            return a.seq - b.seq;
          });
          let dateStart = moment(weekStart);
          let dateEnd = moment(dateStart).add(7, "days").format("YYYY-MM-DD");
          dateSchedItem.value = [];
          while (dateStart.isBefore(dateEnd, "days")) {
            let days = dateStart.format("D");

            dateSchedItem.value.push(days);
            dateStart.add(1, "days");
          }

          const modified = workingDays.value.map((val, index) => {
            val.date = dateSchedItem.value.at(index);
            return val;
          });
          workingDays.value = modified;
        } else {
          scheduleInformation.value = "No Schedule yet";
          item.forEach((value, index) => {
            item.filter(() => {
              workingDays.value[index].time = null;
              workingDays.value[index].duty = false;
            });
          });
        }
      }
    };

    const employeeBirthDate = computed(() => {
      let workStatus = props.employeeProfileDetails.isBiotime;
      let result;
      if (workStatus === 1) {
        result = props.employeeProfileDetails.isBirthDate
          ? "Already Used"
          : "Available";
      }
      if (workStatus === 2) {
        result = "Not Entitle";
      }
      return result;
    });
    const hoverHandler = (index) => {
      selectedIndexHover.value = index;
    };
    const filterHandler = async () => {
      for (let i = 1; i < workingDays.value.length; i++) {
        workingDays.value[i].time = null;
        workingDays.value[i].duty = false;
      }
      itemShiftingSchedule.value = itemSchedule.value.filter((val) => {
        return (
          val.employeeId === props.employeeProfileDetails.id &&
          val.weekNumber === selectedWeek.value &&
          val.isActive
        );
      });
      workingDayItem();
    };
    const availability = (value, payLoad) => {
      let workStatus = props.employeeProfileDetails.isBiotime;
      let result;
      if (workStatus === 1) {
        if (payLoad === 1) {
          result = `${value} / 14 Days`;
        } else if (payLoad === 2) {
          result = `${value} / 10 Days`;
        } else {
          result = value;
        }
      }
      if (workStatus === 2) {
        result = "Not Entitle";
      }
      return result;
    };
    return {
      dateFormat,
      workingDays,
      workingDayItem,
      employeeBirthDate,
      getShiftingSchedule,
      itemShiftingSchedule,
      currentWeek,
      selectedWeek,
      scheduleInformation,
      weekFormat,
      isVisible,
      selectedIndexHover,
      hoverHandler,
      screenWidth,
      filterHandler,
      itemSchedule,
      isLoading,
      availability,
      daySchedule,
      dateSchedItem,
      scheduleStatus,
    };
  },
};
</script>
<style scoped>
.auth-section {
  padding-top: 40px;
}
.section-container {
  margin: 30px 40px;
}
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 15.1px;
}
.title {
  min-width: 180px;
  font-weight: 600;
  color: #464545;
}
.description {
  font-weight: 500;
  color: #5c5c5c;
}
.flex {
  display: flex;
  margin: 10px 0px;
}
h3 {
  font-family: "Montserrat";
  font-weight: 800;
  color: #3e535f;
}
.attendance-card {
  display: grid;
  grid-template-columns: repeat(1, 30fr);
}
.section-container h2 {
  font-family: "Montserrat";
  font-size: 18px;
}

.card-rows {
  margin: 20px 0px;
}
.card-schedule {
  padding: 20px;
  border-radius: 5px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  font-family: "Montserrat";
  font-size: 18px;
}
.card-cols {
  display: flex;
  gap: 20px;
  margin: 20px 0px;
}
.card-day-sched {
  display: flex;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e535f;
  font-size: 14px;
  color: #3e535f;
  font-weight: 600;
  flex-direction: column;
}
.card-time {
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #3e535f;
  padding: 1px;
  font-family: "Montserrat";
  font-weight: 500;
}
.duty {
  background-color: #3e535f;
  color: white;
}
.tooltiptext {
  min-width: 150px;
  background-color: rgb(116, 114, 114);
  text-align: center;
  padding: 6px;
  border-radius: 6px;
  color: rgb(255, 252, 252);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
}
.header-sched {
  display: flex;
  justify-content: space-between;
}

.action-toggle {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .auth-section {
    padding-top: 30px;
  }
  .section-container {
    margin: 16px 10px;
  }
  table {
    font-size: 11.5px;
  }
  .title {
    min-width: 160px;
  }
  .flex {
    display: flex;
    margin: 10px 0px;
  }
  h3 {
    font-size: 15px;
  }

  .card-rows {
    margin: 10px 0px;
  }
  .card-schedule {
    padding: 10px;
    font-size: 14px;
  }
  .card-cols {
    display: flex;
    gap: 5px;
    margin: 10px 0px;
  }
  .card-day-sched {
    display: flex;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border: 1px solid #3e535f;
    font-size: 8px;
    color: #3e535f;
    font-weight: 600;
  }
  .card-time {
    width: 28px;
    font-size: 11px;
    padding: 1px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .duty {
    background-color: #3e535f;
    color: white;
  }
  .header-sched h4 {
    font-size: 11px;
  }
}
</style>

<template>
  <app-auth-title />
  <app-auth-dashboard-header />

  <app-auth-dashboard-leave
    v-if="
      $store.state.getUserType !== 'operator-moderator' &&
      $store.state.getUserType !== 'driver-moderator' &&
      $store.state.getUserType !== 'checker-moderator'
    "
  />
  <app-auth-dashboard-travel
    v-else-if="$store.state.getUserType === 'operator-moderator'"
  />

  <app-auth-dashboard-driver
    v-else-if="$store.state.getUserType === 'driver-moderator'"
  />

  <app-auth-dashboard-conference
    v-else-if="$store.state.getUserType === 'checker-moderator'"
  />
</template>

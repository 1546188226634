<template>
  <div class="setting-body">
    <div class="notif-row-one">
      <div class="flex-card">
        <div class="title">
          <h4>Muted Notifications</h4>
        </div>
        <div class="description">
          <label class="toggle">
            <input
              type="checkbox"
              id="switch"
              v-model="isMute"
              @change="toggleSwitch('Mute')"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="notif-details">
        Turn-off all notifications via email and sms
      </div>
    </div>
    <div class="notif-row-one">
      <div class="flex-card">
        <div class="title">
          <h4>SMS Push Notifications</h4>
        </div>
        <div class="description">
          <label class="toggle">
            <input
              type="checkbox"
              id="switch"
              v-model="isSMS"
              @change="toggleSwitch('SMS')"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="notif-details">
        Receive push notifications update via mobile
      </div>
    </div>
    <div class="notif-row-one">
      <div class="flex-card">
        <div class="title">
          <h4>Email Push Notifications</h4>
        </div>
        <div class="description">
          <label class="toggle">
            <input
              type="checkbox"
              id="switch"
              v-model="isEmail"
              @change="toggleSwitch('Email')"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="notif-details">
        Receive push notifications updates via email
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { inject } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const isMute = ref(false);
    const isSMS = ref(true);
    const isEmail = ref(true);
    const notifStatus = ref(store.state.getNotifSetting);
    onMounted(() => {
      defaultConfiguration();
    });
    const toggleSwitch = (notif) => {
      let category = notif;
      if (category === "Mute") {
        if (isMute.value) {
          isSMS.value = false;
          isEmail.value = false;
        } else {
          isSMS.value = true;
          isEmail.value = true;
        }
      }
      if (category === "SMS") {
        if (isSMS.value || isEmail.value) {
          isMute.value = false;
        } else {
          isMute.value = true;
        }
      }
      if (category === "Email") {
        if (isSMS.value || isEmail.value) {
          isMute.value = false;
        } else {
          isMute.value = true;
        }
      }
      if (!isMute.value & isSMS.value & isEmail.value) {
        notifStatus.value = 1;
      }
      if (isMute.value & !isSMS.value & !isEmail.value) {
        notifStatus.value = 4;
      }
      if (!isMute.value & !isEmail.value & isSMS.value) {
        notifStatus.value = 2;
      }
      if (!isMute.value & !isSMS.value & isEmail.value) {
        notifStatus.value = 3;
      }
      updateHandler();
    };
    const defaultConfiguration = () => {
      let setUp = notifStatus.value;

      if (setUp === 1) {
        isMute.value = false;
        isSMS.value = true;
        isEmail.value = true;
      }
      if (setUp === 2) {
        isMute.value = false;
        isEmail.value = false;
        isSMS.value = true;
      }
      if (setUp === 3) {
        isMute.value = false;
        isSMS.value = false;
        isEmail.value = true;
      }
      if (setUp === 4) {
        isMute.value = true;
        isSMS.value = false;
        isEmail.value = false;
      }
    };

    const updateHandler = async () => {
      const employeeId = store.state.getEmployeeId;
      try {
        await axios.put(`api/user/${employeeId}`, {
          notifSetting: notifStatus.value,
        });

        store.state.getNotifSetting = notifStatus.value;
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };

    return {
      isMute,
      isSMS,
      isEmail,
      toggleSwitch,
      defaultConfiguration,
      updateHandler,
      notifStatus,
    };
  },
};
</script>
<style scoped>
.flex-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 15px;
  font-family: "Montserrat";
}
.notif-details {
  font-size: 12.5px;
  font-family: "Montserrat";
  color: rgb(109, 108, 108);
  position: relative;
  top: -5px;
}
</style>

<template>
  <div class="loading">
    <img src="../../assets/images/loading.gif" alt="icon" />
  </div>
</template>

<style scoped>
.loading {
  max-width: 800px;
  min-height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .loading {
    min-height: 80vh;
  }
  .loading img {
    height: 80px;
    width: 80px;
  }
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>Schedule of {{ itemDetails.model }} ({{ plateNumber }})</h1>
        </div>
        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
      <div class="auth-grid">
        <VCalendar
          :attributes="attributes"
          expanded
          style="font-family: arial"
          title-position="left"
        />
      </div>
      <div class="legend">
        <h3>Legend</h3>
        <ul>
          <li><span class="dot blue md"></span>Single Destination</li>
          <li><span class="dot red md"></span>Multiple Destination</li>
          <li><span class="dot gray md"></span>Schedule of Coding</li>
          <li>
            <span class="dot blue"></span><span class="dot blue md"></span>Two
            requester with same day
          </li>
          <li>
            <span class="dot blue"></span><span class="dot blue"></span
            ><span class="dot red md"></span>Three requester with same day
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
import { computed, ref, inject, onMounted } from "vue";

export default {
  components: {
    closeIconSVG,
  },
  props: {
    plateNumber: String,
    weekDay: String,
    itemDetails: Object,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const travelRequest = ref([]);
    const weekDayNumber = ref([0]);
    const codingDay = ref([
      {
        purpose: "Coding",
        isComplete: false,
        dates: {
          repeat: {
            every: [1, "weeks"],
            weekdays: 4,
          },
        },
        color: "gray",
      },
    ]);
    const goRoute = (val) => {
      emit("passClose", val);
    };
    onMounted(() => {
      weekDayFormat();
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        const plateNumber = props.plateNumber;
        const response = await axios.get(
          `/api/admin/travel/request/${plateNumber}`
        );
        const itemList = response.data.filter((val) => {
          return (
            val.status === "Approved" ||
            val.status === "Completed" ||
            val.status === "Transferred"
          );
        });
        travelRequest.value = itemList.map((item) => {
          let startTime = `${item.dateIntended} ${item.timeIntended}`;
          let endTime = `${item.dateIntended} ${item.timeReturned}`;
          return {
            ...item,
            requester: item.requestedBy,
            purpose: `${item.travelItemDetails[0].purpose} (${moment(
              startTime
            ).format("LT")} - ${moment(endTime).format("LT")})`,

            time: item.timeIntended,
            isComplete: false,
            dates: [item.dateIntended],
            color: item.travelItemDetails.length > 1 ? "red" : "blue",
          };
        });
        codingSchedule();
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const codingSchedule = () => {
      let schedule = {
        purpose: "Coding",
        isComplete: false,
        dates: {
          repeat: {
            every: [1, "weeks"],
            weekdays: weekDayNumber.value,
          },
        },
        color: "gray",
      };
      travelRequest.value.push(schedule);
    };
    const weekDayFormat = () => {
      let day = props.weekDay;
      let weekDay;
      switch (day) {
        case "Monday":
          weekDay = 2;
          break;
        case "Tuesday":
          weekDay = 3;
          break;
        case "Wednesday":
          weekDay = 4;
          break;
        case "Thursday":
          weekDay = 5;
          break;
        case "Friday":
          weekDay = 6;
          break;
        default:
          0;
      }
      weekDayNumber.value = weekDay;
    };
    const attributes = computed(() => [
      // Attributes for travelRequest
      ...travelRequest.value.map((todo) => ({
        dates: todo.dates,
        dot: {
          color: todo.color,
          class: todo.isComplete ? "opacity-75" : "",
        },
        popover: {
          label:
            todo.purpose === "Coding"
              ? todo.purpose
              : `${todo.requester} - ${todo.purpose}`,
        },
      })),
    ]);

    return {
      goRoute,
      codingDay,
      dataLoad,
      attributes,
      travelRequest,
      weekDayFormat,
      weekDayNumber,
    };
  },
};
</script>

<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 600px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.route {
  cursor: pointer;
}
.card {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: auto;
  font-family: "Montserrat";
  font-size: 14px;
}
.card .description,
.card .amount {
  font-size: 16px;
}
.title {
  padding-bottom: 15px;
}
.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.blue {
  background-color: blue;
}
.red {
  background-color: red;
}
.gray {
  background-color: gray;
}
.md {
  margin-right: 10px;
}
ul {
  list-style-type: none;
  color: var(--lightFont);
  font-size: 13px;
  font-style: normal;
  font-family: "Montserrat";
  line-height: 20px;
}
.legend {
  padding: 20px 0px;
}
.legend h3 {
  padding-bottom: 10px;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  .modal {
    font-family: "Montserrat";
    background-color: #fff;
    width: 295px;
    max-width: 100%;
    padding: 30px 50px;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}
</style>

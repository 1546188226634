<template>
  <div class="current-content-title">
    <h2>{{ $store.state.authTitlePage }}</h2>
  </div>
</template>
<style scoped>
.current-content-title {
  padding: 0px 0px 20px 0px;
  color: var(--lightFont);
  font-family: "Poppins";
  font-weight: 200;
  font-size: 11px;
}
</style>

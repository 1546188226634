<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="btn-items">
        <div
          class="main-category-list"
          v-for="(category, index) in categoryList"
          :key="index"
        >
          <div
            class="category-tab"
            :class="[
              currentComponent === category.components ? 'active' : null,
            ]"
            @click="handleClick(category.components)"
          >
            {{ category.title }}
          </div>
        </div>
      </div>
      <div class="category-list-container">
        <component :is="currentComponent" @close-modal="closeModal"></component>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import HeroAddAnnouncementModal from "@/components/common/HeroAddAnnouncementModal.vue";
import HeroAddEventModalMain from "@/components/common/HeroAddEventModalMain.vue";
export default {
  components: {
    HeroAddAnnouncementModal,
    HeroAddEventModalMain,
  },
  setup(props, { emit }) {
    const disabled = ref(false);
    const isStatus = ref(false);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const closeModal = (val, payLoad) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit("passClose", val, payLoad);
    };

    const currentComponent = ref("HeroAddEventModalMain");
    const categoryList = reactive([
      { title: "Announcements", components: "HeroAddAnnouncementModal" },
      { title: "Events", components: "HeroAddEventModalMain" },
    ]);
    const handleClick = (component) => {
      currentComponent.value = component;
    };

    return {
      closeModal,
      disabled,
      isStatus,
      fileData,
      uploadFile,
      handleClick,
      currentComponent,
      categoryList,
    };
  },
};
</script>

<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 400px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 20px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.form-event {
  display: flex;
  line-height: 33px;
  column-gap: 20px;
  margin-bottom: 10px;
}
.data-grid {
  display: grid;
  grid-template-columns: 1.2fr 3fr;
  line-height: 32px;
  margin-bottom: 10px;
}
.time {
  display: flex;
  max-width: 125px;
  column-gap: 10px;
}
.publish {
  background-color: rgb(34, 158, 23);
}
.cancel {
  background-color: rgb(207, 65, 40);
}
.mt {
  margin-top: 18px;
}

.btn-items {
  border-top: solid 1px var(--main-bg-color);
  display: flex;
  justify-content: normal;
  gap: 25px;
  margin-bottom: 30px;
}

.dynamic-btn {
  cursor: pointer;
}

.category-tab {
  color: var(--lightFont);
  font-size: 20px;
  font-weight: 600;
  padding-top: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
}
.category-tab:hover,
.active {
  border-bottom: solid 5px #fa7319;
}
</style>

<template>
  <div class="card-container-header">
    <div class="card-avatar">
      <div class="profile">
        <img
          class="profile-round"
          :src="$store.state.getProfilePath"
          alt="profile"
        />
        <div
          v-show="true"
          class="ring-container"
          @mouseover="isHover = true"
          @mouseleave="isHover = false"
        >
          <div class="badge" :class="[dtrValue ? 'online' : 'offline']"></div>
          <span v-if="isHover" class="tooltiptext">
            {{ dateFormat($store.state.getProfileInfo, true) }}
          </span>
        </div>
      </div>
      <div class="details">
        <div class="view-profile">
          <span
            class="btn-view"
            v-show="true"
            @click="goRoute('SectionAccountProfile')"
            >My Profile</span
          >
        </div>
        <h2>{{ $store.state.getUserLog }}</h2>

        <div class="sub-details">
          <div class="designation">
            <briefCaseSVG />
            <span class="label">{{ $store.state.getDesignation }} </span>
          </div>
          <div class="department">
            <homeIconSVG />
            <span class="label">{{ $store.state.getDepartment }}</span>
          </div>
          <div
            v-if="isHideToggle"
            style="
              display: flex;
              align-content: center;
              line-height: 17px;
              gap: 5px;
            "
          >
            <span class="label-text">Switch as Admin</span>
            <label class="toggle">
              <input
                type="checkbox"
                id="switch"
                v-model="isActive"
                @change="toggleSwitch"
              />
              <span class="slider"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-details">
      <div class="title-container">
        <h1>Recent Activity</h1>
        <div
          v-for="(activity, index) in activityLogs"
          :key="index"
          class="animate__animated animate__fadeIn"
        >
          <div class="grid-container">
            <div class="activity-icon">
              <img
                :src="
                  require(`@/assets/images/icons/${activity.applicationType}.png`)
                "
                alt="icon"
              />
            </div>
            <div class="activity-description">
              {{ activity.description }}
            </div>
            <div class="activity-button">
              <input
                @click="
                  routeModal(
                    true,
                    activity.leaveFormId,
                    activity.applicationType
                  )
                "
                type="submit"
                value="status"
              />
            </div>
            <span></span>
            <span class="publish-date">
              <img src="../../assets/images/time.png" alt="icon" />
              <i>{{ dateFormat(activity.updatedAt, false) }}</i></span
            >
          </div>

          <hr v-show="activityLogs.length - 1 !== index" />
        </div>
        <br />
        <div v-show="false" class="form-carousel-nav-container">
          <ul class="form-carousel-nav">
            <li class="active" />
            <li />
            <li />
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isModal">
    <app-modal
      @passModal="getValue"
      :applicationId="applicationId"
      :applicationType="applicationType"
    ></app-modal>
  </div>
</template>
<script>
import briefCaseSVG from "@/assets/svgs/briefcaseIcon.vue";
import homeIconSVG from "@/assets/svgs/homeIcon.vue";
import { ref } from "@vue/reactivity";
import { onMounted, inject, computed } from "vue";
import moment from "moment";
export default {
  components: {
    briefCaseSVG,
    homeIconSVG,
  },
  emits: ["passLoad"],
  setup(props, { emit }) {
    const store = inject("$store");
    const isModal = ref(false);
    const applicationId = ref(null);
    const applicationType = ref(null);
    const rowArray = ref(0);
    const dtrValue = ref(0);
    const activityLogs = computed(() => store.getters.activityList);
    const isActive = ref(null);
    const isHover = ref(false);
    const toggleSwitch = () => {
      if (isActive.value) {
        let userAccount = "user";
        store.commit("switchAccountHandler", userAccount);
      }
    };
    const isHideToggle = computed(() => {
      let account_role = localStorage.getItem(
        `${process.env.VUE_APP_USER_TYPE}_XXX`
      );

      return account_role !== "null" ? true : false;
    });

    const routeModal = (stat, payload, type) => {
      isModal.value = stat;
      applicationId.value = payload;
      applicationType.value = type;
    };
    const getValue = (value) => {
      isModal.value = value;
    };
    const dateFormat = (value, stat) => {
      if (!stat) {
        return moment(value).format("MMMM D, YYYY | h:mm:ss a");
      } else {
        let date = value.dtrDate;
        let time = value.dtrTime;
        let status = value.ifDtr;
        let message;
        let dateLogged = `${date} ${time}`;

        message = status
          ? `Time-in: ${moment(dateLogged).format("llll")}`
          : `Time-out: ${moment(dateLogged).format("llll")}`;

        return message;
      }
    };
    onMounted(async () => {
      store.commit("activityLog");
      dtrValue.value = store.state.getDTRStat;
    });
    const goRoute = (val) => {
      store.state.isVisible = true;
      store.state.getTitleHeaderForm = "My Profile";
      emit("passLoad", val);
    };

    return {
      dateFormat,
      routeModal,
      getValue,
      rowArray,
      applicationId,
      isModal,
      activityLogs,
      applicationType,
      toggleSwitch,
      isActive,
      isHideToggle,
      goRoute,
      dtrValue,
      isHover,
    };
  },
};
</script>
<style scoped>
.card-container-header {
  display: grid;
  grid-template-columns: 3fr 8fr;
  gap: 15px;
  padding: 40px 0px 40px 0px;
}
.card-avatar,
.card-details {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  padding: 12px 12px 20px 12px;
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 500;
  color: var(--lightFont);
}
.profile {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
.details {
  padding: 18px 15px 0px 10px;
  font-size: 12px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 200;
}

.sub-details {
  padding: 10px 0px 0px 10px;
  line-height: 28px;
}
.label {
  font-size: 13px;
  font-weight: 500;
  padding-left: 4px;
}
img {
  width: 130px;
  height: 130px;
  clip-path: circle();
}
svg {
  position: relative;
  top: 3px;
}
.title-container h1 {
  padding-bottom: 20px;
}

.grid-container {
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 13fr 2fr;
  font-size: 14px;
  margin: 9px 0px 9px 0px;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}
.activity-icon img {
  width: 30px;
  height: 30px;
}
.activity-description {
  line-height: 30px;
  padding-left: 10px;
}
.activity-button input {
  border-radius: 10px;
  font-size: 12px;
  position: relative;
  bottom: -5px;
}
.publish-date {
  font-size: 10px;
  padding-left: 10px;
  margin-top: -4px;
}
.publish-date i {
  padding-left: 5px;
  position: relative;
  bottom: 1.6px;
}
.publish-date img {
  width: 13px;
  height: 13px;
}
.form-carousel-nav-container {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

.form-carousel-nav {
  list-style-type: none;
  overflow: hidden;
}

.form-carousel-nav > li {
  float: left;
  display: block;
  margin-right: 2px;
  margin-right: 10px;
  cursor: pointer;
  height: 5px;
  width: 5px;
  padding: 1px 5px;
  border-radius: 90px;
  background-color: rgb(185, 182, 182);
}

.form-carousel-nav > .active {
  background-color: #d8970a;
  border: solid 1px var(--secondary-color) !important;
}
.label-text {
  font-size: 13px;
  font-weight: 500;
  padding: 2px 0px;
}
.btn-view {
  background-color: #ff5303;
  padding: 8px 14px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
}
.view-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.tooltiptext {
  min-width: 180px;
  background-color: rgb(116, 114, 114);
  text-align: center;
  padding: 6px;
  border-radius: 6px;
  color: rgb(255, 252, 252);
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: -0px;
  right: -35px;
}
@media only screen and (max-width: 600px) {
  .card-container-header {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>

<template>
  <div class="auth-card-content">
    <div class="auth-card-profile">
      <h1>{{ ifEditmode ? "Employee Edit" : "Employee Data Entry" }}</h1>
    </div>
    <div v-show="$store.state.getTitleHeaderForm !== 'My Profile'">
      <span style="cursor: pointer" @click="$emit('isBackMenu', true)"
        >back</span
      >
    </div>
  </div>
  <form @submit.prevent="handleSubmit">
    <div class="form-section">
      <h4>Profile Information</h4>
      <section>
        <div class="form-entry">
          <label> Name: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="name" placeholder="eg. John Doe" />
          </div>
        </div>
        <div class="form-entry">
          <label> Birthdate: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="birthDate" />
          </div>
        </div>
        <div class="form-entry">
          <label> Gender: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="gender">
              <option
                v-for="(item, index) in genderItem"
                :key="index"
                :value="item.code"
              >
                {{ item.description }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-entry">
          <label> Mobile Number: <span class="required">*</span> </label>
          <div class="input">
            <input type="number" v-model="mobile" />
          </div>
        </div>
        <div class="form-entry">
          <label> Email Address: <span class="required">*</span> </label>
          <div class="input">
            <input type="email" v-model="email" />
          </div>
        </div>
        <div class="form-entry" v-if="false">
          <label> Home Address: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
      </section>
    </div>
    <div class="form-section">
      <h4>Employment Information</h4>
      <section>
        <div class="form-entry">
          <label> Employee ID: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="employee_number" />
          </div>
        </div>
        <div class="form-entry">
          <label> Department: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="department">
              <option v-for="(item, index) in departmentItem" :key="index">
                {{ item.deptName }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-entry">
          <label> Position: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="designation" />
          </div>
        </div>
        <div class="form-entry">
          <label> Work Schedule: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="maintenanceScheduleAttendanceId">
              <option
                v-for="(item, index) in attendanceItem"
                :key="index"
                :value="item.id"
              >
                {{ item.workSchedule }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-entry">
          <label> Date Hired: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="dateHired" />
          </div>
        </div>
        <div class="form-entry" v-if="ifEditmode">
          <label> Work Status: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="isBiotime">
              <option
                v-for="(item, index) in workStatus"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </section>
    </div>
    <div class="form-section" v-if="ifEditmode">
      <h4>Account Information</h4>
      <section>
        <div class="form-entry">
          <label> Username: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="username" />
          </div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <label> Reset Password: <span class="required">*</span> </label>

          <label class="toggle">
            <input type="checkbox" id="switch" v-model="isPasswordReset" />
            <span class="slider"></span>
          </label>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <label style="font-size: 14px" v-if="isPasswordReset">
            <b>Default Password: iwcdap</b></label
          >
        </div>
      </section>
    </div>
    <div class="form-group">
      <button class="submit">
        {{ ifEditmode ? "Save Changes" : "Submit" }}
      </button>
    </div>
    <div v-show="isStatus" class="alert-error form-alert">
      {{ $store.state.formErrorMessage }}
    </div>
  </form>
</template>
<script>
import { ref, reactive, onMounted, toRefs, inject } from "vue";
import Swal from "sweetalert2";
export default {
  props: {
    employeeProfileDetails: Object,
    ifEditmode: Boolean,
  },
  emits: ["isBackMenu"],
  setup(props) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isStatus = ref(false);
    const isPasswordReset = ref(false);
    const genderItem = ref([
      {
        code: "F",
        description: "Female",
      },
      {
        code: "M",
        description: "Male",
      },
    ]);
    const departmentItem = ref([]);
    const attendanceItem = ref([]);
    const workStatus = ref([
      {
        id: 1,
        name: "Regular",
      },
      {
        id: 2,
        name: "Probationary",
      },
      { id: 0, name: "Resigned" },
    ]);
    const entryForm = reactive({
      name: null,
      gender: null,
      birthDate: null,
      email: null,
      username: null,
      password: null,
      isResetPassword: 0,
      dateHired: null,
      mobile: null,
      department: null,
      maintenanceScheduleAttendanceId: null,
      designation: null,
      employee_number: null,
      isBiotime: 2,
    });

    onMounted(async () => {
      getDepartment();
      getAttendanceList();
      getDetailInformation();
    });
    const getDepartment = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        departmentItem.value = response.data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const getAttendanceList = async () => {
      try {
        const response = await axios.get("/api/attendanceSchedule");

        attendanceItem.value = response.data
          .map((entry) => {
            let timeIn = `${moment().format("YYYY-MM-DD")} ${entry.timeIn}`;
            let timeOut = `${moment().format("YYYY-MM-DD")} ${entry.timeOut}`;
            let schedule = `${moment(timeIn).format("hh:mm A")} - ${moment(
              timeOut
            ).format("hh:mm A")} ${entry.withWeekend ? "(with Saturday)" : ""}`;
            return {
              ...entry,

              workSchedule: entry.schedStatus ? schedule : entry.description,
            };
          })
          .sort((val, val1) => {
            let fa = val.workSchedule.toLowerCase(),
              fb = val1.workSchedule.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const handleSubmit = async () => {
      handleValidate(entryForm);
    };
    const handleValidate = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (
          key !== "username" &&
          key !== "isBiotime" &&
          key !== "password" &&
          key !== "isResetPassword"
        ) {
          if (!value) {
            validate.push(key);
          }
        }
      });

      if (validate.length) {
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            if (!props.ifEditmode) {
              insertData();
            } else {
              updateData();
            }
          }
        });
      }
      setTimeout(() => {
        isStatus.value = false;
      }, 8000);
    };
    const insertData = async () => {
      try {
        entryForm.username = entryForm.email;
        await axios.post(`/api/user/`, entryForm);
        const SEQUENCE_ARRAY_CODE = 4;
        isStatus.value = false;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearField();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const updateData = async () => {
      try {
        let id = props.employeeProfileDetails.id;
        entryForm.isResetPassword = 1;
        if (isPasswordReset.value) {
          entryForm.password =
            "$2a$12$zTdYr3HwrD3Wr9icX9mBGuT1NNoyq0UfJfHWeT/nhRzsgRzIoV62a";
          entryForm.isResetPassword = 0;
        }
        await axios.put(`/api/user/${id}`, entryForm);
        const SEQUENCE_ARRAY_CODE = 6;
        isStatus.value = false;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        //clearField();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const clearField = () => {
      setTimeout(function () {
        entryForm.name = null;
        entryForm.gender = null;
        entryForm.birthDate = null;
        entryForm.email = null;
        entryForm.username = null;
        entryForm.dateHired = null;
        entryForm.mobile = null;
        entryForm.department = null;
        entryForm.designation = null;
        entryForm.employee_number = null;
        entryForm.maintenanceScheduleAttendanceId = null;
      }, 1100);
    };
    const getDetailInformation = () => {
      if (props.ifEditmode) {
        let info = props.employeeProfileDetails;
        entryForm.isBiotime = info.isBiotime;
        entryForm.name = info.name;
        entryForm.gender = info.gender;
        entryForm.birthDate = info.birthDate;
        entryForm.email = info.email;
        entryForm.username = info.username;
        entryForm.dateHired = info.dateHired;
        entryForm.mobile = info.mobile;
        entryForm.department = info.department;
        entryForm.designation = info.designation;
        entryForm.employee_number = info.employee_number;
        entryForm.maintenanceScheduleAttendanceId =
          info.maintenance_schedule_attendance.id;
        entryForm.password = info.password;
      }
    };
    return {
      genderItem,
      ...toRefs(entryForm),
      getDepartment,
      departmentItem,
      isStatus,
      handleSubmit,
      handleValidate,
      getAttendanceList,
      attendanceItem,
      getDetailInformation,
      updateData,
      clearField,
      workStatus,
      isPasswordReset,
    };
  },
};
</script>
<style scoped>
.auth-card-content {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
}
.auth-card-profile {
  display: flex;
  gap: 30px;
}
.auth-profile-avatar img {
  height: 180px;
  width: 180px;
}
.auth-profile-info {
  line-height: 30px;
}
.auth-profile-info .auth-primary h1 {
  margin: 12px 0px;
  font-size: 34px;
  color: #2b2a2a;
}
.auth-profile-info .auth-primary h4 {
  font-size: 16px;
  color: #fa7319;
}
.form-section {
  font-family: "Montserrat";
  margin: 40px 0px;
}
.form-entry {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 0px;
  margin: 10px 0px;
}
.form-entry .input input,
select {
  font-family: "Montserrat";
  min-width: 660px;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.form-entry label {
  font-weight: 500;
}
section {
  margin: 30px 40px;
}
.form-group {
  margin: 0px 40px;
}
.required {
  color: rgb(241, 80, 80);
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .auth-card-profile {
    display: flex;
    gap: 10px;
  }
  .auth-profile-avatar img {
    height: 180px;
    width: 180px;
  }
  .auth-profile-info {
    line-height: 30px;
  }
  .auth-profile-info .auth-primary h1 {
    margin: 12px 0px;
    font-size: 34px;
    color: #2b2a2a;
  }
  .auth-profile-info .auth-primary h4 {
    font-size: 16px;
    color: #fa7319;
  }
  .form-section {
    font-family: "Montserrat";
    margin: 40px 0px;
  }
  .form-entry {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 0px;
    margin: 10px 0px;
  }
  .form-entry .input input,
  select {
    font-family: "Montserrat";
    min-width: 300px;
    padding: 8px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  section {
    margin: 20px 0px;
  }
  .form-group {
    margin: 0px 0px;
  }
}
</style>

<template>
  <div class="cpanel">
    <table>
      <tr>
        <td class="title">Reference Number:</td>
        <td class="description">
          {{ $store.state.travelReferenceNumber }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Intended and Time:</td>
        <td class="description">
          {{ dateFormat(transaction.dateIntended, transaction.timeIntended) }}
        </td>
      </tr>
      <tr>
        <td class="title">Purpose & Destination:</td>
        <td class="description">
          {{ transaction.travelItemDetails[0].purpose }} - ({{
            transaction.travelItemDetails[0].destination
          }})

          <span
            @click="isModalStatus = true"
            v-if="transaction.travelItemDetails.length > 1"
            style="
              float: right;
              padding-right: 10px;
              color: blue;
              cursor: pointer;
            "
            >See all</span
          >
          <div v-if="isModalStatus">
            <ModalDestinationDetails
              @passClose="isModalStatus = false"
              :destinationDetails="transaction.travelItemDetails"
            >
            </ModalDestinationDetails>
          </div>
        </td>
      </tr>
      <tr v-if="transaction.travelItemDetails.length === 1">
        <td class="title">Area Address:</td>
        <td class="description">
          {{ transaction.travelItemDetails[0].address }}
        </td>
      </tr>
      <tr>
        <td class="title">Passenger:</td>
        <td class="description">
          {{ transaction.passenger }}
        </td>
      </tr>
      <tr>
        <td class="title">Requested by:</td>
        <td class="description">
          {{ transaction.requestedBy }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Requested:</td>
        <td class="description">
          {{ dateFormat(transaction.createdAt) }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Approved:</td>
        <td class="description">
          {{ dateFormat(transaction.dtApproved) }}
        </td>
      </tr>
      <tr>
        <td class="title">Approver Remarks:</td>
        <td class="description">
          {{ transaction.statusRemarks ? transaction.statusRemarks : "-" }}
        </td>
      </tr>
      <tr>
        <td class="title">Assigned Driver:</td>
        <td class="description">
          {{ transaction.driver ? transaction.driver : "-" }}
        </td>
      </tr>
      <tr>
        <td class="title">Assigned Car:</td>
        <td class="description">
          {{ transaction.vehicle ? transaction.vehicle : "-" }}
        </td>
      </tr>
      <tr>
        <td class="title">Average (km/hr):</td>
        <td class="description">
          {{ transaction.averageKm ? transaction.averageKm : "-" }}
        </td>
      </tr>
      <tr>
        <td class="title">Gasoline Consumption:</td>
        <td class="description">
          {{ transaction.gasoline ? transaction.gasoline : "-" }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { ref, onMounted, inject } from "vue";
import ModalDestinationDetails from "@/modals/destinationDetailsModal.vue";
export default {
  components: {
    ModalDestinationDetails,
  },
  props: {
    transaction: Object,
  },
  setup(props) {
    const moment = inject("$moment");
    const store = inject("$store");
    const isModalStatus = ref(false);
    onMounted(() => {
      store.commit("travelReference", props.transaction);
      if (screen.width <= 600) {
        window.scrollTo(0, 0);
      }
    });
    const dateFormat = (date, time) => {
      let modFormat;
      if (time) {
        modFormat = `${date} ${time}`;
        modFormat = moment(modFormat).format("lll");
        return modFormat;
      } else {
        modFormat = moment(date).format("lll");
        return modFormat;
      }
    };

    return {
      isModalStatus,
      dateFormat,
    };
  },
};
</script>
<style scoped>
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #666;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  border: 1px solid #666;
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 230px;
  font-family: "Montserrat";
  font-weight: 800;
  color: #474646;
}
.description {
  max-width: 100%;
  color: #272626;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 14px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
@media only screen and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 12px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 160px;
    font-weight: 500;
    color: #272626;
  }
}
</style>

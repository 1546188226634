<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <div class="auth-content">
      <div class="page-container">
        <div class="show-entries">
          Show
          <select
            v-model="selectedEntry"
            class="item-entries"
            @change="getFilterAction(selectedEntry)"
          >
            <option v-for="item in showEntries" :key="item">
              {{ item }}
            </option>
          </select>
          Entries
        </div>

        <div class="dataTable">
          <table>
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
                <th scope="col">IP Address</th>
                <th scope="col">Device</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filterItem" :key="index">
                <td data-label="Date">{{ dateFormat(item.createdAt) }}</td>
                <td data-label="Action">{{ item.action }}</td>
                <td data-label="IP Address">{{ item.ipAddress }}</td>
                <td data-label="Device">
                  {{ !item.device ? "-" : item.device }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>

      <div class="auth-grid"></div>
    </div>
  </div>
</template>

<script>
import { inject, ref, computed, onActivated } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const showEntries = ref([10, 25, 50]);
    const selectedEntry = ref(10);
    const itemActivity = ref([]);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const activityItemList = ref([]);
    const filterItem = computed(() => {
      return modified.value;
    });
    onActivated(async () => {
      getActivityLog();
    });
    const getActivityLog = async () => {
      try {
        const id = store.state.getEmployeeId;
        const { data } = await axios.get(`/api/userlogs/${id}`);
        itemActivity.value = data;
        getFilterAction();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("MMMM DD YYYY, h:mm:ss a") : "-";
    };
    const pagination = (page, pr_page) => {
      let data = activityItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 10;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;

      total_record.value = data.length;
    };
    const getFilterAction = (val) => {
      let selectedEntry = val;
      activityItemList.value = itemActivity.value.filter((post) => {
        return post;
      });

      pagination(0, selectedEntry);
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    return {
      itemActivity,
      showEntries,
      selectedEntry,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      searchValue,
      activityItemList,
      filterItem,
      prevRecords,
      nextRecords,
      getActivityLog,
      getFilterAction,
      dateFormat,
      pagination,
    };
  },
};
</script>
<style scoped>
.auth-content {
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
}
.page-container {
  font-size: 15px;
  padding: 18px;
}
.search-bar input {
  border-radius: 35px;
  height: 60px;
  padding: 2px 25px 2px 25px;
}
.filter {
  padding: 20px 0px 40px 0px;
}

.pagination {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0px 20px 0px;
}
.pages {
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
.previous,
.next {
  width: auto;
  justify-content: flex-end;
  gap: 5px;
}
.nxt-btn,
.prev-btn {
  background-color: #3e535f;
}

.btn {
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 400;
  justify-content: center;
  text-decoration: none;
  transition: all 250ms;
  line-height: 30px;
}
.btn:hover {
  cursor: pointer;
}
.show-entries {
  padding: 20px 0px 20px 0px;
}
.item-entries {
  font-family: "Montserrat";
  font-size: 14px;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #3b3a3a;
  line-height: 30px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    background-color: #ffffff;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

@media only screen and (max-width: 600px) {
  .auth-content > div > img {
    max-width: 100%;
  }
}
</style>

<template>
  <div class="cpanel">
    <table>
      <tr>
        <td class="title">Reference Number:</td>
        <td class="description">{{ transaction.id + 100000 }}</td>
      </tr>
      <tr>
        <td class="title">Date Intended:</td>
        <td class="description">
          {{ dateFormat(transaction.obDate, null) }}
        </td>
      </tr>
      <tr>
        <td class="title">Time Start & Time End:</td>
        <td class="description">
          {{ timeFormat(transaction.obTime, transaction.returnTime, null) }}
        </td>
      </tr>
      <tr>
        <td class="title">Description:</td>
        <td class="description">
          {{ transaction.remarks }}
        </td>
      </tr>
      <tr>
        <td class="title">Purpose & Destination:</td>
        <td class="description">
          {{ transaction.obItemDetails[0].purpose }} - ({{
            transaction.obItemDetails[0].destination
          }})

          <span
            @click="isModalStatus = true"
            v-if="transaction.obItemDetails.length > 1"
            style="
              float: right;
              padding-right: 10px;
              color: blue;
              cursor: pointer;
            "
            >See all</span
          >
          <div v-if="isModalStatus">
            <ModalDestinationDetails
              @passClose="isModalStatus = false"
              :destinationDetails="transaction.obItemDetails"
            >
            </ModalDestinationDetails>
          </div>
        </td>
      </tr>
      <tr>
        <td class="title">Requested by:</td>
        <td class="description">
          {{ transaction.requestedBy }}
        </td>
      </tr>
      <tr>
        <td class="title">Requested date:</td>
        <td class="description">
          {{ dateFormat(transaction.createdAt, null) }}
        </td>
      </tr>
      <tr>
        <td class="title">Status:</td>
        <td class="description">
          {{ !transaction.status ? "Pending" : transaction.status }}
        </td>
      </tr>
      <tr>
        <td class="title" v-if="transaction.status !== 'Disapproved'">
          {{
            transaction.actionStatus === 2 ? "Pre-approved by:" : "Approved by:"
          }}
        </td>
        <td class="title" v-else>Disapproved by:</td>
        <td class="description">
          {{ !transaction.approverBy ? "-" : transaction.approverBy }}
        </td>
      </tr>
      <tr>
        <td class="title" v-if="transaction.status !== 'Disapproved'">
          {{
            transaction.actionStatus === 2
              ? "Pre-approved remarks:"
              : "Approved remarks:"
          }}
        </td>
        <td class="title" v-else>Disapproved remarks:</td>
        <td class="description">
          {{ !transaction.statusRemarks ? "-" : transaction.statusRemarks }}
        </td>
      </tr>
      <tr>
        <td class="title" v-if="transaction.status !== 'Disapproved'">
          {{
            transaction.actionStatus === 2
              ? "Pre-approved date:"
              : "Approved date:"
          }}
        </td>
        <td class="title" v-else>Disapproved date:</td>
        <td class="description">
          {{
            !transaction.dtApproved
              ? "-"
              : dateFormat(transaction.dtApproved, null)
          }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { ref, onMounted, inject } from "vue";
import ModalDestinationDetails from "@/modals/destinationDetailsModal.vue";
export default {
  components: {
    ModalDestinationDetails,
  },
  props: {
    transaction: Object,
  },
  setup() {
    const moment = inject("$moment");
    const isModalStatus = ref(false);
    onMounted(() => {
      if (screen.width <= 600) {
        window.scrollTo(0, 0);
      }
    });
    const dateFormat = (start, end) => {
      let modFormat;
      if (end) {
        modFormat = `${moment(start).format("LL")} - ${moment(end).format(
          "LL"
        )}`;
      } else {
        modFormat = `${moment(start).format("LL")} `;
      }

      return modFormat;
    };
    const timeFormat = (start, end) => {
      let modFormat;
      let startTime;
      let endTime;

      if (!end) {
        startTime = `${moment().format("YYYY-MM-DD")} ${start}`;
        modFormat = `${moment(startTime).format("LT")}`;
      } else {
        startTime = `${moment().format("YYYY-MM-DD")} ${start}`;
        endTime = `${moment().format("YYYY-MM-DD")} ${end}`;
        modFormat = `${moment(startTime).format("LT")} - ${moment(
          endTime
        ).format("LT")}`;
      }

      return modFormat;
    };
    const convertTime = (time) => {
      if (time === "00:00:00") {
        return "-";
      } else {
        let modFormat;
        let convertTime = `${moment().format("YYYY-MM-DD")} ${time}`;
        let getHours = moment(convertTime).format("HH");
        if (getHours > 0) {
          modFormat = `${moment(convertTime).format("hh [hrs] mm [mins]")}`;
        } else {
          modFormat = `${moment(convertTime).format("mm [mins]")}`;
        }

        return modFormat;
      }
    };

    return {
      isModalStatus,
      dateFormat,
      timeFormat,
      convertTime,
    };
  },
};
</script>
<style scoped>
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #666;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  border: 1px solid #666;
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 230px;
  font-family: "Montserrat";
  font-weight: 800;
  color: #474646;
}
.description {
  max-width: 100%;
  color: #272626;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 14px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
@media only screen and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 12px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 160px;
    font-weight: 500;
    color: #272626;
  }
}
</style>

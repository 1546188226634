import { createRouter, createWebHashHistory } from "vue-router";
import LandingPage from "../layout/LandingPage.vue";
import LoginPage from "../components/auth/LoginPage.vue";
import ChangePasswordPage from "../components/auth/ChangePassword.vue";
import store from "../store";
const routes = [
  {
    path: "/",
    name: "index",
    component: LandingPage,
    meta: {
      title: "Digital Application Portal",
      auth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      title: "Login",
      auth: false,
    },
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: ChangePasswordPage,
    meta: {
      title: "Change Password",
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  if (store.state.isLogout) {
    localStorage.clear();
    store.state.isLogout = false;
  }

  store.state.secretTokenKey = localStorage.getItem(
    `${process.env.VUE_APP_AUTH_TOKEN}_XXX`
  );
  let isAuthorized = store.state.secretTokenKey ? true : false;

  if (to.matched.some((record) => record.meta.auth)) {
    if (!isAuthorized) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && isAuthorized) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  }
});
export default router;

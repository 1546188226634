<template>
  <div class="auth-card-content">
    <div class="auth-card-profile">
      <div class="auth-profile-avatar">
        <img
          v-if="$store.state.getTitleHeaderForm !== 'My Profile'"
          class="profile-round"
          :src="employeeProfileDetails.mediaLink"
          alt="Profile"
        />
        <img
          v-else
          class="profile-round"
          :src="$store.state.getProfilePath"
          alt="Profile"
        />
        <div class="ring-container">
          <div
            class="badge"
            :class="[employeeProfileDetails.ifDtr ? 'online' : 'offline']"
          ></div>
        </div>
      </div>
      <div class="auth-profile-info">
        <div class="auth-primary">
          <h1>{{ employeeProfileDetails.name }}</h1>
          <h4>{{ employeeProfileDetails.designation }}</h4>
        </div>

        <table>
          <tr class="flex">
            <td class="title">
              <img :src="require('@/assets/images/mobile.png')" alt="mobile" />
            </td>
            <td class="description">
              {{
                employeeProfileDetails.mobile
                  ? `0${employeeProfileDetails.mobile}`
                  : "-"
              }}
            </td>
          </tr>
          <tr class="flex">
            <td class="title">
              <img :src="require('@/assets/images/mail.png')" alt="mobile" />
            </td>
            <td class="description">{{ employeeProfileDetails.email }}</td>
          </tr>
          <tr class="flex">
            <td class="title">
              <img :src="require('@/assets/images/date.png')" alt="mobile" />
            </td>
            <td class="description">
              {{ hiredInformation }}
            </td>
          </tr>
        </table>
        <div
          class="btn-edit"
          v-if="
            $store.state.getUserType === 'admin-moderator' &&
            $store.state.getRoleUser === 'admin'
          "
        >
          <span class="btn-view" @click="$emit('editMode', true)"
            >Edit Profile</span
          >
        </div>
      </div>
    </div>
    <div v-show="$store.state.getTitleHeaderForm !== 'My Profile'">
      <span style="cursor: pointer" @click="$emit('isBackMenu', true)"
        >back</span
      >
    </div>
  </div>
</template>
<script>
import { computed, inject, ref, onMounted } from "vue";
export default {
  props: {
    employeeProfileDetails: Object,
  },
  setup(props) {
    const moment = inject("$moment");
    const store = inject("$store");
    const dtrValue = ref(0);

    onMounted(async () => {
      dtrValue.value = store.state.getDTRStat;
    });

    const hiredInformation = computed(() => {
      var currentDate = moment();
      var dateHired = moment(props.employeeProfileDetails.dateHired).format(
        "YYYY-MM-DD"
      );

      var diffDuration = moment.duration(currentDate.diff(dateHired));

      let details = `${diffDuration.years()} Years, ${diffDuration.months()} months and ${diffDuration.days()} days`;

      return details;
    });

    return {
      hiredInformation,
      dtrValue,
    };
  },
};
</script>
<style scoped>
.auth-card-content {
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
}
.auth-card-profile {
  display: flex;
  gap: 30px;
}
.auth-profile-avatar img {
  height: 180px;
  width: 180px;
}
.auth-profile-info {
  font-family: "Montserrat";
  line-height: 30px;
}
.auth-profile-info .auth-primary h1 {
  margin: 12px 0px;
  font-size: 34px;
  color: #2b2a2a;
}
.auth-profile-info .auth-primary h4 {
  font-size: 16px;
  color: #fa7319;
}
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 15.1px;
  margin: 6px 0px;
}
.title {
  min-width: 40px;
}
.description {
  font-weight: 500;
  color: #464545;
  font-weight: 600;
  margin-top: -2px;
}
.flex {
  display: flex;
}
.auth-nav-back {
  font-family: "Montserrat";
}

.badge {
  position: absolute;
  height: 25px;
  width: 25px;
  left: 135px;
  top: -40px;
}
.btn-edit {
  position: relative;
  left: 405px;
  top: -85px;
}
.btn-view {
  background-color: #ff5303;
  padding: 12px 22px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .auth-card-content {
    display: flex;
    font-family: "Montserrat";
  }
  .auth-card-profile {
    display: flex;
    gap: 14px;
  }
  .ring-container {
    display: none;
  }
  .auth-profile-avatar img {
    height: 60px;
    width: 60px;
    display: none;
  }
  .auth-profile-info {
    font-family: "Montserrat";
    line-height: 30px;
  }
  .auth-profile-info .auth-primary h1 {
    margin: 0px 0px 2px 0px;
    font-size: 21px;
  }
  .auth-profile-info .auth-primary h4 {
    font-size: 13px;
  }
  table {
    font-family: "Montserrat";
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 12.1px;
    margin: 6px 0px;
  }
  .title {
    min-width: 35px;
  }
  .btn-edit {
    display: none;
  }
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInLeft">
      <div class="header-card">
        <div class="title">
          <h1>Navbars</h1>
        </div>

        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "animate.css";
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
export default {
  components: {
    closeIconSVG,
  },
  props: {
    approvedBy: String,
    applicationNumber: String,
    activityDetails: Object,
  },
  setup(props, { emit }) {
    const goRoute = (val) => {
      emit("passModal", val);
    };
    return {
      goRoute,
    };
  },
};
</script>
<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>

    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label for=""> Date Filed: </label>
          <div class="input">
            <input type="date" v-model="formData.dateFiled" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Date Intended: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="formData.dateIntended" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Time Intended: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="time"
              v-model="formData.timeIntended"
              step="any"
              @input="autoComputeTime"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Estimated Time of Return: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="time"
              v-model="formData.timeReturned"
              step="any"
              @input="autoComputeTime"
            />
          </div>
        </div>
        <div class="form-group" v-show="false">
          <label for=""> Total Number of Hours:</label>
          <div class="input">
            <input type="text" v-model="formData.noOfHours" step="any" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Purpose: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="formData.purpose">
              <option v-for="item in travelPurposeItem" :key="item.id">
                {{ item.description }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for=""> Destination: <span class="required">*</span> </label>
          <div class="input">
            <GMapAutocomplete
              :select-first-on-enter="true"
              @place_changed="setPlace"
              :value="places"
              :v-model="formData.destination"
              :options="{
                componentRestrictions: { country: 'PH' },
                strictBounds: true,
                types: ['establishment'],
              }"
              @keypress.enter="$event.preventDefault()"
            >
            </GMapAutocomplete>
          </div>
        </div>

        <div class="input btn-add">
          <img
            @click="insertDataTable"
            class="image-button"
            src="../../assets/images/add_.png"
            alt="icon"
          />
        </div>

        <div class="dataTable">
          <table>
            <thead>
              <tr>
                <th scope="col">Purpose</th>
                <th scope="col">Destination</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in travelDetails" :key="index">
                <td data-label="Date" style="width: 100px">
                  {{ item.purpose }}
                </td>
                <td data-label="Action" style="width: 500px">
                  {{ item.destination }}
                </td>
                <td
                  data-label="Action"
                  @click="removeItems(index)"
                  style="cursor: pointer; width: 40px"
                >
                  <img
                    class="image-button"
                    src="../../assets/images/deleted_.png"
                    alt="icon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="form-group" v-show="formData.purpose === 'Others'">
          <label for=""> Description: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="5" />
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Passengers: <span class="required">*</span> </label>
          <div class="input">
            <textarea rows="6" v-model="formData.passenger" />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Requested By: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="formData.requestedBy">
              <option v-for="name in empName" :key="name.id">
                {{ name.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <button class="submit">Submit</button>
        </div>
        <div v-show="isAlert">
          <div v-show="isStatus" class="alert-error form-alert">
            {{ $store.state.formErrorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { inject } from "vue";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const isAlert = ref(false);
    const isStatus = ref(false);
    const empName = ref([]);
    const travelPurposeItem = ref([]);
    const places = ref(null);
    const address = ref(null);
    const clearAutoComplete = ref("hhhh");
    const formData = reactive({
      employeeId: store.state.getEmployeeId,
      dateFiled: moment().format("YYYY-MM-DD"),
      dateIntended: moment().format("YYYY-MM-DD"),
      timeIntended: null,
      timeReturned: null,
      noOfHours: 0,
      purpose: null,
      destination: null,
      passenger: null,
      requestedBy: store.state.getUserLog,
      department: store.state.getDepartment,
      isDirect: 1,
    });
    const travelDetails = reactive([]);

    const handleSubmit = async () => {
      handleValidation(formData);
    };

    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          formData.dateIntended = moment().format("YYYY-MM-DD");
          formData.timeIntended = null;
          formData.timeReturned = null;
          formData.noOfHours = null;
          formData.purpose = null;
          formData.passenger = null;
          formData.requestedBy = store.state.getUserLog;
          formData.destination = null;
          formData.isDirect = 1;
          travelDetails.splice(0, travelDetails.length);
        }, 1100);
      }
    };

    const handleValidation = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "destination" && key !== "purpose") {
          if (!value) {
            validate.push(key);
          }
        }
      });
      if (validate.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else if (travelDetails.length === 0) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `Please input destination`;
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            insertFormData();
            emit("isLoaded");
          }
        });
      }
    };

    const insertFormData = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        let departmentList = response.data;

        let checkIfHead = departmentList.filter((item) => {
          return item.headName === store.state.getUserLog;
        });
        formData.isDirect = checkIfHead.length ? 1 : 0;

        const result = await axios.post(`/api/form/traverRequest/`, formData);
        const travelRequestId = result.data.data;
        isAlert.value = false;
        bulkInsert(travelRequestId);
        const SEQUENCE_ARRAY_CODE = 2;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearFormField(true);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const bulkInsert = async (reqId) => {
      try {
        const modified = travelDetails.map((val, index) => {
          if (index >= 0) {
            val.travelRequestId = reqId;
          }
          return val;
        });
        await axios.post(`/api/form/travelItemBulk`, modified);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getUsers = async () => {
      try {
        const { data } = await axios.get("/api/getUsers");
        empName.value = data.filter((user) => {
          return user.department === store.state.getDepartment;
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getTravelPurposes = async () => {
      try {
        const { data } = await axios.get("/api/travel/purpose/");
        travelPurposeItem.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const setPlace = (place) => {
      formData.destination = place.name;
      places.value = `${place.name}`;
      address.value = place.formatted_address;
    };
    const insertDataTable = () => {
      if (formData.purpose && formData.destination) {
        travelDetails.push({
          purpose: formData.purpose,
          destination: formData.destination,
          address: address.value,
        });
        formData.purpose = null;
        formData.destination = null;
        places.value = null;
        address.value = null;
      }
    };
    const removeItems = (index) => {
      travelDetails.splice(index, 1);
    };
    const autoComputeTime = () => {
      let start = `${formData.dateIntended} ${formData.timeIntended}`;
      let end = `${formData.dateIntended} ${formData.timeReturned}`;
      let hrs = moment(start) - moment(end);
      //let min = moment(end) - moment(start);
      formData.noOfHours = `${8 - moment(hrs).format("H")}`;
    };
    onMounted(async () => {
      try {
        getUsers();
        getTravelPurposes();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    });

    return {
      formData,
      handleSubmit,
      clearFormField,
      insertFormData,
      handleValidation,
      isAlert,
      isStatus,
      getUsers,
      getTravelPurposes,
      empName,
      travelPurposeItem,
      places,
      setPlace,
      travelDetails,
      insertDataTable,
      clearAutoComplete,
      removeItems,
      bulkInsert,
      address,
      autoComputeTime,
    };
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.destination {
  padding-top: 20px;
}

.form1 .autotext .form-group {
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  box-sizing: border-box;
  gap: 5px;
}
.form1 .autotext .form-group select {
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 60.5%;
}
.form1 .autotext .form-group input {
  min-width: 29.5%;
}
.grid-travel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.btn-add {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 20px 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
</style>

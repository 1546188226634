<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <div class="form-container1">
      <ul>
        <div class="show-entries">
          Show
          <select
            v-model="itemsPerPage"
            class="item-entries"
            @change="getFilterCount(itemsPerPage)"
          >
            <option v-for="item in selectedItems" :value="item" :key="item">
              {{ item }}
            </option>
          </select>
          Entries
        </div>
        <div class="show-categories">
          Select Category
          <select
            v-model="categoryName"
            class="item-categories"
            @change="getCategory(categoryName)"
          >
            <option
              v-for="category in categoryList"
              :value="category"
              :key="category"
            >
              {{ category }}
            </option>
          </select>
        </div>
        <div v-if="isRender" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <li v-else v-for="item in paginatedList" :key="item.id">
          <div class="section-list">
            <div class="transaction-icon">
              <img
                class="image-button"
                :src="
                  require(`@/assets/images/icons/${item.applicationType}.png`)
                "
                alt="icon"
              />
            </div>
            <div class="transaction-details-prf">
              <div class="list-time" style="font-weight: bold">
                {{ dateFormat(item.createdAt) }}
              </div>
              <div class="item-rfp" v-show="categoryName === 'All'">
                <div v-show="item.applicationType === 'requestpayment'">
                  <h3>{{ item.payee }}</h3>
                  <h4>Amount: {{ item.total }}</h4>
                </div>
                <div v-show="item.applicationType === 'requestliquidation'">
                  <h3>{{ item.payee }}</h3>
                  <h4>Amount: {{ item.total }}</h4>
                </div>
                <div v-show="item.applicationType === 'requesttravel'">
                  <h3>{{ item.purpose }}</h3>
                  <h4>{{ item.destination }}</h4>
                </div>
                <div v-show="item.applicationType === 'requestreservation'">
                  <h3>{{ item.agenda }}</h3>
                  <h4>{{ item.dateNtime }}</h4>
                </div>
                <div v-show="item.applicationType === 'requestleave'">
                  <h3>{{ item.leaveType }}</h3>
                  <h4>{{ item.rdate }}</h4>
                </div>
                <div v-show="item.applicationType === 'requestob'">
                  <h3>{{ item.obDate }}</h3>
                  <h4>{{ item.obTime }}</h4>
                </div>
                <div v-show="item.applicationType === 'requestovertime'">
                  <h3>{{ item.otDate }}</h3>
                  <h4>{{ item.otTime }}</h4>
                </div>
              </div>
              <div class="item-rfp" v-show="categoryName === 'Payment Request'">
                <h3>{{ item.payee }}</h3>
                <h4>Amount: {{ item.total }}</h4>
              </div>
              <div
                class="item-rfp"
                v-show="categoryName === 'Liquidation Request'"
              >
                <h3>{{ item.payee }}</h3>
                <h4>Amount: {{ item.total }}</h4>
              </div>
              <div class="item-rfp" v-show="categoryName === 'Travel Request'">
                <h3>{{ item.purpose }}</h3>
                <h4>{{ item.destination }}</h4>
              </div>
              <div
                class="item-rfp"
                v-show="categoryName === 'Conference Reservation Request'"
              >
                <h3>{{ item.agenda }}</h3>
                <h4>{{ item.dateNtime }}</h4>
              </div>
              <div class="item-rfp" v-show="categoryName === 'Leave Request'">
                <h3>{{ item.leaveType }}</h3>
                <h4>{{ item.rdate }}</h4>
              </div>
              <div
                class="item-rfp"
                v-show="categoryName === 'Official Business'"
              >
                <h3>{{ item.obDate }}</h3>
                <h4>{{ item.obTime }}</h4>
              </div>
              <div
                class="item-rfp"
                v-show="categoryName === 'Over Time Request'"
              >
                <h3>{{ item.otDate }}</h3>
                <h4>{{ item.otTime }}</h4>
              </div>
              <div class="list-time" style="font-weight: bold">
                Type of Transaction: {{ headerName(item.applicationType) }}
              </div>
            </div>
            <div class="icon-toogle">
              <input
                @click="
                  routeModal(true, item.leaveFormId, item.applicationType)
                "
                type="submit"
                value="See All"
              />
            </div>
          </div>
        </li>
      </ul>
      <div class="btn-pagination">
        <div class="previous">
          <input
            type="submit"
            class="prev-btn btn"
            @click="previousPage"
            value="Previous"
          />
        </div>
        <div class="page-number">
          Page {{ totalPgCount(totalPages) ? currentPage : 0 }} of
          {{ totalPgCount(totalPages) }}
        </div>
        <div class="Next">
          <input
            type="submit"
            class="nxt-btn btn"
            @click="nextPage"
            value="Next"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="isModal">
    <app-modal
      @passModal="getValue"
      :applicationId="applicationId"
      :applicationType="applicationType"
    ></app-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "vue";
import { inject } from "vue";
import moment from "moment";

export default {
  setup() {
    const store = inject("$store");
    const transDetails = ref([]);
    const isModal = ref(false);
    const applicationId = ref(null);
    const applicationType = ref(null);
    const itemsPerPage = ref(5);
    const selectedItems = [5, 10, 25, 50];
    const currentPage = ref(1);
    const totalPages = ref(0);
    const categoryList = ref([
      "All",
      "Payment Request",
      "Liquidation Request",
      "Travel Request",
      "Conference Reservation Request",
      "Leave Request",
      "Official Business",
      "Over Time Request",
    ]);
    const categoryName = ref("All");
    const isRender = ref(true);
    onMounted(async () => {
      try {
        getExpense();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    });

    const getExpense = async () => {
      try {
        const { data } = await axios.get("/api/payAllRequestLog");
        transDetails.value = data;
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const filteredItems = computed(() => {
      return transDetails.value.filter(
        (details) => details.empId === store.state.getEmployeeId
      );
    });

    const filteredData = computed(() => {
      return filteredItems.value.filter((item) => {
        if (categoryName.value === "All") {
          return item.empId === store.state.getEmployeeId;
        } else {
          return headerName(item.applicationType)
            .toLowerCase()
            .includes(categoryName.value.toLowerCase());
        }
      });
    });

    const headerName = (name) => {
      if (name === "requestpayment") {
        name = "Payment Request";
      } else if (name === "requestleave") {
        name = "Leave Request";
      } else if (name === "requestliquidation") {
        name = "Liquidation Request";
      } else if (name === "requesttravel") {
        name = "Travel Request";
      } else if (name === "requestreservation") {
        name = "Conference Reservation Request";
      } else if (name === "requestob") {
        name = "Official Business";
      } else if (name === "requestovertime") {
        name = "Over Time Request";
      }
      return name;
    };

    const routeModal = (stat, payload, type) => {
      isModal.value = stat;
      applicationId.value = payload;
      applicationType.value = type;
    };

    const getValue = (value) => {
      isModal.value = value;
    };

    const dateFormat = (date) => {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    };

    const paginatedList = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage.value;
      const endIndex = startIndex + itemsPerPage.value;
      return filteredData.value.slice(startIndex, endIndex);
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (
        currentPage.value <
        Math.ceil(filteredData.value.length / itemsPerPage.value)
      ) {
        currentPage.value++;
      }
    };

    const totalPgCount = (pages) => {
      if (totalPages.value === 0) {
        pages = Math.ceil(filteredData.value.length / itemsPerPage.value);
      }
      return pages;
    };

    const getFilterCount = () => {
      const selectedArrayValue = selectedItems.find(
        (item) => item === itemsPerPage.value
      );
      itemsPerPage.value = selectedArrayValue;
    };

    const getCategory = (category) => {
      const result = categoryList.value.filter((list) => {
        return list === category;
      });
      categoryName.value = result[0];
      currentPage.value = 1;
    };

    return {
      getExpense,
      transDetails,
      isModal,
      applicationId,
      applicationType,
      filteredItems,
      dateFormat,
      routeModal,
      getValue,
      itemsPerPage,
      selectedItems,
      currentPage,
      paginatedList,
      previousPage,
      nextPage,
      totalPages,
      totalPgCount,
      getFilterCount,
      headerName,
      categoryList,
      getCategory,
      categoryName,
      filteredData,
      isRender,
    };
  },
};
</script>

<style scoped>
.section-list {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  font-family: "Montserrat";
  color: rgb(94, 92, 92);
  padding: 20px;
  margin: 10px;
  display: flex;
  gap: 10px;
}
li {
  list-style: none;
}

.list-time {
  font-size: small;
}

.transaction-icon {
  width: 50px;
  padding-top: 20px;
  padding-left: 5px;
}

.icon-toogle input {
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.transaction-details-prf {
  width: 740px;
}

.transaction-icon img {
  width: 40px;
  height: 40px;
}

.btn-pagination {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0px 20px 0px;
}

.nxt-btn,
.prev-btn {
  background-color: #3e535f;
  color: white;
}

.btn:hover {
  cursor: pointer;
}

.btn {
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 400;
  justify-content: center;
  text-decoration: none;
  transition: all 250ms;
  line-height: 30px;
}

.previous,
.next {
  width: auto;
  justify-content: flex-end;
  gap: 5px;
}

.page-number {
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-family: "Montserrat";
  min-width: 100%;
}

.show-entries {
  padding: 20px 0px 0px 0px;
  font-family: "Montserrat";
  font-size: 17px;
}
.item-entries {
  font-family: "Montserrat";
  font-size: 17px;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #3b3a3a;
  line-height: 30px;
}

.show-categories {
  padding: 0px 0px 20px 0px;
  font-family: "Montserrat";
  font-size: 17px;
}

.item-categories {
  font-family: "Montserrat";
  font-size: 17px;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #3b3a3a;
  line-height: 30px;
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>{{ titleRequest }}</h1>
        </div>
      </div>
      <form @submit.prevent="handleAction(selectedStatus)">
        <div
          class="form-group"
          v-if="dataItems.actionStatus === 1 || dataItems.actionStatus === 4"
        >
          <select v-model="selectedStatus" required>
            <option v-for="item in modItemStatus" :key="item">
              {{ item }}
            </option>
          </select>
          <div class="form-group" v-if="selectedStatus === 'Disapproved'">
            <label for=""> Remarks: <span class="required">*</span> </label>
            <div class="input">
              <textarea rows="8" v-model="approverRemarks" required />
            </div>
          </div>
        </div>
        <div class="form-group" v-else>
          <div>
            <div class="form-group flex">
              <label for="">
                <h4>Date Intended:</h4>
              </label>
              <h4 class="titleMessage">
                {{ dateFormat(dataItems.dateStart, dataItems.dateEnd) }}
              </h4>
            </div>
            <div class="form-group flex">
              <label for="">
                <h4>Render Timeout & hours:</h4>
              </label>
              <h4 class="titleMessage">
                {{
                  `${timeFormat(dataItems.timeStart)}-${timeFormat(
                    dataItems.timeEnd
                  )} (${convertTime(dataItems.origNumberHours)})`
                }}
              </h4>
            </div>
            <div
              class="form-group flex"
              v-if="!$store.state.getProfileInfo.ifSuperVisor && haveSupervisor"
            >
              <label for="">
                <h4>Supervisor Approved Render Hrs:</h4>
              </label>
              <h4 class="titleMessage">
                {{ convertTime(dataItems.numberHours) }}
              </h4>
            </div>
            <div class="form-group flex">
              <label for="">
                <h4>Edit Render Hours:</h4>
              </label>
              <label class="toggle">
                <input type="checkbox" id="switch" v-model="isEditHrs" />
                <span class="slider"></span>
              </label>
            </div>
            <div class="form-group flex-mod" v-if="isEditHrs">
              <label for="">
                <h4>Render Hours:</h4>
              </label>
              <div class="titleMessage">
                <input
                  v-model="modifiedRenderHrs"
                  type="time"
                  class="without_ampm"
                  :max="dataItems.origNumberHours"
                />
              </div>
            </div>
          </div>
          <hr class="separator" />
          <div>
            <h4 class="titleMessage">Biotime Dtr:</h4>
            <div v-for="(item, index) in itemDTRs" :key="index">
              <div class="form-group flex">
                <label for="">
                  <h4 class="indent">
                    {{ item.io === "I" ? "Time-in:" : "Time-out:" }}
                  </h4>
                </label>
                <h4 class="titleMessage">{{ timeFormat(item.timelog) }}</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-button">
          <div>
            <input
              :disabled="disabled"
              class="btn approved"
              type="submit"
              :value="dataItems.actionStatus === 1 ? 'Submit' : 'Approve'"
            />
          </div>

          <div>
            <input
              class="btn cancel"
              type="submit"
              value="Cancel"
              @click="closeModal"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { computed, inject, onMounted, ref } from "vue";
import dtrAttendance from "@/components/utils/reusables/attendanceDTR";
export default {
  props: {
    requestId: Number,
    dataItems: Object,
    ishaveSupervisor: Number,
  },
  setup(props, { emit }) {
    const { getEmployeeDTR, dtrList } = dtrAttendance();
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const disabled = ref(false);
    const approverRemarks = ref(null);
    const posts = ref(null);
    const duplicateMessage = ref(false);
    const isToggle = ref(false);
    const isPreApp = ref(1);
    const actionStatus = ref(1);
    const selectedStatus = ref(null);
    const statusAction = ref(null);
    const approverDate = ref(null);
    const itemDTRs = ref(dtrList);
    const isEditHrs = ref(false);
    const itemStatus = ref(["Pre-approved", "Approved", "Disapproved"]);
    const getEmployeeNo = ref(props.dataItems.employeeno);
    const departmentItem = ref([]);
    const haveSupervisor = ref(props.ishaveSupervisor);
    const ifUserSupervisor = ref(props.dataItems.User.ifSuperVisor);
    const modifiedRenderHrs = ref(props.dataItems.numberHours);
    onMounted(() => {
      getEmployeeDTR(props.dataItems);
    });
    const closeModal = (
      val,
      payLoad,
      status,
      date,
      approverRemarks,
      approver,
      ispreapproved
    ) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit(
        "passClose",
        val,
        payLoad,
        props.requestId,
        status,
        date,
        approverRemarks,
        approver,
        ispreapproved
      );
    };
    const titleRequest = computed(() => {
      return "Overtime Request";
    });
    const modItemStatus = computed(() => {
      let status = props.dataItems.isPreapproved;
      let result;
      result = itemStatus.value.filter((val) => {
        if (!status) {
          return val === "Pre-approved" || val === "Disapproved";
        } else {
          return val !== "Pre-approved";
        }
      });
      return result;
    });

    const approverAction = async (id, action, remarks) => {
      try {
        let getActionStat = props.dataItems.actionStatus;
        let breakpointLog;

        if (getActionStat === 1) {
          statusAction.value = action;
          let preApp;
          if (action === "Pre-approved") {
            preApp = 1;
          }
          actionStatus.value = 2;
          approverDate.value = moment().format();
          await axios.put(`/api/admin/employee/overtime/${id}`, {
            status: statusAction.value,
            isPreapproved: preApp,
            approverBy: store.state.getUserLog,
            dtApproved: moment().format(),
            statusRemarks: remarks,
            actionStatus: actionStatus.value,
          });
        } else if (getActionStat === 2) {
          if (haveSupervisor.value) {
            if (props.dataItems.department === "Marketing") {
              statusAction.value = "Approved";
              actionStatus.value = 4;
            }
            if (
              props.dataItems.department ===
              "Health Provider & Patient Relations"
            ) {
              if (ifUserSupervisor.value) {
                statusAction.value = "Approved";
                actionStatus.value = 4;
              } else {
                statusAction.value = "Pre-qualified";
                actionStatus.value = 3;
              }
            }
            if (props.dataItems.department === "Customer Services") {
              if (ifUserSupervisor.value) {
                statusAction.value = "Approved";
                actionStatus.value = 4;
              } else {
                statusAction.value = "Pre-qualified";
                actionStatus.value = 3;
              }
            }
            if (props.dataItems.department === "Astral Clinic") {
              if (ifUserSupervisor.value) {
                statusAction.value = "Approved";
                actionStatus.value = 4;
              } else {
                statusAction.value = "Pre-qualified";
                actionStatus.value = 3;
              }
            }
            if (props.dataItems.department === "Muntinlupa Clinic") {
              if (ifUserSupervisor.value) {
                statusAction.value = "Approved";
                actionStatus.value = 4;
                breakpointLog = "invalid 1";
              } else {
                statusAction.value = "Pre-qualified";
                actionStatus.value = 3;
                breakpointLog = "valid";
              }
            }
            if (props.dataItems.department === "Corporate Services") {
              if (
                (props.dataItems.designation === "Messenger" ||
                  props.dataItems.designation === "Company Driver" ||
                  props.dataItems.designation === "Utility Worker") &&
                props.dataItems.department === "Corporate Services"
              ) {
                statusAction.value = "Pre-qualified";
                actionStatus.value = 3;
              }
              if (
                props.dataItems.designation !== "Messenger" &&
                props.dataItems.designation !== "Company Driver" &&
                props.dataItems.designation !== "Utility Worker" &&
                props.dataItems.department === "Corporate Services"
              ) {
                statusAction.value = "Approved";
                actionStatus.value = 4;
              }
            }
          } else {
            statusAction.value = "Approved";
            actionStatus.value = 4;
            breakpointLog = "invalid";
          }
          approverDate.value = moment().format();
          await axios.put(`/api/admin/employee/overtime/${id}`, {
            status: statusAction.value,
            approverBy: store.state.getUserLog,
            dtApproved: moment().format(),
            statusRemarks: remarks,
            numberHours: isEditHrs.value
              ? modifiedRenderHrs.value
              : props.dataItems.numberHours,
            actionStatus: actionStatus.value,
            taskCompleted: breakpointLog,
          });
        } else if (getActionStat === 3) {
          statusAction.value = "Approved";
          actionStatus.value = 4;
          approverDate.value = moment().format();
          await axios.put(`/api/admin/employee/overtime/${id}`, {
            status: statusAction.value,
            approverBy: store.state.getUserLog,
            dtApproved: moment().format(),
            statusRemarks: remarks,
            numberHours: isEditHrs.value
              ? modifiedRenderHrs.value
              : props.dataItems.numberHours,
            actionStatus: actionStatus.value,
          });
        } else {
          statusAction.value = action === "Approved" ? "Completed" : action;
          actionStatus.value = 5;
          approverDate.value = moment().format();
          await axios.put(`/api/admin/employee/overtime/${id}`, {
            status: statusAction.value,
            approverBy: store.state.getUserLog,
            dtApproved: moment().format(),
            statusRemarks: remarks,
            actionStatus: actionStatus.value,
          });
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const handleAction = async () => {
      disabled.value = true;
      let action = selectedStatus.value;
      let userType = store.state.getUserType;
      //confirmation
      Swal.fire({
        title: "Are you sure",
        text: "you want to submit?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          const id = props.requestId;
          const remarks = approverRemarks.value;
          approverAction(id, action, remarks);
          swalConfirmation(action, userType);
        } else {
          disabled.value = false;
        }
      });
    };
    const swalConfirmation = async (action) => {
      let message;
      if (statusAction.value === "Pre-qualified") {
        action = "Noted";
      } else {
        action = statusAction.value;
      }
      message =
        action === "Disapproved"
          ? `Request was regretfully ${action}`
          : `Request was successfully ${action}`;

      setTimeout(async () => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 6000,
        });
        closeModal(
          false,
          true,
          statusAction.value,
          approverDate.value,
          approverRemarks.value,
          store.state.getUserLog,
          actionStatus.value
        );
      }, 400);
    };
    const timeFormat = (start) => {
      let modFormat;
      let startTime;

      startTime = `${moment().format("YYYY-MM-DD")} ${start}`;
      modFormat = `${moment(startTime).format("LT")}`;

      return modFormat;
    };
    const dateFormat = (start, end) => {
      let modFormat;
      if (end) {
        modFormat = `${moment(start).format("ll")} - ${moment(end).format(
          "ll"
        )}`;
      } else {
        modFormat = `${moment(start).format("ll")} `;
      }

      return modFormat;
    };
    const convertTime = (time) => {
      if (time === "00:00:00") {
        return "-";
      } else {
        let modFormat;
        let convertTime = `${moment().format("YYYY-MM-DD")} ${time}`;
        let getHours = moment(convertTime).format("HH");
        if (getHours > 0) {
          modFormat = `${moment(convertTime).format("hh [hrs] mm [mins]")}`;
        } else {
          modFormat = `${moment(convertTime).format("mm [mins]")}`;
        }

        return modFormat;
      }
    };

    return {
      closeModal,
      handleAction,
      disabled,
      approverRemarks,
      posts,
      itemStatus,
      modItemStatus,
      selectedStatus,
      approverAction,
      titleRequest,
      swalConfirmation,
      duplicateMessage,
      statusAction,
      isToggle,
      isPreApp,
      actionStatus,
      itemDTRs,
      getEmployeeNo,
      timeFormat,
      isEditHrs,
      dateFormat,
      convertTime,
      departmentItem,
      haveSupervisor,
      ifUserSupervisor,
      modifiedRenderHrs,
      dtrList,
    };
  },
};
</script>
<style scoped>
select {
  font-size: 15px;
  font-style: normal;
  font-family: "Montserrat";
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 100%;
  margin-bottom: 15px;
}
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.approved {
  background-color: rgb(25, 179, 102);
}
.disapproved {
  background-color: rgb(219, 86, 53);
}
.cancel {
  background-color: rgb(137, 67, 228);
}
.dv-form {
  padding-top: 20px;
}
.form-cancelled {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 20px;
}
.flex {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.flex-mod {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.titleMessage {
  color: rgb(49, 49, 49);
}
.indent {
  margin-left: 18px;
}
.separator {
  margin: 15px 0px;
}
.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}
.dataTable {
  margin-bottom: 20px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 0.65em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.65em;
  text-align: left;
  font-size: 0.9em;
}
table th {
  font-size: 0.65em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
@media only screen and (min-width: 210px) and (max-width: 500px) {
  .modal-container {
    min-width: 200px;
  }
  .modal {
    width: 300px;
    max-width: 100%;
    padding: 30px;
  }
}
</style>

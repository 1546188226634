<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label for=""> Date Filed: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="formData.dateFiled" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Date Intended: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="date"
              v-model="formData.dateIntended"
              :min="$store.state.currentDate"
            />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Payee: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="text"
              v-model="formData.payee"
              @input="searchPayee()"
            />
          </div>
          <div
            class="payee-container"
            v-if="formData.payee && payeeItemList.length ? true : false"
          >
            <ul>
              <li
                v-for="payee in payeeItemList.slice(0, 10)"
                :key="payee.name"
                @click="selectedPayee(payee.name)"
              >
                {{ payee.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <div class="checkbox-container">
            <label for=""> Cash Advance: </label>
            <div class="input">
              <input type="checkbox" v-model="formData.ifCashAdvance" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Purpose / Description: <span class="required">*</span>
          </label>
          <div class="input">
            <textarea rows="5" v-model="formData.remarks" />
          </div>
        </div>

        <div class="autotext">
          <div ref="myDiv" class="accountype-title">
            <label for="">Account Type</label>
            <img
              @click="addTextbox"
              class="image-button"
              src="../../assets/images/add_.png"
              alt="icon"
            />
          </div>

          <div
            class="form-group"
            v-for="(expense, index) in expenseDetails"
            :key="index"
          >
            <select
              ref="textbox"
              v-model="expense.name"
              @change="selectedExpense(expense.name)"
              required
            >
              <option v-for="post in itemAccountType" :key="post.id">
                {{ post.description }}
              </option>
            </select>
            <input
              type="number"
              step="any"
              min="1"
              v-model="expense.amount"
              @input="getTotal()"
            />
            <div>
              <img
                class="image-button"
                src="../../assets/images/remove_.png"
                alt="icon"
                @click="removeExpense(index)"
              />
            </div>
          </div>
          <div class="form-group1">
            <label for=""> Total : </label>
            <div class="input1">
              <input
                type="number"
                step="any"
                v-model="formData.total"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Requested By: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="formData.requestedBy">
              <option v-for="name in empName" :key="name.id">
                {{ name.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for=""> Attachment: </label>
            <div class="fileinput input">
              <input
                ref="fileData"
                type="file"
                @change="selectFile(fileData)"
              />
            </div>
            <div v-if="!fileData"></div>
            <div>
              <h5 style="color: red">Note: Maximum upload file size: 5 MB</h5>
            </div>
            <div v-if="$store.state.errorFileSize" style="padding-top: 20px">
              <div class="alert-error form-alert">
                {{ $store.state.errorFileSize }}
              </div>
            </div>
            <div v-if="isAlert" style="padding-top: 20px">
              <div class="alert-error form-alert">
                {{ $store.state.errorMessage }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="submit" :disabled="buttonMessageVisible">
            {{ !buttonMessageVisible ? "Submit" : "Please wait..." }}
          </button>
        </div>
        <div v-show="isAlert">
          <div v-show="isStatus" class="alert-error form-alert">
            {{ $store.state.formErrorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive, ref, onMounted, computed } from "vue";
import { inject } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  setup(props, { emit }) {
    const store = inject("$store");
    const isVisible = ref(false);
    const isAlert = ref(false);
    const isStatus = ref(false);
    const posts = ref([]);
    const empName = ref([]);
    const recValue = ref(null);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const modFileName = ref(null);
    const formData = reactive({
      employeeID: store.state.getEmployeeId,
      dateFiled: moment().format("YYYY-MM-DD"),
      dateIntended: null,
      payee: null,
      remarks: null,
      total: "0",
      requestedBy: store.state.getUserLog,
      approver: "semi-moderator",
      department: store.state.getDepartment,
      ifrecurring: "1",
      ifCashAdvance: false,
      filename: "",
      modFileName: "",
    });
    const payeeList = ref([]);
    const payeeItemList = ref([]);
    const buttonMessageVisible = ref(false);
    const itemAccountType = computed(() => {
      let result;
      return posts.value.filter((post) => {
        let userDepartment = store.state.getDepartment;
        if (userDepartment === "Accounting") {
          result = post;
        } else if (userDepartment === "Corporate Services") {
          result = post.filterBy !== "ACCOUNTING";
        } else {
          result = post.filterBy === "GENERAL";
        }
        return result;
      });
    });
    const insertFormData = async () => {
      try {
        const result = await axios.post(`/api/form/payRequest`, formData);
        const paymentId = result.data.data;
        isAlert.value = false;
        insertPayees();
        bulkInsert(paymentId);
      } catch (error) {
        console.log(error);
        store.commit("errorHandler", error);
      }
    };
    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          formData.dateFiled = moment().format("YYYY-MM-DD");
          formData.dateIntended = null;
          formData.payee = null;
          formData.remarks = null;
          formData.total = 0;
          formData.ifCashAdvance = false;
          formData.filename = "";
          formData.requestedBy = store.state.getUserLog;
          expenseDetails.splice(1, expenseDetails.length);
          expenseDetails[0].name = null;
          expenseDetails[0].amount = 0;
          store.state.getPaymentReference = null;
          fileData.value.value = null;
          modFileName.value = null;
        }, 1100);
      }
    };
    const handleValidation = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (
          key !== "ifCashAdvance" &&
          key !== "filename" &&
          key !== "modFileName"
        ) {
          if (!value) {
            validate.push(key);
          }
        }
      });
      if (validate.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else if (payeeItemList.value.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `Please Select Payee`;
      } else if (store.state.errorFileSize) {
        store.state.errorFileSize;
      } else {
        if (recValue.value === 1) {
          formData.approver = "semi-moderator";
          formData.ifrecurring = "1";
        } else {
          formData.ifrecurring = "0";
          if (store.state.getDepartment === "Marketing") {
            formData.approver = "moderator";
          } else if (
            store.state.getDepartment === "Health Provider & Patient Relations"
          ) {
            formData.approver = "moderator";
          } else if (store.state.getDepartment === "Accounting") {
            formData.approver = "pre-moderator";
          } else if (store.state.getDesignation === "Purchasing Coordinator") {
            formData.approver = "super";
          } else {
            formData.approver = "semi-moderator";
          }
        }
        Swal.fire({
          title: "Are you sure you",
          text: "want to submit this request?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            buttonMessageVisible.value = true;
            if (modFileName.value) {
              uploadHandler();
            } else {
              insertFormData();
            }
            emit("isLoaded");
          }
        });
      }
    };
    const handleSubmit = async () => {
      handleValidation(formData);
    };
    onMounted(async () => {
      try {
        getUsers();
        getExpense();
        getPayees();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    });
    const selectedExpense = (recurring) => {
      const result = posts.value.filter((post) => {
        return post.description === recurring;
      });
      let checkIfAdvances = result[0].subAccount;
      if (checkIfAdvances) {
        formData.ifCashAdvance = true;
      }
      recValue.value = result[0].recurring;
    };
    const getExpense = async () => {
      try {
        const { data } = await axios.get("/api/form/expense");
        posts.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const getUsers = async () => {
      try {
        const { data } = await axios.get("/api/getUsers");
        empName.value = data.filter((user) => {
          return user.department === store.state.getDepartment;
        });
      } catch (error) {
        console.log(error);
        store.commit("errorHandler", error);
      }
    };
    const expenseDetails = reactive([
      {
        name: null,
        amount: 0,
        reqID: null,
        recurring: 0,
      },
    ]);
    const bulkInsert = async (paymentId) => {
      try {
        const modified = expenseDetails.map((val, index) => {
          if (index >= 0) {
            val.reqID = paymentId;
          }
          return val;
        });
        await axios.post(`/api/form/bulkExpense`, modified);
        const SEQUENCE_ARRAY_CODE = 2;
        store.state.getPaymentReference = 1000000 + paymentId;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearFormField(true);
        buttonMessageVisible.value = false;
      } catch (error) {
        console.log(error);
        store.commit("errorHandler", error);
      }
    };
    const selectFile = () => {
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];
        uploadFile.value = fileData.value;

        modFileName.value = `${
          fileData.value.lastModified
        }.${fileData.value.name.split(".").pop()}`;
        formData.modFileName = modFileName.value;
        formData.filename = fileData.value.name;
        let size = fileData.value.size;
        store.commit("fileHandler", size);
      } else {
        formData.filename = null;
        formData.modFileName = null;
        modFileName.value = null;
      }
    };
    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", uploadFile.value, modFileName.value);
      try {
        await axios.post(`/api/upload/`, formData);
        insertFormData();
      } catch (error) {
        console.log(error);
        isAlert.value = true;
        setTimeout(() => {
          isAlert.value = false;
        }, 5000);
        store.commit("errorHandler", error);
      }
    };
    const addTextbox = () => {
      if (recValue.value != 1) {
        expenseDetails.push({
          name: "",
          amount: 0,
          reqID: null,
          recurring: 0,
        });
      }
    };
    const removeExpense = (index) => {
      const arrayLength = ref(expenseDetails.length);
      if (arrayLength.value > 1) {
        expenseDetails.splice(index, 1);
      }
      getTotal();
    };
    const getTotal = () => {
      const totalPrice = expenseDetails.reduce((total, item) => {
        return total + item.amount;
      }, 0);
      formData.total = totalPrice.toFixed(2);
    };
    const searchPayee = () => {
      payeeItemList.value = payeeList.value.filter((item) => {
        return item.name
          .toLocaleUpperCase()
          .trim()
          .includes(formData.payee.toLocaleUpperCase().trim());
      });
    };
    const selectedPayee = (payee) => {
      formData.payee = payee;
      payeeItemList.value = [];
      isAlert.value = false;
      isStatus.value = false;
    };
    const getPayees = async () => {
      try {
        const { data } = await axios.get("/api/payees");
        payeeList.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const insertPayees = async () => {
      try {
        await axios.post(`/api/payees/`, {
          name: formData.payee,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
      setTimeout(async () => {
        getPayees();
      }, 2000);
    };
    return {
      itemAccountType,
      selectedPayee,
      searchPayee,
      payeeList,
      payeeItemList,
      getPayees,
      insertPayees,
      formData,
      insertFormData,
      isVisible,
      clearFormField,
      isStatus,
      handleValidation,
      handleSubmit,
      isAlert,
      posts,
      selectedExpense,
      recValue,
      empName,
      fileData,
      uploadFile,
      selectFile,
      uploadHandler,
      getUsers,
      getExpense,
      addTextbox,
      expenseDetails,
      bulkInsert,
      removeExpense,
      getTotal,
      modFileName,
      buttonMessageVisible,
    };
  },
};
</script>
<style scoped>
select {
  width: 30px;
}
.fileinput input {
  width: 30px;
}
.form1 .autotext .form-group {
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  box-sizing: border-box;
  gap: 5px;
}
.form1 .autotext .form-group select {
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 60.5%;
}
.form1 .autotext .form-group input {
  min-width: 29.5%;
}
.form-group img {
  padding-top: 7px;
  cursor: pointer;
}
.image-button {
  cursor: pointer;
}
.form1 .form-group1 {
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  box-sizing: border-box;
  gap: 5px;
}
.form1 .form-group1 label {
  min-width: 60.5%;
  text-align: right;
  padding-top: 8px;
}
.form1 .form-group1 .input1 input {
  float: left;
  width: 40%;
  min-width: 83.5%;
}
.checkbox-container {
  display: flex;
  align-items: center;
}
.checkbox-container label {
  margin-right: 14px; /* Adjust as needed */
}
.checkbox-container .input input {
  margin-top: 6px; /* Adjust as needed */
}
.accountype-title {
  display: flex;
  justify-content: space-between;
}
.payee-container {
  margin-top: -12px;
  background-color: #fff;
  padding: 10px 0px;
  border-radius: 5px;
  max-width: auto;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  color: var(--lightFont);
  cursor: pointer;
}
.payee-container ul {
  list-style: none;
  line-height: 30px;
}
.payee-container ul li {
  padding: 0px 15px;
}
.payee-container ul li:hover {
  background-color: #ffdbaf;
}
.alert-error form-alert {
  padding-top: 5px;
}
</style>

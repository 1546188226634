import { inject } from "vue";
import Swal from "sweetalert2";
export default function userDetails() {
  const store = inject("$store");
  const axios = inject("$axios");
  const router = inject("$router");
  const moment = inject("$moment");
  const getUserDetails = async () => {
    store.state.isVisible = false;
    store.state.currentDate = moment().format("YYYY-MM-DD");
    store.state.secretTokenKey = localStorage.getItem(
      `${process.env.VUE_APP_AUTH_TOKEN}_XXX`
    );
    if (store.state.secretTokenKey) {
      try {
        const user = await axios.get("/api/getUserInfo");
        store.state.getProfileInfo = user.data.data;
        store.state.getEmployeeId = user.data.data.id;
        store.state.getUserLog = user.data.data.name;
        store.state.getBirthDate = user.data.data.birthDate;
        store.state.getDepartment = user.data.data.department;
        store.state.getDesignation = user.data.data.designation;
        store.state.getIfChangePassword = user.data.data.isResetPassword;
        store.state.getGender = user.data.data.gender;
        store.state.getNotifCounter = user.data.data.notifCounter;
        store.state.swalDetailList[0].balance = user.data.data.vlBalance;
        store.state.swalDetailList[1].balance = user.data.data.slBalance;
        store.state.getMobile = user.data.data.mobile;
        store.state.getEmail = user.data.data.email;
        store.state.getNotifSetting = user.data.data.notifSetting;
        store.state.getBdayStat = user.data.data.isBirthDate;
        store.state.getSPL = user.data.data.splCredit;
        store.state.getPL = user.data.data.plCredit;
        store.state.getVAWC = user.data.data.vawcCredit;
        store.state.getDTRStat = user.data.data.ifDtr;
        store.state.getdtrDate = user.data.data.dtrDate;
        store.state.getdtrTime = user.data.data.dtrTime;
        store.state.getEmployeeNo = user.data.data.employee_number;
        store.state.NUMBER_FILING_LIMIT = user.data.data.filingLimit;

        const { data } = await axios.get("/api/systemmaintenance");
        let dataItem = data;
        let statusCode = dataItem[0].code;
        if (statusCode === "Active") {
          if (store.state.getIfChangePassword === 1) {
            let workStatus = store.state.getProfileInfo.isBiotime;
            if (workStatus === 0) {
              Swal.fire({
                title: "We are happy to see you.",
                text: `Thank you for your services. As you are not currently connected to this organization, please be advised that your account will be closed soon.`,
                imageUrl: require("../../../assets/images/welcomeback.png"),
                imageWidth: 240,
                imageHeight: 240,
                imageAlt: "Custom image",
              });
              store.state.isVisible = false;
              store.commit("logoutHandler");
              return router.push("/login");
            } else {
              return router.push("/");
            }
          } else {
            store.state.isVisible = false;
            store.commit("logoutHandler");
            return router.push("/login");
          }
        } else {
          store.state.isVisible = false;
          store.commit("logoutHandler");
          return router.push("/login");
        }
      } catch (err) {
        store.commit("errorHandler", err);
      }
    }
  };
  return {
    getUserDetails,
  };
}

<template>
  <div class="setting-body">
    <table>
      <tr>
        <td class="title">
          <img
            :src="$store.state.getProfilePath"
            alt="icon"
            class="avatar profile-round"
          />
        </td>
        <td class="description" v-if="isDefault"></td>
        <td class="description fileInput" v-else>
          <input
            ref="fileData"
            type="file"
            name="avatar"
            id=""
            accept="image/png, image/jpeg"
            @change="selectFile()"
          />
        </td>
        <div v-if="!fileData"></div>
      </tr>
      <tr class="tr-note" v-if="!isDefault">
        <td></td>
        <td class="note">
          Recommended: For good quality:
          <a href="https://crop-circle.imageonline.co/" target="_blank"
            >click here</a
          >
        </td>
      </tr>
      <tr>
        <td class="title">Name:</td>
        <td class="description" v-if="isDefault">
          {{ $store.state.getUserLog }}
        </td>
        <td class="description" v-else>
          {{ $store.state.getUserLog }}
        </td>
      </tr>
      <tr>
        <td class="title">Gender:</td>
        <td class="description" v-if="isDefault">
          {{ $store.state.getGender === "F" ? "Female" : "Male" }}
        </td>
        <td class="description" v-else>
          {{ $store.state.getGender === "F" ? "Female" : "Male" }}
        </td>
      </tr>
      <tr>
        <td class="title">Birth Date:</td>
        <td class="description" v-if="isDefault">
          {{ !$store.state.getBirthDate ? "NA" : $store.state.getBirthDate }}
        </td>
        <td class="description" v-else>
          <input type="text" v-model="getBirthDate" />
        </td>
      </tr>
      <tr>
        <td class="title">Email:</td>
        <td class="description" v-if="isDefault">
          {{ !$store.state.getEmail ? "NA" : $store.state.getEmail }}
        </td>
        <td class="description" v-else>
          <input type="text" v-model="getEmail" />
        </td>
      </tr>
      <tr>
        <td class="title">Mobile:</td>
        <td class="description" v-if="isDefault">
          {{ `${$store.state.getMobile}` }}
        </td>
        <td class="description" v-else>
          <input type="text" v-model="getMobile" />
        </td>
      </tr>
    </table>

    <div class="input-field btn-content">
      <input
        type="submit"
        class="btn"
        :value="!isDefault ? 'Update' : 'Edit'"
        @click="submitHandler()"
      />
      <input
        type="submit"
        class="btn"
        value="Cancel"
        v-show="!isDefault"
        @click="cancelHandler()"
      />
    </div>
  </div>
</template>
<script>
import { ref, inject } from "vue";
import Swal from "sweetalert2";
export default {
  setup() {
    const isDefault = ref(true);
    const axios = inject("$axios");
    const store = inject("$store");
    const getEmail = ref(store.state.getEmail);
    const getMobile = ref(store.state.getMobile);
    const getBirthDate = ref(store.state.getBirthDate);
    const getProfile = ref(null);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const profileURL = ref(null);
    const submitHandler = () => {
      isDefault.value = false;
      let checkIfFiles;
      if (fileData.value) {
        checkIfFiles = fileData.value.files.length;
      }
      if (
        getEmail.value !== store.state.getEmail ||
        getMobile.value !== store.state.getMobile ||
        getBirthDate.value !== store.state.getBirthDate ||
        checkIfFiles
      ) {
        Swal.fire({
          title: "Are you sure",
          text: "you want to submit?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            updateHandler();
            store.state.getEmail = getEmail.value;
            store.state.getMobile = getMobile.value;
            store.state.getBirthDate = getBirthDate.value;
            isDefault.value = true;
          }
        });
      }
    };
    const cancelHandler = () => {
      isDefault.value = true;
      getEmail.value = store.state.getEmail;
      getMobile.value = store.state.getMobile;
      getBirthDate.value = store.state.getBirthDate;
    };
    const updateHandler = async () => {
      try {
        const employeeId = store.state.getEmployeeId;

        if (
          getEmail.value !== store.state.getEmail &&
          getMobile.value !== store.state.getMobile &&
          getBirthDate.value !== store.state.getBirthDate &&
          fileData.value.files.length
        ) {
          await axios.put(`api/user/${employeeId}`, {
            email: getEmail.value,
            mobile: getMobile.value,
            birthDate: getBirthDate.value,
          });
          profileHandler(employeeId);
        } else if (
          getEmail.value !== store.state.getEmail &&
          fileData.value.files.length
        ) {
          await axios.put(`api/user/${employeeId}`, {
            email: getEmail.value,
          });
          profileHandler(employeeId);
        } else if (
          getBirthDate.value !== store.state.getBirthDate &&
          fileData.value.files.length
        ) {
          await axios.put(`api/user/${employeeId}`, {
            birthDate: getBirthDate.value,
          });
          profileHandler(employeeId);
        } else if (
          getMobile.value !== store.state.getMobile &&
          fileData.value.files.length
        ) {
          await axios.put(`api/user/${employeeId}`, {
            mobile: getMobile.value,
          });
          profileHandler(employeeId);
        } else if (
          getEmail.value !== store.state.getEmail &&
          getMobile.value !== store.state.getMobile &&
          getBirthDate.value !== store.state.getBirthDate
        ) {
          await axios.put(`api/user/${employeeId}`, {
            email: getEmail.value,
            mobile: getMobile.value,
            birthDate: getBirthDate.value,
          });
        } else if (getEmail.value !== store.state.getEmail) {
          await axios.put(`api/user/${employeeId}`, {
            email: getEmail.value,
          });
        } else if (getBirthDate.value !== store.state.getBirthDate) {
          await axios.put(`api/user/${employeeId}`, {
            birthDate: getBirthDate.value,
          });
        } else if (getMobile.value !== store.state.getMobile) {
          await axios.put(`api/user/${employeeId}`, {
            mobile: getMobile.value,
          });
        } else if (fileData.value.files.length) {
          profileHandler(employeeId);
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const selectFile = () => {
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];
        uploadFile.value = fileData.value;

        getProfile.value = `${fileData.value.lastModified}.${fileData.value.name
          .split(".")
          .pop()}`;
      } else {
        getProfile.value = null;
      }
    };
    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", uploadFile.value, getProfile.value);
      try {
        let result = await axios.post(`/api/upload/profile/`, formData);
        profileURL.value = result.data.url;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const reminderPopUp = () => {
      Swal.fire({
        title: "Preparing your Profile Picture...",
        text: "This will only take a several minutes. Please do not refresh the page.",
        imageUrl: require("../../assets/images/upload.gif"),

        imageAlt: "Custom image",
      });
    };
    const profileHandler = async () => {
      uploadHandler();
      reminderPopUp();

      setTimeout(async () => {
        try {
          const employeeId = store.state.getEmployeeId;
          await axios.put(`api/user/${employeeId}`, {
            mediaLink: profileURL.value,
          });
          store.commit("fetchProfileHandler", profileURL.value);
        } catch (error) {
          store.commit("errorHandler", error);
        }
      }, 8000);
    };

    return {
      isDefault,
      getEmail,
      getMobile,
      getProfile,
      updateHandler,
      submitHandler,
      cancelHandler,
      uploadHandler,
      profileHandler,
      selectFile,
      fileData,
      uploadFile,
      reminderPopUp,
      getBirthDate,
      profileURL,
    };
  },
};
</script>
<style scoped>
table {
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  word-break: break-all;
}
.tr-note {
  height: 0px;
}
.title {
  width: 120px;
  font-family: "Montserrat";
  font-weight: 800;
}
.description {
  max-width: 100%;
  color: #272626;
}
.btn-content {
  max-width: 140px;
  margin: 20px 0px 20px 0px;
  display: flex;
  gap: 20px;
}
.submit {
  background-color: #3e535f;
}
.avatar {
  width: 70px;
  height: 70px;
}

.btn {
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 400;
  justify-content: center;
  text-decoration: none;
  transition: all 250ms;
  line-height: 30px;
  background-color: #3e535f;
}
.btn:hover {
  cursor: pointer;
}
.note {
  font-size: 12px;
  color: rgb(255, 0, 0);
  position: relative;
  font-family: "Montserrat";
  font-weight: 400;
  top: -16px;
}
@media only screen and (max-width: 600px) {
  .note {
    font-size: 11px;
    top: -8px;
  }
  table {
    font-size: 13px;
  }
  table > tr {
    height: 30px;
  }
  .title {
    width: 70px;
    font-family: "Montserrat";
    font-weight: 800;
  }
  .description {
    color: #272626;
  }
  .avatar {
    width: 55px;
    height: 55px;
  }
  .fileInput input {
    max-width: 30px;
  }
  .btn-content {
    max-width: 100px;
    margin: 10px 0px 10px 0px;
    display: flex;
    gap: 14px;
  }

  .btn {
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 400;
  }
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>Status History</h1>
        </div>
        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
      <div class="auth-grid">
        <div v-for="(item, index) in itemList" :key="index">
          <div class="card">
            <div class="status-approver">
              <h4>
                {{ item.status }} by
                {{ item.approver }}
              </h4>
            </div>
            <div class="status-date">
              <div>{{ item.date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, inject, onMounted } from "vue";
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
export default {
  components: {
    closeIconSVG,
  },
  props: {
    paymentId: Number,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const itemList = ref([]);
    const goRoute = (val) => {
      emit("passClose", val);
    };

    onMounted(() => {
      statusActivity();
    });
    const statusActivity = async () => {
      try {
        const response = await axios.get(
          `api/approver/paymentstatuslog/${props.paymentId}`
        );
        itemList.value = response.data.map((entry) => {
          return {
            ...entry,

            date: moment(entry.createdAt).format("LLL"),
            status: entry.status,
            approver: entry.approver,
          };
        });
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    return {
      goRoute,
      statusActivity,
      itemList,
    };
  },
};
</script>

<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.route {
  cursor: pointer;
}
.card {
  padding: 10px 10px;
  border-radius: 5px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  font-family: "Poppins";
}
.auth-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-top: 30px;
}

.status-title span h2 {
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 600;
}
.status-approver h4 {
  padding: 4px 0px 4px 0px;
  color: var(--lightSecondary);
  line-height: 22px;
  font-size: 15px;
}
.status-date {
  display: flex;
  font-family: "Poppins";
  padding: 2px 0px 2px 0px;
  font-size: 14px;
}
</style>

<template>
  <div class="wrapper" v-if="isLoad">
    <div class="form-container">
      <div class="logo">
        <img src="../../assets/images/iwc-logo.png" alt="icon" />
      </div>
      <div class="top-header">
        <header>Digital Application Portal</header>
        <form @submit.prevent="submitHandler()">
          <div class="input-field">
            <input
              class="input"
              type="text"
              placeholder="username"
              v-model="username"
              required
            />
            <i class="icon-user">
              <userSVG />
            </i>
          </div>
          <div class="input-field">
            <input
              class="input"
              type="password"
              placeholder="password"
              v-model="password"
              autocomplete="on"
              required
            />
            <i class="icon-lock">
              <lockSVG />
            </i>
          </div>
          <div class="input-field">
            <input type="submit" class="submit" value="Login" />
          </div>
          <div class="alert error" v-show="errorStat">
            {{ errorMessage }}
          </div>
        </form>
        <span class="title-footer">{{ appVersion }}</span>
      </div>
    </div>
  </div>
  <app-loading v-else />
</template>
<script>
import { reactive, toRefs, ref, computed } from "vue";
import router from "@/router";
import { inject } from "vue";
import userSVG from "@/assets/svgs/userIcon.vue";
import lockSVG from "@/assets/svgs/lockIcon.vue";
import systemVersion from "@/components/utils/version/systemVersion";
import Swal from "sweetalert2";
export default {
  components: {
    userSVG,
    lockSVG,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const title = ref("Login Page");
    const isLoad = ref(true);
    const lsTokenKey = ref("myToken");
    const errorStat = ref(false);
    const errorMessage = computed(() => store.getters.message);
    const { appVersion } = systemVersion();
    const userInfo = reactive({
      username: "",
      password: "",
    });
    const submitHandler = async () => {
      try {
        /*
        Swal.fire({
          title: "System Maintenance Advisory",
          text: "Please be informed that our system will undergo a scheduled system maintenance activity on April 20, 2023 from 6:00 PM to 12 MN. During this period, Employee Portal and Approver Portal will not be available. We apologize for the inconvenience.",
          imageUrl: require("../../assets/images/alert.jpg"),
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
        });*/
        isLoad.value = false;
        const result = await axios.post(`/api/auth/signin`, userInfo);
        localStorage.setItem(
          `${process.env.VUE_APP_AUTH_TOKEN}_XXX`,
          result.data.token
        );
        localStorage.setItem(
          `${process.env.VUE_APP_USER_ROLE}_XXX`,
          result.data.role
        );
        store.state.getRoleUser = localStorage.getItem(
          `${process.env.VUE_APP_USER_ROLE}_XXX`
        );

        localStorage.setItem(
          `${process.env.VUE_APP_USER_TYPE}_XXX`,
          result.data.userType
        );
        store.state.getUserType = localStorage.getItem(
          `${process.env.VUE_APP_USER_TYPE}_XXX`
        );

        const user = await axios.get("/api/getUserInfo");

        store.state.getProfileInfo = user.data.data;
        store.state.getEmployeeId = user.data.data.id;
        store.state.getUserLog = user.data.data.name;
        store.state.getBirthDate = user.data.data.birthDate;
        store.state.getDepartment = user.data.data.department;
        store.state.getDesignation = user.data.data.designation;
        store.state.getIfChangePassword = user.data.data.isResetPassword;
        store.state.getGender = user.data.data.gender;
        store.state.getNotifCounter = user.data.data.notifCounter;
        // Leave Credit
        store.state.swalDetailList[0].balance = user.data.data.vlBalance;
        store.state.swalDetailList[1].balance = user.data.data.slBalance;
        store.state.getMobile = user.data.data.mobile;
        store.state.getEmail = user.data.data.email;
        store.state.getNotifSetting = user.data.data.notifSetting;
        store.state.getBdayStat = user.data.data.isBirthDate;
        store.state.getSPL = user.data.data.splCredit;
        store.state.getPL = user.data.data.plCredit;
        store.state.getVAWC = user.data.data.vawcCredit;
        store.state.getDTRStat = user.data.data.ifDtr;
        store.state.getdtrDate = user.data.data.dtrDate;
        store.state.getdtrTime = user.data.data.dtrTime;
        store.state.NUMBER_FILING_LIMIT = user.data.data.filingLimit;

        let profileURL = user.data.data.mediaLink;
        store.commit("fetchProfileHandler", profileURL);
        const { data } = await axios.get("/api/systemmaintenance");
        let dataItem = data;
        let statusCode = dataItem[0].code;
        let statusMessages = dataItem[0].messages;
        if (statusCode === "Active") {
          if (store.state.getIfChangePassword === 1) {
            isLoad.value = true;
            let workStatus = store.state.getProfileInfo.isBiotime;
            if (workStatus === 0) {
              Swal.fire({
                title: "We are happy to see you.",
                text: `Thank you for your services. As you are not currently connected to this organization, please be advised that your account will be closed soon.`,
                imageUrl: require("../../assets/images/welcomeback.png"),
                imageWidth: 240,
                imageHeight: 240,
                imageAlt: "Custom image",
              });
            } else {
              return router.push("/");
            }
          } else {
            return router.push("/changepassword");
          }
        } else {
          Swal.fire({
            title: "We'll be back soon!",
            text: `${statusMessages} We apologize for the inconvenience.`,
            imageUrl: require("../../assets/images/alert.jpg"),
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
          });
          isLoad.value = true;
          store.state.isVisible = false;
          store.commit("logoutHandler");
          return router.push("/login");
        }

        //under maintenance
      } catch (err) {
        store.commit("errorHandler", err);

        errorStat.value = store.state.errorStatus;
        hideAlert(errorStat.value);
        isLoad.value = true;
      }
    };
    const hideAlert = (clear) => {
      if (clear) {
        setTimeout(function () {
          errorStat.value = false;
        }, 12000);
      }
    };
    return {
      appVersion,
      title,
      lsTokenKey,
      errorStat,
      errorMessage,
      isLoad,
      ...toRefs(userInfo),
      submitHandler,
      hideAlert,
    };
  },
};
</script>
<style scoped>
.wrapper {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 15px;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 140px;
}
.input-field .input {
  height: 45px;
  width: 87%;
  border: none;
  border-radius: 30px;
  color: var(--lightFont);
  font-size: 15px;
  padding: 0 0 0 48px;
  background: rgba(255, 255, 255, 1);
  outline: none;
}
span {
  color: var(--lightFont);
  font-size: small;
  display: flex;
  justify-content: center;
  padding: 10px 0 10px 0;
}
header {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
  padding: 28px 0 40px 0;
}
.icon-user,
.icon-lock {
  position: relative;
  left: 18px;
  top: -35px;
}
.alert {
  margin-top: 25px;
  border-radius: 10px;
  padding: 12px;
}
.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}
.title-footer {
  margin: 50px 0px 30px 0px;
}

::-webkit-input-placeholder {
  color: var(--lightFont);
}
</style>

<template>
  <app-auth-title />
  <div class="auth-content">
    <div style="padding: 5px">
      <img :src="require(`@/assets/images/underconstruction.png`)" alt="icon" />
    </div>

    <div class="auth-grid"></div>
  </div>
</template>

<style scoped>
.auth-content {
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
}
.auth-content > div > img {
  max-width: 1090px;
  display: flex;
  justify-content: center;
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.auth-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 30px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 120px;
  max-width: 240px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  font-family: "Poppins";
}
.event-date span h1 {
  font-size: 48px;
  font-family: "Montserrat";
  font-weight: 800;
}
.event-date .date {
  color: var(--lightSecondary);
  margin: 5px 8px 0px 15px;
  line-height: 22px;
}
.event-date .date h2 {
  font-size: 18px;
}
.event-date .date h3 {
  font-size: 14px;
}
.event-date,
.event-place,
.event-name {
  display: flex;
  font-family: "Poppins";
  padding: 2px 0px 2px 0px;
}
.event-name img,
.event-place img {
  padding-right: 6px;
}
.event-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.event-title-place {
  font-size: 14px;
  line-height: 20px;
  margin-top: 1.5px;
}

@media only screen and (max-width: 600px) {
  .auth-content > div > img {
    max-width: 100%;
  }
}
</style>

<template>
  <app-auth-title />
  <div class="auth-grid-one">
    <div class="card-one-header">
      <div class="grid-info">
        <h1>Total Released</h1>
        <div class="card-count">
          <span>{{ dashboard.countReleased }}</span>
        </div>
      </div>
    </div>
    <div class="card-two-header">
      <div class="grid-info">
        <h1>Total Disapproved</h1>
        <div class="card-count">
          <span>{{ dashboard.countDisapproved }}</span>
        </div>
      </div>
    </div>
    <div class="card-three-header">
      <div class="grid-info">
        <h1>Total for Funding</h1>
        <div class="card-count">
          <span>{{ dashboard.countFunding }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="auth-grid-one">
    <div class="card-one-header">
      <div class="grid-info">
        <h1>Total of Hold</h1>
        <div class="card-count">
          <span>{{ dashboard.countHold }}</span>
        </div>
      </div>
    </div>
    <div class="card-two-header">
      <div class="grid-info">
        <h1>Total of Unliquidated</h1>
        <div class="card-count">
          <span>{{ dashboard.countUnliquidated }}</span>
        </div>
      </div>
    </div>
    <div class="card-three-header">
      <div class="grid-info">
        <h1>Total Disbursed</h1>
        <div class="card-count">
          <span>{{ amountFormat(dashboard.totalNetExpenses) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="auth-content">
    <div class="auth-grid">
      <div class="card-chart-one">
        <div class="grid-info">
          <canvas id="myChart" width="100" height="100"></canvas>
        </div>
      </div>

      <div class="card-chart-two">
        <div class="graph-pie">
          <div class="grid-info" style="position: relative; height: 40vh">
            <canvas id="myChartOne"></canvas>
          </div>
        </div>
        <div class="graph-doughnut" v-if="false">
          <div class="grid-info" style="position: relative; height: 40vh">
            <div class="title-graph">
              <h4>Utilization</h4>
            </div>
            <canvas id="myChartTwo"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { inject, reactive, onMounted } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const itemList = reactive([]);
    const itemListPie = reactive([]);
    const itemListdoughnut = reactive([]);
    const dashboard = reactive({
      countReleased: 0,
      countDisapproved: 0,
      countFunding: 0,
      countHold: 0,
      countUnliquidated: 0,
      totalNetExpenses: 0,
    });
    onMounted(() => {
      getData();
    });

    const barChart = () => {
      const ctx = document.getElementById("myChart");

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Monthly Request for Payment 2023",
              data: itemList,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(255, 205, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(201, 203, 207, 0.2)",
              ],
              borderColor: [
                "rgb(255, 99, 132)",
                "rgb(255, 159, 64)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
                "rgb(54, 162, 235)",
                "rgb(153, 102, 255)",
                "rgb(201, 203, 207)",
              ],
              borderWidth: 1,
            },
          ],
        },
      });
    };

    const pieChart = () => {
      const ctx = document.getElementById("myChartOne");

      new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["Cash Advance", "Recurring", "Others"],
          datasets: [
            {
              data: itemListPie,
            },
          ],
        },
      });
    };
    const doughnutChart = () => {
      const ctx = document.getElementById("myChartTwo");

      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Hospital", "Clinic", "Doctors", "Reimbursement"],
          datasets: [
            {
              data: itemListdoughnut,
              backgroundColor: [
                "rgba(71, 179, 156)",
                "rgba(255, 193, 84)",
                "rgba(236, 107, 86)",
                "rgba(31, 117, 254)",
              ],
            },
          ],
        },
      });
    };
    const getData = async () => {
      try {
        const response = await axios.get(`api/form/payrequest`);

        filterChartRecord(response.data);
        filterCategory(response.data);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const filterChartRecord = (data) => {
      const filterDate = "2023";
      const itemListYear = data.filter((item) => {
        if (
          moment(item.dateIntended).format("YYYY") === filterDate &&
          item.dtprocessed
        ) {
          return item;
        }
      });
      monthlyItems(itemListYear);
    };
    const monthlyItems = (data) => {
      let itemData = data;
      const monthJan = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "01";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthFeb = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "02";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthMar = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "03";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthApr = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "04";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthMay = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "05";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthJun = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "06";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthJul = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "07";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthAug = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "08";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthSep = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "09";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthOct = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "10";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthNov = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "11";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const monthDec = itemData
        .filter((val) => {
          return moment(val.dateIntended).format("MM") === "12";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      const cashAdvancePie = itemData
        .filter((val) => {
          return val.ifCashAdvance;
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      const recurringPie = itemData
        .filter((val) => {
          return val.ifrecurring === 1;
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);
      const othersPie = itemData
        .filter((val) => {
          return val.ifrecurring === 0 && !val.ifCashAdvance;
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      const hospitalDougnut = itemData
        .filter((val) => {
          return val.expenseDetails[0].name === "UTILIZATION (HOSPITALS)";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      const clinicDougnut = itemData
        .filter((val) => {
          return val.expenseDetails[0].name === "UTILIZATION (CLINICS)";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      const doctorDougnut = itemData
        .filter((val) => {
          return val.expenseDetails[0].name === "UTILIZATION (DOCTORS)";
        })
        .map((val) => {
          return val.expenseDetails[0].amount;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      const reimburseDougnut = itemData
        .filter((val) => {
          return val.expenseDetails[0].name === "UTILIZATION (REIMBURSEMENTS)";
        })
        .map((val) => {
          return val.liquidationActualAmount
            ? val.liquidationActualAmount
            : val.liquidationActualAmount === 0
            ? val.liquidationActualAmount
            : val.total;
        })
        .reduce((accumulator, curValue) => {
          return accumulator + curValue;
        }, 0);

      itemList.push(monthJan);
      itemList.push(monthFeb);
      itemList.push(monthMar);
      itemList.push(monthApr);
      itemList.push(monthMay);
      itemList.push(monthJun);
      itemList.push(monthJul);
      itemList.push(monthAug);
      itemList.push(monthSep);
      itemList.push(monthOct);
      itemList.push(monthNov);
      itemList.push(monthDec);
      barChart();
      itemListPie.push(cashAdvancePie);
      itemListPie.push(recurringPie);
      itemListPie.push(othersPie);
      pieChart();
      itemListdoughnut.push(hospitalDougnut);
      itemListdoughnut.push(clinicDougnut);
      itemListdoughnut.push(doctorDougnut);
      itemListdoughnut.push(reimburseDougnut);
      doughnutChart();
    };
    const filterCategory = (data) => {
      const filterItem = data;

      const releasedCount = filterItem.filter((item) => {
        return item.dtprocessed;
      }).length;

      const disapprovedCount = filterItem.filter((item) => {
        return item.statusRequest === "Disapproved";
      }).length;

      const fundingCount = filterItem.filter((item) => {
        return item.statusProcessed === "For Funding";
      }).length;

      const holdCount = filterItem.filter((item) => {
        return item.statusProcessed === "Hold";
      }).length;

      const unliquidateCount = filterItem.filter((item) => {
        return (
          item.ifCashAdvance &&
          item.liquidatedStatus !== "Completed" &&
          item.dtprocessed
        );
      }).length;

      let requestedAmount = itemList.reduce((accumulator, curValue) => {
        return accumulator + curValue;
      }, 0);
      dashboard.countReleased = releasedCount;
      dashboard.countDisapproved = disapprovedCount;
      dashboard.countFunding = fundingCount;
      dashboard.countHold = holdCount;
      dashboard.countUnliquidated = unliquidateCount;
      dashboard.totalNetExpenses = requestedAmount;
    };
    const amountFormat = (val) => {
      if (val >= 4000000) {
        if (val >= 1000000) {
          return `₱${(val / 1000000).toFixed(1).replace(/\.0$/, "")}M`;
        }
        if (val >= 1000000000) {
          return `₱${(val / 1000000000).toFixed(1).replace(/\.0$/, "")}B`;
        }
      }
    };

    return {
      barChart,
      pieChart,
      doughnutChart,
      getData,
      filterCategory,
      filterChartRecord,
      monthlyItems,
      amountFormat,
      dashboard,
      itemList,
    };
  },
};
</script>
<style scoped>
.auth-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1fr;
}
.auth-grid-one {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
}
.card-one,
.card-two,
.card-one-header,
.card-two-header,
.card-three-header {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  color: var(--lightSecondary);
  font-family: "Montserrat";
  padding-bottom: 20px;
}

.card-chart-one {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  color: var(--lightSecondary);
  font-family: "Montserrat";
  padding-bottom: 20px;
}
.card-chart-two .graph-pie,
.card-chart-two .graph-doughnut {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  color: var(--lightSecondary);
  font-family: "Montserrat";
  margin: 0px 0px 20px 0px;
  padding-top: 10px;
}

.grid-info {
  padding: 30px 20px 0px 20px;
}
.grid-info h1 {
  font-size: 28px;
}
.card-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}
.card-count span {
  color: #f8a334;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 54px;
}
.title-graph {
  text-align: center;
  padding-bottom: 10px;
}
.title-graph h4 {
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .auth-grid-one {
    grid-template-columns: repeat(1, 1fr);
  }
  .auth-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-info h1 {
    font-size: 25px;
  }
  .card-count span {
    color: #f8a334;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 44px;
  }
  .card-chart-two .graph-pie,
  .card-chart-two .graph-doughnut {
    padding-bottom: 40px;
  }
}
</style>

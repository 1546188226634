<template>
  <div v-if="isLoading">
    <app-header></app-header>
    <div class="portal-container" v-if="userRole === 'user'">
      <app-card @passLoad="getValue" />
      <keep-alive v-if="$store.state.isVisible">
        <component :is="activeTab" @isLoaded="checkLoaded"> </component>
      </keep-alive>
      <SectionGridCard @passLoad="getValue" v-else />
    </div>
    <div v-else class="auth-portal-container">
      <app-auth-grid />
    </div>
    <app-footer></app-footer>
  </div>
  <app-loading v-else />
</template>
<script>
import { inject } from "vue";
import { computed, ref, onMounted } from "vue";
import SectionGridCard from "@/components/partials/SectionGridCard.vue";
import SectionLeavePage from "@/components/pages/SectionLeaveForm.vue";
import SectionOvertimePage from "@/components/pages/SectionOvertimeForm.vue";
import SectionIdPage from "@/components/pages/SectionIdForm.vue";
import SectionOBPage from "@/components/pages/SectionOBForm.vue";
import SectionPettyCashPage from "@/components/pages/SectionPettyCashForm.vue";
import SectionLaptopAgreementForm from "@/components/pages/SectionLaptopAgreementForm.vue";
import SectionPaymentRequestForm from "@/components/pages/SectionPaymentRequestForm.vue";
import SectionLiquidationForm from "@/components/pages/SectionLiquidationForm.vue";
import SectionAnnouncementPage from "@/components/pages/SectionAnnouncementPage.vue";
import SectionTransactionHistory from "@/components/pages/SectionTransactionHistory.vue";
import SectionUserActivityLog from "@/components/pages/SectionUserActivityForm.vue";
import SectionTravelForm from "@/components/pages/SectionTravelForm.vue";
import SectionConferenceReservation from "@/components/pages/SectionConferenceReservationForm.vue";
import SectionAccountSetting from "@/components/pages/SectionAccountSetting.vue";
import SectionAccountProfile from "@/components/pages/SectionAccountProfile.vue";
export default {
  name: "LandingPage",
  components: {
    SectionGridCard,
    SectionLeavePage,
    SectionOvertimePage,
    SectionIdPage,
    SectionOBPage,
    SectionPettyCashPage,
    SectionLaptopAgreementForm,
    SectionPaymentRequestForm,
    SectionLiquidationForm,
    SectionAnnouncementPage,
    SectionTransactionHistory,
    SectionUserActivityLog,
    SectionTravelForm,
    SectionConferenceReservation,
    SectionAccountSetting,
    SectionAccountProfile,
  },
  setup() {
    const store = inject("$store");
    const isActive = ref(null);
    const isLoading = ref(false);
    const userRole = computed(() => store.getters.role);
    const isEnabled = ref(null);
    const getValue = (value) => {
      isActive.value = value;
    };
    const checkLoaded = () => {
      setTimeout(async () => {
        store.commit("activityLog");
      }, 5000);
    };

    const activeTab = computed(() => {
      return isActive.value;
    });
    onMounted(() => {
      setTimeout(function () {
        isLoading.value = true;
      }, 4000);
    });

    return {
      getValue,
      checkLoaded,
      isLoading,
      isActive,
      isEnabled,
      activeTab,
      userRole,
    };
  },
};
</script>
<style scoped>
.portal-container {
  margin: auto;
  max-width: 990px;
  padding: 10px 20px 10px 20px;
}
.auth-portal-container {
  margin: auto;
  max-width: 1500px;
  padding: 10px 20px 10px 20px;
}
</style>

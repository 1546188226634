<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="admin-grid">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="filter">
        Filter by Status:
        <select
          v-show="true"
          v-model="selectedStatus"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterStat" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="payment-grid">
        <div v-if="isNotFound">
          <h3>{{ messageNotFound }}</h3>
        </div>
        <div v-if="isRender" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <div v-else v-for="(transaction, index) in filterItem" :key="index">
          <div class="admin-card accordion">
            <div style="display: flex">
              <div class="accordion-content">
                <div>
                  <h4>{{ transaction.payee }}</h4>
                </div>
                <div>
                  <span class="card-date"
                    >Date Released:
                    {{ dateFormat(transaction.dtprocessed) }}</span
                  >
                </div>
                <div>
                  <span class="card-date"
                    >Date Liquidated:
                    {{ dateFormat(transaction.liquidationDated) }}</span
                  >
                </div>
                <div>
                  <span
                    class="card-status"
                    v-if="
                      $store.state.getUserType === 'reactive-moderator' ||
                      $store.state.getUserType === 'super'
                    "
                  >
                    Status:
                    <span
                      class="dot"
                      :class="[
                        transaction.liquidationDated &&
                        !transaction.liquidatedBy
                          ? 'status-pending'
                          : transaction.liquidatedStatus ===
                            'Liquidation Disapproved'
                          ? 'status-declined'
                          : statusColor(transaction),
                      ]"
                    ></span>
                    {{
                      transaction.liquidationDated &&
                      !transaction.liquidatedApprovedDate &&
                      transaction.liquidatedStatus !== "Liquidation Disapproved"
                        ? "For checking"
                        : transaction.liquidatedStatus
                        ? transaction.liquidatedStatus
                        : dayLapsed(transaction.dtprocessed)
                    }}</span
                  >
                  <span class="card-status" v-else>
                    Status:
                    <span
                      class="dot"
                      :class="[
                        transaction.liquidatedStatus !== 'Completed'
                          ? 'status-pending'
                          : 'status-completed',
                      ]"
                    ></span>
                    {{ transaction.liquidatedStatus }}</span
                  >
                </div>
              </div>
            </div>

            <div>
              <input
                v-if="$store.state.getUserType === 'distributed-moderator'"
                @click="routeModal(index)"
                type="submit"
                :value="
                  amountFormat(
                    transaction.total - transaction.liquidationActualAmount
                  )
                "
                class="btn-amount"
                :class="[
                  transaction.liquidationActualAmount > transaction.total
                    ? 'reimburse'
                    : '',
                ]"
              />

              <input
                v-else
                @click="routeModal(index)"
                type="submit"
                :value="
                  amountFormat(
                    !transaction.liquidationActualAmount
                      ? transaction.total
                      : transaction.liquidationActualAmount
                  )
                "
                class="btn-amount"
              />
            </div>
          </div>
          <div
            class="panel"
            v-if="index === indexSelected && ifExpand ? true : false"
          >
            <SectionLiquidationStatus
              :transaction="transaction"
            ></SectionLiquidationStatus>
            <div class="panel-payment-details">
              <SectionLiquidationDetails
                :transaction="transaction"
              ></SectionLiquidationDetails>
              <div class="btn-action-container">
                <input
                  v-if="actionApprover(transaction)"
                  class="btn btn-action"
                  type="submit"
                  value="Action"
                  @click="isModal = true"
                />
                <input
                  class="btn btn-close"
                  type="submit"
                  value="Close"
                  @click="ifExpand = false"
                />
              </div>
              <div v-if="isModal">
                <app-modal-approval
                  :requestId="transaction.id"
                  :amountToApproved="transaction.total"
                  :ifRecurring="transaction.ifrecurring"
                  :ifReimburse="
                    transaction.liquidationActualAmount > transaction.total
                  "
                  @passClose="getValue"
                ></app-modal-approval>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-record">
          <div>Total Record(s): {{ total_record }}</div>
          <div></div>
          <div>Total Amount: {{ amountFormat(total_amount) }}</div>
        </div>
        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLiquidationDetails from "@/components/partials/payments/AuthLiquidationDetails.vue";
import SectionLiquidationStatus from "@/components/partials/payments/AuthLiquidationStatusTracker.vue";
import { ref, computed, onActivated } from "vue";
import { inject } from "vue";
export default {
  components: {
    SectionLiquidationDetails,
    SectionLiquidationStatus,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const ifExpand = ref(false);
    const indexSelected = ref(0);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const isRender = ref(true);
    const messageNotFound = ref("");
    const isNotFound = ref(false);
    const showEntries = ref([5, 10, 25, 50]);
    const showFilterDate = ref([
      "Today",
      "Last 7 Days",
      "This Month",
      "Last Month",
      "Year to Date",
      "All Time",
    ]);
    const showFilterStatus = ref([
      "All",
      "Pending",
      "Last 10 Days",
      "Past Due (11-20 Days)",
      "Past Due (21-40 Days)",
      "Past Due (41-60 Days)",
      "Past Due (61 Days Above)",
      "For Reimbursement",
      "For OR preparation",
      "Completed",
    ]);
    const selectedEntry = ref(5);
    const total_amount = ref(0);
    const transactions = ref([]);
    const paymentItemList = ref([]);
    const selectedFilter = ref("All Time");
    const selectedStatus = ref("Pending");
    const expandPanel = ref(false);
    const filterItem = computed(() => {
      return modified.value;
    });
    const filterStat = computed(() => {
      return showFilterStatus.value.filter((stat) => {
        let status;

        if (store.state.getUserType === "distributed-moderator") {
          status =
            stat !== "Last 10 Days" &&
            stat !== "Past Due (11-20 Days)" &&
            stat !== "Past Due (21-40 Days)" &&
            stat !== "Past Due (41-60 Days)" &&
            stat !== "Past Due (61 Days Above)";
        } else if (store.state.getUserType === "assurance-moderator") {
          status =
            stat !== "Pending" &&
            stat !== "Last 10 Days" &&
            stat !== "Past Due (11-20 Days)" &&
            stat !== "Past Due (21-40 Days)" &&
            stat !== "Past Due (41-60 Days)" &&
            stat !== "Past Due (61 Days Above)";
        } else {
          status =
            stat !== "For Reimbursement" && stat !== "For OR preparation";
        }

        return status;
      });
    });
    onActivated(() => {
      selectedStatus.value =
        store.state.getUserType === "assurance-moderator" ? "All" : "Pending";

      getPaymentRequest();
    });

    const routeModal = (val) => {
      indexSelected.value = val;

      ifExpand.value = !ifExpand.value;
    };
    const actionApprover = (val) => {
      let data = val;
      let result;
      let userType = store.state.getUserType;

      if (userType === "reactive-moderator") {
        result = !data.liquidatedStatus;
      }
      if (userType === "distributed-moderator") {
        result =
          data.liquidatedStatus === "For Reimbursement" ||
          data.liquidatedStatus === "For OR preparation";
      }

      return result;
    };
    const getValue = (val, payload, reqId, status, isPreapproved) => {
      isModal.value = val;

      if (payload) {
        /*setTimeout(async () => {
          getPaymentRequest();
          expandPanel.value = false;
          ifExpand.value = false;
        }, 500);*/

        setTimeout(async () => {
          let itemData;
          itemData = transactions.value.find((val) => {
            return val.id === reqId;
          });

          itemData.liquidatedStatus = status;
          itemData.isPreapproved = isPreapproved;
          getFilterAction();
          store.commit("notificationCounter", transactions.value);
          expandPanel.value = false;
          ifExpand.value = false;
        }, 500);
      }
    };
    const totalAmountComputation = (data) => {
      if (store.state.getUserType === "distributed-moderator") {
        total_amount.value = data.reduce(function (accumulator, curValue) {
          return (
            accumulator + curValue.total - curValue.liquidationActualAmount
          );
        }, 0);
      } else {
        total_amount.value = data.reduce(function (accumulator, curValue) {
          return accumulator + curValue.total;
        }, 0);
      }

      total_record.value = data.length;
    };
    const getFilterAction = (val) => {
      let selectedEntry = val;
      paymentItemList.value = transactions.value
        .filter((post) => {
          let payee = post.payee;
          let referenceNumber = post.id + 1000000;
          let dvNumber = post.dvNumber ? post.dvNumber : "";
          let checkNumber = post.checkNumber ? post.checkNumber : "";
          referenceNumber = referenceNumber.toString();
          return (
            payee.toLowerCase().includes(searchValue.value.toLowerCase()) ||
            post.requestedBy
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            post.dateFiled
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            dvNumber.toLowerCase().includes(searchValue.value.toLowerCase()) ||
            checkNumber
              .toLowerCase()
              .includes(searchValue.value.toLowerCase()) ||
            referenceNumber
              .toLowerCase()
              .includes(searchValue.value.toLowerCase())
          );
        })
        .filter((role) => {
          let userLogIn = store.state.getUserType;
          let userType;

          switch (userLogIn) {
            case "reactive-moderator":
              userType = role.ifCashAdvance && role.dtprocessed;
              break;
            case "super":
              userType = role.ifCashAdvance && role.dtprocessed;
              break;
            case "assurance-moderator":
              userType =
                role.ifCashAdvance && role.dtprocessed && role.liquidatedStatus;
              break;
            case "distributed-moderator":
              userType =
                role.liquidatedStatus === "For Reimbursement" ||
                role.liquidatedStatus === "For OR preparation" ||
                (role.liquidatedStatus === "Completed" &&
                  store.state.getUserLog === role.lastModifiedBy);
              break;
            default:
              userType;
          }
          return userType;
        })
        .filter((stat) => {
          let status;
          //let currentDate = store.state.currentDate;
          //let days = moment().diff(moment(stat.dtprocessed), "days");

          let startDate = moment(stat.dtprocessed);
          let endDate = moment();
          let daysDuration = [];

          while (startDate.isBefore(endDate, "days")) {
            let days = startDate.format("dddd");
            startDate.add(1, "days");
            if (days !== "Saturday" && days !== "Sunday") {
              daysDuration.push(days);
            }
          }

          //Difference in number of days
          let dayRemaining = daysDuration.length;

          if (selectedStatus.value === "All") {
            status = stat;
          } else if (selectedStatus.value === "Pending") {
            let userType = store.state.getUserType;

            if (userType === "reactive-moderator") {
              status = stat.liquidationDated && !stat.liquidatedStatus;
            }

            if (userType === "distributed-moderator") {
              status =
                stat.liquidatedStatus === "For Reimbursement" ||
                stat.liquidatedStatus === "For OR preparation";
            }
          } else if (selectedStatus.value === "Last 10 Days") {
            status =
              dayRemaining >= 0 && dayRemaining <= 10 && !stat.liquidationDated;
          } else if (selectedStatus.value === "Past Due (11-20 Days)") {
            status =
              dayRemaining >= 11 &&
              dayRemaining <= 20 &&
              !stat.liquidationDated;
          } else if (selectedStatus.value === "Past Due (21-40 Days)") {
            status =
              dayRemaining >= 21 &&
              dayRemaining <= 40 &&
              !stat.liquidationDated;
          } else if (selectedStatus.value === "Past Due (41-60 Days)") {
            status =
              dayRemaining >= 41 &&
              dayRemaining <= 60 &&
              !stat.liquidationDated;
          } else if (selectedStatus.value === "Past Due (61 Days Above)") {
            status = dayRemaining >= 61 && !stat.liquidationDated;
          } else {
            status = stat.liquidatedStatus === selectedStatus.value;
          }

          return status;
        });
      pagination(0, selectedEntry);
    };
    const pagination = (page, pr_page) => {
      let data = paymentItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 5;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;
      isNotFound.value = !modified.value.length ? true : false;

      totalAmountComputation(data);

      messageNotFound.value = searchValue.value
        ? `We couldn't find anything for ${searchValue.value.toLowerCase()}. Try a new search.`
        : `No transactions yet. Want to try again with different status?`;
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const getPaymentRequest = async () => {
      try {
        const response = await axios.get(`/api/form/liquidation/payRequest`);
        isNotFound.value = response.data.length ? false : true;
        transactions.value = response.data;
        store.commit("notificationCounter", response.data);
        getFilterAction();
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const amountFormat = (val) => {
      val = val === null ? 0 : val;
      val = val >= 0 ? val : val * -1; //convert to positive integer
      return val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("ll") : "-";
    };
    const dayLapsed = (val) => {
      let dateReleased = val;

      let startDate = moment(dateReleased);
      let endDate = moment();
      let daysDuration = [];

      while (startDate.isBefore(endDate, "days")) {
        let days = startDate.format("dddd");
        startDate.add(1, "days");
        if (days !== "Saturday" && days !== "Sunday") {
          daysDuration.push(days);
        }
      }
      let daysRemaining = daysDuration.length;
      return `Unliquidated ${daysRemaining} days ago`;
    };
    const statusColor = (val) => {
      let startDate = moment(val.dtprocessed);
      let endDate = moment();
      let daysDuration = [];

      while (startDate.isBefore(endDate, "days")) {
        let days = startDate.format("dddd");
        startDate.add(1, "days");
        if (days !== "Saturday" && days !== "Sunday") {
          daysDuration.push(days);
        }
      }
      let daysRemaining = daysDuration.length;
      let colorClass;
      if (daysRemaining >= 0 && daysRemaining <= 10 && !val.liquidationDated) {
        colorClass = "status-alert-mild";
      } else if (
        daysRemaining >= 11 &&
        daysRemaining <= 20 &&
        !val.liquidationDated
      ) {
        colorClass = "status-alert-one";
      } else if (
        daysRemaining >= 21 &&
        daysRemaining <= 40 &&
        !val.liquidationDated
      ) {
        colorClass = "status-alert-two";
      } else if (
        daysRemaining >= 41 &&
        daysRemaining <= 60 &&
        !val.liquidationDated
      ) {
        colorClass = "status-alert-three";
      } else if (daysRemaining >= 61 && !val.liquidationDated) {
        colorClass = "status-alert-four";
      } else {
        colorClass = "status-completed";
      }
      return colorClass;
    };
    const generateReference = (val) => {
      return 1000000 + val;
    };

    return {
      isModal,
      showEntries,
      selectedEntry,
      searchValue,
      transactions,
      paymentItemList,
      selectedStatus,
      showFilterStatus,
      ifExpand,
      indexSelected,
      filterItem,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      messageNotFound,
      isNotFound,
      total_amount,
      selectedFilter,
      showFilterDate,
      expandPanel,
      filterStat,
      isRender,
      prevRecords,
      nextRecords,
      actionApprover,
      getFilterAction,
      totalAmountComputation,
      routeModal,
      pagination,
      getPaymentRequest,
      amountFormat,
      dateFormat,
      dayLapsed,
      statusColor,
      generateReference,
      getValue,
    };
  },
};
</script>
<style scoped>
.status-approved {
  background-color: #5636e9;
}
.status-pending {
  background-color: #fab700;
}
.status-alert-mild {
  background-color: #daf590;
}
.status-alert-one {
  background-color: #f78880;
}
.status-alert-two {
  background-color: #f14e43;
}
.status-alert-three {
  background-color: #ad1105;
}
.status-alert-four {
  background-color: #6d0a03;
}
.status-completed {
  background-color: #1d9405;
}
.status-declined {
  background-color: rgb(243, 78, 36);
}
</style>

<template>
  <div class="auth-content">
    <h1>Conference Schedule</h1>

    <div class="conference-container">
      <div class="auth-grid">
        <VCalendar :attributes="attributes" expanded title-position="left" />
      </div>
      <div class="container-activity" v-show="false">
        <div class="card-one">
          <div class="activity-header">
            <div class="activity-container">
              <div class="activity-date">
                <h4>Mon</h4>
                <h1>05</h1>
              </div>
              <div class="activity-title">
                <h2>Stockholders Meeting</h2>
                <div class="activity-time">
                  <h4>09:30 - 01:00</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="activity-header">
            <div class="activity-container">
              <div class="activity-date">
                <h4>Mon</h4>
                <h1>05</h1>
              </div>
              <div class="activity-title">
                <h2>Clients Meeting</h2>
                <div class="activity-time">
                  <h4>09:30 - 01:00</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-one">
          <div class="activity-header">
            <div class="activity-container">
              <div class="activity-date">
                <h4>Mon</h4>
                <h1>05</h1>
              </div>
              <div class="activity-title">
                <h2>Officers Meeting</h2>
                <div class="activity-time">
                  <h4>09:30 - 01:00</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onActivated, computed } from "vue";
export default {
  components: {},
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const date = ref(new Date());
    const conferenceRequest = ref([]);

    onActivated(() => {
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        const response = await axios.get(`/api/admin/conference/reservation`);
        const itemList = response.data.filter((val) => {
          return val.status === "Approved" || val.status === "Reschedule";
        });
        conferenceRequest.value = itemList.map((item) => {
          let startTime = `${item.dateIntended} ${item.timeStart}`;
          let endTime = `${item.dateIntended} ${item.timeEnd}`;
          return {
            ...item,

            requester: item.requestedBy,
            purpose: `${item.purpose} (${moment(startTime).format(
              "LT"
            )} - ${moment(endTime).format("LT")})`,

            time: item.timeStart,
            isComplete: false,
            dates: [item.dateIntended],
            color:
              item.severity === "MEDIUM"
                ? "orange"
                : item.severity === "HIGH"
                ? "red"
                : "gray",
          };
        });
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const attributes = computed(() => [
      // Attributes for travelRequest
      ...conferenceRequest.value.map((todo) => ({
        dates: todo.dates,
        dot: {
          color: todo.color,
          class: todo.isComplete ? "opacity-75" : "",
        },
        popover: {
          label:
            todo.purpose === "Coding"
              ? todo.purpose
              : `${todo.requester} - ${todo.purpose}`,
          visibility: "focus",
        },
      })),
    ]);

    return {
      isModal,
      date,
      dataLoad,
      conferenceRequest,
      attributes,
    };
  },
};
</script>
<style scoped>
.auth-content {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  padding: 30px 20px 50px 20px;
  margin-top: 30px;
  font-family: "Montserrat";
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.conference-container {
  display: grid;
  grid-template-columns: 1fr;
}
.auth-grid {
  display: grid;
  gap: 30px;
  margin-top: 30px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 180px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
}
.auto-description {
  padding-bottom: 20px;
}
.auto-title {
  padding: 20px 0px;
}
.auto-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.auto-btn-availability {
  margin-bottom: 8px;
}
.avatar {
  max-width: 100%;
  height: auto;
}
.card-one,
.card-two {
  display: flex;
  line-height: 26px;
  gap: 6px;
  font-size: 14px;
}
.submit {
  background-color: rgb(62, 83, 95);
}
.available {
  background-color: rgb(250, 115, 25);
}
.maintenance {
  background-color: rgb(103, 104, 103);
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.blue {
  background-color: blue;
}
.red {
  background-color: red;
}
.gray {
  background-color: gray;
}
.md {
  margin-right: 10px;
}
ul {
  list-style-type: none;
  color: var(--lightFont);
  font-size: 13px;
  font-style: normal;
  font-family: "Montserrat";
  line-height: 20px;
}
.legend {
  padding: 20px 0px;
}
.legend h3 {
  padding-bottom: 10px;
}
.my-calendar {
  color: #6366f1;
}
.container-activity {
  margin: 25px 10px;
}
.card-one {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  font-family: "Montserrat";
  color: rgb(94, 92, 92);
  margin: 10px 0px;
}
.activity-header {
  padding: 15px 15px 10px 15px;
}
.activity-container {
  display: flex;
  justify-content: space-between;
  min-height: 55px;
}
.activity-title {
  padding: 2px 10px 0px 10px;
}
.activity-time {
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 600px) {
  .auth-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: auto;
  }
  .conference-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .container-activity {
    margin: 25px 1px;
  }
}
/* Medium Devices, Desktops */
</style>

<template>
  <div class="auth-navigation" v-show="true">
    <ul v-for="item in itemList" :key="item.title">
      <li
        :class="[activeComponent === item.component ? 'active' : null]"
        @click="handleButtonClick(item.component)"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
export default {
  setup(props, { emit }) {
    const activeComponent = ref("indexProfile");
    const itemList = reactive([
      {
        title: "Profile",
        component: "indexProfile",
      },
      {
        title: "Employment",
        component: "indexEmployment",
      },
      {
        title: "Attendance",
        component: "indexAttedance",
      },
    ]);

    const handleButtonClick = (component) => {
      activeComponent.value = component;
      emit("currentComponent", component);
    };

    return {
      itemList,
      handleButtonClick,
      activeComponent,
    };
  },
};
</script>
<style scoped>
.auth-navigation {
  margin: 35px 20px 24px 20px;
  display: flex;
  gap: 60px;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
}
.auth-navigation ul {
  list-style-type: none;
}
.auth-navigation ul > li:hover {
  cursor: pointer;
}
.active {
  padding-bottom: 5px;
  border-bottom: solid 5px #fa7319;
  color: #fa7319;
}

@media only screen and (max-width: 600px) {
  .auth-navigation {
    margin: 15px 3px 24px 3px;
    flex-direction: column;
    gap: 20px;
    font-size: 11.5px;
  }
  .auth-navigation ul {
    list-style-type: none;
  }
  .auth-navigation ul > li:hover {
    cursor: pointer;
  }
  .active {
    padding-bottom: 5px;
    border-bottom: solid 5px #fa7319;
  }
}
</style>

<template>
  <nav>
    <div class="title-footer">{{ appVersion }}</div>
  </nav>
</template>

<script>
import systemVersion from "@/components/utils/version/systemVersion";
export default {
  setup() {
    const { appVersion } = systemVersion();
    return {
      appVersion,
    };
  },
};
</script>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>{{ titleRequest }}</h1>
        </div>
      </div>
      <form
        @submit.prevent="
          handleAction(selectedStatus, amountToApproved, ifRecurring)
        "
      >
        <div
          class="form-group"
          v-if="
            $store.state.getUserType !== 'post-moderator' &&
            $store.state.getUserType !== 'distributed-moderator' &&
            $store.state.getUserType !== 'dispatch-moderator'
          "
        >
          <div
            class="form-cancelled"
            v-if="$store.state.getUserType === 'reactive-moderator'"
          >
            <label for=""> Void Liquidation Transaction: </label>
            <label class="toggle">
              <input type="checkbox" id="switch" v-model="isToggle" />
              <span class="slider"></span>
            </label>
          </div>
          <select v-if="!isToggle" v-model="selectedStatus" required>
            <option v-for="item in modItemStatus" :key="item">
              {{ item }}
            </option>
          </select>
          <div class="form-group" v-else>
            <label for=""> Remarks: <span class="required">*</span> </label>
            <div class="input">
              <textarea rows="8" v-model="approverRemarks" />
            </div>
          </div>
        </div>
        <div
          v-if="
            $store.state.getUserType !== 'post-moderator' &&
            $store.state.getUserType !== 'distributed-moderator' &&
            $store.state.getUserType !== 'dispatch-moderator' &&
            $store.state.getUserType !== 'reactive-moderator'
          "
        >
          <textarea rows="8" v-model="approverRemarks" />
        </div>
        <div
          v-else-if="
            $store.state.getUserType === 'distributed-moderator' ||
            $store.state.getUserType === 'dispatch-moderator'
          "
          class="dv-form"
        >
          <div
            class="form-group"
            v-if="$store.state.authTitlePage === 'Releasing of Payment'"
          >
            <label for="">
              Date Release: <span class="required">*</span>
            </label>
            <div class="input">
              <input type="date" required v-model="selectedDtReleased" />
            </div>
            <div class="form-group">
              <label for=""> Remarks: <span class="required">*</span> </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" />
              </div>
            </div>
          </div>
          <div class="form-group" v-else>
            <div v-if="ifReimburse">
              <label for=""> Tag as Compelete: </label>
              <div class="input">
                <select v-model="selectedStatus" required>
                  <option v-for="item in modItemStatus" :key="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div v-else>
              <label for=""> OR Number: <span class="required">*</span> </label>
              <div class="input">
                <input type="input" required v-model="selectedORNumber" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="$store.state.getUserType === 'post-moderator'"
          class="dv-form"
        >
          <div class="form-group" v-if="ifStatus !== 'Forwarded to Treasury'">
            <div class="form-cancelled">
              <label for=""> Void Transaction: </label>
              <label class="toggle">
                <input type="checkbox" id="switch" v-model="isToggle" />
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <div v-if="!isToggle && ifStatus !== 'Forwarded to Treasury'">
            <div class="form-group">
              <label for=""> DV Number: <span class="required">*</span> </label>
              <div class="input">
                <input type="text" required v-model="selectedDvNumber" />
              </div>
            </div>
            <div class="form-group">
              <label for="">
                Check Number: <span class="required">*</span>
              </label>
              <div class="input">
                <input type="text" required v-model="selectedCheckNumber" />
              </div>
            </div>
            <div class="form-group">
              <label for="">
                Date Prepared: <span class="required">*</span>
              </label>
              <div class="input">
                <input type="date" required v-model="selectedDtPrepared" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="form-group">
              <label for=""> Remarks: <span class="required">*</span> </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" />
              </div>
            </div>
          </div>
        </div>
        <div class="alert error" v-if="duplicateMessage">
          {{ duplicateName }} Already Taken
        </div>
        <div class="grid-button">
          <div>
            <input
              :disabled="disabled"
              class="btn approved"
              type="submit"
              value="Submit"
            />
          </div>

          <div>
            <input
              class="btn cancel"
              type="submit"
              value="Cancel"
              @click="closeModal"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { computed, inject, ref } from "vue";
export default {
  props: {
    requestId: Number,
    amountToApproved: Number,
    ifRecurring: Number,
    ifStatus: String,
    checkIfPreApproved: Number,
    ifReimburse: Boolean,
    expenseItem: Object,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const disabled = ref(false);
    const approverRemarks = ref(null);
    const posts = ref(null);
    const selectedStatus = ref(null);
    const selectedDvNumber = ref(null);
    const selectedCheckNumber = ref(null);
    const selectedORNumber = ref(null);
    const selectedDtPrepared = ref(store.state.currentDate);
    const selectedDtReleased = ref(store.state.currentDate);
    const ifPreApproved = ref(0);
    const duplicateMessage = ref(false);
    const isToggle = ref(false);
    const itemStatus = ref([
      "Approved",
      "Disapproved",
      "For check preparation",
      "For Funding",
      "Hold",
      "Cancelled",
      "Forwarded to Treasury",
      "Completed",
      "For OR preparation",
      "For Reimbursement",
    ]);
    const closeModal = (
      val,
      payLoad,
      status,
      approver,
      approverDate,
      approverRemarks,
      dvNumber,
      chckNumber,
      chckPreparedDate,
      releasedDate
    ) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit(
        "passClose",
        val,
        payLoad,
        props.requestId,
        status,
        approver,
        approverDate,
        approverRemarks,
        dvNumber,
        chckNumber,
        chckPreparedDate,
        releasedDate
      );
    };
    const titleRequest = computed(() => {
      let userType = store.state.getUserType;
      return userType === "post-moderator"
        ? "Payment Preparation Details:"
        : userType === "distributed-moderator"
        ? store.state.authTitlePage
        : userType === "reactive-moderator"
        ? "Liquidation of Payment:"
        : "Approval Request";
    });
    const modItemStatus = computed(() => {
      return itemStatus.value.filter((val) => {
        let userType = store.state.getUserType;
        let page = store.state.authTitlePage;
        let status;

        if (page === "Payment Request" || page === "Payment Liquidation") {
          if (userType === "reactive-moderator") {
            status =
              val === "Completed" ||
              val === "For OR preparation" ||
              val === "For Reimbursement";
          } else if (userType === "distributed-moderator") {
            status = val === "Completed";
          } else {
            status = val.includes("Approved") || val.includes("Disapproved");
          }
        } else {
          if (userType === "pre-moderator") {
            status =
              val !== "Approved" &&
              val !== "Disapproved" &&
              val !== "Forwarded to Treasury" &&
              val !== "For OR preparation" &&
              val !== "For Reimbursement" &&
              val !== "Completed";
          }
        }
        return status;
      });
    });

    //need to refactor

    const approverAction = async (
      id,
      action,
      remarks,
      approverBy,
      appoverDate,
      preApproved
    ) => {
      try {
        await axios.put(`api/form/payrequest/${id}`, {
          statusRequest: action,
          ifPreApproved:
            props.checkIfPreApproved === 1
              ? props.checkIfPreApproved
              : preApproved,
          approverRemarks: remarks,
          approverBy: approverBy,
          approverDate: appoverDate,
          lastModifiedBy: approverBy,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const preApproverAction = async (id, status, remarks, approverBy) => {
      try {
        await axios.put(`api/form/payrequest/${id}`, {
          statusProcessed: status,
          remarkProcessed: remarks,
          lastModifiedBy: approverBy,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const postApproverAction = async (
      id,
      dvNumber,
      dtprepared,
      checkNumber,
      approverBy,
      toggle,
      remarks
    ) => {
      try {
        //update
        if (toggle || props.ifStatus === "Forwarded to Treasury") {
          selectedStatus.value = "Cancelled";
          await axios.put(`api/form/payrequest/${id}`, {
            statusProcessed: "Cancelled",
            remarkProcessed: remarks,
            lastModifiedBy: approverBy,
          });
        } else {
          selectedStatus.value = "Forwarded to Treasury";
          await axios.put(`api/form/payrequest/${id}`, {
            statusProcessed: "Forwarded to Treasury",
            dvNumber: dvNumber,
            checkNumber: checkNumber,
            dtprepared: dtprepared,
            lastModifiedBy: approverBy,
          });
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const distributedApproverAction = async (
      id,
      dtprocessed,
      approverBy,
      remarks
    ) => {
      try {
        selectedStatus.value = "Released";
        console.log(remarks);
        await axios.put(`api/form/payrequest/${id}`, {
          statusProcessed: "Released",
          dtprocessed: dtprocessed,
          lastModifiedBy: approverBy,
          remarkProcessed: remarks,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const reactiveApproverAction = async (
      id,
      status,
      approverBy,
      toggle,
      approverRemarks
    ) => {
      try {
        if (toggle) {
          selectedStatus.value = "Liquidation Disapproved";
          await axios.put(`api/form/payrequest/${id}`, {
            liquidatedBy: approverBy,
            liquidatedStatus: "Liquidation Disapproved",
            lastModifiedBy: approverBy,
            remarkProcessed: approverRemarks,
          });
        } else {
          selectedStatus.value = status;
          await axios.put(`api/form/payrequest/${id}`, {
            liquidatedBy: approverBy,
            liquidatedStatus: status,
            liquidatedApprovedDate: moment(),
            lastModifiedBy: approverBy,
          });
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const liquidationApproverAction = async (id, status, approverBy) => {
      try {
        console.log(status);
        await axios.put(`api/form/payrequest/${id}`, {
          liquidatedApprovedDate: moment(),
          liquidatedStatus: "Liquidation " + status,
          lastModifiedBy: approverBy,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const orReleasedApproverAction = async (id, approverBy) => {
      try {
        selectedStatus.value = "Completed";
        await axios.put(`api/form/payrequest/${id}`, {
          orNumber: selectedORNumber.value,
          liquidatedStatus: "Completed",
          lastModifiedBy: approverBy,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const handleAction = async (status, amount, recurring) => {
      disabled.value = true;
      let amountRequested = amount;
      let ifRecurring = recurring;
      let action = null;
      let remarks = approverRemarks.value;
      let approverBy = store.state.getUserLog;
      let appoverDate = moment().format();
      let userType = store.state.getUserType;
      let page = store.state.authTitlePage;
      let expenseListItems = props.expenseItem;

      //confirmation
      Swal.fire({
        title: "Are you sure",
        text: "you want to submit?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          //Approver (super, semi-moderator, moderator)
          if (
            userType === "super" ||
            userType === "semi-moderator" ||
            userType === "moderator"
          ) {
            const id = props.requestId;
            if (status === "Approved") {
              if (ifRecurring === 1) {
                action = "Approved";
              } else if (amountRequested <= 5000) {
                action = "Approved";
              } else if (amountRequested > 5000 && userType === "super") {
                action = "Approved";
              } else if (
                expenseListItems[0].name === "UTILIZATION (REIMBURSEMENTS)"
              ) {
                action = "Approved";
              } else {
                action = "Pre-approved";
                ifPreApproved.value = 1;
              }
            } else {
              action = "Disapproved";
            }
            //api
            const preApproved = ifPreApproved.value;

            //Liquidation
            let selectedAction = action;
            if (page === "Payment Liquidation") {
              liquidationApproverAction(id, selectedAction, approverBy);
            } else {
              approverAction(
                id,
                action,
                remarks,
                approverBy,
                appoverDate,
                preApproved
              );
            }
          } else if (
            userType === "pre-moderator" &&
            page !== "Payment Preparation"
          ) {
            const id = props.requestId;
            if (status === "Approved") {
              if (ifRecurring === 1) {
                action = "Approved";
              } else {
                action = "Approved";
              }
            } else {
              action = "Disapproved";
            }
            const preApproved = 0;
            approverAction(
              id,
              action,
              remarks,
              approverBy,
              appoverDate,
              preApproved
            );
          } else if (
            userType === "pre-moderator" &&
            page === "Payment Preparation" //For Accounting Final Approve
          ) {
            const id = props.requestId;
            const selectedAction = selectedStatus.value;
            preApproverAction(id, selectedAction, remarks, approverBy);
          } else if (
            userType === "post-moderator" &&
            page === "Payment Preparation" //For Accounting to forwarded treasury
          ) {
            const id = props.requestId;
            const dvNumber = selectedDvNumber.value;
            const checkNumber = selectedCheckNumber.value;
            const dtprepared = selectedDtPrepared.value;
            const toggle = isToggle.value;
            const approverRemarks = remarks;
            postApproverAction(
              id,
              dvNumber,
              dtprepared,
              checkNumber,
              approverBy,
              toggle,
              approverRemarks
            );
          } else if (
            userType === "distributed-moderator" ||
            userType === "dispatch-moderator"
          ) {
            const id = props.requestId;
            const releaseDate = moment(selectedDtReleased.value);
            const remarks = approverRemarks.value;

            if (page === "Releasing of Payment") {
              distributedApproverAction(id, releaseDate, approverBy, remarks);
            } else {
              orReleasedApproverAction(id, approverBy);
            }
          } else if (userType === "reactive-moderator") {
            const id = props.requestId;
            const selectedAction = selectedStatus.value;
            const toggle = isToggle.value;
            const approverRemarks = remarks;
            reactiveApproverAction(
              id,
              selectedAction,
              approverBy,
              toggle,
              approverRemarks
            );
          } else if (userType === "checker-moderator") {
            selectedStatus.value =
              selectedStatus.value === "Disapproved"
                ? selectedStatus.value
                : "Pre-approved";
            const id = props.requestId;
            const action = selectedStatus.value;

            const preApproved = 1;
            approverAction(
              id,
              action,
              remarks,
              approverBy,
              appoverDate,
              preApproved
            );
          }

          action = !action ? selectedStatus.value : action;
          swalConfirmation(action, userType);
        } else {
          disabled.value = false;
        }
      });
    };
    const swalConfirmation = async (action, userType) => {
      let message;

      if (userType === "post-moderator") {
        if (isToggle.value || props.ifStatus === "Forwarded to Treasury") {
          message = "Request was successfully put on Cancelled!";
        } else {
          message = "Request was Successfully Forwarded to Treasury!";
        }
      }
      if (
        userType === "distributed-moderator" ||
        userType === "dispatch-moderator"
      ) {
        message = "Request was Successfully Completed!";
      }
      if (userType === "pre-moderator") {
        if (action === "For check preparation") {
          message = "Request was approved for check preparation!";
        } else {
          message = `Request was successfully put on ${action}`;
        }
      }
      if (userType === "moderator") {
        message =
          action === "Disapproved"
            ? `Request was regretfully ${action}`
            : `Request was successfully ${action}`;
      }
      if (userType === "semi-moderator") {
        message =
          action === "Disapproved"
            ? `Request was regretfully ${action}`
            : `Request was successfully ${action}`;
      }
      if (userType === "super") {
        message = `Request was successfully put on ${action}`;
      }
      if (userType === "reactive-moderator") {
        message = isToggle.value
          ? `Liquidation was Disapproved`
          : `Liquidation was successfully put on ${action}`;
      }
      if (userType === "checker-moderator") {
        message =
          action === "Disapproved"
            ? `Request was regretfully ${action}`
            : `Request was successfully Pre-approved`;
      }

      setTimeout(async () => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 6000,
        });
        closeModal(
          false,
          true,
          (selectedStatus.value = ifPreApproved.value
            ? "Pre-approved"
            : selectedStatus.value),
          store.state.getUserLog,
          moment().format(),
          approverRemarks.value,
          selectedDvNumber.value,
          selectedCheckNumber.value,
          selectedDtPrepared.value,
          selectedDtReleased.value
        );
        selectedStatus.value = null;
        approverRemarks.value = null;
        selectedDvNumber.value = null;
        selectedCheckNumber.value = null;
        selectedDtPrepared.value = null;
        selectedDtReleased.value = null;
      }, 400);
    };

    return {
      closeModal,
      handleAction,
      disabled,
      approverRemarks,
      posts,
      itemStatus,
      modItemStatus,
      selectedStatus,
      approverAction,
      preApproverAction,
      postApproverAction,
      distributedApproverAction,
      orReleasedApproverAction,
      titleRequest,
      swalConfirmation,
      selectedDvNumber,
      selectedORNumber,
      selectedDtPrepared,
      selectedDtReleased,
      ifPreApproved,
      selectedCheckNumber,
      duplicateMessage,
      liquidationApproverAction,
      isToggle,
    };
  },
};
</script>

<style scoped>
select {
  font-size: 15px;
  font-style: normal;
  font-family: "Montserrat";
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 100%;
  margin-bottom: 15px;
}
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.approved {
  background-color: rgb(25, 179, 102);
}
.disapproved {
  background-color: rgb(219, 86, 53);
}
.cancel {
  background-color: rgb(137, 67, 228);
}
.dv-form {
  padding-top: 20px;
}
.form-cancelled {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 20px;
}
</style>

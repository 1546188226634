import { reactive, toRefs, inject } from "vue";
export default function formList() {
  const axios = inject("$axios");
  const store = inject("$store");
  const state = reactive({
    forms: [
      {
        title: "Announcement",
        icon: "announcement_02.png",
        component: "SectionAnnouncementPage",
        name: "Announcement",
        notif: true,
        counter: 0,
      },
      {
        title: "Leave Request",
        icon: "calendar-01.png",
        component: "SectionLeavePage",
        name: "Leave Request",
        notif: false,
        counter: 0,
      },
      {
        title: "Overtime Request",
        icon: "requestovertime.png",
        component: "SectionOvertimePage",
        name: "Overtime Request",
        notif: true,
        counter: 0,
      },
      {
        title: "Payment Request",
        icon: "petty-02.png",
        component: "SectionPaymentRequestForm",
        name: "Payment Request",
        notif: false,
        counter: 0,
      },
      {
        title: "Liquidation",
        icon: "liquidation-01.png",
        component: "SectionLiquidationForm",
        name: "Liquidation Request",
        notif: true,
        counter: 0,
      },
      {
        title: "Travel Request",
        icon: "car.png",
        component: "SectionTravelForm",
        name: "Travel Request",
        notif: false,
        counter: 0,
      },
      {
        title: "Official Business Form",
        icon: "ob-02.png",
        component: "SectionOBPage",
        name: "Official Business Request",
        notif: true,
        counter: 0,
      },
      {
        title: "Conference Reservation",
        icon: "meeting.png",
        component: "SectionConferenceReservation",
        name: "Conference Reservation",
        notif: false,
        counter: 0,
      },
      {
        title: "Transaction History",
        icon: "clock-02.png",
        component: "SectionTransactionHistory",
        name: "Transaction History",
        notif: false,
      },
      {
        title: "Activity Log",
        icon: "activity_log.png",
        component: "SectionUserActivityLog",
        name: "Activity Log",
        notif: false,
        counter: 0,
      },
      {
        title: "Account Setting",
        icon: "setting.png",
        component: "SectionAccountSetting",
        name: "Account Setting",
        notif: false,
        counter: 0,
      },
    ],
    selectedNotif: [],
  });
  const formNotificationHandler = async () => {
    let itemList = state.forms;
    let res = itemList.filter((item) => {
      return item.notif;
    });

    for (let i = 0; i < res.length; i++) {
      let formName = res[i].name;

      if (formName === "Announcement") {
        state.forms[0].counter = store.state.getNotifCounter;
        if (state.forms[0].counter) {
          state.selectedNotif.push(formName);
        } else {
          state.selectedNotif.splice(i, 1);
        }
      }
      if (formName === "Liquidation Request") {
        try {
          const { data } = await axios.get("/api/reference");

          let res = data.filter((item) => {
            return (
              !item.liquidationDated ||
              item.liquidatedStatus === "Liquidation Disapproved"
            );
          });
          state.forms[4].counter = res.length;
          if (state.forms[4].counter) {
            state.selectedNotif.push(formName);
          } else {
            state.selectedNotif.splice(i, 1);
          }
        } catch (error) {
          store.commit("errorHandler", error);
        }
      }
      if (formName === "Overtime Request") {
        try {
          const { data } = await axios.get("/api/otreference");
          state.forms[2].counter = data.length;
          if (state.forms[2].counter) {
            state.selectedNotif.push(formName);
          } else {
            state.selectedNotif.splice(i, 1);
          }
        } catch (error) {
          store.commit("errorHandler", error);
        }
      }
      if (formName === "Official Business Request") {
        try {
          const { data } = await axios.get("/api/obreference");
          state.forms[6].counter = data.length;
          if (state.forms[6].counter) {
            state.selectedNotif.push(formName);
          } else {
            state.selectedNotif.splice(i, 1);
          }
        } catch (error) {
          store.commit("errorHandler", error);
        }
      }
    }
  };
  return {
    ...toRefs(state),
    formNotificationHandler,
  };
}

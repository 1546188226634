<template>
  <app-auth-title />
  <div class="auth-content">
    <form @submit.prevent="handleSubmit">
      <div class="form-section">
        <h4>Profile Information</h4>
        <section>
          <div class="form-entry">
            <label> Name: <span class="required">*</span> </label>
            <div class="input">
              <select
                v-model="selectedEmployeeId"
                class="item-entries"
                @change="selectHandler"
                required
              >
                <option
                  v-for="item in employeeItem"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-entry">
            <label>Schedule</label>
            <div class="icons">
              <img
                @click="addItemSchedule"
                :src="require(`@/assets/images/add_.png`)"
              />
            </div>
          </div>
          <div
            class="form-entry"
            v-for="(item, index) in itemSchedule"
            :key="index"
          >
            <label> </label>
            <div class="entry-schedule">
              <input class="colOne" type="date" v-model="item.date" required />
              <select v-model="item.workTime" class="colTwo" required>
                <option>Off</option>
                <option
                  v-for="(item, index) in attendanceItem"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.workSchedule }}
                </option>
              </select>
              <span class="icons">
                <img
                  @click="removeItems(index)"
                  class="image-button"
                  :src="require(`@/assets/images/remove_.png`)"
                />
              </span>
            </div>
          </div>
        </section>
      </div>
      <div class="form-group">
        <button class="submit">Submit</button>
      </div>
      <div v-show="isStatus" class="alert-error form-alert">
        {{ $store.state.formErrorMessage }}
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { reactive, ref, inject, onActivated } from "vue";
export default {
  components: {},
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isStatus = ref(false);
    const selectedEmployeeId = ref(null);
    const attendanceItem = ref([]);
    const itemSchedule = reactive([
      {
        employeeId: null,
        weekNumber: null,
        date: null,
        workTime: null,
        timeIn: null,
        timeOut: null,
        isActive: 1,
      },
    ]);
    const employeeItem = ref([]);
    onActivated(() => {
      moment.updateLocale("en", {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
      getEmployeeShifting();
      getAttendanceList();
    });
    const getEmployeeShifting = async () => {
      try {
        const response = await axios.get("/api/getUsers");
        employeeItem.value = response.data
          .filter((val) => {
            return val.isBiotime && val.maintenanceScheduleAttendanceId === 2;
          })
          .sort((a, b) => {
            var nameOne = a.name.toUpperCase();
            var nameTwo = b.name.toUpperCase();
            let name = 0;

            if (nameOne > nameTwo) {
              name = 1;
            } else if (nameOne < nameTwo) {
              name = -1;
            }
            return name;
          });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const addItemSchedule = () => {
      itemSchedule.push({
        employeeId: selectedEmployeeId.value,
        weekNumber: null,
        date: null,
        workTime: null,
        timeIn: null,
        timeOut: null,
        isActive: 1,
      });
    };
    const removeItems = (index) => {
      let arrayLength = itemSchedule.length;
      if (arrayLength > 1) {
        itemSchedule.splice(index, 1);
      }
    };
    const handleSubmit = async () => {
      selectTimeHandler(itemSchedule);
      const unique = [];
      for (const item of itemSchedule) {
        const isDuplicate = unique.find((obj) => obj.date === item.date);
        if (!isDuplicate) {
          unique.push(item);
        }
      }
      if (unique.length !== itemSchedule.length) {
        isStatus.value = true;
        store.state.formErrorMessage = `Duplicate entry date`;
      } else {
        Swal.fire({
          title: "Are you sure",
          text: "You want to save?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            insertHandler();
            Swal.fire("Saved!", "Successfully added.", "success");
          }
        });
      }
      setTimeout(() => {
        isStatus.value = false;
      }, 8000);
    };
    const selectHandler = () => {
      itemSchedule.splice(1, itemSchedule.length);
      itemSchedule[0].employeeId = null;
      itemSchedule[0].date = null;
      itemSchedule[0].timeIn = null;
      itemSchedule[0].timeOut = null;
      let arrayLength = itemSchedule.length;
      if (arrayLength === 1) {
        itemSchedule[0].employeeId = selectedEmployeeId.value;
      }
    };
    const getAttendanceList = async () => {
      try {
        const response = await axios.get("/api/attendanceSchedule");

        attendanceItem.value = response.data
          .filter((val) => {
            return val.timeIn && !val.withWeekend;
          })
          .map((entry) => {
            let timeIn = `${moment().format("YYYY-MM-DD")} ${entry.timeIn}`;
            let timeOut = `${moment().format("YYYY-MM-DD")} ${entry.timeOut}`;
            let schedule = `${moment(timeIn).format("hh:mm A")} - ${moment(
              timeOut
            ).format("hh:mm A")} ${entry.withWeekend ? "(with Saturday)" : ""}`;
            return {
              ...entry,

              workSchedule: entry.schedStatus ? schedule : entry.description,
            };
          })
          .sort((val, val1) => {
            let fa = val.workSchedule.toLowerCase(),
              fb = val1.workSchedule.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const selectTimeHandler = (item) => {
      item.forEach((value, index) => {
        let timeId = value.workTime;
        let dateSched = value.date;
        attendanceItem.value.filter((val) => {
          if (val.id === timeId) {
            let convertWeekNumber = moment(dateSched).format("W");
            itemSchedule[index].timeIn = val.timeIn;
            itemSchedule[index].timeOut = val.timeOut;
            itemSchedule[index].weekNumber = convertWeekNumber;
          } else {
            let convertWeekNumber = moment(dateSched).format("W");
            itemSchedule[index].weekNumber = convertWeekNumber;
          }
        });
      });
    };
    const insertHandler = async () => {
      try {
        let res = await axios.post(`/api/shiftingSchedule/`, itemSchedule);
        let ifSuccess = res.data.status;
        if (ifSuccess) {
          itemSchedule.splice(1, itemSchedule.length);
          selectedEmployeeId.value = null;
          itemSchedule[0].employeeId = null;
          itemSchedule[0].date = null;
          itemSchedule[0].timeIn = null;
          itemSchedule[0].timeOut = null;
          itemSchedule[0].workTime = null;
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    return {
      itemSchedule,
      addItemSchedule,
      removeItems,
      getEmployeeShifting,
      employeeItem,
      selectedEmployeeId,
      handleSubmit,
      selectHandler,
      getAttendanceList,
      selectTimeHandler,
      attendanceItem,
      insertHandler,
      isStatus,
    };
  },
};
</script>
<style scoped>
.auth-content {
  padding: 30px 20px 50px 20px;
  background-color: #fff;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}

.form-section {
  font-family: "Montserrat";
  margin: 20px 0px;
}
.form-entry {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 0px;
  margin: 10px 0px;
}
.form-entry .input input,
select {
  font-family: "Montserrat";
  min-width: 660px;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.form-entry label {
  font-weight: 500;
}
section {
  margin: 30px 40px;
}
.form-group {
  margin: 0px 40px;
}
.required {
  color: rgb(241, 80, 80);
  font-weight: 600;
}
.entry-schedule {
  display: flex;
  gap: 6px;
}
.entry-schedule .colOne {
  min-width: 365px;
}
.entry-schedule .colTwo {
  min-width: 260px;
}
.icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
/* Medium Devices, Desktops */
</style>

<template>
  <div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <SectionPaymentStatus
      v-else
      :transaction="transactions"
    ></SectionPaymentStatus>
    <div class="header-form">Request for Payment Details</div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <div v-else class="grid-container">
      <table>
        <tr>
          <td class="title">Reference Number:</td>
          <td class="description">{{ 1000000 + transactions.id }}</td>
        </tr>
        <tr>
          <td class="title">Name of Payee:</td>
          <td class="description">{{ transactions.payee }}</td>
        </tr>
        <tr>
          <td class="title">Type of Expenses:</td>
          <td class="description">
            {{ expenseName }}
            <span
              @click="isModalExpenseStatus = true"
              v-if="true"
              style="
                float: right;
                padding-right: 10px;
                color: blue;
                cursor: pointer;
              "
              >See all</span
            >
            <div v-if="isModalExpenseStatus">
              <ModalExpensesHistory
                @passClose="isModalExpenseStatus = false"
                :expenseList="transactions.expenseDetails"
                :totalAmount="transactions.total"
                :itemList="transactions"
              >
              </ModalExpensesHistory>
            </div>
          </td>
        </tr>
        <tr>
          <td class="title">
            {{
              transactions.ifCashAdvance ? "Cash Advance:" : "Payable Amount:"
            }}
          </td>
          <td class="description">
            {{
              new Intl.NumberFormat("en-PH", {
                style: "currency",
                currency: "PHP",
              }).format(transactions.total)
            }}
          </td>
        </tr>
        <tr>
          <td class="title">Requested by:</td>
          <td class="description">{{ transactions.requestedBy }}</td>
        </tr>
        <tr>
          <td class="title">Date Requested:</td>
          <td class="description">{{ dateFormat(transactions.dateFiled) }}</td>
        </tr>
        <tr>
          <td class="title">Date Intended:</td>
          <td class="description">
            {{ dateFormat(transactions.dateIntended) }}
          </td>
        </tr>
        <tr>
          <td class="title">Purpose:</td>
          <td class="description">{{ transactions.remarks }}</td>
        </tr>

        <tr>
          <td class="title">
            {{
              transactions.statusRequest === "Disapproved"
                ? "Disapproved by:"
                : transactions.statusRequest === "Pre-approved"
                ? "Checked by:"
                : "Approved by:"
            }}
          </td>
          <td class="description">
            {{ !transactions.approverBy ? "-" : transactions.approverBy }}
          </td>
        </tr>
        <tr>
          <td class="title">
            {{
              transactions.statusRequest === "Disapproved"
                ? "Disapprover Remarks:"
                : transactions.statusRequest === "Pre-approved"
                ? "Checked Remarks:"
                : "Approver Remarks:"
            }}
          </td>
          <td class="description">
            {{
              !transactions.approverRemarks ? "-" : transactions.approverRemarks
            }}
          </td>
        </tr>
        <tr>
          <td class="title">
            {{
              transactions.statusRequest === "Disapproved"
                ? "Date Disapproved:"
                : transactions.statusRequest === "Pre-approved"
                ? "Date Checked:"
                : "Date Approved:"
            }}
          </td>
          <td class="description">
            {{ dateFormat(transactions.approverDate) }}
          </td>
        </tr>
        <tr>
          <td class="title">Released Date:</td>
          <td class="description">
            {{ dateFormat(transactions.dtprocessed) }}
          </td>
        </tr>
        <tr>
          <td class="title">Attachment File:</td>
          <td class="description">
            <div
              style="display: flex; gap: 5px; cursor: pointer"
              @click="download(transactions.modFileName)"
            >
              <span>
                <img src="../../assets/images/download_.png" alt="icon"
              /></span>

              {{
                !transactions.filename ? "No attachment" : transactions.filename
              }}
            </div>
          </td>
        </tr>
        <tr
          v-if="
            $store.state.getProfileInfo.designation === 'Purchasing Coordinator'
          "
        >
          <td class="title">Attachment File:</td>
          <td class="description">
            <div style="display: flex; gap: 5px; cursor: pointer">
              <span>
                <a :href="transactions.reuploadfile" target="_blank">
                  <img src="../../assets/images/download_.png" alt="icon" /></a
              ></span>

              {{
                !transactions.reuploadfile ? "No attachment" : "View Details"
              }}
            </div>
          </td>
        </tr>
        <tr
          v-if="
            $store.state.getProfileInfo.designation === 'Purchasing Coordinator'
          "
        >
          <td class="title">Re-upload:</td>
          <td class="description">
            <div style="display: flex; gap: 5px; cursor: pointer">
              <input
                class="idUpload"
                ref="fileData"
                type="file"
                @change="selectFile()"
              />
              <input
                class="idBtnUpload"
                type="button"
                value="update"
                @click="uploadHandler()"
              />
            </div>
            <div v-if="!fileData"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import SectionPaymentStatus from "@/components/partials/payments/AuthPaymentStatusTracker.vue";
import ModalExpensesHistory from "@/modals/expensesHistoryModal.vue";
import { inject, onMounted, ref } from "vue";
import Swal from "sweetalert2";
export default {
  components: {
    SectionPaymentStatus,
    ModalExpensesHistory,
  },
  setup() {
    const store = inject("$store");
    const axios = inject("$axios");
    const moment = inject("$moment");
    const transactions = ref([]);
    const expenseName = ref(null);
    const isModalExpenseStatus = ref(false);
    const isRender = ref(true);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const modFileName = ref(null);
    onMounted(() => {
      getData();
    });
    const getData = async () => {
      try {
        const paymentId = store.state.applicationId;
        const response = await axios.get(`api/form/payrequest/${paymentId}`);
        transactions.value = response.data[0];
        expenseName.value = response.data[0].expenseDetails[0].name;
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("ll") : "-";
    };
    const download = async (val) => {
      const filename = val;
      try {
        const response = await axios.get(
          `api/media/meta/resources/files/${filename}`
        );
        let fileName = response.data.name;
        let httpUrl = "https://storage.googleapis.com/iwc-data-files/";
        let url = `${httpUrl}${fileName}`;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const selectFile = () => {
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];
        uploadFile.value = fileData.value;

        modFileName.value = `${
          fileData.value.lastModified
        }.${fileData.value.name.split(".").pop()}`;
      } else {
        modFileName.value = null;
      }
    };
    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", uploadFile.value, modFileName.value);
      try {
        let result = await axios.post(`/api/upload/`, formData);
        let url = result.data.url;
        const id = store.state.applicationId;
        await axios.put(`api/form/payrequest/${id}`, {
          reuploadfile: url,
        });
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: true,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    return {
      getData,
      transactions,
      dateFormat,
      isModalExpenseStatus,
      expenseName,
      download,
      isRender,
      fileData,
      uploadFile,
      modFileName,
      selectFile,
      uploadHandler,
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;

  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 35px;
}
table > tr .title,
table > tr .description {
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 160px;
  font-family: "Montserrat";
  font-weight: 500;
}
.description {
  width: 360px;
  color: #272626;
}

.grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 40px 0px 20px 0px;
}
.col-status-one,
.col-status-two,
.col-status-three,
.col-status-four {
  background-color: green;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-four {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img,
.col-status-four img {
  width: 50px;
  height: 50px;
}

.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
.header-form {
  padding-bottom: 30px;
  color: var(--lightFont);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}
.idUpload {
  min-width: 260px;
}
.idBtnUpload {
  min-width: 150px;
}
@media only screen and (max-width: 600px) {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 12px;
    width: 100%;
  }
  table > tr {
    height: 28px;
  }
  table > tr .title,
  table > tr .description {
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 124px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .description {
    max-width: 100%;
    color: #272626;
  }
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0px 30px 0px 30px;
  }

  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four {
    height: 10px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img {
    width: 34px;
    height: 34px;
  }
  .icons {
    height: 70px;
    font-size: 12px;
  }
  .header-form {
    padding-bottom: 30px;
    font-size: 16px;
  }
}
/* Extra Small Devices, Phones */
</style>

<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label> Leave Type: <span class="required">*</span> </label>
          <div class="input">
            <select
              name="leaveType"
              @change="selectedLeave"
              v-model="leaveType"
            >
              <option
                v-for="(types, index) in filterLeaveTypes"
                :key="index"
                :value="types.code"
              >
                {{ types.name }} - ({{ types.code }})
              </option>
            </select>
          </div>
        </div>
        <div class="form-group" v-if="isEmergency">
          <label> Select Types: <span class="required">*</span> </label>
          <div class="input">
            <select name="emergency" v-model="selectedEmergencyCat">
              <option v-for="(item, index) in itemEmergency" :key="index">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group" v-show="isAWOP">
          <label> Type of AWOP: <span class="required">*</span> </label>
          <div class="input">
            <select name="awopType" v-model="awopType" @change="selectedLeave">
              <option
                v-for="(types, index) in awopTypes"
                :key="index"
                :value="types.code"
              >
                {{ types.name }} - {{ types.code }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group" v-show="isVisible">
          <label> Other Leave: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" />
          </div>
        </div>
        <div class="form-group" v-show="isImmediateFamVisible">
          <div class="checkbox-container">
            <label for=""> Death of Immediate Family Member: </label>
            <div class="input">
              <input
                type="checkbox"
                v-model="isImmediateFamVal"
                @click="
                  computeDurationLeave(
                    dateStart,
                    dateEnd,
                    (isImmediateFamVal = !isImmediateFamVal)
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="form-group" v-show="isPLTransferred">
          <div class="checkbox-container">
            <label for=""> Transferred 7 Days to Paternity Leave: </label>
            <div class="input">
              <input
                type="checkbox"
                v-model="isPLTransferredVal"
                @click="getMaternity"
              />
            </div>
          </div>
        </div>
        <div class="form-group" v-show="isMLReceived">
          <div class="checkbox-container">
            <label for=""> Received 7 Days from Maternity Leave: </label>
            <div class="input">
              <input
                type="checkbox"
                v-model="isMLTransferredVal"
                @click="getPaternity"
              />
            </div>
          </div>
        </div>
        <div class="form-group" v-show="isMedAppnt">
          <div class="checkbox-container">
            <label for=""> Medical Appointment: </label>
            <div class="input">
              <input
                type="checkbox"
                v-model="isMedAppntVal"
                @click="
                  computeDurationLeave(
                    dateStart,
                    dateEnd,
                    (isMedAppntVal = !isMedAppntVal)
                  )
                "
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label> Date Start: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="date"
              v-model="dateStart"
              :min="datePrevious"
              :max="dateIncoming"
              @change="computeDurationLeave(dateStart, dateEnd)"
            />
          </div>
        </div>
        <div class="form-group">
          <label> Date End: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="date"
              v-model="dateEnd"
              :min="datePrevious"
              :max="dateIncoming"
              :disabled="isMaternityDt"
              @change="computeDurationLeave(dateStart, dateEnd)"
            />
          </div>
        </div>
        <div class="form-group" v-if="!isHalfDayVisible">
          <div class="checkbox-container">
            <label for=""> Half Day: </label>
            <div class="input">
              <input
                type="checkbox"
                id="am"
                value="am"
                v-model="isHalfDay"
                @click="
                  computeDurationLeave(
                    dateStart,
                    dateEnd,
                    (isHalfDay = !isHalfDay)
                  )
                "
              />
            </div>
            <div class="input">
              <input
                type="radio"
                id="am"
                value="Half Day (AM)"
                v-model="isHalfDayVal"
                v-if="isHalfDay"
              />
            </div>
            <label for="" v-if="isHalfDay"> AM </label>

            <div class="input">
              <input
                type="radio"
                id="pm"
                value="Half Day (PM)"
                v-model="isHalfDayVal"
                v-if="isHalfDay"
              />
            </div>
            <label for="" v-if="isHalfDay"> PM </label>
          </div>
        </div>
        <div class="form-group">
          <label> Number of days: <span class="required">*</span> </label>
          <div class="input">
            <input type="number" step="any" v-model="numberDays" disabled />
          </div>
          <span
            v-show="
              isSufficientBalance[0].balance ||
              isSufficientBalance[1].balance ||
              isSufficientBalance[2].balance
            "
            ><h5 class="required">{{ balanceMessage }}</h5></span
          >
          <span v-show="isApproved"
            ><h5 class="required">{{ VLMessage }}</h5></span
          >
          <span v-show="BLApproved"
            ><h5 class="required">{{ BLMessage }}</h5></span
          >
          <span v-show="leaveBal"
            ><h5 class="required">{{ leaveMessage }}</h5></span
          >
          <span v-show="isSPL"
            ><h5 class="required">{{ splMessage }}</h5></span
          >
          <span v-show="isPlRemarksVisible"
            ><h5 class="required">{{ isPlRemarks }}</h5></span
          >
          <span v-show="isEmgLeave"
            ><h5 class="required">{{ isEmgLeaveRemarks }}</h5></span
          >
          <span v-show="isVAWCRemarksVisible"
            ><h5 class="required">{{ isVAWCRemarks }}</h5></span
          >
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label> Date Resumed: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="date"
              v-model="dateResume"
              :min="dateEnd"
              :max="maxDt"
            />
          </div>
        </div>
        <div class="form-group" v-show="!rmkHidden">
          <label>
            {{ isEmergency ? "Description (Optional)" : "Reason" }}:
            <span class="required" v-if="!isEmergency">*</span>
          </label>
          <div class="input">
            <textarea rows="8" v-model="reason" />
          </div>
        </div>
        <div class="form-group" v-if="ifMedCert">
          <label for="">
            {{ medLabel }}: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              ref="fileData"
              type="file"
              @change="selectFile(fileData)"
              style="width: 40px"
            />
          </div>
        </div>

        <div class="form-group">
          <button class="submit" :disabled="buttonMessageVisible">
            {{ !buttonMessageVisible ? "Submit" : "Please wait..." }}
          </button>
        </div>
        <div v-show="isStatus" class="alert-error form-alert">
          {{ $store.state.formErrorMessage }}
        </div>
      </div>
    </form>
  </div>
</template>
<script>
//import searchIcon from "@/assets/svgs/searchIcon.vue";
import { reactive, ref, toRefs, onMounted, computed } from "vue";
import { inject } from "vue";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  components: {
    //searchIcon,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const ifMedCert = ref(false);
    const isVisible = ref(false);
    const isStatus = ref(false);
    const isBalance = ref(false);
    const isEmergency = ref(false);
    const itemEmergency = ref([
      "Accident",
      "Calamities/Disasters/Strike",
      "Death of immediate family",
      "Provide care for immediate family.",
    ]);
    const isSufficientBalance = reactive([
      {
        balance: false,
        type: "VL",
      },
      {
        balance: false,
        type: "SL",
      },
      {
        balance: false,
        type: "EL",
      },
    ]);
    const isApproved = ref(false);
    const isMedAppnt = ref(false);
    const isMedAppntVal = ref(false);
    const isMaternity = ref(false);
    const isMaternityDt = ref(false);
    const BLApproved = ref(false);
    const leaveBal = ref(false);
    const leaveMessage = ref(false);
    const isAWOP = ref(false);
    const isPLTransferred = ref(false);
    const isPLTransferredVal = ref(false);
    const isMLReceived = ref(false);
    const isMLTransferredVal = ref(false);
    const isHalfDay = ref(false);
    const isSPL = ref(false);
    const splMessage = ref(null);
    const rmkHidden = ref(false);
    const isHalfDayVisible = ref(false);
    const isHalfDayVal = ref("Half Day (AM)");
    const medLabel = ref("Medical Certificate");
    const datePrevious = ref(null);
    const dateIncoming = ref(null);
    const balanceMessage = ref(null);
    const VLMessage = ref(null);
    const BLMessage = ref(null);
    const startDt = ref(null);
    const remBalance = ref(null);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const isPlRemarksVisible = ref(false);
    const isPlRemarks = ref(null);
    const isEmgLeave = ref(false);
    const isEmgLeaveRemarks = ref(null);
    const isImmediateFamVisible = ref(false);
    const isImmediateFamVal = ref(false);
    const isVAWCRemarksVisible = ref(false);
    const isVAWCRemarks = ref(null);
    const dbHolidays = ref([]);
    const dbShiftSched = ref([]);
    const dbLeaveSched = ref([]);
    const maxDt = ref(null);
    const screenWidth = screen.width;
    const withWeekend = ref(
      store.state.getProfileInfo.maintenance_schedule_attendance.withWeekend
    );
    const leaveTypes = reactive([
      { name: "Absent Without Pay", code: "AWOP" },
      { name: "Birthday Leave", code: "BL" },
      { name: "Compassionate Leave", code: "CL" },
      { name: "Emergency Leave", code: "EL" },
      { name: "Magna Carta for Women", code: "MCW" },
      { name: "Maternity Leave", code: "ML" },
      { name: "Paternity Leave", code: "PL" },
      { name: "Sick Leave", code: "SL" },
      { name: "Solo Parent Leave", code: "SPL" },
      { name: "Vacation Leave", code: "VL" },
      { name: "Violence Against Women and Their Children", code: "VAWC" },
      { name: "Others", code: "O" },
    ]);
    const awopTypes = reactive([
      { name: "Sick Leave", code: "AWOPSL" },
      { name: "Vacation Leave", code: "AWOPVL" },
    ]);
    const leaveForm = reactive({
      empId: store.state.getEmployeeId,
      department: store.state.getDepartment,
      leaveType: "AWOP",
      selectedEmergencyCat: null,
      awopType: "AWOPSL",
      leaveCategory: "Whole Day",
      dateStart: null,
      dateEnd: null,
      numberDays: 0,
      dateResume: null,
      filename: "",
      reason: null,
      isDirect: 1,
      requestedBy: store.state.getUserLog,
      birthDate: store.state.getBirthDate,
    });

    const category = reactive([
      { name: "Whole Day" },
      { name: "Half Day (AM)" },
      { name: "Half Day (PM)" },
    ]);
    const buttonMessage = ref(null);
    const buttonMessageVisible = ref(false);
    onMounted(() => {
      store.commit("globalDate");
      isAWOP.value = true;
      selectedLeave();
      getHolidays();
      getShiftingSched();
      getLeaveSched();
    });

    const selectedLeave = () => {
      leaveForm.leaveCategory = "Whole Day";
      //isHalfDayVisible.value = false;
      isHalfDay.value = false;
      const selectedType = leaveForm.leaveType;
      isEmergency.value = selectedType === "EL" ? true : false;
      isVisible.value = selectedType === "O" ? true : false;
      isMedAppnt.value = selectedType === "SL" ? true : false;
      store.commit("noticeAlert", selectedType);
      store.commit("globalDate");

      if (selectedType === "VL") {
        datePrevious.value = store.state.customDate;
        leaveForm.dateStart = store.state.customDate;
        leaveForm.dateEnd = store.state.customDate;
        leaveForm.dateResume = leaveForm.dateEnd;
        dateIncoming.value = null;
      } else if (selectedType === "SL" || selectedType === "EL") {
        datePrevious.value = null;
        dateIncoming.value = store.state.currentDate;
        leaveForm.dateStart = store.state.currentDate;
        leaveForm.dateEnd = store.state.currentDate;
        leaveForm.dateResume = leaveForm.dateEnd;
      } else if (selectedType === "AWOP") {
        if (leaveForm.awopType === "AWOPSL") {
          datePrevious.value = null;
          dateIncoming.value = store.state.currentDate;
          leaveForm.dateStart = store.state.currentDate;
          leaveForm.dateEnd = store.state.currentDate;
          leaveForm.dateResume = leaveForm.dateEnd;
        } else if (leaveForm.awopType === "AWOPVL") {
          datePrevious.value = store.state.customDate;
          leaveForm.dateStart = store.state.customDate;
          leaveForm.dateEnd = store.state.customDate;
          leaveForm.dateResume = leaveForm.dateEnd;
          dateIncoming.value = null;
        } else {
          datePrevious.value = null;
          dateIncoming.value = store.state.currentDate;
          leaveForm.dateStart = store.state.currentDate;
          leaveForm.dateEnd = store.state.currentDate;
          leaveForm.dateResume = leaveForm.dateEnd;
        }
      } else if (selectedType === "ML") {
        leaveForm.dateStart = store.state.currentDate;
        datePrevious.value = null;
        dateIncoming.value = null;
      } else if (selectedType === "SPL") {
        datePrevious.value = null;
        dateIncoming.value = null;
        leaveForm.dateStart = store.state.currentDate;
        leaveForm.dateEnd = store.state.currentDate;
      } else if (selectedType === "MCW") {
        datePrevious.value = store.state.currentDate;
        leaveForm.dateStart = store.state.currentDate;
        leaveForm.dateEnd = store.state.customDate;
        leaveForm.dateResume = leaveForm.dateEnd;
        dateIncoming.value = null;
      } else {
        leaveForm.dateStart = store.state.currentDate;
        leaveForm.dateEnd = store.state.currentDate;
        leaveForm.dateResume = leaveForm.dateEnd;
        datePrevious.value = null;
        dateIncoming.value = null;
      }
      computeDurationLeave(leaveForm.dateStart, leaveForm.dateEnd);
    };

    const handleSubmit = async () => {
      handleValidate(leaveForm);
    };
    const insertData = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        let departmentList = response.data;
        let checkIfHead = departmentList.filter((item) => {
          return item.headName === store.state.getUserLog;
        });
        leaveForm.isDirect = checkIfHead.length ? 1 : 0;

        if (leaveForm.leaveType === "EL") {
          if (!leaveForm.reason) {
            leaveForm.reason = leaveForm.selectedEmergencyCat;
          } else {
            leaveForm.reason = `${leaveForm.selectedEmergencyCat} - ${leaveForm.reason} `;
          }
        }

        leaveForm.awopType =
          leaveForm.leaveType === "AWOP" ? leaveForm.awopType : null;
        await axios.post(`/api/form/leave`, leaveForm);
        isStatus.value = false;
        const SEQUENCE_ARRAY_CODE = 2;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearField(true);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const clearField = (value) => {
      if (value) {
        setTimeout(function () {
          leaveForm.leaveType = "AWOP";
          leaveForm.awopType = "AWOPSL";
          leaveForm.leaveCategory = "Whole Day";
          leaveForm.dateStart = null;
          leaveForm.dateEnd = null;
          leaveForm.numberDays = 0;
          leaveForm.dateResume = null;
          leaveForm.filename = "";
          leaveForm.isDirect = 1;
          leaveForm.reason = null;
          isStatus.value = false;
          isMedAppnt.value = false;
          ifMedCert.value = false;
          isMaternity.value = false;
          isMaternityDt.value = false;
          isPLTransferred.value = false;
          isMLReceived.value = false;
          isAWOP.value = true;
          isHalfDay.value = false;
          isHalfDayVisible.value = false;
          rmkHidden.value = false;
          isImmediateFamVisible.value = false;
          isImmediateFamVal.value = false;
          tableDetails.splice(0, tableDetails.length);
          buttonMessageVisible.value = false;
        }, 1100);
      }
    };
    const handleValidate = (formData) => {
      const selectedType = leaveForm.leaveType;
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (ifMedCert.value && key !== "selectedEmergencyCat") {
          if (!value) {
            validate.push(key);
          }
        } else {
          if (selectedType === "EL") {
            if (key !== "filename" && key !== "reason") {
              if (!value) {
                validate.push(key);
              }
            }
          } else {
            if (key !== "filename" && key !== "selectedEmergencyCat") {
              if (!value) {
                validate.push(key);
              }
            }
          }
        }
      });

      if (validate.length) {
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else if (
        isSufficientBalance[0].balance ||
        isSufficientBalance[1].balance ||
        isSufficientBalance[2].balance ||
        BLApproved.value ||
        isApproved.value ||
        isEmgLeave.value ||
        isVAWCRemarksVisible.value ||
        isSPL.value ||
        isPlRemarksVisible.value
      ) {
        isStatus.value = true;
        store.state.formErrorMessage = `There was a problem with your submission. Please review the fields that require your attention.`;
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            buttonMessageVisible.value = true;
            if (leaveForm.leaveType === "SL" && leaveForm.numberDays <= 2) {
              if (isMedAppntVal.value) {
                insertData();
                emit("isLoaded");
                uploadHandler();
              } else {
                insertData();
                emit("isLoaded");
              }
            } else if (
              (leaveForm.leaveType === "SL" ||
                leaveForm.awopType === "AWOPSL") &&
              leaveForm.numberDays > 2
            ) {
              insertData();
              emit("isLoaded");
              uploadHandler();
            } else if (
              leaveForm.leaveType === "VAWC" ||
              leaveForm.leaveType === "MCW"
            ) {
              insertData();
              emit("isLoaded");
              uploadHandler();
            } else {
              insertData();
              emit("isLoaded");
            }
          }
        });
      }
      setTimeout(() => {
        isStatus.value = false;
      }, 8000);
    };

    const getHolidays = async () => {
      try {
        const { data } = await axios.get("/api/form/holidays");
        dbHolidays.value = data.filter((val) => {
          if (withWeekend.value) {
            return val.types === "Regular";
          } else {
            return val;
          }
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getShiftingSched = async () => {
      try {
        const { data } = await axios.get("/api/form/shiftingSched");
        dbShiftSched.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getLeaveSched = async () => {
      try {
        const { data } = await axios.get("/api/form/leave/user");
        dbLeaveSched.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const computeDurationLeave = (start, end) => {
      let selectedLeaveType = leaveForm.leaveType;
      let startDate = moment(start);
      let endDate = moment(end).add(1, "days");
      let itemListNoWorks = [];
      let daysDuration = [];
      startDt.value = leaveForm.dateStart;
      leaveForm.dateResume = leaveForm.dateEnd;
      maxDt.value = null;

      dbHolidays.value.forEach((item) => {
        let getScheduleStatus =
          store.state.getProfileInfo.maintenance_schedule_attendance
            .description;
        if (getScheduleStatus !== "Shifting") {
          itemListNoWorks.push(item.dbDate);
        }
      });

      dbLeaveSched.value.forEach((item) => {
        itemListNoWorks.push(item.dateStart);
      });

      dbShiftSched.value
        .filter((val) => {
          return !val.timeIn;
        })
        .forEach((shift) => {
          itemListNoWorks.push(shift.date);
        });

      while (startDate.isBefore(endDate, "days")) {
        let days = startDate.format("dddd");
        let holidaysFormatted = startDate.format("YYYY-MM-DD");
        startDate.add(1, "days");
        let getScheduleStatus =
          store.state.getProfileInfo.maintenance_schedule_attendance
            .description;
        if (
          getScheduleStatus === "Shifting" &&
          !itemListNoWorks.includes(holidaysFormatted)
        ) {
          daysDuration.push(days);
        } else if (
          withWeekend.value &&
          !itemListNoWorks.includes(holidaysFormatted) &&
          days !== "Sunday" //MUNTILUPA
        ) {
          daysDuration.push(days);
        } else {
          if (
            days !== "Saturday" &&
            days !== "Sunday" &&
            !itemListNoWorks.includes(holidaysFormatted)
          ) {
            daysDuration.push(days);
          }
        }
      }
      leaveForm.numberDays = daysDuration.length;

      if (isHalfDay.value) {
        const hd = 0.5;
        leaveForm.numberDays = leaveForm.numberDays - hd;
        isHalfDayVal.value = "Half Day (AM)";
        leaveForm.leaveCategory = isHalfDayVal;
      } else {
        leaveForm.leaveCategory = "Whole Day";
        leaveForm.numberDays = daysDuration.length;
      }

      if (selectedLeaveType === "VL") {
        const vlBal = store.state.swalDetailList[0].balance;
        if (vlBal == 0) {
          isApproved.value = false;
          isSufficientBalance[0].balance = true;
          balanceMessage.value = "You have no remaining Vacation Leave!";
        } else if (vlBal < leaveForm.numberDays) {
          isApproved.value = false;
          isSufficientBalance[0].balance = true;
          balanceMessage.value = "You have insufficient leave balance!";
        } else if (computedTotalDays.value < 5 && leaveForm.numberDays >= 3) {
          isSufficientBalance[0].balance = false;
          isApproved.value = true;
          VLMessage.value = "Must be 1 week prior to the intended leave!";
        } else {
          isSufficientBalance[0].balance = false;
          isApproved.value = false;
          remBalance.value = store.state.creditBalance - leaveForm.numberDays;
        }
      } else {
        isApproved.value = false;
        isSufficientBalance[0].balance = false;
      }
      if (selectedLeaveType === "SL") {
        let NUMBER_OF_DAYS = 3;
        if (isMedAppntVal.value) {
          medLabel.value = "Proof of Appointment";
          datePrevious.value = store.state.customDate;
          dateIncoming.value = null;
          maxDt.value = null;
          isApproved.value = false;
        } else {
          medLabel.value = "Medical Certificate";
          datePrevious.value = null;
          dateIncoming.value = store.state.currentDate;
          leaveForm.dateStart = start > store.state.currentDate ? null : start;
          leaveForm.dateEnd = end > store.state.currentDate ? null : end;
          leaveForm.dateResume = leaveForm.dateEnd;
          leaveForm.numberDays =
            start > store.state.currentDate ? 0 : leaveForm.numberDays;
          leaveForm.filename = null;

          //const dtStart = moment(leaveForm.dateEnd);
          // const dtEnd = moment(dtStart, "YYYY-MM-DD");
          // let SLdays = dtStart.format("dddd");

          /* Mag add ka dito ng shiftng condtion pag ang status ng worksched ni employee makikita yun sa store.state.getProfileInfo.maintenance_schedule_attendance
              .description  */

          let shedStatus =
            store.state.getProfileInfo.maintenance_schedule_attendance
              .description;
          if (shedStatus === "Shifting") {
            let item = dbShiftSched.value.filter((val) => {
              return val.timeIn;
            });
            const slCurdate = moment().format("YYYY-MM-DD");
            let res = item.find((value) => {
              return value.date === slCurdate;
            });
            if (res) {
              maxDt.value = slCurdate;
            }
          } else {
            const dtCurrent = moment().add(1, "days");
            const dtEnd = moment(leaveForm.dateEnd);
            let dtDurations = [];
            while (dtEnd.isBefore(dtCurrent, "days")) {
              let days = dtEnd.format("dddd");
              let dtToResume = dtEnd.format("YYYY-MM-DD");
              let holidaysFormatted = dtEnd.format("YYYY-MM-DD");
              dtEnd.add(1, "days");

              if (
                days !== "Saturday" &&
                days !== "Sunday" &&
                !withWeekend.value &&
                !itemListNoWorks.includes(holidaysFormatted)
              ) {
                dtDurations.push(dtToResume);
              }
              if (
                days !== "Sunday" &&
                withWeekend.value &&
                !itemListNoWorks.includes(holidaysFormatted)
              ) {
                dtDurations.push(dtToResume);
              }
            }
            if (dtDurations.length > 2) {
              maxDt.value = dtDurations[1];
              isApproved.value = true;
              VLMessage.value = "Late filing! Please coordinate with HR.";
            } else {
              maxDt.value = dtDurations.pop();
              isApproved.value = false;
            }
          }
        }

        ifMedCert.value =
          leaveForm.numberDays >= NUMBER_OF_DAYS || isMedAppntVal.value
            ? true
            : false;
        let slBal = store.state.swalDetailList[1].balance;
        let vlBal = store.state.swalDetailList[0].balance;
        const leaveTotal = slBal + vlBal;
        if (leaveForm.numberDays > leaveTotal) {
          isSufficientBalance[1].balance = true;
          balanceMessage.value = "You have insufficient leave balance!";
        } else {
          isSufficientBalance[1].balance = false;
        }
      } else {
        isMedAppntVal.value = false;
        //ifMedCert.value = false;
        isSufficientBalance[1].balance = false;
      }

      if (selectedLeaveType === "BL") {
        let ifBday = 1;

        isHalfDayVisible.value = true;
        rmkHidden.value = true;
        leaveForm.reason = "Birthday Leave";
        if (store.state.getBdayStat == ifBday) {
          BLApproved.value = true;
          BLMessage.value = "You already used your Birthday Leave!";
        } else {
          if (
            moment(store.state.getBirthDate).format("M") !=
            moment(leaveForm.dateStart).format("M")
          ) {
            BLApproved.value = true;
            BLMessage.value = "It's not your birth month!";
          } else if (leaveForm.numberDays > 1) {
            BLMessage.value = "Birthday Leave must not exceed 1 day";
            BLApproved.value = true;
          } else {
            BLApproved.value = false;
          }
        }
      } else {
        BLApproved.value = false;
        isHalfDayVisible.value = false;
        rmkHidden.value = false;
        leaveForm.reason = null;
      }

      if (selectedLeaveType === "AWOP") {
        isAWOP.value = true;
        if (leaveForm.awopType === "AWOPSL") {
          let NUMBER_OF_DAYS_AWOPSL = 3;
          medLabel.value = "Medical Certificate";
          datePrevious.value = null;
          dateIncoming.value = store.state.currentDate;
          leaveForm.dateStart = start > store.state.currentDate ? null : start;
          leaveForm.dateEnd = end > store.state.currentDate ? null : end;
          leaveForm.dateResume = leaveForm.dateEnd;
          leaveForm.numberDays =
            start > store.state.currentDate ? 0 : leaveForm.numberDays;
          leaveForm.filename = null;

          ifMedCert.value =
            leaveForm.numberDays >= NUMBER_OF_DAYS_AWOPSL || isMedAppntVal.value
              ? true
              : false;
        }
      } else {
        isAWOP.value = false;
      }

      if (selectedLeaveType === "EL") {
        isAWOP.value = false;

        let vlBal = store.state.swalDetailList[0].balance;

        if (!vlBal) {
          isEmgLeave.value = true;
          isEmgLeaveRemarks.value = "You have no remaining leave balance!";
        } else if (vlBal < leaveForm.numberDays) {
          isSufficientBalance[2].balance = true;
          balanceMessage.value = "You have insufficient leave balance!";
        } else {
          isEmgLeave.value = false;
          isSufficientBalance[2].balance = false;

          const dtCurrent = moment().add(1, "days");
          const dtEnd = moment(leaveForm.dateEnd);
          let dtDurations = [];
          while (dtEnd.isBefore(dtCurrent, "days")) {
            let days = dtEnd.format("dddd");
            let dtToResume = dtEnd.format("YYYY-MM-DD");
            let holidaysFormatted = dtEnd.format("YYYY-MM-DD");
            dtEnd.add(1, "days");

            if (
              days !== "Saturday" &&
              days !== "Sunday" &&
              !itemListNoWorks.includes(holidaysFormatted)
            ) {
              dtDurations.push(dtToResume);
            }
          }
          if (dtDurations.length > 2) {
            maxDt.value = dtDurations[1];
            isEmgLeave.value = true;
            isEmgLeaveRemarks.value = "Late filing! Please coordinate with HR.";
          } else {
            maxDt.value = dtDurations.pop();
            isEmgLeave.value = false;
          }
        }
      } else {
        isEmgLeave.value = false;
        isSufficientBalance[2].balance = false;
      }

      if (selectedLeaveType === "ML") {
        isHalfDayVisible.value = true;
        isMaternity.value = true;
        isPLTransferred.value = true;
        isMaternityDt.value = true;
        const start = moment(leaveForm.dateStart);
        const end = moment(start, "YYYY-MM-DD");
        const mlCredit = 105;
        const plTransferred = 98;
        if (isPLTransferredVal.value) {
          leaveForm.dateEnd = end
            .add(plTransferred, "days")
            .format("YYYY-MM-DD");
          leaveForm.dateResume = leaveForm.dateEnd;
          leaveForm.numberDays = plTransferred;
          leaveForm.leaveCategory = " ";
        } else {
          leaveForm.dateEnd = end.add(mlCredit, "days").format("YYYY-MM-DD");
          leaveForm.dateResume = leaveForm.dateEnd;
          leaveForm.numberDays = mlCredit;
          leaveForm.leaveCategory = " ";
        }
      } else if (selectedLeaveType === "PL") {
        isHalfDayVisible.value = true;
        isMaternity.value = true;
        isMLReceived.value = true;

        const PLCredit = store.state.getPL;
        const regPL = 7;
        const addPL = 14;
        if (!isMLTransferredVal.value) {
          const remBal = regPL - PLCredit;
          if (remBal == 0) {
            isPlRemarksVisible.value = true;
            isPlRemarks.value = "You have no remaining Paternity Leave - (PL)";
          } else if (remBal < leaveForm.numberDays) {
            isPlRemarksVisible.value = true;
            isPlRemarks.value = "Insufficent Paternity Leave - (PL)";
          } else {
            isPlRemarksVisible.value = false;
          }
        } else {
          const remBal = addPL - PLCredit;
          if (remBal == 0) {
            isPlRemarksVisible.value = true;
            isPlRemarks.value = "You have no remaining Paternity Leave - (PL)";
          } else if (remBal < leaveForm.numberDays) {
            isPlRemarksVisible.value = true;
            isPlRemarks.value = "Insufficent Paternity Leave - (PL)";
          } else {
            isPlRemarksVisible.value = false;
          }
        }
      } else {
        isMaternity.value = false;
        isMaternityDt.value = false;
        isPLTransferred.value = false;
        isPLTransferredVal.value = false;
        isMLReceived.value = false;
      }

      if (selectedLeaveType === "SPL") {
        let SPLCredit = store.state.getSPL;
        let totalSPL = 14;
        const SPLremBal = totalSPL - SPLCredit;

        if (store.state.getSPL === totalSPL) {
          isSPL.value = true;
          splMessage.value =
            "You already used all of your Solo Parent Leave - (SPL) Credit!";
        } else if (leaveForm.numberDays > SPLremBal) {
          isSPL.value = true;
          splMessage.value = "Insufficent Solo Parent Leave - (SPL) Credit!";
        } else if (SPLCredit === 0 && leaveForm.numberDays > totalSPL) {
          isSPL.value = true;
          splMessage.value =
            "Solo Parent Leave - (SPL) must not exceed 14 days!";
        } else {
          isSPL.value = false;
        }
      } else {
        isSPL.value = false;
      }

      if (selectedLeaveType === "CL") {
        isMaternityDt.value = true;
        isHalfDayVisible.value = true;
        isImmediateFamVisible.value = true;
        ifMedCert.value = false;

        const start = moment(leaveForm.dateStart);
        let isImmediate = 3;
        let isNotImmediate = 1;
        let curDate = moment(leaveForm.dateStart).format("dddd");

        if (curDate === "Thursday" || curDate === "Friday") {
          if (isImmediateFamVal.value) {
            leaveForm.dateEnd = start.add(4, "days").format("YYYY-MM-DD");
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isImmediate;
          } else if (curDate === "Friday") {
            leaveForm.dateEnd = leaveForm.dateStart;
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isNotImmediate;
          } else {
            leaveForm.dateEnd = leaveForm.dateStart;
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isNotImmediate;
          }
        } else if (curDate === "Wednesday") {
          if (isImmediateFamVal.value) {
            leaveForm.dateEnd = start.add(2, "days").format("YYYY-MM-DD");
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isImmediate;
          } else {
            leaveForm.dateEnd = leaveForm.dateStart;
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isNotImmediate;
          }
        } else {
          if (isImmediateFamVal.value) {
            leaveForm.dateEnd = start.add(2, "days").format("YYYY-MM-DD");
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isImmediate;
          } else {
            leaveForm.dateEnd = leaveForm.dateStart;
            leaveForm.dateResume = leaveForm.dateEnd;
            leaveForm.numberDays = isNotImmediate;
          }
        }
      } else {
        isMaternityDt.value = false;
        isImmediateFamVisible.value = false;
        isImmediateFamVal.value = false;
      }

      if (selectedLeaveType === "MCW") {
        isMaternityDt.value = true;
        isHalfDayVisible.value = true;
        ifMedCert.value = true;

        const start = moment(leaveForm.dateStart);
        const end = moment(start, "YYYY-MM-DD");
        let MagnaCartaCrdt = 45;

        leaveForm.dateEnd = end
          .add(MagnaCartaCrdt, "days")
          .format("YYYY-MM-DD");
        leaveForm.dateResume = leaveForm.dateEnd;
        leaveForm.numberDays = MagnaCartaCrdt;
      } else {
        isMaternityDt.value = false;
        //ifMedCert.value = false;
      }

      if (selectedLeaveType === "VAWC") {
        ifMedCert.value = true;
        medLabel.value = "Attachment file";
        let totalVAWC = store.state.getVAWC;
        let VAWCCrdt = 10;
        let AvailableVAWCCrdt = VAWCCrdt - totalVAWC;

        if (totalVAWC == 0 && leaveForm.numberDays > VAWCCrdt) {
          isVAWCRemarksVisible.value = true;
          isVAWCRemarks.value = "VAWC must not exceed 10 days!";
        } else if (leaveForm.numberDays > AvailableVAWCCrdt) {
          isVAWCRemarksVisible.value = true;
          isVAWCRemarks.value = "Insufficent VAWC Leave Credit!";
        } else {
          isVAWCRemarksVisible.value = false;
        }
      } else {
        //ifMedCert.value = false;
        isVAWCRemarksVisible.value = false;
      }
    };

    const computedTotalDays = computed(() => totalDays.value);
    const totalDays = computed(() => {
      const start = moment(startDt.value);
      const end = moment();
      return start.diff(end, "days");
    });
    const getMaternity = () => {
      const start = moment(leaveForm.dateStart);
      const end = moment(start, "YYYY-MM-DD");
      const mlCredit = 105;
      const plTransferred = 98;
      if (!isPLTransferredVal.value) {
        leaveForm.dateEnd = end.add(plTransferred, "days").format("YYYY-MM-DD");
        leaveForm.dateResume = leaveForm.dateEnd;
        leaveForm.numberDays = plTransferred;
        leaveForm.leaveCategory = " ";
      } else {
        leaveForm.dateEnd = end.add(mlCredit, "days").format("YYYY-MM-DD");
        leaveForm.dateResume = leaveForm.dateEnd;
        leaveForm.numberDays = mlCredit;
        leaveForm.leaveCategory = " ";
      }
    };
    const getPaternity = () => {
      const PLCredit = store.state.getPL;
      const regPL = 7;
      const addPL = 14;
      if (!isMLTransferredVal.value) {
        const remBal = addPL - PLCredit;
        if (remBal == 0) {
          isPlRemarksVisible.value = true;
          isPlRemarks.value = "You have no remaining Paternity Leave - (PL)";
        } else if (remBal < leaveForm.numberDays) {
          isPlRemarksVisible.value = true;
          isPlRemarks.value = "Insufficent Paternity Leave - (PL)";
        } else {
          isPlRemarksVisible.value = false;
        }
      } else {
        const remBal = regPL - PLCredit;
        if (remBal == 0) {
          isPlRemarksVisible.value = true;
          isPlRemarks.value = "You have no remaining Paternity Leave - (PL)";
        } else if (remBal < leaveForm.numberDays) {
          isPlRemarksVisible.value = true;
          isPlRemarks.value = "Insufficent Paternity Leave - (PL)";
        } else {
          isPlRemarksVisible.value = false;
        }
      }
    };

    const filterLeaveTypes = computed(() => {
      return leaveTypes
        .filter((types) => {
          if (store.state.getGender === "F") {
            return types.code !== "PL" && types.code !== "O";
          } else if (store.state.getGender === "M") {
            return (
              types.code !== "ML" &&
              types.code !== "O" &&
              types.code !== "VAWC" &&
              types.code !== "MCW"
            );
          }
        })
        .map((value) => {
          if (screenWidth > 768) {
            return value;
          } else if (screenWidth <= 320) {
            if (value.code === "VAWC") {
              value.name = "Violence Against..";
            }
            return value;
          } else {
            if (value.code === "VAWC") {
              value.name = "Violence Against Women..";
            }
            return value;
          }
        });
    });
    const selectFile = () => {
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];
        uploadFile.value = fileData.value;
        leaveForm.filename = fileData.value.name;
      }
    };

    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", uploadFile.value);
      try {
        await axios.post(`/api/upload/`, formData);
        clearField(true);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const tableDetails = reactive([]);
    const addDataTable = () => {
      if (leaveForm.leaveType && leaveForm.dateStart && leaveForm.dateEnd) {
        tableDetails.push({
          leaveType: leaveForm.leaveType,
          dateStart: leaveForm.dateStart,
          dateEnd: leaveForm.dateEnd,
        });
      }
    };
    const removeItems = (index) => {
      tableDetails.splice(index, 1);
    };

    return {
      ifMedCert,
      leaveTypes,
      category,
      isVisible,
      isStatus,
      isBalance,
      balanceMessage,
      VLMessage,
      datePrevious,
      dateIncoming,
      ...toRefs(leaveForm),
      selectedLeave,
      handleValidate,
      handleSubmit,
      insertData,
      clearField,
      computeDurationLeave,
      computedTotalDays,
      startDt,
      isApproved,
      remBalance,
      BLMessage,
      BLApproved,
      filterLeaveTypes,
      isMedAppnt,
      isMedAppntVal,
      fileData,
      selectFile,
      uploadFile,
      uploadHandler,
      leaveBal,
      leaveMessage,
      awopTypes,
      isAWOP,
      isMaternity,
      getMaternity,
      isMaternityDt,
      isPLTransferred,
      isPLTransferredVal,
      isMLReceived,
      getPaternity,
      isMLTransferredVal,
      addDataTable,
      tableDetails,
      removeItems,
      isHalfDay,
      isHalfDayVisible,
      isHalfDayVal,
      isSPL,
      splMessage,
      rmkHidden,
      medLabel,
      isPlRemarksVisible,
      isPlRemarks,
      isEmgLeave,
      isEmgLeaveRemarks,
      isSufficientBalance,
      isImmediateFamVisible,
      isImmediateFamVal,
      isVAWCRemarksVisible,
      isVAWCRemarks,
      getHolidays,
      dbHolidays,
      maxDt,
      getShiftingSched,
      dbShiftSched,
      dbLeaveSched,
      getLeaveSched,
      screenWidth,
      isEmergency,
      itemEmergency,
      withWeekend,
      buttonMessage,
      buttonMessageVisible,
    };
  },
};
</script>
<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 20px;
}
.checkbox {
  cursor: pointer;
}
.checkbox-container label {
  margin-right: 14px;
}
.checkbox-container .input input {
  margin-top: 6px;
  cursor: pointer;
  width: 25px;
  text-align: center;
}
.radio {
  margin-right: 10px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

.btn-add {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 10px 0px;
  cursor: pointer;
}
</style>

<template>
  <div class="cpanel">
    <table>
      <tr>
        <td class="title">Reference Number:</td>
        <td class="description">
          {{ generateReference(transaction.id) }}
        </td>
      </tr>
      <tr>
        <td class="title">Name of Payee:</td>
        <td class="description">{{ transaction.payee }}</td>
      </tr>
      <tr>
        <td class="title">Type of Expenses:</td>
        <td class="description">
          {{ transaction.expenseDetails[0].name }}
          <span
            @click="isModalExpenseStatus = true"
            v-if="transaction.expenseDetails.length > 1"
            style="
              float: right;
              padding-right: 10px;
              color: blue;
              cursor: pointer;
            "
            >See all</span
          >
          <div v-if="isModalExpenseStatus">
            <ModalExpensesHistory
              @passClose="isModalExpenseStatus = false"
              :expenseList="transaction.expenseDetails"
              :totalAmount="transaction.total"
              :itemList="transaction"
            >
            </ModalExpensesHistory>
          </div>
        </td>
      </tr>
      <tr>
        <td class="title">
          {{ transaction.ifCashAdvance ? "Cash Advance:" : "Payable Amount:" }}
        </td>
        <td class="description">
          {{ amountFormat(transaction.total) }}
        </td>
      </tr>
      <tr>
        <td class="title">Requested by:</td>
        <td class="description">
          {{ transaction.requestedBy }} - ({{
            transaction.department.toUpperCase()
          }})
        </td>
      </tr>
      <tr>
        <td class="title">Date Requested:</td>
        <td class="description">
          {{ dateFormat(transaction.dateFiled, true) }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Intended:</td>
        <td class="description">
          {{ dateFormat(transaction.dateIntended, true) }}
        </td>
      </tr>
      <tr>
        <td class="title">Purpose:</td>
        <td class="description">{{ transaction.remarks }}</td>
      </tr>
      <tr>
        <td class="title">
          {{
            transaction.statusRequest === "Disapproved"
              ? "Disapproved by:"
              : transaction.statusRequest === "Pre-approved"
              ? "Checked by:"
              : "Approved by:"
          }}
        </td>
        <td class="description">
          {{ !transaction.approverBy ? "-" : transaction.approverBy }}
        </td>
      </tr>
      <tr>
        <td class="title">
          {{
            transaction.statusRequest === "Disapproved"
              ? "Disapprover Remarks:"
              : transaction.statusRequest === "Pre-approved"
              ? "Checked Remarks:"
              : "Approver Remarks:"
          }}
        </td>
        <td class="description">
          {{ !transaction.approverRemarks ? "-" : transaction.approverRemarks }}
        </td>
      </tr>
      <tr>
        <td class="title">
          {{
            transaction.statusRequest === "Disapproved"
              ? "Date Disapproved:"
              : transaction.statusRequest === "Pre-approved"
              ? "Date Checked:"
              : "Date Approved:"
          }}
        </td>
        <td class="description">
          {{ dateFormat(transaction.approverDate) }}
        </td>
      </tr>
      <tr>
        <td class="title">Accounting Status:</td>
        <td class="description">
          {{ !transaction.statusProcessed ? "-" : transaction.statusProcessed }}
          <span
            @click="isModalStatus = true"
            v-if="transaction.statusProcessed"
            style="
              float: right;
              padding-right: 10px;
              color: blue;
              cursor: pointer;
            "
            >See all updates</span
          >
          <div v-if="isModalStatus">
            <app-modal-payment-status
              @passClose="isModalStatus = false"
              :paymentId="transaction.id"
            >
            </app-modal-payment-status>
          </div>
        </td>
      </tr>
      <tr>
        <td class="title">Accounting Remarks:</td>
        <td class="description">
          {{ !transaction.remarkProcessed ? "-" : transaction.remarkProcessed }}
        </td>
      </tr>
      <tr>
        <td class="title">DV Number:</td>
        <td class="description">
          {{ transaction.dvNumber }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Prepared:</td>
        <td class="description">
          {{ dateFormat(transaction.dtprepared, true) }}
        </td>
      </tr>
      <tr>
        <td class="title">Check Number:</td>
        <td class="description">{{ transaction.checkNumber }}</td>
      </tr>
      <tr>
        <td class="title">Date Released:</td>
        <td class="description">
          {{ dateFormat(transaction.dtprocessed, true) }}
        </td>
      </tr>
      <tr>
        <td class="title">Attachment File</td>
        <td class="description">
          <div
            style="display: flex; gap: 5px; cursor: pointer"
            @click="download(transaction.modFileName)"
          >
            <span>
              <img src="../../../assets/images/link_.png" alt="icon"
            /></span>

            {{ !transaction.filename ? "No attachment" : transaction.filename }}
          </div>
        </td>
      </tr>
      <tr>
        <td class="title">Attachment File II</td>
        <td class="description">
          <div
            style="display: flex; gap: 5px; cursor: pointer"
            @click="newTabLink(transaction.reuploadfile)"
          >
            <span>
              <img src="../../../assets/images/link_.png" alt="icon"
            /></span>

            {{
              !transaction.reuploadfile ? "No attachment" : "Please download"
            }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import ModalExpensesHistory from "@/modals/expensesHistoryModal.vue";
import { inject, ref, onMounted } from "vue";
export default {
  components: {
    ModalExpensesHistory,
  },
  props: {
    transaction: Object,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModalStatus = ref(false);
    const isModalExpenseStatus = ref(false);
    const amountFormat = (val) => {
      return val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    };
    onMounted(() => {
      if (screen.width <= 600) {
        window.scrollTo(0, 0);
      }
    });
    const dateFormat = (val, format) => {
      let result;
      if (!format) {
        result = val ? moment(val).format("LLL") : "-";
      } else {
        result = val ? moment(val).format("LL") : "-";
      }
      return result;
    };
    const generateReference = (val) => {
      return 1000000 + val;
    };
    const download = async (val) => {
      const filename = val;
      try {
        const response = await axios.get(
          `api/media/meta/resources/files/${filename}`
        );
        //let url = response.data.mediaLink;
        let fileName = response.data.name;
        let httpUrl = "https://storage.googleapis.com/iwc-data-files/";
        let url = `${httpUrl}${fileName}`;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const newTabLink = async (val) => {
      const filename = val;
      try {
        let httpUrl = filename;
        let url = `${httpUrl}`;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      amountFormat,
      dateFormat,
      generateReference,
      isModalStatus,
      isModalExpenseStatus,
      download,
      newTabLink,
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #666;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  border: 1px solid #666;
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 188px;
  font-family: "Montserrat";
  font-weight: 800;
}
.description {
  max-width: 100%;
  color: #272626;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 14px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
@media only screen and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 12px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
</style>

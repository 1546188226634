<template>
  <div class="header-card">
    <div class="title">
      <h1>New Event</h1>
    </div>
  </div>
  <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
    <div class="data-grid">
      <div>Date:</div>
      <div>
        <input v-model="date" type="date" />
      </div>
    </div>
    <div class="data-grid">
      <div>Time:</div>
      <div class="time">
        <input v-model="timeStart" type="time" />
        <input v-model="timeEnd" type="time" />
      </div>
    </div>
    <div class="data-grid">
      <div>Event:</div>
      <div><textarea rows="4" v-model="name" /></div>
    </div>
    <div class="data-grid">
      <div>Venue:</div>
      <div><input type="text" v-model="venue" /></div>
    </div>
    <div class="data-grid" v-if="false">
      <div>Participants:</div>
      <div class="input">
        <select v-model="leaveCategory">
          <option>All</option>
          <option>INFORMATION TECHNOLOGY</option>
          <option>ACCOUNTING</option>
        </select>
      </div>
    </div>
    <div class="data-grid">
      <div>Attachment:</div>
      <div>
        <input type="file" ref="fileData" @change="selectFile()" />
      </div>
      <div v-if="!fileData"></div>
    </div>
    <div class="data-grid">
      <div></div>
      <div class="time mt">
        <input
          :disabled="disabled"
          class="btn publish"
          type="submit"
          value="Publish"
        />
        <input
          :disabled="disabled"
          class="btn cancel"
          type="submit"
          value="Cancel"
          @click="$emit('closeModal')"
        />
      </div>
    </div>
  </form>
  <div v-show="isStatus" class="alert-error form-alert">
    {{ $store.state.formErrorMessage }}
  </div>
</template>
<script>
import { ref, inject, reactive, toRefs } from "vue";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const disabled = ref(false);
    const isStatus = ref(false);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const closeModal = (val, payLoad) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit("passClose", val, payLoad);
    };
    const eventForm = reactive({
      date: moment().format("YYYY-MM-DD"),
      timeStart: moment().format("HH:mm"),
      timeEnd: moment().format("HH:mm"),
      name: null,
      venue: null,
      filename: null,
    });
    const handleSubmit = async () => {
      handleValidate(eventForm);
    };
    const handleValidate = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (!value) {
          validate.push(key);
        }
      });

      if (validate.length) {
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else {
        Swal.fire({
          title: "Are you sure",
          text: "you want to create this event?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            insertData();
            closeModal(false, true);
          }
        });
      }
    };
    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          eventForm.date = moment().format("YYYY-MM-DD");
          eventForm.timeStart = moment().format("HH:mm");
          eventForm.timeEnd = moment().format("HH:mm");
          eventForm.name = null;
          eventForm.venue = null;
          eventForm.filename = null;
        }, 1100);
      }
    };
    const insertData = async () => {
      try {
        await axios.post(`/api/event`, eventForm);
        isStatus.value = false;
        uploadHandler();
        const SEQUENCE_ARRAY_CODE = 5;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearFormField(true);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const selectFile = () => {
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];
        uploadFile.value = fileData.value;
        eventForm.filename = fileData.value.name;
      }
    };
    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", uploadFile.value);
      try {
        await axios.post(`/api/upload/announcement`, formData);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      closeModal,
      insertData,
      handleSubmit,
      handleValidate,
      uploadHandler,
      selectFile,
      ...toRefs(eventForm),
      disabled,
      eventForm,
      isStatus,
      fileData,
      uploadFile,
    };
  },
};
</script>

<style scoped>
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 20px;
  color: var(--lightSecondary);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.form-event {
  display: flex;
  line-height: 33px;
  column-gap: 20px;
  margin-bottom: 10px;
}
.data-grid {
  display: grid;
  grid-template-columns: 1.2fr 3fr;
  line-height: 32px;
  margin-bottom: 10px;
}
.time {
  display: flex;
  max-width: 125px;
  column-gap: 10px;
}
.publish {
  background-color: rgb(34, 158, 23);
}
.cancel {
  background-color: rgb(207, 65, 40);
}
.mt {
  margin-top: 18px;
}
</style>

<template>
  <div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <SectionOfficialBusinessStatus
      v-else
      :transaction="transactions"
    ></SectionOfficialBusinessStatus>
    <div class="header-form">Request for OB Details</div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <div v-else class="grid-container">
      <table>
        <tr>
          <td class="title">Reference Number:</td>
          <td class="description">{{ transactions.id + 100000 }}</td>
        </tr>
        <tr>
          <td class="title">Date Intended:</td>
          <td class="description">
            {{ dateFormat(transactions.obDate, null) }}
          </td>
        </tr>
        <tr>
          <td class="title">Time Start & Time End:</td>
          <td class="description">
            {{ timeFormat(transactions.obTime, transactions.returnTime, null) }}
          </td>
        </tr>
        <tr>
          <td class="title">Description:</td>
          <td class="description">
            {{ transactions.remarks }}
          </td>
        </tr>
        <tr>
          <td class="title">Purpose & Destination:</td>
          <td class="description">{{ travelDestination }}</td>

          <span
            @click="isModalStatus = true"
            v-if="isMultiple"
            style="
              float: right;
              padding-right: 10px;
              color: blue;
              cursor: pointer;
            "
            >See all</span
          >
          <div v-if="isModalStatus">
            <ModalDestinationDetails
              @passClose="isModalStatus = false"
              :destinationDetails="transactions.obItemDetails"
            >
            </ModalDestinationDetails>
          </div>
        </tr>
        <tr>
          <td class="title">Requested by:</td>
          <td class="description">
            {{ transactions.requestedBy }}
          </td>
        </tr>
        <tr>
          <td class="title">Requested date:</td>
          <td class="description">
            {{ dateFormat(transactions.createdAt, null) }}
          </td>
        </tr>
        <tr>
          <td class="title">Status:</td>
          <td class="description">
            {{ !transactions.status ? "Pending" : transactions.status }}
          </td>
        </tr>
        <tr>
          <td class="title" v-if="transactions.status !== 'Disapproved'">
            {{
              transactions.actionStatus === 2
                ? "Pre-approved by:"
                : "Approved by:"
            }}
          </td>
          <td class="title" v-else>Disapproved by:</td>
          <td class="description">
            {{ !transactions.approverBy ? "-" : transactions.approverBy }}
          </td>
        </tr>
        <tr>
          <td class="title" v-if="transactions.status !== 'Disapproved'">
            {{
              transactions.actionStatus === 2
                ? "Pre-approved remarks:"
                : "Approved remarks:"
            }}
          </td>
          <td class="title" v-else>Disapproved remarks:</td>
          <td class="description">
            {{ !transactions.statusRemarks ? "-" : transactions.statusRemarks }}
          </td>
        </tr>
        <tr>
          <td class="title" v-if="transactions.status !== 'Disapproved'">
            {{
              transactions.actionStatus === 2
                ? "Pre-approved date:"
                : "Approved date:"
            }}
          </td>
          <td class="title" v-else>Disapproved date:</td>
          <td class="description">
            {{
              !transactions.dtApproved
                ? "-"
                : dateFormat(transactions.dtApproved, null)
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import SectionOfficialBusinessStatus from "@/components/partials/officialbusiness/AuthOfficialBusinessStatusTracker.vue";
import ModalDestinationDetails from "@/modals/destinationDetailsModal.vue";
import { inject, onMounted, ref } from "vue";
export default {
  components: {
    SectionOfficialBusinessStatus,
    ModalDestinationDetails,
  },
  setup() {
    const store = inject("$store");
    const axios = inject("$axios");
    const moment = inject("$moment");
    const transactions = ref([]);
    const travelDestination = ref(null);
    const isMultiple = ref(false);
    const isModalStatus = ref(false);
    const isRender = ref(true);
    onMounted(() => {
      getData();
    });
    const getData = async () => {
      try {
        const id = store.state.applicationId;
        const response = await axios.get(`api/admin/employee/ob/${id}`);
        transactions.value = response.data[0];
        travelDestination.value = `${response.data[0].obItemDetails[0].purpose} - (${response.data[0].obItemDetails[0].destination})`;
        isMultiple.value =
          response.data[0].obItemDetails.length > 1 ? true : false;
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (start, end) => {
      let modFormat;
      if (end) {
        modFormat = `${moment(start).format("LL")} - ${moment(end).format(
          "LL"
        )}`;
      } else {
        modFormat = `${moment(start).format("LL")} `;
      }

      return modFormat;
    };
    const timeFormat = (start, end) => {
      let modFormat;
      let startTime;
      let endTime;

      if (!end) {
        startTime = `${moment().format("YYYY-MM-DD")} ${start}`;
        modFormat = `${moment(startTime).format("LT")}`;
      } else {
        startTime = `${moment().format("YYYY-MM-DD")} ${start}`;
        endTime = `${moment().format("YYYY-MM-DD")} ${end}`;
        modFormat = `${moment(startTime).format("LT")} - ${moment(
          endTime
        ).format("LT")}`;
      }

      return modFormat;
    };
    const convertTime = (time) => {
      if (time === "00:00:00") {
        return "-";
      } else {
        let modFormat;
        let convertTime = `${moment().format("YYYY-MM-DD")} ${time}`;
        let getHours = moment(convertTime).format("HH");
        if (getHours > 0) {
          modFormat = `${moment(convertTime).format("hh [hrs] mm [mins]")}`;
        } else {
          modFormat = `${moment(convertTime).format("mm [mins]")}`;
        }

        return modFormat;
      }
    };
    return {
      getData,
      timeFormat,
      convertTime,
      transactions,
      travelDestination,
      isMultiple,
      dateFormat,
      isModalStatus,
      isRender,
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;

  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 35px;
}
table > tr .title,
table > tr .description {
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 210px;
  font-family: "Montserrat";
  font-weight: 500;
}
.description {
  width: 360px;
  color: #272626;
}

.grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 40px 0px 20px 0px;
}
.col-status-one,
.col-status-two,
.col-status-three,
.col-status-four {
  background-color: green;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-four {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img,
.col-status-four img {
  width: 50px;
  height: 50px;
}

.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
.header-form {
  padding-bottom: 30px;
  color: var(--lightFont);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}

@media only screen and (max-width: 600px) {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 12px;
    width: 100%;
  }
  table > tr {
    height: 28px;
  }
  table > tr .title,
  table > tr .description {
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 124px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .description {
    max-width: 100%;
    color: #272626;
  }
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0px 30px 0px 30px;
  }

  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four {
    height: 10px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img {
    width: 34px;
    height: 34px;
  }
  .icons {
    height: 70px;
    font-size: 12px;
  }
  .header-form {
    padding-bottom: 30px;
    font-size: 16px;
  }
}
/* Extra Small Devices, Phones */
</style>

<template>
  <div class="auth-content">
    <h1>Vehicle Inventory</h1>

    <div class="auth-grid">
      <div v-for="(item, index) in vehicleList" :key="index">
        <div class="card">
          <div class="auto-avatar">
            <img
              class="avatar"
              :src="require(`@/assets/images/${item.plateNumber}.png`)"
              alt="icon"
            />
          </div>
          <div class="auto-description">
            <div class="auto-title">
              <h3>{{ item.brand }} {{ item.model }}</h3>
            </div>
            <div class="auto-details">
              <div class="card-one">
                <span>
                  <img src="../../../assets/images/gear.png" alt="icon" />
                </span>
                {{ item.transmission }}
              </div>
              <div class="card-one">
                <span>
                  <img src="../../../assets/images/day-off.png" alt="icon" />
                </span>
                {{ item.dayOfCoding }}
              </div>
              <div class="card-two">
                <span>
                  <img src="../../../assets/images/seater.png" alt="icon" />
                </span>
                {{ item.capacity }}
              </div>
              <div class="card-two">
                <span>
                  <img src="../../../assets/images/plate.png" alt="icon" />
                </span>
                {{ item.plateNumber }}
              </div>
            </div>
          </div>
          <div class="auto-btn-availability">
            <input
              type="submit"
              class="submit"
              :class="[
                item.status === 1
                  ? 'available'
                  : item.status === 2
                  ? 'private'
                  : 'maintenance',
              ]"
              :value="
                item.status === 1
                  ? 'Schedule'
                  : item.status === 2
                  ? 'Private Services'
                  : 'Maintenance'
              "
              @click="handlerModal(item, 'SD')"
            />
          </div>
          <div class="auto-btn-details">
            <input
              type="submit"
              class="submit"
              value="Specification"
              @click="handlerModal(item, 'CD')"
            />
            <div v-if="isModalAutoDetails">
              <ModalAutoDetails
                @passClose="isModalAutoDetails = false"
                :itemDetails="itemModified"
              >
              </ModalAutoDetails>
            </div>
          </div>
          <div class="auto-btn-maintenance">
            <input
              type="submit"
              class="submit maintenance"
              value="Repairs & Incident Report"
              @click="handlerModal(item, 'RD')"
            />
            <div v-if="isModalRepairDetails">
              <ModalRepairDetails
                @passClose="isModalRepairDetails = false"
                :itemDetails="itemModified"
              >
              </ModalRepairDetails>
            </div>
          </div>
        </div>
        <div v-if="isModalSchedStatus">
          <ModalScheduleAuto
            @passClose="isModalSchedStatus = false"
            :plateNumber="itemModified.plateNumber"
            :weekDay="itemModified.dayOfCoding"
            :itemDetails="itemModified"
          >
          </ModalScheduleAuto>
        </div>
      </div>

      <div class="card add-event" v-show="false">
        <img
          src="../../../assets/images/add_icon.png"
          alt="icon"
          @click="isModal = true"
        />
      </div>
      <div v-if="isModal">
        <app-modal-calendar @passClose="getValue"></app-modal-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import ModalScheduleAuto from "@/modals/scheduleAutoModal.vue";
import ModalAutoDetails from "@/modals/vechicleDetailsModal.vue";
import ModalRepairDetails from "@/modals/repairsDetailsModal.vue";
import { inject, ref, onActivated } from "vue";
export default {
  components: {
    ModalScheduleAuto,
    ModalAutoDetails,
    ModalRepairDetails,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const vehicleList = ref([]);
    const isModal = ref(false);
    const date = ref(new Date());
    const isModalSchedStatus = ref(false);
    const isModalAutoDetails = ref(false);
    const isModalRepairDetails = ref(false);
    const itemModified = ref([]);

    onActivated(() => {
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        const response = await axios.get(`/api/vehicles/`);
        const itemList = response.data;

        vehicleList.value = itemList.filter((item) => {
          return item.status;
        });
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const handlerModal = (val, code) => {
      itemModified.value = val;

      isModalAutoDetails.value = code === "CD" ? true : false;
      isModalSchedStatus.value = code === "SD" ? true : false;
      isModalRepairDetails.value = code === "RD" ? true : false;
    };

    return {
      vehicleList,
      isModal,
      date,
      isModalSchedStatus,
      isModalAutoDetails,
      isModalRepairDetails,
      dataLoad,
      handlerModal,
      itemModified,
    };
  },
};
</script>
<style scoped>
.auth-content {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  padding: 30px 20px 50px 20px;
  margin-top: 30px;
  font-family: "Montserrat";
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.auth-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 30px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 180px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
}
.auto-description {
  padding-bottom: 20px;
}
.auto-title {
  padding: 20px 0px;
}
.auto-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.auto-btn-availability {
  margin-bottom: 8px;
}
.auto-btn-maintenance {
  margin-top: 8px;
}
.avatar {
  max-width: 100%;
  height: auto;
}
.card-one,
.card-two {
  display: flex;
  line-height: 26px;
  gap: 6px;
  font-size: 14px;
}
.submit {
  background-color: rgb(62, 83, 95);
}
.available {
  background-color: rgb(250, 115, 25);
}
.private {
  background-color: rgb(250, 115, 25);
}
.maintenance {
  background-color: rgb(103, 104, 103);
}
@media only screen and (max-width: 600px) {
  .auth-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: auto;
  }
}
/* Medium Devices, Desktops */
</style>

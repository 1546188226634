<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
      <div class="auth-grid">
        <h4>Repairs & Incident Log</h4>

        <div class="auto-details">
          <table>
            <tr class="flex-card">
              <td>Under Maintenance</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import closeIconSVG from "@/assets/svgs/closeIcon.vue";

export default {
  components: {
    closeIconSVG,
  },
  props: {
    itemDetails: Object,
  },
  setup(props, { emit }) {
    const goRoute = (val) => {
      emit("passClose", val);
    };

    return {
      goRoute,
    };
  },
};
</script>

<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 600px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px 0px 0px;
}
.auto-title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
  padding-bottom: 15px;
}
.auto-details {
  padding: 10px 0px;
}

.route {
  cursor: pointer;
}
.card {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: auto;
  font-family: "Montserrat";
  font-size: 14px;
}
.card .description,
.card .amount {
  font-size: 16px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 18px;
}
table > tr .title,
table > tr .description {
  border: 1px solid #e9bdbd;
  word-break: break-all;
  padding-left: 10px;
}
.flex-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  font-size: 15px;
  font-family: "Montserrat";
}

@media only screen and (max-width: 600px) {
  .modal {
    font-family: "Montserrat";
    background-color: #fff;
    width: 290px;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>{{ titleRequest }}</h1>
        </div>
      </div>
      <form @submit.prevent="handleAction(selectedStatus)">
        <div>
          <div class="form-group">
            <div class="dataTable">
              <table v-if="itemSchedule.length">
                <thead>
                  <tr>
                    <th scope="col">Reschedule</th>
                    <th scope="col">Purpose</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in itemSchedule" :key="index">
                    <td data-label="Purpose">
                      <input
                        type="checkbox"
                        id="chckBox"
                        :value="item.id"
                        v-model="selectedSchedule"
                      />
                    </td>
                    <td data-label="Purpose">
                      {{ item.purpose }}
                      <span class="badge-severity">{{ item.severity }}</span>
                    </td>
                    <td data-label="Time">
                      {{
                        dateFormat(
                          item.dateIntended,
                          item.timeStart,
                          item.timeEnd
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-else>
                <thead>
                  <tr>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Description">No Schedule Today</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="form-group" style="margin-top: 20px">
            <select v-model="selectedStatus" required>
              <option v-for="item in modItemStatus" :key="item">
                {{ item }}
              </option>
            </select>

            <div class="form-group" v-if="selectedStatus === 'Disapproved'">
              <label for=""> Remarks: <span class="required">*</span> </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" required />
              </div>
            </div>
            <div
              class="form-group"
              v-if="selectedStatus === 'Approved' && selectedSchedule.length"
            >
              <label for="">
                Reason to Reschedule: <span class="required">*</span>
              </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" required />
              </div>
            </div>
          </div>
        </div>

        <div class="grid-button">
          <div>
            <input
              :disabled="disabled"
              class="btn approved"
              type="submit"
              value="Submit"
            />
          </div>

          <div>
            <input
              class="btn cancel"
              type="submit"
              value="Cancel"
              @click="closeModal"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { computed, inject, onMounted, ref } from "vue";
export default {
  props: {
    requestId: Number,
    dateIntended: String,
    transaction: Object,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const disabled = ref(false);
    const approverRemarks = ref(null);
    const isToggle = ref(false);
    const selectedStatus = ref(null);
    const isReSched = ref(false);
    const selectedSchedule = ref([]);
    const itemStatus = ref([
      "Approved",
      "Disapproved",
      "Cancelled",
      "Completed",
    ]);
    const itemSchedule = ref([]);
    onMounted(() => {
      let dateIntended = props.dateIntended;
      let itemList = props.transaction;

      itemSchedule.value = itemList
        .filter((val) => {
          return val.dateIntended === dateIntended && val.status === "Approved";
        })
        .sort(function (a, b) {
          var c = new Date(`${a.dateIntended} ${a.timeStart}`);
          var d = new Date(`${b.dateIntended} ${b.timeStart}`);
          return c - d;
        });
    });
    const closeModal = (val, payLoad) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit("passClose", val, payLoad);
    };
    const titleRequest = computed(() => {
      return "Conference Reservation";
    });
    const modItemStatus = computed(() => {
      return itemStatus.value.filter((val) => {
        let status;
        status = val === "Approved" || val === "Disapproved";
        return status;
      });
    });

    const approverAction = async (id) => {
      try {
        await axios.put(`/api/admin/conference/reservation/${id}`, {
          status: selectedStatus.value,
          approverBy: store.state.getUserLog,
          dtapproved: moment().format(),
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (date, start, end) => {
      let startTime = `${date} ${start}`;
      let endTime = `${date} ${end}`;
      let modFormat;

      modFormat = `${moment(startTime).format("LT")} - ${moment(endTime).format(
        "LT"
      )}`;
      return modFormat;
    };
    const updateReschedule = async () => {
      let maxRow = selectedSchedule.value.length;
      for (let i = 1; i <= maxRow; i++) {
        try {
          let id = i;
          await axios.put(`/api/admin/conference/reservation/${id}`, {
            status: "Reschedule",
            approverBy: store.state.getUserLog,
            dtapproved: moment().format(),
          });
        } catch (error) {
          store.commit("errorHandler", error);
        }
      }
    };

    const handleAction = async () => {
      updateReschedule();
      disabled.value = true;
      //confirmation
      Swal.fire({
        title: "Are you sure",
        text: "you want to submit?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          const id = props.requestId;
          approverAction(id);
          updateReschedule();
          swalConfirmation();
        } else {
          disabled.value = false;
        }
      });
    };
    const swalConfirmation = async () => {
      let message;

      message =
        selectedStatus.value === "Approved"
          ? "Reservation was successfully Approved"
          : "Reservation was Disapproved";

      setTimeout(async () => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 6000,
        });
        closeModal(false, true);
      }, 400);
    };

    return {
      closeModal,
      handleAction,
      disabled,
      approverRemarks,
      itemStatus,
      modItemStatus,
      approverAction,
      titleRequest,
      swalConfirmation,
      dateFormat,
      isToggle,
      itemSchedule,
      selectedStatus,
      isReSched,
      updateReschedule,
      selectedSchedule,
    };
  },
};
</script>
<style scoped>
select {
  font-size: 15px;
  font-style: normal;
  font-family: "Montserrat";
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 100%;
  margin-bottom: 15px;
}
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.approved {
  background-color: rgb(25, 179, 102);
}
.disapproved {
  background-color: rgb(219, 86, 53);
}
.cancel {
  background-color: rgb(137, 67, 228);
}
.dv-form {
  padding-top: 20px;
}
.form-cancelled {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 20px;
}
.room-schedule {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  font-size: 16px;
}
.badge-severity {
  margin: 0px 7px;
  font-size: 10px;
  background-color: #f53b0c;
  padding: 6px;
  border-radius: 20px;
  color: white;
  font-variant: small-caps;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 0.65em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.65em;
  text-align: left;
  font-size: 0.9em;
}

table th {
  font-size: 0.65em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
@media only screen and (min-width: 210px) and (max-width: 500px) {
  .modal-container {
    min-width: 200px;
  }
  .modal {
    width: 300px;
    max-width: 100%;
    padding: 30px;
  }
}
</style>

<template>
  <div class="auth-dashboard-grid">
    <div class="card-one">
      <div class="grid-info">
        <h1>Notification</h1>
        <div class="card-count">
          <span v-if="isRender" class="waiting">
            <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
          </span>
          <span v-else>{{ $store.state.approverNotifCounter }}</span>
        </div>
      </div>
    </div>
    <div class="card-two">
      <div class="grid-info">
        <h1>
          {{ cardTwoTitle($store.state.getUserType) }}
        </h1>
        <div class="card-count">
          <span v-if="isRender" class="waiting">
            <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
          </span>
          <span v-else> {{ cardTwoStat($store.state.getUserType) }}</span>
        </div>
      </div>
    </div>
    <div class="card-three">
      <div class="grid-info">
        <h1>
          {{ cardThreeTitle($store.state.getUserType) }}
        </h1>
        <div class="card-count">
          <span v-if="isRender" class="waiting">
            <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
          </span>
          <span v-else> {{ cardThreeStat($store.state.getUserType) }}</span>
        </div>
        <div class="card-footer" v-if="ifRestrict">
          <h6>{{ syncLabel }}</h6>
          <img
            width="30"
            height="30"
            :src="require(`@/assets/images/sync.gif`)"
            alt="icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onActivated, ref } from "vue";
export default {
  components: {},
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const totalNotif = ref(0);
    const activeEmployees = ref(0);
    const attedanceEmployees = ref(0);
    const itemEmployees = ref([]);
    const isRender = ref(true);
    const ifRestrict = ref(false);
    const syncLabel = ref(null);
    onActivated(() => {
      //let userList = ["checker-moderator"];

      let userType = store.state.getUserType;
      if (userType === "super") {
        store.commit("notificationCounterTravel");
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);

        getPaymentRequest();
      }
      if (userType === "head") {
        store.commit("notificationCounterTravel");
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
      }
      if (userType === "moderator") {
        store.commit("notificationCounterTravel");
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
        getPaymentRequest();
      }
      if (userType === "semi-moderator") {
        store.commit("notificationCounterTravel");
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
        getPaymentRequest();
      }
      if (userType === "pre-moderator") {
        store.commit("notificationCounterTravel");
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
        getPaymentRequest();
      }
      if (userType === "post-moderator") {
        getPaymentRequest();
      }
      if (userType === "distributed-moderator") {
        getPaymentRequest();
      }
      if (userType === "dispatch-moderator") {
        getPaymentRequest();
      }
      if (userType === "reactive-moderator") {
        getPaymentRequest();
      }
      if (userType === "checker-moderator") {
        store.commit("notificationCounterReservation");
        getPaymentRequest();
      }
      if (userType === "assurance-moderator") {
        getPaymentRequest();
      }
      if (userType === "driver-moderator") {
        store.commit("notificationCounterTravel");
      }
      if (userType === "operator-moderator") {
        store.commit("notificationCounterTravel");
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
      }
      if (userType === "admin-moderator") {
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
      }
      if (userType === "supervisor-moderator") {
        store.commit("notificationCounterLeave", null);
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
      }
      if (userType === "cohead-moderator") {
        store.commit("notificationCounterOvertime", null);
        store.commit("notificationCounterOfficialBusiness", null);
      }
      getUsers();
    });

    const cardTwoTitle = (userType) => {
      if (userType === "super" || userType === "admin-moderator") {
        return "Total Employees";
      } else if (userType === "driver-moderator") {
        return "Total Completed";
      } else if (userType === "operator-moderator") {
        return "Total Completed";
      } else {
        return "Under Maintenance";
      }
    };

    const cardTwoStat = (userType) => {
      if (userType === "super" || userType === "admin-moderator") {
        return activeEmployees.value;
      } else if (userType === "driver-moderator") {
        return store.state.countTravelCompleted;
      } else if (userType === "operator-moderator") {
        return store.state.countTravelCompleted;
      } else {
        return "NA";
      }
    };

    const cardThreeTitle = (userType) => {
      if (userType === "super" || userType === "admin-moderator") {
        return "Total Attendances";
      } else {
        return "Under Maintenance";
      }
    };

    const cardThreeStat = (userType) => {
      if (userType === "super" || userType === "admin-moderator") {
        ifRestrict.value = true;
        return attedanceEmployees.value;
      } else {
        return "NA";
      }
    };

    const getPaymentRequest = async () => {
      try {
        //const approver = store.state.getUserType;

        const response = await axios.get(`api/form/payrequest`);
        store.commit("notificationCounter", response.data);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const getUsers = async () => {
      try {
        const { data } = await axios.get("/api/getUsers");
        itemEmployees.value = data.filter((item) => {
          return item.isBiotime > 0 && item.isBiotime < 3;
        });
        activeEmployees.value = itemEmployees.value.filter((val) => {
          return val;
        }).length;

        attedanceEmployees.value = itemEmployees.value.filter((val) => {
          return val.ifDtr;
        }).length;

        syncLabel.value = `Last Sync: ${moment().format("lll")}`;
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    return {
      getPaymentRequest,
      getUsers,
      cardTwoTitle,
      cardTwoStat,
      cardThreeTitle,
      cardThreeStat,
      totalNotif,
      activeEmployees,
      attedanceEmployees,
      itemEmployees,
      isRender,
      ifRestrict,
      syncLabel,
    };
  },
};
</script>
<style scoped>
.auth-dashboard-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}
.card-one,
.card-two,
.card-three {
  height: 210px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.grid-info {
  padding: 30px 20px 0px 20px;
}
.grid-info h1 {
  font-size: 28px;
}
.card-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}
.card-count span {
  color: #f8a334;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 60px;
}
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 100;
  font-style: italic;
}
@media only screen and (max-width: 600px) {
  .auth-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>

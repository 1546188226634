<template>
  <div class="auth-content">
    <h1>Calendar Reminders</h1>

    <div class="filter-header-title">
      <h2 v-if="itemModified.length">Upcoming</h2>
      <img
        v-show="false"
        src="../../../assets/images/archive01.png"
        alt="Profile"
      />
    </div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <div v-else>
      <span v-if="!itemModified.length" class="auth-no-calendar">
        <img :src="require(`@/assets/images/no-calendar.png`)" alt="icon" />
        <label for="">No upcoming schedules</label>
      </span>
      <div v-else class="auth-dashboard-grid">
        <div v-for="(item, index) in itemModified" :key="index">
          <div class="card">
            <div class="header-title">
              <div class="dateMonth">
                <h1>{{ dateFormat(item.date, "date") }}</h1>
                <h2>{{ dateFormat(item.date, "month") }}</h2>
                <h5>{{ dateFormat(item.date, "day") }}</h5>
              </div>
              <hr />
              <div class="header-details">
                <h2>{{ convertToWord(item.type, item.awop) }}</h2>
                <div class="card-requester">
                  <div
                    v-for="(item1, index1) in item.mediaLink.slice(0, 5)"
                    :key="index1"
                  >
                    <img
                      class="profile-round"
                      :src="item1.avatar"
                      alt="Profile"
                      @mouseover="avatarUser(index1, index)"
                    />
                  </div>
                </div>
                <div class="card-requester">
                  <div
                    v-for="(item1, index1) in item.mediaLink.slice(5, 10)"
                    :key="index1"
                  >
                    <img
                      class="profile-round"
                      :src="item1.avatar"
                      alt="Profile"
                      @mouseover="avatarUser(index1 + 5, index)"
                    />
                  </div>
                </div>
                <div class="card-requester">
                  <div
                    v-for="(item1, index1) in item.mediaLink.slice(10, 15)"
                    :key="index1"
                  >
                    <img
                      class="profile-round"
                      :src="item1.avatar"
                      alt="Profile"
                      @mouseover="avatarUser(index1 + 10, index)"
                    />
                  </div>
                </div>
                <div class="card-requester">
                  <div
                    v-for="(item1, index1) in item.mediaLink.slice(15, 20)"
                    :key="index1"
                  >
                    <img
                      class="profile-round"
                      :src="item1.avatar"
                      alt="Profile"
                      @mouseover="avatarUser(index1 + 15, index)"
                    />
                  </div>
                </div>
                <div class="card-requester">
                  <div
                    v-for="(item1, index1) in item.mediaLink.slice(20, 25)"
                    :key="index1"
                  >
                    <img
                      class="profile-round"
                      :src="item1.avatar"
                      alt="Profile"
                      @mouseover="avatarUser(index1 + 20, index)"
                    />
                  </div>
                </div>
                <div v-for="(item2, index2) in item.name" :key="index2">
                  <span v-if="selectedIndex === index" class="tooltip">
                    {{ getIndexAvatar === index2 ? item2.user : null }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { inject, ref } from "vue";
export default {
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const itemList = ref([]);
    const isModal = ref(false);
    const monthItems = ref([]);
    const ObjMap = ref([]);
    const getIndexAvatar = ref(0);
    const selectedIndex = ref(-1);
    const itemHolidays = ref([]);
    const isRender = ref(true);
    onMounted(() => {
      getHolidays();
      dataLoad();
    });
    const dataLoad = async () => {
      try {
        let itemData;
        const response = await axios.get(`/api/admin/employee/leave`);
        itemData = response.data.filter((val) => {
          let result;
          if (store.state.getUserType === "admin-moderator") {
            result = val.approverStatus === "Approved";
          } else if (store.state.getUserType === "super") {
            result =
              (val.isDirect || val.department === "Executive Office") &&
              val.approverStatus === "Approved";
          } else if (store.state.getUserType === "moderator") {
            result =
              (val.department === "Marketing" ||
                val.department === "Health Provider & Patient Relations") &&
              val.approverStatus === "Approved";
          } else {
            result =
              val.approverStatus === "Approved" &&
              val.department === store.state.getDepartment &&
              (store.state.getUserType === "head" ||
                store.state.getUserType === "semi-moderator" ||
                store.state.getUserType === "supervisor-moderator" ||
                store.state.getUserType === "pre-moderator");
          }
          return result;
        });

        const res = await axios.get(`api/getUsers`);
        let userList = res.data;

        itemData.filter((val) => {
          let dateStart = moment(val.dateStart);
          let dateEnd = moment(val.dateEnd);

          let user = userList.find((value) => {
            let result;
            if (store.state.getUserType === "admin-moderator") {
              result = value.id === val.empId;
            } else if (store.state.getUserType === "super") {
              result =
                (val.isDirect || val.department === "Executive Office") &&
                value.id === val.empId;
            } else if (store.state.getUserType === "moderator") {
              result =
                (val.department === "Marketing" ||
                  val.department === "Health Provider & Patient Relations") &&
                value.id === val.empId;
            } else {
              result =
                value.id === val.empId &&
                val.department === store.state.getDepartment;
            }

            return result;
          });

          while (dateEnd.isSameOrAfter(dateStart)) {
            var isExist = itemList.value.some(
              (i) =>
                i.date.includes(dateStart.format("YYYY-MM-DD")) &&
                i.type.includes(val.leaveType)
            );
            let getIndex = itemList.value.findIndex(function (item) {
              return (
                item.date === dateStart.format("YYYY-MM-DD") &&
                item.type === val.leaveType
              );
            });

            if (isExist) {
              itemList.value[getIndex].mediaLink.push({
                avatar: user.mediaLink,
              });
              itemList.value[getIndex].name.push({
                user: val.requestedBy,
              });
            } else {
              itemList.value.push({
                date: dateStart.format("YYYY-MM-DD"),
                id: val.id,
                type: val.leaveType,
                name: [{ user: val.requestedBy }],
                department: val.department,
                isDirect: val.isDirect,
                awop: val.awopType,
                workSched: user.maintenanceScheduleAttendanceId,
                mediaLink: [
                  {
                    avatar: user.mediaLink,
                  },
                ],
                monthYear: moment(dateStart).format("MMMM YYYY"),
              });
            }

            dateStart = dateStart.add(1, "day");
          }
        });
        isRender.value = false;
      } catch (err) {
        store.commit("errorHandler", err);
      }
    };
    const itemModified = computed(() => {
      let start = moment().format("YYYY-MM-DD");

      //let end = "2023-08-31";
      let sat = "Saturday";
      let sun = "Sunday";
      return itemList.value
        .filter((val) => {
          return val.date >= start;
        })
        .sort((a, b) => {
          var c = new Date(`${a.date}`);
          var d = new Date(`${b.date}`);
          return c - d;
        })
        .filter((val) => {
          if (store.state.getUserType === "super") {
            return val.isDirect || val.department === "Executive Office";
          } else if (store.state.getUserType === "admin-moderator") {
            return val;
          } else if (store.state.getUserType === "moderator") {
            return (
              val.department === "Marketing" ||
              val.department === "Health Provider & Patient Relations"
            );
          } else if (val.department === store.state.getDepartment) {
            return val;
          }
        })
        .filter((val) => {
          if (val.workSched === 2) {
            return val && !itemHolidays.value.includes(val.date);
          } else {
            return (
              moment(val.date).format("dddd") !== sat &&
              moment(val.date).format("dddd") !== sun &&
              !itemHolidays.value.includes(val.date)
            );
          }
        });
    });
    const convertToWord = (val, subVal) => {
      let abbreviation = val;
      let awopAbbrev = subVal;
      let word;
      if (val) {
        if (abbreviation === "SL") {
          word = "Sick Leave";
        }
        if (abbreviation === "VL") {
          word = "Vacation Leave";
        }
        if (abbreviation === "EL") {
          word = "Emergency Leave";
        }
        if (abbreviation === "AWOP") {
          let subWord;
          if (awopAbbrev === "AWOPSL") {
            subWord = "(SL)";
          }
          if (awopAbbrev === "AWOPVL") {
            subWord = "(VL)";
          }
          word = `AWOP - ${subWord}`;
        }
        if (abbreviation === "CL") {
          word = "Compassionate Leave";
        }
        if (abbreviation === "ML") {
          word = "Maternity Leave";
        }
        if (abbreviation === "PL") {
          word = "Paternity Leave";
        }
        if (abbreviation === "BL") {
          word = "Birthday Leave";
        }
        if (abbreviation === "SPL") {
          word = "Solo Parent Leave";
        }
        if (abbreviation === "MCW") {
          word = "Magna Carta for Women";
        }
        if (abbreviation === "VAWC") {
          word = "Violence Againts Women and their Children";
        }
        return word;
      }
    };
    const dateFormat = (value, key) => {
      let result;
      if (key === "date") {
        result = moment(value).format("DD");
      }
      if (key === "month") {
        result = moment(value).format("MMM");
      }
      if (key === "day") {
        result = moment(value).format("ddd");
      }
      return result;
    };
    const insertPerMonth = () => {
      let itemMod = itemList.value;

      itemMod.forEach((element) => {
        var makeKey = element.monthYear;
        if (!ObjMap.value[makeKey]) {
          ObjMap.value[makeKey] = [];
        }
        ObjMap.value[makeKey].push({
          date: element.date,
          id: element.id,
          type: element.type,
          name: element.name,
          department: element.department,
          isDirect: element.isDirect,
          awop: element.awopType,
          mediaLink: element.mediaLink,
        });
      });
      ObjMap.value = Object.groupBy(itemMod, (item) => item.monthYear);
    };

    const avatarUser = (indexOne, index) => {
      getIndexAvatar.value = indexOne;
      selectedIndex.value = index;
    };
    const getHolidays = async () => {
      try {
        const { data } = await axios.get("/api/form/holidays");
        let itemData = data;
        itemData.forEach((item) => {
          itemHolidays.value.push(item.dbDate);
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      itemList,
      monthItems,
      ObjMap,
      isModal,
      dataLoad,
      convertToWord,
      dateFormat,
      insertPerMonth,
      itemModified,
      avatarUser,
      getIndexAvatar,
      selectedIndex,
      getHolidays,
      itemHolidays,
      isRender,
    };
  },
};
</script>
<style scoped>
.auth-content {
  background-color: white;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
  border-radius: 10px;
  padding: 30px 20px 50px 20px;
  margin-top: 30px;
}
.auth-content h1 {
  color: var(--lightSecondary);
  font-family: "Montserrat";
}
.auth-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}
.card {
  padding: 20px 20px;
  border-radius: 5px;
  max-width: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  font-family: "Poppins";
}
.filter-header-title {
  padding: 15px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.filter-header-title h2 {
  font-family: "Montserrat";
  color: #747575;
  font-size: 18px;
  font-weight: 500;
  margin-top: 18px;
}

.header-title {
  display: flex;
  gap: 14px;
}
.dateMonth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header-title h1 {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 600;
  color: #3e535f;
}
.header-title h2 {
  font-family: "Montserrat";
  font-size: 21px;
  font-weight: 400;
  color: var(--lightSecondary);
}
.header-details h2 {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  color: #fa7319;
}

.header-details img {
  margin: 10px 0px;
  height: 34px;
  width: 34px;
  cursor: pointer;
}
.card-requester {
  display: flex;
  gap: 5px;
  flex-direction: row;
}
.card-requester h4 {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  color: #636261;
  padding: 10px 0px;
  margin-top: 8px;
  margin-left: 5px;
}
.tooltip {
  position: relative;
  top: -10px;
  font-size: 14px;
  font-weight: 600;
}
.dateMonth h5 {
  padding-top: 2px;
  color: #fa7319;
}
.auth-no-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  color: #636261;
}
.auth-no-calendar img {
  height: 120px;
  width: 120px;
}
@media only screen and (max-width: 600px) {
  .auth-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: auto;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .header-details img {
    margin: 10px 0px;
    height: 27px;
    width: 27px;
    cursor: pointer;
  }
  .tooltip {
    font-size: 12px;
  }
}
/* Medium Devices, Desktops */
</style>

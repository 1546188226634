<template>
  <app-auth-title />
  <div class="admin-content">
    <div class="admin-grid">
      <div class="search-bar">
        <input
          type="text"
          placeholder="search..."
          v-model="searchValue"
          @input="getFilterAction(selectedEntry)"
        />
      </div>
      <div class="show-entries">
        Show
        <select
          v-model="selectedEntry"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in showEntries" :key="item">
            {{ item }}
          </option>
        </select>
        Entries
      </div>
      <div class="filter">
        Filter by date requested:
        <select
          v-model="selectedFilter"
          class="item-entries"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterDate" :key="item">
            {{ item }}
          </option>
        </select>
        <select
          v-show="$store.state.getUserType !== 'driver-moderator'"
          v-model="selectedDriver"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option>All</option>
          <option v-for="item in driverList" :key="item">
            {{ item.name }}
          </option>
        </select>
        <select
          v-show="$store.state.getUserType !== 'driver-moderator'"
          v-model="selectedStatus"
          class="item-entries md-status"
          @change="getFilterAction(selectedEntry)"
        >
          <option v-for="item in filterStat" :key="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="payment-grid">
        <div v-if="isNotFound">
          <h3>{{ messageNotFound }}</h3>
        </div>
        <div v-if="isRender" class="waiting">
          <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
        </div>
        <div v-else v-for="(transaction, index) in filterItem" :key="index">
          <div class="admin-card accordion">
            <div style="display: flex">
              <div class="accordion-content">
                <div>
                  <h4>
                    {{ transaction.travelItemDetails[0].purpose }} - ({{
                      transaction.travelItemDetails[0].destination
                    }})
                  </h4>
                </div>
                <div>
                  <span class="card-date"
                    >Date Intended:
                    {{ dateFormat(transaction.dateIntended) }}</span
                  >
                </div>
                <div v-if="$store.state.getUserType === 'driver-moderator'">
                  <span class="card-date"
                    >Requested by: {{ transaction.requestedBy }}</span
                  >
                </div>
                <div v-else>
                  <span class="card-date"
                    >Date Requested:
                    {{ dateFormat(transaction.dateFiled) }}</span
                  >
                </div>
                <div v-if="$store.state.getUserType === 'driver-moderator'">
                  <span class="card-date"
                    >Vehicle: {{ transaction.vehicle }}</span
                  >
                </div>
                <div v-else>
                  <span class="card-date"
                    >Requested by: {{ transaction.requestedBy }}</span
                  >
                </div>
              </div>
            </div>

            <div>
              <input
                v-if="$store.state.getUserType === 'operator-moderator'"
                @click="routeModal(index)"
                type="submit"
                :value="
                  transaction.status === 'Checked' || !transaction.status
                    ? 'Pending'
                    : transaction.status
                "
                class="btn-status"
                :class="[
                  transaction.status === 'Checked' || !transaction.status
                    ? 'status-pending'
                    : transaction.status === 'Approved' ||
                      transaction.status === 'Transferred'
                    ? 'status-approved'
                    : transaction.status === 'Completed'
                    ? 'status-completed'
                    : 'status-declined ',
                ]"
              />
              <input
                v-else
                @click="routeModal(index)"
                type="submit"
                :value="
                  !transaction.status
                    ? 'Pending'
                    : transaction.status === 'Checked'
                    ? 'Forwarded to Operation'
                    : transaction.status === 'Transferred'
                    ? 'Approved'
                    : transaction.status
                "
                class="btn-status"
                :class="[
                  !transaction.status
                    ? 'status-pending'
                    : transaction.status === 'Approved' ||
                      transaction.status === 'Transferred'
                    ? 'status-approved'
                    : transaction.status === 'Checked'
                    ? 'status-approved'
                    : transaction.status === 'Completed'
                    ? 'status-completed'
                    : 'status-declined ',
                ]"
              />
            </div>
          </div>
          <div
            class="panel"
            v-if="index === indexSelected && ifExpand ? true : false"
          >
            <SectionTravelStatus
              :transaction="transaction"
            ></SectionTravelStatus>
            <div class="panel-payment-details">
              <SectionTravelDetails
                :transaction="transaction"
              ></SectionTravelDetails>
              <div class="btn-action-container">
                <input
                  v-if="actionApprover(transaction)"
                  class="btn btn-action"
                  type="submit"
                  value="Action"
                  @click="isModal = true"
                />
                <input
                  class="btn btn-close"
                  type="submit"
                  value="Close"
                  @click="ifExpand = false"
                />
              </div>
              <div v-if="isModal">
                <app-modal-travel-approval
                  :requestId="transaction.id"
                  :codingDay="dayFormat(transaction.dateIntended)"
                  :status="transaction.status"
                  :dateIntended="transaction.dateIntended"
                  :timeIntended="transaction.timeIntended"
                  @passClose="getValue"
                ></app-modal-travel-approval>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-record">
          <div>Total Record(s): {{ total_record }}</div>
        </div>
        <div class="pagination">
          <div class="previous">
            <input
              type="submit"
              class="prev-btn btn"
              value="Previous"
              @click="prevRecords(current_page)"
            />
          </div>

          <div class="pages">
            <span
              >Page {{ total_record ? current_page : 0 }} of
              {{ total_page }}
            </span>
          </div>
          <div class="next">
            <input
              :disabled="total_page === current_page ? true : false"
              type="submit"
              class="nxt-btn btn"
              value="Next"
              @click="nextRecords(current_page)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTravelDetails from "@/components/partials/travel/AuthTravelDetails.vue";
import SectionTravelStatus from "@/components/partials/travel/AuthTravelStatusTracker.vue";
import { ref, computed, onActivated } from "vue";
import { inject } from "vue";
export default {
  components: {
    SectionTravelDetails,
    SectionTravelStatus,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModal = ref(false);
    const ifExpand = ref(false);
    const indexSelected = ref(0);
    const searchValue = ref("");
    const current_page = ref(1);
    const per_page = ref(10);
    const total_record = ref(0);
    const total_page = ref(0);
    const modified = ref([]);
    const messageNotFound = ref("");
    const isNotFound = ref(false);
    const showEntries = ref([5, 10, 25, 50]);
    const isRender = ref(true);
    const showFilterDate = ref([
      "Today",
      "Incoming",
      "Last 7 Days",
      "This Month",
      "Last Month",
      "Year to Date",
      "All Time",
    ]);
    const showFilterStatus = ref([
      "All",
      "Pending",
      "Approved",
      "Disapproved",
      "Cancelled",
      "Completed",
    ]);
    const selectedEntry = ref(5);
    const total_amount = ref(0);
    const transactions = ref([]);
    const travelItemList = ref([]);
    const selectedFilter = ref("All Time");
    const selectedStatus = ref("Pending");
    const selectedDriver = ref("All");
    const expandPanel = ref(false);
    const driverList = ref([]);
    const filterItem = computed(() => {
      return modified.value;
    });
    const filterStat = computed(() => {
      return showFilterStatus.value.filter((stat) => {
        return stat;
      });
    });
    const filterDate = computed(() => {
      return showFilterDate.value.filter((stat) => {
        if (store.state.getUserType === "driver-moderator") {
          stat = stat === "All Time" || stat === "Today" || stat === "Incoming";
        } else {
          stat = stat !== "Incoming";
        }
        return stat;
      });
    });
    onActivated(() => {
      selectedFilter.value =
        store.state.getUserType === "driver-moderator" ? "Today" : "All Time";
      getTravelRequest();
      driverItem();
    });

    const routeModal = (val) => {
      indexSelected.value = val;
      ifExpand.value = !ifExpand.value;
    };
    const actionApprover = (val) => {
      if (store.state.getUserType === "operator-moderator") {
        return (
          val.status !== "Completed" &&
          val.status !== "Disapproved" &&
          val.status !== "Cancelled" &&
          val.status !== "Revert"
        );
      } else if (store.state.getUserType === "driver-moderator") {
        return val.status === "Approved" || val.status === "Transferred";
      } else {
        return !val.status;
      }
    };
    const getValue = (val, payload) => {
      isModal.value = val;
      if (payload) {
        setTimeout(async () => {
          getTravelRequest();
          expandPanel.value = false;
          ifExpand.value = false;
        }, 500);
      }
    };

    const getFilterAction = (val) => {
      let selectedEntry = val;
      travelItemList.value = transactions.value
        .filter((post) => {
          return post.requestedBy
            .toLowerCase()
            .includes(searchValue.value.toLowerCase());
        })
        .filter((val) => {
          let dateSelected;
          const currentDate = store.state.currentDate;
          const lastSevenDays = moment(currentDate)
            .subtract(7, "days")
            .format("YYYY-MM-DD");

          const thisMonth = moment(currentDate)
            .subtract(moment().format("DD"), "days")
            .format("YYYY-MM-DD");

          const lastMonth = moment(currentDate)
            .subtract(1, "months")
            .format("YYYY-MM");

          const yearDate = moment(currentDate).format("YYYY");

          if (store.state.getUserType === "driver-moderator") {
            switch (selectedFilter.value) {
              case "Today":
                dateSelected = val.dateIntended === currentDate;
                break;
              case "Incoming":
                dateSelected = val.dateIntended > currentDate;
                break;

              default:
                dateSelected = val;
            }

            return dateSelected;
          } else {
            switch (selectedFilter.value) {
              case "Today":
                dateSelected = val.dateFiled === currentDate;
                break;
              case "Last 7 Days":
                dateSelected = val.dateFiled > lastSevenDays;
                break;
              case "This Month":
                dateSelected = val.dateFiled > thisMonth;
                break;
              case "Last Month":
                dateSelected =
                  moment(val.dateFiled).format("YYYY-MM") === lastMonth;
                break;
              case "Year to Date":
                dateSelected =
                  moment(val.dateFiled).format("YYYY") === yearDate;
                break;
              default:
                dateSelected = val;
            }

            return dateSelected;
          }
        })
        .filter((role) => {
          let userLogIn = store.state.getUserType;
          let userType;

          switch (userLogIn) {
            case "operator-moderator":
              userType =
                role.department === "Corporate Services" ||
                role.department === "Astral Clinic" ||
                role.department === "Muntinlupa Clinic" ||
                role.checkedBy;
              break;
            case "moderator":
              userType =
                (role.department === "Health Provider & Patient Relations" ||
                  role.department === "Marketing") &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "pre-moderator":
              userType =
                role.department === "Accounting" &&
                role.requestedBy !== store.state.getUserLog;
              break;
            case "super":
              userType =
                role.department === "Executive Office" || role.isDirect;
              break;
            case "driver-moderator":
              userType = role.driver === store.state.getUserLog;
              break;
            case "head":
              userType =
                role.department === store.state.getDepartment &&
                role.requestedBy !== store.state.getUserLog;

              break;
            default:
              userType;
          }
          return userType;
        })
        .filter((stat) => {
          let status;

          if (selectedStatus.value === "All") {
            status = stat;
          } else if (selectedStatus.value === "Pending") {
            if (store.state.getUserType === "operator-moderator") {
              status = stat.status === "Checked" || !stat.status;
            } else if (store.state.getUserType === "moderator") {
              status = !stat.status;
            } else if (store.state.getUserType === "driver-moderator") {
              status =
                stat.status === "Approved" ||
                stat.status === "Completed" ||
                stat.status === "Transferred";
            } else {
              status = !stat.status;
            }
          } else if (selectedStatus.value === "Approved") {
            status =
              stat.status === "Approved" || stat.status === "Transferred";
          } else {
            status = stat.status === selectedStatus.value;
          }
          return status;
        })
        .filter((item) => {
          let driver = selectedDriver.value;
          let result;
          if (driver === "All") {
            result = item;
          } else {
            result = driver === item.driver;
          }

          return result;
        });
      pagination(0, selectedEntry);
    };
    const pagination = (page, pr_page) => {
      let data = travelItemList.value;
      current_page.value = page || 1;
      per_page.value = pr_page || 5;
      const offset = (current_page.value - 1) * per_page.value;
      const paginatedItems = data.slice(offset).slice(0, per_page.value);
      total_page.value = Math.ceil(data.length / per_page.value);
      modified.value = paginatedItems;
      isNotFound.value = !modified.value.length ? true : false;
      messageNotFound.value = searchValue.value
        ? `We couldn't find anything for ${searchValue.value.toLowerCase()}. Try a new search.`
        : `No transactions yet. Want to try again with different status?`;

      total_record.value = data.length;
    };
    const prevRecords = (val) => {
      let page = val - 1 ? val - 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const nextRecords = (val) => {
      let page = total_page.value > val ? val + 1 : null;
      let currentEntryPage = selectedEntry.value;
      pagination(page, currentEntryPage);
    };
    const getTravelRequest = async () => {
      try {
        const response = await axios.get(`/api/admin/travel/request`);
        isNotFound.value = response.data.length ? false : true;
        transactions.value = response.data;
        store.commit("notificationCounterTravel");
        getFilterAction();
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const driverItem = async () => {
      try {
        const response = await axios.get("/api/getUsers");
        const items = response.data;
        driverList.value = items.filter((item) => {
          return item.designation === "Company Driver";
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val) => {
      return val ? moment(val).format("ll") : "-";
    };
    const dayFormat = (val) => {
      return val ? moment(val).format("dddd") : "-";
    };

    return {
      isModal,
      showEntries,
      selectedEntry,
      searchValue,
      transactions,
      travelItemList,
      selectedStatus,
      showFilterStatus,
      ifExpand,
      indexSelected,
      filterItem,
      current_page,
      per_page,
      total_page,
      total_record,
      modified,
      messageNotFound,
      isNotFound,
      total_amount,
      selectedFilter,
      showFilterDate,
      expandPanel,
      filterStat,
      filterDate,
      prevRecords,
      nextRecords,
      actionApprover,
      getFilterAction,
      routeModal,
      pagination,
      getTravelRequest,
      dateFormat,
      dayFormat,
      getValue,
      driverList,
      driverItem,
      selectedDriver,
      isRender,
    };
  },
};
</script>
<style scoped>
.status-approved {
  background-color: #3496e6;
}

.status-approved:hover,
.status-approved:focus {
  background-color: #3496e6;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-approved:active {
  background-color: #0073df;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-pending {
  background-color: #f09409;
}

.status-completed {
  background-color: #1d9405;
}
.status-completed:hover,
.status-completed:focus {
  background-color: #1d9405;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-completed:active {
  background-color: #0e5f04;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.status-declined {
  background-color: #ad1105;
}
.status-declined:hover,
.status-declined:focus {
  background-color: #ad1105;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}
.status-declined:active {
  background-color: #81130b;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
</style>

<template>
  <div class="cpanel">
    <table>
      <tr>
        <td class="title">Reference Number:</td>
        <td class="description">{{ transaction.id + 100000 }}</td>
      </tr>
      <tr>
        <td class="title">Leave Type:</td>
        <td class="description">
          {{ convertToWord(transaction.leaveType, null) }}
          <span v-if="transaction.leaveType === 'AWOP'">{{
            convertToWord(null, transaction.awopType)
          }}</span>
        </td>
      </tr>
      <tr>
        <td class="title">Leave Category:</td>
        <td class="description">
          {{ transaction.leaveCategory }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Intended:</td>
        <td class="description">
          {{ dateFormat(transaction.dateStart, transaction.dateEnd) }}
        </td>
      </tr>
      <tr>
        <td class="title">Date Resume:</td>
        <td class="description">
          {{ dateFormat(transaction, null) }}
        </td>
      </tr>
      <tr>
        <td class="title">Total Durations:</td>
        <td class="description">
          {{ `${transaction.numberDays} day(s)` }}
        </td>
      </tr>
      <tr>
        <td class="title">Reason:</td>
        <td class="description">
          {{ transaction.reason }}
        </td>
      </tr>
      <tr>
        <td class="title">Requested by:</td>
        <td class="description">
          {{ transaction.requestedBy }}
        </td>
      </tr>
      <tr>
        <td class="title">Requested date:</td>
        <td class="description">
          {{ dateFormat(transaction.createdAt, null) }}
        </td>
      </tr>
      <tr>
        <td class="title">Status:</td>
        <td class="description">
          {{
            !transaction.approverStatus ? "Pending" : transaction.approverStatus
          }}
        </td>
      </tr>
      <tr>
        <td class="title">Approved by:</td>
        <td class="description">
          {{ !transaction.approverBy ? "-" : transaction.approverBy }}
        </td>
      </tr>
      <tr>
        <td class="title">Approver Remarks:</td>
        <td class="description">
          {{ !transaction.approverRemarks ? "-" : transaction.approverRemarks }}
        </td>
      </tr>
      <tr>
        <td class="title">
          {{
            transaction.approverStatus === "Approved" ||
            transaction.approverStatus === "Pre-approved"
              ? "Approved Date:"
              : "Disapproved Date:"
          }}
        </td>
        <td class="description" v-if="transaction.approverDtAdded">
          {{
            !transaction.approverDtAdded
              ? "-"
              : dateFormat(transaction.approverDtAdded, null)
          }}
        </td>
        <td class="description" v-else>
          {{
            !transaction.superiorDtAdded
              ? "-"
              : dateFormat(transaction.superiorDtAdded, null)
          }}
        </td>
      </tr>
      <tr>
        <td class="title">Attachment File</td>
        <td class="description">
          <div
            style="display: flex; gap: 5px; cursor: pointer"
            @click="download(transaction.filename)"
          >
            <span>
              <img src="../../../assets/images/link_.png" alt="icon"
            /></span>

            {{ !transaction.filename ? "No attachment" : transaction.filename }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { ref, onMounted, inject } from "vue";
export default {
  components: {},
  props: {
    transaction: Object,
  },
  setup() {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const isModalStatus = ref(false);
    onMounted(() => {
      if (screen.width <= 600) {
        window.scrollTo(0, 0);
      }
    });
    const dateFormat = (start, end) => {
      let modFormat;

      if (end) {
        modFormat = `${moment(start).format("LL")} - ${moment(end).format(
          "LL"
        )}`;
        return modFormat;
      } else {
        if (start.dateResume) {
          modFormat = `${moment(start.dateResume).format("LL")} `;
        } else {
          modFormat = `${moment(start).format("LLL")} `;
        }
        return modFormat;
      }
    };
    const convertToWord = (val, subVal) => {
      let abbreviation = val;
      let awopAbbrev = subVal;
      let word;
      if (val) {
        if (abbreviation === "SL") {
          word = "Sick Leave";
        }
        if (abbreviation === "VL") {
          word = "Vacation Leave";
        }
        if (abbreviation === "EL") {
          word = "Emergency Leave";
        }
        if (abbreviation === "AWOP") {
          word = "Absent without pay";
        }
        if (abbreviation === "CL") {
          word = "Compassionate Leave";
        }
        if (abbreviation === "ML") {
          word = "Maternity Leave";
        }
        if (abbreviation === "PL") {
          word = "Paternity Leave";
        }
        if (abbreviation === "BL") {
          word = "Birthday Leave";
        }
        if (abbreviation === "SPL") {
          word = "Solo Parent Leave";
        }
        if (abbreviation === "MCW") {
          word = "Magna Carta for Women";
        }
        if (abbreviation === "VAWC") {
          word = "Violence Againts Women and their Children";
        }
        return word;
      } else {
        if (awopAbbrev === "AWOPSL") {
          word = "(Sick Leave)";
        }
        if (awopAbbrev === "AWOPVL") {
          word = "(Vacation Leave)";
        }
        return word;
      }
    };
    const download = async (val) => {
      const filename = val;
      try {
        const response = await axios.get(
          `api/media/meta/resources/files/${filename}`
        );
        //let url = response.data.mediaLink;

        let fileName = response.data.name;
        let httpUrl = "https://storage.googleapis.com/iwc-data-files/";
        let url = `${httpUrl}${fileName}`;
        window.open(url, "_blank");
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      isModalStatus,
      dateFormat,
      convertToWord,
      download,
    };
  },
};
</script>
<style scoped>
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #666;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  border: 1px solid #666;
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 230px;
  font-family: "Montserrat";
  font-weight: 800;
  color: #474646;
}
.description {
  max-width: 100%;
  color: #272626;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 14px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
@media only screen and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 12px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    border: 1px solid #666;
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 160px;
    font-weight: 500;
    color: #272626;
  }
}
</style>

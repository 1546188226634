<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="handleSubmit()">
      <div class="form1">
        <div class="form-group">
          <label for="">
            Search Reference Number: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="text"
              v-model="formData.referenceNumber"
              @input="searchReferenceNumber()"
            />
          </div>
          <div
            class="payee-container"
            v-if="
              formData.referenceNumber && referenceItemList.length
                ? true
                : false
            "
          >
            <ul>
              <li
                v-for="(reference, index) in referenceItemList.slice(0, 10)"
                :key="index"
                @click="selectedReference(reference.id + 1000000, index)"
              >
                {{ reference.id + 1000000 }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for=""> Date Filed: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="formData.dateFiled" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Date Intended: <span class="required">*</span> </label>
          <div class="input">
            <input type="date" v-model="formData.dateIntended" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Payee: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="formData.payee" disabled />
          </div>
          <div
            class="payee-container"
            v-if="formData.payee && referenceItemList.length ? true : false"
          >
            <ul>
              <li
                v-for="payee in referenceItemList.slice(0, 10)"
                :key="payee.name"
                @click="selectedPayee(payee.name)"
              >
                {{ payee.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Purpose / Description: <span class="required">*</span>
          </label>
          <div class="input">
            <textarea rows="5" v-model="formData.remarks" disabled />
          </div>
        </div>

        <div class="autotext">
          <div class="accountype-title">
            <div>
              <label for="">Expense Details</label>
            </div>
            <img
              v-if="iconAction"
              @click="enabledMode"
              class="image-button"
              src="../../assets/images/edit_icon.png"
              alt="icon"
            />
            <img
              v-else
              @click="addTextbox"
              class="image-button"
              src="../../assets/images/add_.png"
              alt="icon"
            />
          </div>
          <div class="accountype-subtitle">
            <div>
              <label for="">Account Type:</label>
            </div>
            <div>
              <label for="">Amount:</label>
            </div>
          </div>
          <div
            class="form-group"
            v-for="(expense, index) in expenseDetails"
            :key="index"
          >
            <select
              v-if="getIndexExistingExpenses.includes(index)"
              ref="textbox"
              v-model="expense.name"
              @change="selectedExpense(expense.name)"
              required
              disabled
            >
              <option v-for="post in itemAccountType" :key="post.id">
                {{ post.description }}
              </option>
            </select>
            <select
              v-else
              ref="textbox"
              v-model="expense.name"
              @change="selectedExpense(expense.name)"
              required
              :disabled="disableStat"
            >
              <option v-for="post in itemAccountType" :key="post.id">
                {{ post.description }}
              </option>
            </select>
            <input
              type="number"
              min="0"
              :max="
                !getIndexExistingExpenses.includes(index) &&
                index === currentIndex
                  ? maxAmount
                  : null
              "
              step="any"
              v-model="expense.amount"
              @input="getTotal(expense.amount, index)"
              :disabled="disableStat"
              style="text-align: right"
            />
            <div>
              <img
                v-if="!getIndexExistingExpenses.includes(index)"
                class="image-button"
                src="../../assets/images/remove_.png"
                alt="icon"
                @click="removeExpense(index)"
              />
            </div>
          </div>
          <div class="amount-container">
            <div class="amount-container-details">
              <label for=""> Total Actual Amount : </label>
            </div>

            <div class="amount-container-details">
              <label for="">
                {{ amountFormat(formData.total) }}
              </label>
            </div>
          </div>
          <div v-if="!disableStat" class="amount-container">
            <div class="amount-container-details">
              <label for=""> Total Advances : </label>
            </div>
            <div class="amount-container-details">
              <label for="">
                {{ amountFormat(cashAdvanceOrig) }}
              </label>
            </div>
          </div>
          <div v-if="!disableStat" class="amount-container">
            <div class="amount-container-details">
              <label for="">
                {{ amountFormat(totalAmountLiquidateLabel) }}
              </label>
            </div>
            <div class="amount-container-details">
              <label for="">
                {{ amountFormat(totalAmountLiquidate) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for=""> Requested by: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="formData.requestedBy" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Attachment <span class="required">*</span> </label>
          <div class="fileinput input">
            <input ref="fileData" type="file" @change="selectFile()" />
          </div>
          <div v-if="!fileData"></div>
          <div>
            <h5 style="color: red">Note: Maximum upload file size: 5 MB</h5>
          </div>
          <div v-if="$store.state.errorFileSize" style="padding-top: 20px">
            <div class="alert-error form-alert">
              {{ $store.state.errorFileSize }}
            </div>
          </div>
          <div v-if="isAlert" style="padding-top: 20px">
            <div class="alert-error form-alert">
              {{ $store.state.errorMessage }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="submit">Update</button>
        </div>
        <div v-show="isAlert">
          <div v-show="isStatus" class="alert-error form-alert">
            {{ $store.state.formErrorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive, ref, onMounted, computed } from "vue";
import { inject } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  setup(props, { emit }) {
    const store = inject("$store");
    const isVisible = ref(false);
    const isAlert = ref(false);
    const isStatus = ref(false);
    const posts = ref([]);
    const empName = ref([]);
    const recValue = ref(null);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const liquidatedModFilename = ref(null);
    const formData = reactive({
      referenceNumber: null,
      employeeID: store.state.getEmployeeId,
      dateFiled: moment().format("YYYY-MM-DD"),
      dateIntended: null,
      payee: null,
      remarks: null,
      total: "0",
      requestedBy: null,
      approver: "semi-moderator",
      department: store.state.getDepartment,
      ifrecurring: "1",
      ifCashAdvance: false,
      filename: null,
      liquidatedModFilename: null,
    });
    const totalAmountLiquidateLabel = ref("Total:");
    const totalAmountLiquidate = ref(0);
    const cashAdvanceOrig = ref(0);
    const disableStat = ref(true);
    const iconAction = ref(true);
    const maxAmount = ref(null);
    const currentIndex = ref(0);
    const addCount = ref(0);
    const getIndexExistingExpenses = ref([]);
    const referenceList = ref([]);
    const referenceItemList = ref([]);
    const itemAccountType = computed(() => {
      let result;
      return posts.value.filter((post) => {
        let userDepartment = store.state.getDepartment;
        if (userDepartment === "Accounting") {
          result = post;
        } else if (userDepartment === "Corporate Services") {
          result = post.filterBy !== "ACCOUNTING";
        } else {
          result = post.filterBy === "GENERAL";
        }
        return result;
      });
    });
    const insertFormData = async () => {
      try {
        let id = formData.referenceNumber - 1000000;
        await axios.put(`api/form/payrequest/${id}`, {
          liquidationActualAmount: formData.total,
          liquidationDated: moment(),
          liquidatedStatus: null,
          liquidatedAttachment: formData.filename,
          liquidatedModFilename: liquidatedModFilename.value,
          lastModifiedBy: store.state.getUserLog,
        });
        isAlert.value = false;
        //uploadHandler();
        bulkInsert(id);
        const SEQUENCE_ARRAY_CODE = 3;
        store.commit("successAlert", SEQUENCE_ARRAY_CODE);
        clearFormField(true);
        setTimeout(async () => {
          getReferenceNumber();
        }, 2000);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          formData.referenceNumber = null;
          formData.dateFiled = moment().format("YYYY-MM-DD");
          formData.dateIntended = null;
          formData.payee = null;
          formData.remarks = null;
          formData.total = 0;
          formData.ifCashAdvance = false;
          formData.requestedBy = store.state.getUserLog;
          expenseDetails.value.splice(1, expenseDetails.value.length);
          expenseDetails.value[0].name = null;
          expenseDetails.value[0].amount = 0;
          fileData.value.value = null;
          disableStat.value = true;
          totalAmountLiquidate.value = 0;
          totalAmountLiquidateLabel.value = "Total:";
          iconAction.value = true;
          liquidatedModFilename.value = null;
        }, 1100);
      }
    };
    const handleValidation = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (
          key !== "ifCashAdvance" &&
          key !== "total" &&
          key !== "liquidatedModFilename"
        ) {
          if (!value) {
            validate.push(key);
          }
        }
      });
      if (validate.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else if (formData.total > cashAdvanceOrig.value + 1000) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `You have exceed the limit amount, To proceed please input maximum 1000 reimbursable amount`;
      } else if (store.state.errorFileSize) {
        store.state.errorFileSize;
      } else {
        Swal.fire({
          title: "Are you sure you",
          text: "want to submit this request?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            if (liquidatedModFilename.value) {
              uploadHandler();
            } else {
              insertFormData();
            }
            emit("isLoaded");
          } else {
            isAlert.value = false;
            isStatus.value = false;
          }
        });
      }
      setTimeout(function () {
        isAlert.value = false;
        isStatus.value = false;
      }, 8000);
    };
    const handleSubmit = async () => {
      handleValidation(formData);
    };
    onMounted(async () => {
      try {
        getUsers();
        getExpense();
        getReferenceNumber();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    });

    const selectedExpense = (recurring) => {
      const result = posts.value.filter((post) => {
        return post.description === recurring;
      });
      recValue.value = result[0].recurring;
    };

    const getExpense = async () => {
      try {
        const { data } = await axios.get("/api/form/expense");
        posts.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getUsers = async () => {
      try {
        const { data } = await axios.get("/api/getUsers");
        empName.value = data.filter((user) => {
          return (
            user.userType !== "super" &&
            user.department === store.state.getDepartment
          );
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const expenseDetails = ref([
      {
        name: null,
        amount: 0,
        reqID: null,
      },
    ]);

    const bulkInsert = async (paymentId) => {
      try {
        const modified = expenseDetails.value.map((val, index) => {
          if (index >= 0) {
            val.reqID = paymentId;
          }
          return val;
        });
        await axios.post(`/api/form/liquidation/bulkExpense/`, modified);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const selectFile = () => {
      if (fileData.value.files.length > 0) {
        fileData.value = fileData.value.files[0];
        uploadFile.value = fileData.value;

        liquidatedModFilename.value = `${
          fileData.value.lastModified
        }.${fileData.value.name.split(".").pop()}`;
        formData.filename = fileData.value.name;

        let size = fileData.value.size;
        store.commit("fileHandler", size);
      } else {
        formData.filename = null;
        formData.liquidatedModFilename = null;
        liquidatedModFilename.value = null;
      }
    };

    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", uploadFile.value, liquidatedModFilename.value);
      try {
        await axios.post(`/api/upload/`, formData);
        insertFormData();
      } catch (error) {
        isAlert.value = true;
        setTimeout(() => {
          isAlert.value = false;
        }, 5000);
        store.commit("errorHandler", error);
      }
    };

    const addTextbox = () => {
      if (totalAmountLiquidate.value <= 1000) {
        expenseDetails.value.push({ name: "", amount: 0, reqID: null });
        addCount.value += 1;
        maxAmount.value = null;
      }
    };

    const removeExpense = (index) => {
      const arrayLength = ref(expenseDetails.value.length);
      if (arrayLength.value > 1) {
        expenseDetails.value.splice(index, 1);
      }
      getTotal();
    };

    const getTotal = (val, index) => {
      let MAX_AMOUNT = 1000;
      currentIndex.value = index;
      const totalPrice = expenseDetails.value.reduce((total, item) => {
        return total + item.amount;
      }, 0);
      formData.total = totalPrice.toFixed(2);

      totalAmountLiquidate.value = formData.total - cashAdvanceOrig.value;
      totalAmountLiquidate.value =
        totalAmountLiquidate.value < 0
          ? totalAmountLiquidate.value * -1
          : totalAmountLiquidate.value;

      totalAmountLiquidateLabel.value =
        formData.total > cashAdvanceOrig.value
          ? "Balance Reimbursable:"
          : "Excess Redeposited:";

      if (formData.total > cashAdvanceOrig.value + MAX_AMOUNT) {
        iconAction.value = true;
      } else {
        iconAction.value = false;
      }
      maxAmount.value = val - totalAmountLiquidate.value + MAX_AMOUNT;
    };

    const searchReferenceNumber = () => {
      referenceItemList.value = referenceList.value.filter((item) => {
        if (
          !item.liquidationDated ||
          item.liquidatedStatus === "Liquidation Disapproved"
        ) {
          let convertString = 1000000 + item.id;
          return convertString
            .toString()
            .includes(formData.referenceNumber.trim());
        }
      });
    };
    const selectedReference = (referenceNumber, index) => {
      maxAmount.value = null;
      iconAction.value = true;
      getIndexExistingExpenses.value = [];
      totalAmountLiquidate.value = 0;
      totalAmountLiquidateLabel.value = "Total:";
      formData.referenceNumber = referenceNumber;
      let itemDetails = referenceItemList.value[index];

      formData.dateFiled = itemDetails.dateFiled;
      formData.dateIntended = itemDetails.dateIntended;
      formData.payee = itemDetails.payee;
      formData.remarks = itemDetails.remarks;
      formData.requestedBy = itemDetails.requestedBy;
      expenseDetails.value = itemDetails.expenseDetails;
      formData.total = itemDetails.total;
      cashAdvanceOrig.value = itemDetails.total;

      expenseDetails.value.forEach((val, index) => {
        getIndexExistingExpenses.value.push(index);
      });

      referenceItemList.value = [];
      isAlert.value = false;
      isStatus.value = false;

      let dateReleased = itemDetails.dtprocessed;

      store.commit("liquidationAlert", dateReleased);
    };
    const getReferenceNumber = async () => {
      try {
        const { data } = await axios.get("/api/reference");
        referenceList.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const insertPayees = async () => {
      try {
        await axios.post(`/api/payees/`, {
          name: formData.payee,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const enabledMode = () => {
      if (formData.payee) {
        disableStat.value = false;
        iconAction.value = false;
      }
    };
    const amountFormat = (val) => {
      return val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    };

    return {
      enabledMode,
      itemAccountType,
      selectedReference,
      searchReferenceNumber,
      referenceList,
      referenceItemList,
      getReferenceNumber,
      insertPayees,
      formData,
      insertFormData,
      isVisible,
      clearFormField,
      isStatus,
      handleValidation,
      handleSubmit,
      isAlert,
      posts,
      selectedExpense,
      recValue,
      empName,
      fileData,
      uploadFile,
      selectFile,
      uploadHandler,
      getUsers,
      getExpense,
      addTextbox,
      expenseDetails,
      disableStat,
      addCount,
      iconAction,
      bulkInsert,
      removeExpense,
      getTotal,
      maxAmount,
      currentIndex,
      cashAdvanceOrig,
      totalAmountLiquidate,
      totalAmountLiquidateLabel,
      amountFormat,
      getIndexExistingExpenses,
      liquidatedModFilename,
    };
  },
};
</script>
<style scoped>
select {
  width: 30px;
}

.fileinput input {
  width: 30px;
}

.form1 .autotext .form-group {
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  box-sizing: border-box;
  gap: 5px;
}
.form1 .autotext .form-group select {
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 60.5%;
}

.form1 .autotext .form-group input {
  min-width: 29.5%;
}

.form-group img {
  padding-top: 7px;
  cursor: pointer;
}

.image-button {
  cursor: pointer;
}

.form1 .form-group1 {
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-family: Montserrat;
  box-sizing: border-box;
  gap: 8px;
}

.form1 .form-group1 label {
  min-width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form1 .form-group1 .input1 input {
  width: 50%;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  margin-right: 14px; /* Adjust as needed */
}
.checkbox-container .input input {
  margin-top: 6px; /* Adjust as needed */
}
.accountype-title {
  display: flex;
  justify-content: space-between;
}
.payee-container {
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  max-width: auto;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  cursor: pointer;
}
.payee-container ul {
  list-style: none;
  line-height: 30px;
}
.payee-container ul li:hover {
  background-color: #e0dede;
}
.accountype-subtitle,
.amount-container {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  padding: 5px 0px;
}
.amount-container-details {
  display: flex;
  justify-content: flex-end;
}
</style>

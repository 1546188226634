<template>
  <nav class="nav-bar">
    <div class="hamburger-menu" v-if="$store.state.getRoleUser === 'admin'">
      <span @click="$store.state.isExpandMenu = !$store.state.isExpandMenu">
        <menuIconSVG v-if="!$store.state.isExpandMenu" />
        <menuCloseIconSVG v-else />
      </span>
    </div>
    <h5>IWC Digital Application Portal</h5>
    <div class="logout" @click="logoutHandler">
      <logoutIconSVG />
    </div>
  </nav>
  <div v-if="isModal"></div>
</template>
<script>
import logoutIconSVG from "@/assets/svgs/logoutIcon.vue";
import menuIconSVG from "@/assets/svgs/burgerMenuIcon.vue";
import menuCloseIconSVG from "@/assets/svgs/closeMenuIcon.vue";
import { inject, ref } from "vue";
import router from "@/router";
export default {
  components: {
    logoutIconSVG,
    menuIconSVG,
    menuCloseIconSVG,
  },
  setup() {
    const store = inject("$store");
    const isModal = ref(false);
    const logoutHandler = () => {
      store.state.isVisible = false;
      store.commit("logoutHandler");
      return router.push("/login");
    };

    return {
      logoutHandler,
      isModal,
    };
  },
};
</script>
<style scoped>
.nav-bar {
  background-color: var(--primaryColorDark);
  font-size: 16px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 400;
  line-height: 50px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 0px 10px;
  position: sticky;
}
.logout svg {
  position: relative;
  top: 4px;
}

.hamburger-menu {
  position: relative;
  top: 10px;
  left: 4px;
}
.hamburger-menu:hover {
  cursor: pointer;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }
}

@media only screen and (min-width: 210px) and (max-width: 500px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
</style>

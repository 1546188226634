<template>
  <div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <SectionConferenceStatus
      v-else
      :transaction="transactions"
    ></SectionConferenceStatus>
    <div class="header-form">Request for Conference Reservation Details</div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <div v-else class="grid-container">
      <table>
        <tr>
          <td class="title">Reference Number:</td>
          <td class="description">{{ referenceFormat(transactions.id) }}</td>
        </tr>
        <tr>
          <td class="title">Date Intended and Time:</td>
          <td class="description">
            {{
              dateFormat(
                transactions.dateIntended,
                transactions.timeStart,
                transactions.timeEnd
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="title">Purpose:</td>
          <td class="description">
            {{ transactions.purpose }}
          </td>
        </tr>
        <tr>
          <td class="title">Remarks:</td>
          <td class="description">
            {{ transactions.remarks }}
          </td>
        </tr>
        <tr>
          <td class="title">Requested by:</td>
          <td class="description">
            {{ transactions.requestedBy }}
          </td>
        </tr>
        <tr>
          <td class="title">Status:</td>
          <td class="description">
            {{ !transactions.status ? "Pending" : transactions.status }}
          </td>
        </tr>
        <tr>
          <td class="title">Approve by:</td>
          <td class="description">
            {{ transactions.approverBy ? transactions.approverBy : "-" }}
          </td>
        </tr>
        <tr>
          <td class="title">Approved date:</td>
          <td class="description">
            {{ transactions.dtapproved }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import SectionConferenceStatus from "@/components/partials/conferences/AuthReservationStatusTracker.vue";
import { inject, onMounted, ref } from "vue";
export default {
  components: {
    SectionConferenceStatus,
  },
  setup() {
    const store = inject("$store");
    const axios = inject("$axios");
    const moment = inject("$moment");
    const transactions = ref([]);
    const isMultiple = ref(false);
    const isToggle = ref(false);
    const isRender = ref(true);
    onMounted(() => {
      getData();
    });
    const getData = async () => {
      try {
        const conferenceRequestId = store.state.applicationId;
        const response = await axios.get(
          `/api/admin/conference/reservation/${conferenceRequestId}`
        );
        transactions.value = response.data[0];
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (date, start, end) => {
      let modFormat;
      let startTime = `${date} ${start}`;
      let endTime = `${date} ${end}`;

      modFormat = `${moment(date).format("LL")} (${moment(startTime).format(
        "LT"
      )} - ${moment(endTime).format("LT")})`;
      return modFormat;
    };
    const referenceFormat = (reference) => {
      let serial = 100000;
      return reference + serial;
    };
    const getValue = (val, payload) => {
      isToggle.value = val;
      if (payload) {
        setTimeout(async () => {
          getData();
        }, 5000);
      }
    };
    const toggleHandler = () => {};
    return {
      getData,
      transactions,
      dateFormat,
      isMultiple,
      isToggle,
      toggleHandler,
      getValue,
      referenceFormat,
      isRender,
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;

  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 35px;
}
table > tr .title,
table > tr .description {
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 160px;
  font-family: "Montserrat";
  font-weight: 500;
}
.description {
  width: 360px;
  color: #272626;
}

.grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 40px 0px 20px 0px;
}
.col-status-one,
.col-status-two,
.col-status-three,
.col-status-four {
  background-color: green;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-four {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img,
.col-status-four img {
  width: 50px;
  height: 50px;
}

.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
.header-form {
  padding-bottom: 30px;
  color: var(--lightFont);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}

@media only screen and (max-width: 600px) {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 12px;
    width: 100%;
  }
  table > tr {
    height: 28px;
  }
  table > tr .title,
  table > tr .description {
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 124px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  .description {
    max-width: 100%;
    color: #272626;
  }
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0px 30px 0px 30px;
  }

  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four {
    height: 10px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img {
    width: 34px;
    height: 34px;
  }
  .icons {
    height: 70px;
    font-size: 12px;
  }
  .header-form {
    padding-bottom: 30px;
    font-size: 16px;
  }
}
/* Extra Small Devices, Phones */
</style>

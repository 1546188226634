<template>
  <div class="grid-content">
    <div class="col-status-one">
      <img src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-two"
      :class="[
        transaction.actionStatus < 2
          ? 'pending'
          : transaction.status === 'Disapproved' &&
            transaction.actionStatus === 2
          ? 'decline'
          : '',
      ]"
    >
      <img
        v-if="
          transaction.status === 'Disapproved' && transaction.actionStatus === 2
        "
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-three"
      :class="[
        transaction.actionStatus < 3
          ? 'pending'
          : transaction.actionStatus === 3 &&
            transaction.status === 'Disapproved'
          ? 'decline'
          : '',
      ]"
    >
      <img
        v-if="
          transaction.status === 'Disapproved' && transaction.actionStatus === 3
        "
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-four"
      :class="[
        transaction.actionStatus < 4
          ? 'pending'
          : transaction.actionStatus === 4 &&
            transaction.status === 'Disapproved'
          ? 'decline'
          : '',
      ]"
    >
      <img
        v-if="
          transaction.status === 'Disapproved' && transaction.actionStatus === 4
        "
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-five"
      :class="[
        transaction.actionStatus < 5
          ? 'pending'
          : transaction.actionStatus === 4 &&
            transaction.status === 'Disapproved'
          ? 'decline'
          : '',
      ]"
    >
      <img
        v-if="
          transaction.status === 'Disapproved' && transaction.actionStatus === 4
        "
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
  </div>
  <div class="grid-content">
    <div class="icons">Request Placed</div>
    <div class="icons">Pre-approved</div>
    <div class="icons">Processing</div>
    <div class="icons">Final Approved</div>
    <div class="icons">HR Resources</div>
  </div>
</template>
<script>
export default {
  props: {
    transaction: Object,
  },
  setup() {
    return {};
  },
};
</script>
<style scoped>
.grid-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 30px 0px 30px;
}
.col-status-one,
.col-status-two,
.col-status-three,
.col-status-four,
.col-status-five {
  background-color: green;
  height: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-five {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img,
.col-status-four img,
.col-status-five img {
  width: 38px;
  height: 38px;
}
.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  .grid-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-top: 20px 0px 0px 0px;
  }
  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four,
  .col-status-five {
    background-color: green;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-status-one {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .col-status-five {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img,
  .col-status-five img {
    max-width: 28px;
    max-height: 28px;
  }
  .pending img {
    filter: grayscale(100%);
  }
  .pending {
    background-color: rgb(148, 148, 150);
  }
  .decline {
    background-color: rgb(243, 78, 36);
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .grid-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-top: 40px 0px 0px 0px;
  }
  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four,
  .col-status-five {
    background-color: green;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-status-one {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .col-status-five {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img,
  .col-status-five img {
    max-width: 28px;
    max-height: 28px;
  }
  .pending img {
    filter: grayscale(100%);
  }
  .pending {
    background-color: rgb(148, 148, 150);
  }
  .decline {
    background-color: rgb(243, 78, 36);
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90px;
    font-size: 12px;
  }
}
</style>

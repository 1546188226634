<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>{{ titleRequest }}</h1>
        </div>
      </div>
      <form @submit.prevent="handleAction()">
        <div v-if="userType === 'Employee'">
          <label for=""> Reason Cancel: <span class="required">*</span> </label>
          <div class="input" style="padding-top: 15px">
            <textarea rows="8" v-model="approverRemarks" required />
          </div>
        </div>
        <div v-else>
          <div
            class="form-group"
            v-if="$store.state.getUserType !== 'admin-moderator'"
          >
            <div class="dataTable">
              <table v-if="itemSchedule.length">
                <thead>
                  <tr>
                    <th scope="col">Leave Type</th>
                    <th scope="col">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in itemSchedule" :key="index">
                    <td data-label="Purpose">
                      {{ item.leaveType }}
                    </td>
                    <td data-label="Purpose">
                      {{ item.requestedBy }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-else>
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Description">No Schedule</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <select v-model="selectedStatus" required>
              <option v-for="item in modItemStatus" :key="item">
                {{ item }}
              </option>
            </select>
            <div class="form-group" v-if="selectedStatus === 'Disapproved'">
              <label for=""> Remarks: <span class="required">*</span> </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" required />
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="form-group"
              v-if="dataItems.approverStatus === 'Approved'"
            >
              <label for=""
                >Void Remarks: <span class="required">*</span>
              </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" required />
              </div>
            </div>
            <div
              class="form-cancelled"
              v-show="dataItems.approverStatus !== 'Approved'"
            >
              <label for=""> Disapprove Request: </label>
              <label class="toggle">
                <input type="checkbox" id="switch" v-model="isToggle" />
                <span class="slider"></span>
              </label>
            </div>
            <div class="form-group" v-if="isToggle">
              <label for=""> Remarks: <span class="required">*</span> </label>
              <div class="input">
                <textarea rows="8" v-model="approverRemarks" required />
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  dataItems.leaveType === 'SL' ||
                  dataItems.leaveType === 'VL' ||
                  dataItems.leaveType === 'EL'
                "
              >
                <div class="form-group flex">
                  <label for="">
                    <h4>Sick Leave Credit Balance:</h4>
                  </label>
                  <h4 class="titleMessage">{{ balanceSL }}</h4>
                </div>
                <div class="form-group flex">
                  <label for="">
                    <h4>Vacation Leave Credit Balance:</h4>
                  </label>
                  <h4 class="titleMessage">{{ balanceVL }}</h4>
                </div>
              </div>
              <hr />
              <div>
                <div class="form-group flex">
                  <label for="">
                    <h4>Leave Type:</h4>
                  </label>
                  <h4 class="titleMessage">
                    {{ convertToWord(dataItems.leaveType) }}
                    <span v-if="dataItems.leaveType === 'AWOP'">{{
                      convertToWord(null, dataItems.awopType)
                    }}</span>
                  </h4>
                </div>
                <div class="form-group flex">
                  <label for="">
                    <h4>Number of day(s):</h4>
                  </label>
                  <h4 class="titleMessage">{{ dataItems.numberDays }}</h4>
                </div>
              </div>
              <hr />
              <div
                v-if="
                  dataItems.leaveType === 'SL' ||
                  dataItems.leaveType === 'VL' ||
                  dataItems.leaveType === 'EL'
                "
              >
                <h4 class="titleMessage">Expected Balance:</h4>
                <div class="form-group flex">
                  <label for="">
                    <h4 class="indent">Sick Leave:</h4>
                  </label>
                  <h4 class="titleMessage">{{ expectedSL }}</h4>
                </div>
                <div class="form-group flex">
                  <label for="">
                    <h4 class="indent">Vacation Leave:</h4>
                  </label>
                  <h4 class="titleMessage">{{ expectedVL }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-button">
          <div>
            <input
              :disabled="disabled"
              class="btn approved"
              type="submit"
              :value="
                dataItems.approverStatus !== 'Approved'
                  ? 'Submit'
                  : 'Void Request'
              "
            />
          </div>

          <div>
            <input
              class="btn cancel"
              type="submit"
              value="Cancel"
              @click.prevent="closeModal()"
            />
          </div>
        </div>
      </form>
      <div v-if="isNotProceed"></div>

      <div v-show="isNotProceed" class="alert-error form-alert errorMessage">
        Insufficent Balance
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { computed, inject, onMounted, ref, reactive } from "vue";
export default {
  props: {
    requestId: Number,
    dataItems: Object,
    transaction: Object,
    employeeItems: Object,
    userType: String,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const store = inject("$store");
    const moment = inject("$moment");
    const disabled = ref(false);
    const approverRemarks = ref(null);
    const posts = ref(null);
    const selectedStatus = ref(null);
    const duplicateMessage = ref(false);
    const isToggle = ref(false);
    const employeeID = ref(null);
    const balanceSL = ref(0);
    const balanceVL = ref(0);
    const expectedSL = ref(0);
    const expectedVL = ref(0);
    const itemSchedule = ref([]);
    const statusAction = ref(null);
    const approverDate = ref(null);
    const superiorDate = ref(null);
    const creditType = ref(0);
    const isNotProceed = ref(false);
    const outStandingBal = ref(0);
    const getIndexUser = ref(0);
    const itemStatus = ref(["Approved", "Disapproved", "Cancelled"]);
    const leaveBreakDown = reactive([]);
    onMounted(() => {
      employeeID.value = props.dataItems.empId;
      let dateIntended = props.dataItems.dateStart;
      let itemList = props.transaction;

      if (props.userType !== "Employee") {
        itemSchedule.value = itemList.filter((val) => {
          let userLogIn = store.state.getUserType;
          let result;

          if (userLogIn === "moderator") {
            result =
              (val.approverStatus === "Approved" ||
                val.approverStatus === "Pre-approved") &&
              val.dateStart === dateIntended &&
              val.requestedBy !== store.state.getUserLog &&
              (val.department === "Health Provider & Patient Relations" ||
                val.department === "Marketing");
          } else if (userLogIn === "super") {
            result =
              (val.approverStatus === "Approved" ||
                val.approverStatus === "Pre-approved") &&
              val.dateStart === dateIntended &&
              (val.isDirect === 1 ||
                val.department === store.state.getDepartment) &&
              val.requestedBy !== store.state.getUserLog;
          } else if (userLogIn === "semi-moderator") {
            result =
              (val.approverStatus === "Approved" ||
                val.approverStatus === "Pre-approved") &&
              val.dateStart === dateIntended &&
              (val.department === "Corporate Services" ||
                val.department === "Astral Clinic") &&
              val.requestedBy !== store.state.getUserLog;
          } else {
            result =
              (val.approverStatus === "Approved" ||
                val.approverStatus === "Pre-approved") &&
              val.dateStart === dateIntended &&
              val.department === store.state.getDepartment &&
              val.requestedBy !== store.state.getUserLog;
          }

          return result;
        });
        employeeMasterList();
      }

      //insertBreakDetails();
    });
    const closeModal = (
      val,
      payLoad,
      status,
      date,
      superiorDatee,
      approverRemarks,
      approver
    ) => {
      payLoad = !payLoad ? false : true;
      val = false;
      emit(
        "passClose",
        val,
        payLoad,
        props.requestId,
        status,
        date,
        superiorDatee,
        approverRemarks,
        approver
      );
    };
    const titleRequest = computed(() => {
      return "Employee's Leave";
    });
    const modItemStatus = computed(() => {
      return itemStatus.value.filter((val) => {
        let status;
        status = val === "Approved" || val === "Disapproved";
        return status;
      });
    });

    const approverAction = async (id, action, remarks) => {
      try {
        if (props.userType === "Employee") {
          try {
            await axios.put(`/api/admin/employee/leave/${id}`, {
              approverStatus: "Revert",
              approverRemarks: remarks,
              lastModifiedBy: store.state.getUserLog,
            });
          } catch (error) {
            store.commit("errorHandler", error);
          }
        } else {
          if (store.state.getUserType === "admin-moderator") {
            statusAction.value = isToggle.value
              ? "Disapproved"
              : props.dataItems.approverStatus === "Approved"
              ? "Cancelled"
              : "Approved";
            approverDate.value = moment();

            if (!isToggle.value) {
              insertLeaveCreditHistory();
              updateLeaveCredits();
            }

            try {
              await axios.put(`/api/admin/employee/leave/${id}`, {
                approverStatus: statusAction.value,
                approverBy: store.state.getUserLog,
                approverDtAdded: approverDate.value,
                approverRemarks: remarks,
                lastModifiedBy: store.state.getUserLog,
              });
            } catch (error) {
              store.commit("errorHandler", error);
            }
          } else {
            statusAction.value =
              action === "Approved" ? "Pre-approved" : action;
            superiorDate.value = moment();
            try {
              await axios.put(`/api/admin/employee/leave/${id}`, {
                approverStatus: statusAction.value,
                approverBy: store.state.getUserLog,
                isPreapproved: action === "Approved" ? 1 : 0,
                superiorDtAdded: superiorDate.value,
                approverRemarks: remarks,
                lastModifiedBy: store.state.getUserLog,
              });
            } catch (error) {
              store.commit("errorHandler", error);
            }
          }
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const updateLeaveCredits = async () => {
      try {
        let index = getIndexUser.value;
        let employeeId = employeeID.value;
        let splUsed;
        let vawcUsed;
        let birthDateUsed;
        let res = store.state.employeeMasterList.find((val) => {
          return val.id === props.dataItems.empId;
        });
        if (props.dataItems.leaveType === "BL") {
          birthDateUsed = 1;
        }
        birthDateUsed =
          statusAction.value === "Cancelled"
            ? 0
            : res.isBirthDate || birthDateUsed;
        if (props.dataItems.leaveType === "SPL") {
          splUsed =
            props.dataItems.numberDays +
            store.state.employeeMasterList[index].splCredit;
        }
        if (props.dataItems.leaveType === "VAWC") {
          vawcUsed =
            props.dataItems.numberDays +
            store.state.employeeMasterList[index].vawcCredit;
        }
        await axios.put(`/api/user/${employeeId}`, {
          slBalance: expectedSL.value,
          vlBalance: expectedVL.value,
          isBirthDate: birthDateUsed,
          splCredit: splUsed,
          vawcCredit: vawcUsed,
        });
        //getindex

        store.state.employeeMasterList[index].slBalance = expectedSL.value;
        store.state.employeeMasterList[index].vlBalance = expectedVL.value;
        store.state.employeeMasterList[index].splCredit = splUsed;
        store.state.employeeMasterList[index].vawcCredit = vawcUsed;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const insertLeaveCreditHistory = async () => {
      try {
        let no_days = props.dataItems.numberDays;
        let employeeId = employeeID.value;
        const id = props.requestId;
        await axios.post(`/api/admin/employee/leave/history/`, {
          leaveFormId: id,
          employeeId: employeeId,
          numberDays: no_days,
          slBalFrom: balanceSL.value,
          slBalTo: expectedSL.value,
          vlBalFrom: balanceVL.value,
          vlBalTo: expectedVL.value,
          creditType: creditType.value,
        });
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const handleAction = async () => {
      //disabled.value = true;
      let action = selectedStatus.value;
      let remarks = approverRemarks.value;
      let userType = store.state.getUserType;

      if (userType === "admin-moderator") {
        if (outStandingBal.value < 0) {
          if (isToggle.value) {
            Swal.fire({
              title: "Are you sure",
              text: "you want to submit?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Submit",
            }).then((result) => {
              if (result.isConfirmed) {
                const id = props.requestId;
                approverAction(id, action, remarks);
                swalConfirmation(action, userType);
              }
            });
          } else {
            isNotProceed.value = true;
          }
          setTimeout(() => {
            isNotProceed.value = false;
          }, 6000);
        } else {
          Swal.fire({
            title: "Are you sure",
            text: "you want to submit?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Submit",
          }).then((result) => {
            if (result.isConfirmed) {
              const id = props.requestId;
              approverAction(id, action, remarks);
              swalConfirmation(action, userType);
            }
          });
        }
      } else {
        Swal.fire({
          title: "Are you sure",
          text: "you want to submit?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            const id = props.requestId;
            approverAction(id, action, remarks);
            swalConfirmation(action, userType);
          }
        });
      }
    };
    const swalConfirmation = async (action, userType) => {
      let message;

      if (props.userType === "Employee") {
        message = `Request was successfully Revert`;
      } else {
        if (userType === "admin-moderator") {
          if (isToggle.value) {
            message = "Request was regretfully Disapproved";
          } else {
            if (props.dataItems.approverStatus === "Approved") {
              message = `Request was successfully Cancelled`;
            } else {
              message = `Request was successfully Approved`;
            }
          }
        }
        if (
          userType === "moderator" ||
          userType === "head" ||
          userType === "semi-moderator" ||
          userType === "pre-moderator" ||
          userType === "supervisor-moderator" ||
          userType === "operator-moderator" ||
          userType === "super"
        ) {
          message =
            action === "Disapproved"
              ? `Request was regretfully ${action}`
              : `Request was successfully Pre-approved`;
        }
      }

      setTimeout(async () => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 4000,
        });
        closeModal(
          false,
          true,
          statusAction.value,
          approverDate.value,
          superiorDate.value,
          approverRemarks.value,
          store.state.getUserLog
        );
        statusAction.value = null;
        approverDate.value = null;
        superiorDate.value = null;
        approverRemarks.value = null;
      }, 400);
    };

    const employeeMasterList = async () => {
      try {
        let itemData = props.employeeItems;
        let data = itemData.find((item) => {
          return item.id === employeeID.value;
        });

        getIndexUser.value = itemData.findIndex((object) => {
          return object.id === employeeID.value;
        });
        balanceSL.value = data.slBalance;
        balanceVL.value = data.vlBalance;
        computationLeaveCredits();
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const computationLeaveCredits = async () => {
      let SL_BALANCE = balanceSL.value;
      let VL_BALANCE = balanceVL.value;
      let LEAVE_TYPE = props.dataItems.leaveType;
      let NO_DAYS = props.dataItems.numberDays;
      let EXPECTED_SL = balanceSL.value;
      let OUTSTANDING_DEDUCTION = 0;
      let EXPECTED_VL = balanceVL.value;

      if (props.dataItems.approverStatus === "Pre-approved") {
        if (LEAVE_TYPE === "SL") {
          if (SL_BALANCE) {
            if (SL_BALANCE >= NO_DAYS) {
              EXPECTED_SL = SL_BALANCE - NO_DAYS;
            } else {
              OUTSTANDING_DEDUCTION = NO_DAYS - SL_BALANCE;
              let balanceToSettle = NO_DAYS - OUTSTANDING_DEDUCTION;
              EXPECTED_SL = SL_BALANCE - balanceToSettle;
              //utilize SL
              //go to VL
            }
          } else {
            if (VL_BALANCE) {
              if (VL_BALANCE >= NO_DAYS) {
                EXPECTED_VL = VL_BALANCE - NO_DAYS;
              } else {
                OUTSTANDING_DEDUCTION = VL_BALANCE - NO_DAYS;
                EXPECTED_VL = OUTSTANDING_DEDUCTION;
              }
            }
          }
        }
        if (LEAVE_TYPE === "VL") {
          if (VL_BALANCE) {
            if (VL_BALANCE >= NO_DAYS) {
              EXPECTED_VL = VL_BALANCE - NO_DAYS;
            } else {
              OUTSTANDING_DEDUCTION = VL_BALANCE - NO_DAYS;
              EXPECTED_VL = OUTSTANDING_DEDUCTION;
            }
          }
        }
        if (LEAVE_TYPE === "EL") {
          if (VL_BALANCE) {
            if (VL_BALANCE >= NO_DAYS) {
              EXPECTED_VL = VL_BALANCE - NO_DAYS;
            } else {
              OUTSTANDING_DEDUCTION = VL_BALANCE - NO_DAYS;
              EXPECTED_VL = OUTSTANDING_DEDUCTION;
            }
          }
        }
        if (OUTSTANDING_DEDUCTION > 0) {
          if (VL_BALANCE) {
            if (VL_BALANCE >= OUTSTANDING_DEDUCTION) {
              EXPECTED_VL = VL_BALANCE - OUTSTANDING_DEDUCTION;
            }
          }
        } else {
          outStandingBal.value = OUTSTANDING_DEDUCTION;
        }

        expectedSL.value = EXPECTED_SL;
        expectedVL.value = EXPECTED_VL;
        creditType.value = 0;
      } else if (props.dataItems.approverStatus === "Approved") {
        const id = props.requestId;
        const response = await axios.get(
          `/api/admin/employee/leave/history/${id}`
        );
        let slBalFrom = response.data[0].slBalFrom;
        let slBalTo = response.data[0].slBalTo;

        let vlBalFrom = response.data[0].vlBalFrom;
        let vlBalTo = response.data[0].vlBalTo;

        let slRetrieve = slBalFrom - slBalTo;
        let vlRetrieve = vlBalFrom - vlBalTo;

        expectedSL.value = slRetrieve + SL_BALANCE;
        expectedVL.value = vlRetrieve + VL_BALANCE;
        creditType.value = 1;
      }
    };
    const convertToWord = (val, subVal) => {
      let abbreviation = val;
      let awopAbbrev = subVal;
      let word;
      if (val) {
        if (abbreviation === "SL") {
          word = "Sick Leave";
        }
        if (abbreviation === "VL") {
          word = "Vacation Leave";
        }
        if (abbreviation === "EL") {
          word = "Emergency Leave";
        }
        if (abbreviation === "AWOP") {
          word = "Absent without pay";
        }
        if (abbreviation === "CL") {
          word = "Compassionate Leave";
        }
        if (abbreviation === "ML") {
          word = "Maternity Leave";
        }
        if (abbreviation === "PL") {
          word = "Paternity Leave";
        }
        if (abbreviation === "BL") {
          word = "Birthday Leave";
        }
        if (abbreviation === "SPL") {
          word = "Solo Parent Leave";
        }
        if (abbreviation === "MCW") {
          word = "Magna Carta for Women";
        }
        if (abbreviation === "VAWC") {
          word = "Violence Againts Women and their Children";
        }
        return word;
      } else {
        if (awopAbbrev === "AWOPSL") {
          word = "(Sick Leave)";
        }
        if (awopAbbrev === "AWOPVL") {
          word = "(Vacation Leave)";
        }
        return word;
      }
    };
    const insertBreakDetails = () => {
      let selectedLeaveType = props.dataItems.leaveType;
      let startDate = moment(props.dataItems.dateStart);
      let endDate = moment(props.dataItems.dateEnd).add(1, "days");
      let daysDuration = [];
      let getDepartments = store.state.getDepartment;

      while (startDate.isBefore(endDate, "days")) {
        let days = startDate.format("dddd");
        let date = startDate.format("YYYY-MM-DD");
        startDate.add(1, "days");
        if (getDepartments === "Customer Services") {
          daysDuration.push(days);
        } else if (getDepartments === "Muntinlupa Clinic") {
          if (days !== "Sunday") {
            daysDuration.push(days);
          }
        } else if (selectedLeaveType === "ML") {
          daysDuration.push(days);
        } else {
          if (days !== "Saturday" && days !== "Sunday") {
            employeeMasterList();
            daysDuration.push(days);
            leaveBreakDown.push({
              dateIntended: date,
              leaveType: selectedLeaveType,
              vlBalance: expectedVL.value,
            });
          }
        }
      }
    };

    return {
      closeModal,
      handleAction,
      disabled,
      approverRemarks,
      posts,
      itemStatus,
      modItemStatus,
      selectedStatus,
      approverAction,
      titleRequest,
      swalConfirmation,
      duplicateMessage,
      isToggle,
      convertToWord,
      employeeMasterList,
      employeeID,
      balanceSL,
      balanceVL,
      expectedSL,
      expectedVL,
      computationLeaveCredits,
      updateLeaveCredits,
      insertLeaveCreditHistory,
      itemSchedule,
      statusAction,
      approverDate,
      creditType,
      isNotProceed,
      getIndexUser,
      insertBreakDetails,
      leaveBreakDown,
    };
  },
};
</script>
<style scoped>
select {
  font-size: 15px;
  font-style: normal;
  font-family: "Montserrat";
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 100%;
  margin-bottom: 15px;
}
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.grid-button {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.btn {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Montserrat";
  align-items: center;
  background-clip: padding-box;
  font-weight: 600;
  justify-content: center;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
}
.btn:hover,
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-5px);
}

.btn:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.approved {
  background-color: rgb(25, 179, 102);
}
.disapproved {
  background-color: rgb(219, 86, 53);
}
.cancel {
  background-color: rgb(137, 67, 228);
}
.dv-form {
  padding-top: 20px;
}
.form-cancelled {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 20px;
}
.flex {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.titleMessage {
  color: rgb(49, 49, 49);
}
.indent {
  margin-left: 18px;
}
hr {
  margin: 10px 0px;
}
.dataTable {
  margin-bottom: 20px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 0.65em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.65em;
  text-align: left;
  font-size: 0.9em;
}

table th {
  font-size: 0.65em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.errorMessage {
  margin-top: 30px;
}
@media only screen and (min-width: 210px) and (max-width: 500px) {
  .modal-container {
    min-width: 200px;
  }
  .modal {
    width: 300px;
    max-width: 100%;
    padding: 30px;
  }
}
</style>

<template>
  <div class="auth-wrapper">
    <div class="sidebar">
      <app-auth-profile />
      <app-auth-nav @passComponent="getComponent" />
    </div>

    <div class="content">
      <keep-alive>
        <component :is="activeTab"> </component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import SectionAuthDashboard from "@/components/pages/admin/Dashboard/SectionDashboard.vue";
import SectionAuthPaymentManagement from "@/components/pages/admin/PaymentRequest/SectionPaymentManagement.vue";
import SectionAuthPaymentPreparation from "@/components/pages/admin/PaymentRequest/SectionPaymentPreparation.vue";
import SectionAuthPaymentReleased from "@/components/pages/admin/PaymentRequest/SectionPaymentReleasing.vue";
import SectionAuthPaymentLiquidation from "@/components/pages/admin/PaymentRequest/SectionPaymentLiquidation.vue";
import SectionAuthPaymentAnalytics from "@/components/pages/admin/PaymentRequest/SectionPaymentAnalytics.vue";
import SectionAuthActivityLog from "@/components/pages/admin/Maintenance/SectionActivityLog.vue";
import SectionAuthAccountSetting from "@/components/pages/admin/Maintenance/SectionAccountSetting.vue";
import SectionAuthSystemDetails from "@/components/pages/admin/Maintenance/SectionSystemDetails.vue";
import SectionDemoChart from "@/components/utils/charts/demoPieChart.vue";
import SectionAuthTravel from "@/components/pages/admin/Travel/SectionTravel.vue";
import SectionAuthTravelAnalytics from "@/components/pages/admin/Travel/SectionTravelAnalytics.vue";
import SectionAuthTravelSchedule from "@/components/pages/admin/Travel/SectionTravelSchedule.vue";
import SectionAuthConferenceReservation from "@/components/pages/admin/Conference/SectionConferenceReservation.vue";
import SectionAuthEmployeeLeaveManagement from "@/components/pages/admin/Employees/leave/SectionLeaveManagement.vue";
import SectionAuthEmployeeMasterlist from "@/components/pages/admin/Employees/masterlist/SectionMasterEmployee.vue";
import SectionAuthEmployeeLeaveReport from "@/components/pages/admin/Employees/leave/SectionLeaveReport.vue";
import SectionShiftingSchedule from "@/components/pages/admin/Employees/maintenance/SectionShiftingSchedule.vue";
import SectionAuthOvertime from "@/components/pages/admin/Overtime/SectionOverTime.vue";
import SectionAuthOfficialBusiness from "@/components/pages/admin/OfficialBusiness/SectionOfficialBusiness.vue";
import { computed, ref, onMounted } from "vue";
import { inject } from "vue";
export default {
  name: "LandingPage",
  components: {
    SectionAuthDashboard,
    SectionAuthEmployeeLeaveManagement,
    SectionAuthPaymentManagement,
    SectionAuthPaymentPreparation,
    SectionAuthPaymentReleased,
    SectionAuthPaymentLiquidation,
    SectionAuthPaymentAnalytics,
    SectionAuthActivityLog,
    SectionAuthAccountSetting,
    SectionAuthSystemDetails,
    SectionAuthTravel,
    SectionAuthTravelAnalytics,
    SectionAuthTravelSchedule,
    SectionAuthConferenceReservation,
    SectionAuthEmployeeMasterlist,
    SectionAuthEmployeeLeaveReport,
    SectionAuthOvertime,
    SectionAuthOfficialBusiness,
    SectionShiftingSchedule,
    SectionDemoChart,
  },
  setup() {
    onMounted(() => {
      if (store.state.getUserType === "assurance-moderator") {
        isActive.value = "SectionAuthPaymentPreparation";
        store.state.authTitlePage = "Checking Audit";
      } else {
        isActive.value = "SectionAuthDashboard";
        store.state.authTitlePage = "Dashboard";
      }
    });
    const store = inject("$store");
    const isActive = ref(null);
    const getComponent = (value) => {
      isActive.value = value;
    };
    const activeTab = computed(() => {
      return isActive.value;
    });
    return {
      getComponent,
      activeTab,
      isActive,
    };
  },
};
</script>
<style scoped>
.auth-wrapper {
  display: grid;
  grid-template-columns: 1.4fr 4.2fr;
  gap: 30px;
  padding: 40px 0px 40px 0px;
}

@media only screen and (max-width: 600px) {
  .auth-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>

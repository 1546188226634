<template>
  <div class="auth-section">
    <h3>Basic Information</h3>
    <div class="section-container">
      <table>
        <tr class="flex">
          <td class="title">Fullname:</td>
          <td class="description">{{ employeeProfileDetails.name }}</td>
        </tr>
        <tr class="flex">
          <td class="title">Birthdate:</td>
          <td class="description">
            {{ dateFormat(employeeProfileDetails.birthDate) }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Gender</td>
          <td class="description">
            {{ employeeProfileDetails.gender === "M" ? "Male" : "Female" }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Civil Status:</td>
          <td class="description">-</td>
        </tr>
        <tr class="flex">
          <td class="title">Home Address:</td>
          <td class="description">-</td>
        </tr>
      </table>
    </div>
    <h3>Contact Information</h3>
    <div class="section-container">
      <table>
        <tr class="flex">
          <td class="title">Mobile Number:</td>
          <td class="description">
            {{
              employeeProfileDetails.mobile
                ? `0${employeeProfileDetails.mobile}`
                : "-"
            }}
          </td>
        </tr>
        <tr class="flex">
          <td class="title">Email Address:</td>
          <td class="description">{{ employeeProfileDetails.email }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { inject } from "vue";
export default {
  props: {
    employeeProfileDetails: Object,
  },
  setup() {
    const moment = inject("$moment");

    const dateFormat = (val) => {
      return val ? moment(val).format("LL") : "-";
    };
    return {
      dateFormat,
    };
  },
};
</script>
<style scoped>
.auth-section {
  padding-top: 40px;
}
.section-container {
  margin: 30px 40px;
}
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 15.1px;
}
.title {
  min-width: 180px;
  font-weight: 600;
  color: #464545;
}
.description {
  font-weight: 500;
  color: #5c5c5c;
}
.flex {
  display: flex;
  margin: 10px 0px;
}
h3 {
  font-family: "Montserrat";
  font-weight: 800;
  color: #3e535f;
}

@media only screen and (max-width: 600px) {
  .auth-section {
    padding-top: 30px;
  }
  .section-container {
    margin: 16px 10px;
  }
  table {
    font-size: 11.5px;
  }
  .title {
    min-width: 130px;
  }
  .flex {
    display: flex;
    margin: 10px 0px;
  }
  h3 {
    font-size: 15px;
  }
}
</style>

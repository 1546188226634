<template>
  <div class="card-container">
    <app-form-header></app-form-header>
    <form class="form-container" @submit.prevent="handleSubmit">
      <div class="form1">
        <div class="form-group">
          <label for="">
            Transaction Type: <span class="required">*</span>
          </label>
          <div class="input">
            <select name="obType" v-model="obType" @change="OBDuration">
              <option
                v-for="(types, index) in obTypes"
                :key="index"
                :value="types.code"
              >
                {{ types.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" v-show="!ifNewOB">
          <div class="checkbox-container">
            <label for=""> With Travel Request: </label>
            <div class="input">
              <input
                type="checkbox"
                v-model="ifWithTravelRef"
                @click="OBDuration((ifWithTravelRef = !ifWithTravelRef))"
              />
            </div>
          </div>
        </div>

        <div class="form-group" v-if="ifWithTravelRef">
          <label for="">
            Search Travel Request Ref. Number:
            <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="text"
              v-model="formData.travelRefNo"
              placeholder="Search Travel Request reference number"
              @input="searchTravelReferenceNumber()"
            />
          </div>
          <div
            class="obref-container"
            v-if="
              formData.travelRefNo && travelreferenceItemList.length
                ? true
                : false
            "
          >
            <ul>
              <li
                v-for="(reference, index) in travelreferenceItemList.slice(
                  0,
                  10
                )"
                :key="index"
                @click="selectedTravelReference(reference.id + 100000, index)"
              >
                {{ reference.id + 100000 }}
              </li>
            </ul>
          </div>
        </div>

        <div class="form-group" v-show="ifNewOB">
          <label for="">
            Search Reference Number: <span class="required">*</span>
          </label>
          <div class="input">
            <input
              type="text"
              v-model="formData.referenceNumber"
              placeholder="Search reference number"
              @input="searchReferenceNumber()"
            />
          </div>
          <div
            class="obref-container"
            v-if="
              formData.referenceNumber && obreferenceItemList.length
                ? true
                : false
            "
          >
            <ul>
              <li
                v-for="(reference, index) in obreferenceItemList.slice(0, 10)"
                :key="index"
                @click="selectedReference(reference.id + 100000, index)"
              >
                {{ reference.id + 100000 }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for=""> Date of OB: <span class="required">*</span> </label>
          <div class="input">
            <input
              type="date"
              v-model="formData.obDate"
              :min="trfDateIntended || pastDates"
              :disabled="ifNewOB"
            />
          </div>
        </div>
        <div class="form-group">
          <label for=""> Depart From: <span class="required">*</span> </label>
          <div class="input">
            <input type="text" v-model="formData.departure" />
          </div>
        </div>
        <div class="form-group">
          <label for="">
            Departure Time: <span class="required">*</span>
          </label>
          <div class="input">
            <input type="time" v-model="formData.obTime" />
          </div>
        </div>
        <div class="form-group" v-show="ifNewOB">
          <label for="">
            Time of Return: <span class="required">*</span>
          </label>
          <div class="input">
            <input type="time" v-model="formData.returnTime" step="any" />
          </div>
        </div>
        <div class="form-group" v-if="!ifWithTravelRef">
          <label for="">
            Location / Area: <span class="required">*</span>
          </label>
          <div class="input">
            <GMapAutocomplete
              :select-first-on-enter="true"
              @place_changed="setPlace"
              :value="places"
              :v-model="formData.destination"
              :options="{
                componentRestrictions: { country: 'PH' },
                strictBounds: true,
                types: ['establishment'],
              }"
              @keypress.enter="$event.preventDefault()"
            >
            </GMapAutocomplete>
          </div>
        </div>
        <div class="form-group" v-if="!ifWithTravelRef">
          <label for=""> Purpose: <span class="required">*</span> </label>
          <div class="input">
            <select v-model="formData.purpose">
              <option v-for="item in obPurposeItem" :key="item.id">
                {{ item.description }}
              </option>
            </select>
          </div>
        </div>

        <div class="input btn-add" v-if="!ifWithTravelRef">
          <img
            @click="insertDataTable()"
            class="image-button"
            src="../../assets/images/add_.png"
            alt="icon"
          />
        </div>

        <div class="dataTable">
          <table>
            <thead>
              <tr>
                <th scope="col">Location / Area</th>
                <th scope="col">Purpose</th>
                <th scope="col" v-if="!ifWithTravelRef">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in obDetails" :key="index">
                <td data-label="Date" style="width: 100px">
                  {{ item.destination }}
                </td>
                <td data-label="Action" style="width: 500px">
                  {{ item.purpose }}
                </td>
                <td
                  data-label="Action"
                  @click="removeItems(index)"
                  style="cursor: pointer; width: 40px"
                >
                  <img
                    class="image-button"
                    src="../../assets/images/deleted_.png"
                    alt="icon"
                    v-if="!ifWithTravelRef"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form2">
        <div class="form-group">
          <label for="">
            Description / Remarks: <span class="required">*</span>
          </label>
          <div class="input">
            <textarea rows="5" v-model="formData.remarks" :disabled="ifNewOB" />
          </div>
        </div>
        <div class="form-group">
          <button class="submit" :disabled="buttonMessageVisible">
            {{ !buttonMessageVisible ? "Submit" : "Please wait..." }}
          </button>
        </div>
        <div v-show="isAlert">
          <div v-show="isStatus" class="alert-error form-alert">
            {{ $store.state.formErrorMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { inject } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import dtrAttendance from "@/components/utils/reusables/attendanceDTR";
export default {
  setup(props, { emit }) {
    const { getEmployeeDTR, dtrTimeOut } = dtrAttendance();
    const isAlert = ref(false);
    const isStatus = ref(false);
    const pastDates = ref(null);
    const ifNewOB = ref(false);
    const ifOBCompletion = ref(false);
    const ifWithTravelRef = ref(false);
    const obReferenceList = ref([]);
    const obreferenceItemList = ref([]);
    const travelreferenceItemList = ref([]);
    const obDetails = ref([]);
    const travelDetails = ref([]);
    const obPurposeItem = ref([]);
    const splicedItem = ref([]);
    const store = inject("$store");
    const obType = ref("FNO");
    const obTypes = reactive([
      { name: "File New OB", code: "FNO" },
      { name: "OB Completion", code: "OC" },
    ]);
    const formData = reactive({
      referenceNumber: null,
      travelRefNo: null,
      empId: store.state.getProfileInfo.id,
      employeeno: store.state.getProfileInfo.employee_number,
      department: store.state.getDepartment,
      designation: store.state.getDesignation,
      isDirect: 1,
      obDate: null,
      obTime: null,
      departure: null,
      remarks: null,
      returnTime: null,
      requestedBy: store.state.getUserLog,
    });
    const places = ref(null);
    const address = ref(null);
    const dataItems = ref([]);
    const trfDateIntended = ref(null);
    const buttonMessageVisible = ref(false);
    onMounted(() => {
      store.commit("globalDate");
      pastDates.value = store.state.currentDate;
      getOBReferenceNumber();
      getOBPurposes();
      getTravelRef();
    });

    const handleSubmit = async () => {
      handleValidation(formData);
    };

    const insertFormData = async () => {
      try {
        const response = await axios.get("/api/form/dept");
        let departmentList = response.data;
        let checkIfHead = departmentList.filter((item) => {
          return item.headName === store.state.getUserLog;
        });
        formData.isDirect = checkIfHead.length ? 1 : 0;

        if (obType.value === "FNO") {
          const result = await axios.post(`/api/form/ob`, formData);
          isAlert.value = false;
          const obRequestId = result.data.data;
          bulkInsert(obRequestId);
          const SEQUENCE_ARRAY_CODE = 2;
          store.commit("successAlert", SEQUENCE_ARRAY_CODE);
          clearFormField(true);
        } else if (obType.value === "OC") {
          let id = formData.referenceNumber - 100000;
          await axios.put(`/api/form/ob/${id}`, {
            returnTime: formData.returnTime,
          });
          isAlert.value = false;
          const obRequestId = formData.referenceNumber - 100000;
          bulkInsert(obRequestId);
          const SEQUENCE_ARRAY_CODE = 2;
          store.commit("successAlert", SEQUENCE_ARRAY_CODE);
          clearFormField(true);
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const bulkInsert = async (reqId) => {
      try {
        if (obType.value === "FNO") {
          const modified = obDetails.value.map((val, index) => {
            if (index >= 0) {
              val.obRequestId = reqId;
            }
            return val;
          });
          await axios.post(`/api/form/obItemBulk`, modified);
        } else if (obType.value === "OC") {
          const modified = obDetails.value.filter((ob) => {
            return ob.forIns == 0;
          });
          await axios.post(`/api/form/obItemBulk`, modified);
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const clearFormField = (value) => {
      if (value) {
        setTimeout(function () {
          formData.obType = "FNO";
          formData.obDate = null;
          formData.referenceNumber = null;
          formData.travelRefNo = null;
          formData.obTime = null;
          formData.departure = null;
          formData.remarks = null;
          formData.returnTime = null;
          formData.requestedBy = store.state.getUserLog;
          formData.isDirect = 1;
          obDetails.value.splice(0, obDetails.value.length);
          buttonMessageVisible.value = false;
        }, 1100);
      }
    };

    const handleValidation = (formData) => {
      const validate = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (obType.value === "FNO") {
          if (
            key !== "referenceNumber" &&
            key !== "travelRefNo" &&
            key !== "returnTime" &&
            key !== "purpose" &&
            key !== "destination"
          ) {
            if (!value) {
              validate.push(key);
            }
          }
        } else if (obType.value === "OC") {
          if (
            key !== "destination" &&
            key !== "purpose" &&
            key !== "travelRefNo"
          ) {
            if (!value) {
              validate.push(key);
            }
          }
        }
      });

      if (validate.length) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `All Fields Required (*)`;
      } else if (obDetails.value.length == 0) {
        isAlert.value = true;
        isStatus.value = true;
        store.state.formErrorMessage = `Please input Location / Area`;
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to submit this request.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((result) => {
          if (result.isConfirmed) {
            buttonMessageVisible.value = true;
            insertFormData();
            bulkRemove();
            emit("isLoaded");
          }
        });
      }
    };

    const OBDuration = () => {
      let selectedOPType = obType.value;

      if (selectedOPType === "FNO") {
        ifNewOB.value = false;
        ifOBCompletion.value = false;

        formData.remarks = "";
        formData.departure = "";
        formData.obTime = "";
        formData.obDate = "";
        formData.referenceNumber = "";
        obDetails.value = [];
      } else {
        ifOBCompletion.value = true;
        ifNewOB.value = true;
      }

      if (selectedOPType === "OC") {
        ifWithTravelRef.value = false;
      }
    };

    const getOBReferenceNumber = async () => {
      try {
        const { data } = await axios.get("/api/obreference");
        obReferenceList.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const searchReferenceNumber = () => {
      obreferenceItemList.value = obReferenceList.value.filter((item) => {
        let convertString = 100000 + item.id;
        return convertString
          .toString()
          .includes(formData.referenceNumber.trim());
      });
    };

    const selectedReference = (referenceNumber, index) => {
      let itemDetails = obreferenceItemList.value[index];
      dataItems.value = itemDetails;
      formData.referenceNumber = referenceNumber;

      // ot table

      formData.obDate = itemDetails.obDate;
      formData.obTime = itemDetails.obTime;
      formData.departure = itemDetails.departure;
      formData.remarks = itemDetails.remarks;

      obreferenceItemList.value = [];
      getOBRequest();
      getEmployeeDTR(dataItems.value, formData.dateEnd);
      formData.returnTime = dtrTimeOut;
    };

    const updateOBDetails = async () => {
      let id = formData.referenceNumber - 100000;
      await axios.put(`/api/form/obRequest/${id}`, {
        isActive: 0,
      });
      isAlert.value = false;
      const SEQUENCE_ARRAY_CODE = 2;
      store.commit("successAlert", SEQUENCE_ARRAY_CODE);
      clearFormField(true);
    };

    const insertDataTable = async () => {
      if (formData.destination && formData.purpose) {
        obDetails.value.push({
          destination: formData.destination,
          purpose: formData.purpose,
          obRequestId: formData.referenceNumber - 100000,
          forIns: 0,
        });

        formData.destination = null;
        formData.purpose = null;
        places.value = null;
        address.value = null;
      }
    };
    const removeItems = (index) => {
      try {
        const arrayLength = obDetails.value.length;
        if (arrayLength >= 1) {
          if (obType.value === "OC") {
            const item = obDetails.value.splice(index, 1)[0];
            splicedItem.value.push(item.id);
          } else {
            obDetails.value.splice(index, 1)[0];
          }
        }
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getOBPurposes = async () => {
      try {
        const { data } = await axios.get("/api/travel/purpose/");
        obPurposeItem.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getOBRequest = async () => {
      try {
        const reqID = formData.referenceNumber - 100000;
        const { data } = await axios.get(`/api/form/obRequest/${reqID}`);
        obDetails.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const getTravelRef = async () => {
      try {
        const { data } = await axios.get("/api/travelreference");
        travelDetails.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const searchTravelReferenceNumber = () => {
      travelreferenceItemList.value = travelDetails.value.filter((item) => {
        let convertString = 100000 + item.id;
        return convertString.toString().includes(formData.travelRefNo.trim());
      });
    };

    const selectedTravelReference = (referenceNumber, index) => {
      let itemDetails = travelreferenceItemList.value[index];
      formData.travelRefNo = referenceNumber;

      // travelRequests table

      formData.obDate = itemDetails.dateIntended;
      formData.obTime = itemDetails.timeIntended;
      formData.remarks = itemDetails.remarks;
      trfDateIntended.value = itemDetails.dateIntended;

      travelreferenceItemList.value = [];
      getTravelItemRequest();
    };

    const getTravelItemRequest = async () => {
      try {
        const reqID = formData.travelRefNo - 100000;
        const { data } = await axios.get(`/api/form/TravelRequest/${reqID}`);
        obDetails.value = data;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    const bulkRemove = async () => {
      const arrayLength = splicedItem.value.length;
      if (arrayLength) {
        for (let i = 0; i < arrayLength; i++) {
          let obId = splicedItem.value[i];
          await axios.put(`/api/form/obRequest/${obId}`, {
            isActive: 0,
          });
        }
      }
    };

    const setPlace = (place) => {
      formData.destination = place.name;
      places.value = `${place.name}`;
      address.value = place.formatted_address;
    };

    return {
      formData,
      insertFormData,
      isStatus,
      isAlert,
      clearFormField,
      handleValidation,
      handleSubmit,
      pastDates,
      obTypes,
      obType,
      OBDuration,
      ifNewOB,
      ifOBCompletion,
      getOBReferenceNumber,
      obReferenceList,
      searchReferenceNumber,
      obreferenceItemList,
      selectedReference,
      insertDataTable,
      obDetails,
      removeItems,
      getOBPurposes,
      obPurposeItem,
      bulkInsert,
      getOBRequest,
      updateOBDetails,
      getTravelRef,
      travelDetails,
      searchTravelReferenceNumber,
      travelreferenceItemList,
      selectedTravelReference,
      getTravelItemRequest,
      ifWithTravelRef,
      splicedItem,
      bulkRemove,
      setPlace,
      address,
      places,
      getEmployeeDTR,
      dtrTimeOut,
      dataItems,
      trfDateIntended,
      buttonMessageVisible,
    };
  },
};
</script>

<style scoped>
.obref-container {
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  max-width: auto;
  box-shadow: 0 1px 5px rgb(0 0 0 / 0.1);
  color: var(--lightFont);
  cursor: pointer;
}
.obref-container ul {
  list-style: none;
  line-height: 30px;
}
.obref-container ul li:hover {
  background-color: #e0dede;
}

.btn-add {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 20px 0px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.checkbox-container {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;
}

.checkbox-container label {
  margin-right: 14px;
}
.checkbox-container .input input {
  margin-top: 6px;
  cursor: pointer;
  width: 25px;
  text-align: center;
}
</style>

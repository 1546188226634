<template>
  <div class="grid-content">
    <div
      class="col-status-one"
      :class="[transaction.status === 'Revert' ? 'decline' : '']"
    >
      <img
        v-if="transaction.status === 'Revert'"
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-two"
      :class="[
        transaction.checkedBy
          ? transaction.status === 'Revert'
            ? 'decline'
            : ''
          : transaction.status === 'Disapproved'
          ? 'decline'
          : 'pending',
      ]"
    >
      <img
        v-if="
          (transaction.status === 'Disapproved' ||
            transaction.status === 'Revert') &&
          !transaction.checkedBy
        "
        src="../../../assets/images/success.png"
        alt="icon"
      />
      <img
        v-else-if="
          (transaction.status === 'Disapproved' ||
            transaction.status === 'Revert') &&
          !transaction.approverBy
        "
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-three"
      :class="[
        transaction.status === 'Checked'
          ? 'pending'
          : (transaction.status === 'Disapproved' ||
              transaction.status === 'Cancelled') &&
            transaction.checkedBy
          ? 'decline'
          : transaction.status === 'Approved' ||
            transaction.status === 'Completed' ||
            transaction.status === 'Transferred'
          ? ''
          : 'pending',
      ]"
    >
      <img
        v-if="
          (transaction.status === 'Disapproved' ||
            transaction.status === 'Cancelled') &&
          transaction.checkedBy
        "
        src="../../../assets/images/disapproved.png"
        alt="icon"
      />
      <img v-else src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div
      class="col-status-four"
      :class="[transaction.status === 'Completed' ? '' : 'pending']"
    >
      <img src="../../../assets/images/success.png" alt="icon" />
    </div>
    <div class="icons">Request Placed</div>
    <div class="icons">Head</div>
    <div class="icons">Approved</div>
    <div class="icons">Completed</div>
  </div>
</template>
<script>
export default {
  props: {
    transaction: Object,
  },
  setup() {
    return {};
  },
};
</script>
<style scoped>
.grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 40px 30px 0px 30px;
}
.col-status-one,
.col-status-two,
.col-status-three,
.col-status-four {
  background-color: green;
  height: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-four {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img,
.col-status-four img {
  width: 38px;
  height: 38px;
}
.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 40px 0px 0px 0px;
  }
  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four {
    background-color: green;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-status-one {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .col-status-four {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img {
    max-width: 28px;
    max-height: 28px;
  }
  .pending img {
    filter: grayscale(100%);
  }
  .pending {
    background-color: rgb(148, 148, 150);
  }
  .decline {
    background-color: rgb(243, 78, 36);
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 40px 0px 0px 0px;
  }
  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four {
    background-color: green;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-status-one {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .col-status-four {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img {
    max-width: 28px;
    max-height: 28px;
  }
  .pending img {
    filter: grayscale(100%);
  }
  .pending {
    background-color: rgb(148, 148, 150);
  }
  .decline {
    background-color: rgb(243, 78, 36);
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90px;
    font-size: 12px;
  }
}
</style>

<template>
  <div class="modal-container">
    <div class="modal animate__animated animate__slideInDown">
      <div class="header-card">
        <div class="title">
          <h1>Destination Details</h1>
        </div>
        <div class="route" @click="goRoute(false)">
          <closeIconSVG />
        </div>
      </div>
      <div class="auth-grid">
        <div class="dataTable">
          <table>
            <thead>
              <tr>
                <th scope="col">Purpose</th>
                <th scope="col">Destination</th>
                <th scope="col">Address</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in itemModified" :key="index">
                <td data-label="Purpose">
                  {{ item.purpose }}
                </td>
                <td data-label="Destination">
                  {{ item.destination }}
                </td>
                <td data-label="Address">{{ item.address }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
import { computed } from "vue";
export default {
  components: {
    closeIconSVG,
  },
  props: {
    titleModal: String,
    destinationDetails: Object,
  },
  setup(props, { emit }) {
    const goRoute = (val) => {
      emit("passClose", val);
    };
    const itemModified = computed(() => {
      let item = props.destinationDetails;
      return item.filter((val) => {
        if (typeof val.isActive === "undefined") {
          return val;
        } else {
          return val.isActive;
        }
      });
    });
    return {
      goRoute,
      itemModified,
    };
  },
};
</script>

<style scoped>
.modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  font-family: "Montserrat";
  background-color: #fff;
  width: 800px;
  max-width: 100%;
  padding: 30px 50px;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow-y: initial !important;
  overflow-y: scroll;
}
.modal h1 {
  font-size: 18px;
  margin: 0px;
  padding-bottom: 15px;
  color: var(--lightFont);
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 40px 0px 20px 0px;
}
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}

.route {
  cursor: pointer;
}
.card {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: auto;
  font-family: "Montserrat";
  font-size: 14px;
}
.card .description,
.card .amount {
  font-size: 16px;
}
.title {
  padding-bottom: 15px;
}
.auth-grid {
  height: 400px;
  overflow-y: auto;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    background-color: #ffffff;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 210px) and (max-width: 500px) {
  .modal-container {
    min-width: 200px;
  }
  .modal {
    width: 300px;
    max-width: 100%;
    padding: 30px;
  }
  table caption {
    font-size: 3.5em;
    margin: 0.5em 0 0.75em;
  }
}
</style>

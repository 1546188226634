<template>
  <div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <SectionTravelStatus
      v-else
      :transaction="transactions"
    ></SectionTravelStatus>
    <div class="header-form">Request for Travel Details</div>
    <div v-if="isRender" class="waiting">
      <img :src="require(`@/assets/images/waiting.gif`)" alt="icon" />
    </div>
    <div v-else class="grid-container">
      <table>
        <tr>
          <td class="title">Reference Number:</td>
          <td class="description">
            {{ $store.state.travelReferenceNumber }}
          </td>
        </tr>
        <tr>
          <td class="title">Date Intended and Time:</td>
          <td class="description">
            {{ dateFormat(transactions.dateIntended, "date") }}
            {{ dateFormat(transactions.timeIntended, "time") }}
          </td>
        </tr>
        <tr>
          <td class="title">Purpose & Destination:</td>
          <td class="description">
            {{ travelDestination }}
            <span
              @click="isModalTravelDetails = true"
              v-if="isMultiple"
              style="
                float: right;
                padding-right: 10px;
                color: blue;
                cursor: pointer;
              "
              >See all</span
            >
            <div v-if="isModalTravelDetails">
              <ModalDestinationDetails
                @passClose="isModalTravelDetails = false"
                :destinationDetails="transactions.travelItemDetails"
              >
              </ModalDestinationDetails>
            </div>
          </td>
        </tr>
        <tr>
          <td class="title">Passenger:</td>
          <td class="description">
            {{ transactions.passenger }}
          </td>
        </tr>
        <tr>
          <td class="title">Requested by:</td>
          <td class="description">
            {{ transactions.requestedBy }}
          </td>
        </tr>
        <tr>
          <td class="title">
            {{
              transactions.status === "Revert"
                ? "Cancelled Remarks:"
                : "Approver Remarks"
            }}
          </td>
          <td class="description">
            {{ transactions.statusRemarks ? transactions.statusRemarks : "-" }}
          </td>
        </tr>
        <tr>
          <td class="title">Assigned Driver:</td>
          <td class="description">
            {{ transactions.driver ? transactions.driver : "-" }}
          </td>
        </tr>
        <tr>
          <td class="title">Assigned Car:</td>
          <td class="description">
            {{ transactions.vehicle ? transactions.vehicle : "-" }}
          </td>
        </tr>
        <tr v-show="transactions.status === 'Checked' || !transactions.status">
          <td class="title">Cancel Request:</td>
          <td class="description">
            <label class="toggle">
              <input
                type="checkbox"
                id="switch"
                v-model="isToggle"
                @change="toggleHandler"
              />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
        <div v-if="isToggle">
          <app-modal-travel-approval
            :requestId="transactions.id"
            :status="transactions.status"
            :dateIntended="transactions.dateIntended"
            :timeIntended="transactions.timeIntended"
            userType="Employee"
            @passClose="getValue"
          ></app-modal-travel-approval>
        </div>
      </table>
    </div>
  </div>
</template>
<script>
import SectionTravelStatus from "@/components/partials/travel/AuthTravelStatusTracker.vue";
import ModalDestinationDetails from "@/modals/destinationDetailsModal.vue";
import { inject, onMounted, ref } from "vue";
export default {
  components: {
    SectionTravelStatus,
    ModalDestinationDetails,
  },
  setup() {
    const store = inject("$store");
    const axios = inject("$axios");
    const moment = inject("$moment");
    const transactions = ref([]);
    const travelDestination = ref(null);
    const isModalTravelDetails = ref(false);
    const isMultiple = ref(false);
    const isToggle = ref(false);
    const isRender = ref(true);
    onMounted(() => {
      getData();
    });
    const getData = async () => {
      try {
        const travelRequestId = store.state.applicationId;
        const response = await axios.get(
          `/api/admin/travel/request/transaction/${travelRequestId}`
        );
        transactions.value = response.data[0];
        travelDestination.value = `${response.data[0].travelItemDetails[0].purpose} - (${response.data[0].travelItemDetails[0].destination})`;
        isMultiple.value =
          response.data[0].travelItemDetails.length > 1 ? true : false;
        store.commit("travelReference", transactions.value);
        isRender.value = false;
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };
    const dateFormat = (val, stat) => {
      if (stat === "date") {
        return val ? moment(val).format("ll") : "-";
      } else {
        return val;
      }
    };
    const getValue = (val, payload) => {
      isToggle.value = val;
      if (payload) {
        setTimeout(async () => {
          getData();
        }, 5000);
      }
    };
    const toggleHandler = () => {};
    return {
      getData,
      transactions,
      dateFormat,
      isModalTravelDetails,
      travelDestination,
      isMultiple,
      isToggle,
      toggleHandler,
      getValue,
      isRender,
    };
  },
};
</script>
<style scoped>
table {
  font-family: "Montserrat";
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  font-size: 16px;
}
table > tr {
  height: 30px;
}
table > tr .title,
table > tr .description {
  word-break: break-all;
  padding-left: 10px;
}
.title {
  width: 230px;
  font-family: "Montserrat";
  font-weight: 800;
  color: #474646;
}
.description {
  max-width: 100%;
  color: #272626;
}
.grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 40px 0px 20px 0px;
}
.col-status-one,
.col-status-two,
.col-status-three,
.col-status-four {
  background-color: green;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-status-one {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.col-status-four {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.col-status-one img,
.col-status-two img,
.col-status-three img,
.col-status-four img {
  width: 50px;
  height: 50px;
}

.pending img {
  filter: grayscale(100%);
}
.pending {
  background-color: rgb(148, 148, 150);
}
.decline {
  background-color: rgb(243, 78, 36);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 13px;
}
.header-form {
  padding-bottom: 30px;
  color: var(--lightFont);
  font-size: 18px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}

@media only screen and (min-width: 410px) and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 14px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 150px;
    font-weight: 500;
    color: #272626;
  }
}
@media only screen and (max-width: 600px) {
  table {
    max-width: 100%;
    font-size: 12px;
  }
  table > tr {
    height: 30px;
  }
  table > tr .title,
  table > tr .description {
    word-break: break-all;
    padding-left: 10px;
  }
  .title {
    width: 160px;
    font-weight: 500;
    color: #272626;
  }
  .grid-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0px 30px 0px 30px;
  }

  .col-status-one,
  .col-status-two,
  .col-status-three,
  .col-status-four {
    height: 10px;
  }
  .col-status-one img,
  .col-status-two img,
  .col-status-three img,
  .col-status-four img {
    width: 34px;
    height: 34px;
  }
  .icons {
    height: 70px;
    font-size: 12px;
  }
  .header-form {
    padding-bottom: 30px;
    font-size: 16px;
  }
}
/* Extra Small Devices, Phones */
</style>

<template>
  <div class="header-card">
    <div class="title">{{ titleForm }}</div>
    <div class="route" @click="goRoute(false)">
      <closeIconSVG />
    </div>
  </div>
  <hr />
</template>
<script>
import closeIconSVG from "@/assets/svgs/closeIcon.vue";
import { inject, computed } from "vue";
export default {
  components: {
    closeIconSVG,
  },
  setup(props, { emit }) {
    const store = inject("$store");
    const titleForm = computed(() => store.getters.titleForm);
    const goRoute = (val) => {
      store.state.isVisible = val;
      emit("passLoad", val);

      store.state.getNotifCounter =
        titleForm.value === "Announcement" ? 0 : store.state.getNotifCounter;
    };

    return {
      goRoute,
      titleForm,
    };
  },
};
</script>
<style scoped>
.header-card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 24px 0px;
}
.title {
  color: var(--lightFont);
  font-size: 28px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 900;
}
hr {
  border: 0.8px solid rgba(100, 100, 100, 0.1);
}
.route {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 28px;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 300px) {
  .title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 480px) {
  .title {
    font-size: 24px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .title {
    font-size: 28px;
  }
}
</style>
